import { action } from 'mobx';
import _get from 'lodash/get';
import { handlerTokenExpired, authHeader } from 'helpers/serviceHelpers';
import { axiosInstace } from 'service';

export class Lessons {
  @action
  lessonsAll = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/progress/getTrailCards/", {}, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  conquestsAll = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get("/content/trophy/findAll/", requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  conquestsUserAll = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get("/progress/trophies/", requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  detailsLesson = async (id) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get(`/content/trail/${id}/`, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  detailsListLesson = async (body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/progress/getLessonCards/", body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  lessonProgress = async (id) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get(`/progress/trail/${id}/`, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  lessonById = async (id) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get(`/content/trail/${id}/`, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  lessonByProgress = async (id, identifier) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get(`/progress/trail/${id}/${identifier}/`, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  lessonRegisterProgress = async (id, identifier, body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post(`/progress/${id}/register/${identifier}/`, body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  challengeDetails = async (body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/progress/getChallengePhaseCards/", body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  challengeByProgress = async (id, identifierChallenge, identifier) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get(`/progress/challenge/${id}/${identifierChallenge}/${identifier}/`, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  tutorialById = async (body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("content/trail/tutorial/findByTicker/", body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };
};