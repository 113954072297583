import { useState } from 'react';
import cx from 'classnames';
import Header from '../Header';
import IntroductionChallenge from './components/IntroductionChallenge';
import Challenges from './components/Challenges';
import { ROUTES } from 'routes/routes';

const TrailChallengeForm = ({ doWorkChallenge, handleMyArea, challengesTotal, challengeData, challengeIntro }) => {
  const [componentRender, setComponentRender] = useState("IntroductionChallenge");

  const handleNextChallenge = (component) => {
    setComponentRender(component);
  };

  const renderChallengeLesson = () => {
    switch (componentRender) {
      case "IntroductionChallenge":
        return (
          <IntroductionChallenge
            challengeIntro={challengeIntro}
            changeNext={handleNextChallenge}
            handleMyArea={handleMyArea}
          />
        );
      case "Challenges":
        return (
          <Challenges
            challengeData={challengeData}
            percentage={challengesTotal?.percentage}
            challengesTotal={challengesTotal}
            doWorkChallenge={doWorkChallenge}
            handleMyArea={handleMyArea}
          />
        );
    }
  }

  return (
    <div className="mt-24">
      <Header route={ROUTES.LESSON_DETAIL} />
      <hr className="divider-internal mt-24" />
      <div className="container">
        <div className="content">
          {renderChallengeLesson()}
        </div>
      </div>
    </div>
  );
};

export default TrailChallengeForm;