import { Form, Input, Button } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { expired, logoColor } from 'assets';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import UserMessage from '../UserMessage';
import styles from './NewPasswordForm.module.less';

const NewPasswordForm = ({ passwordNew, setLoading, token, loading }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value) {
        if (getFieldValue("password") !== value) {
          return Promise.reject(message.confirm_password)
        }
      };

      return Promise.resolve()
    },
  })

  const handleSendPassword = async (params) => {
    delete params.confirmPassword;
    await passwordNew(params.password);
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className={cx("mt-24 mb-24", { "flex flex-row justify-between item-center": isMobile, "flex justify-center item-center": !isMobile })}>
            {(isMobile || isTablet) ? (
              <>
                <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
                  <FiArrowLeft className="i-24" />
                </button>
                <div className="flex justify-center item-center w-100">
                  <img src={logoColor} alt="logo-new-password" />
                </div>
              </>
            ) : (
              <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
                <img src={logoColor} alt="logo-recover" />
              </button>
            )}
          </div>
        </div>
      </div>
      <hr className={cx("divider", styles.dividerTop)} />
      <div className="container">
        <div className="content">
          {token ? (
            <>
              <div className={cx("flex flex-column justify-center item-center", styles.topTitle, { "mb-24": isMobile, "mb-40": !isMobile })}>
                <h5>Recuperação de senha</h5>
              </div>
              <div className={cx("flex flex-column justify-center item-center", styles.bottomFooter)}>
                <Form
                  name="new-password"
                  layout="vertical"
                  onFinish={handleSendPassword}
                  onFinishFailed={() => setLoading(false)}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Digite sua nova senha"
                    name="password"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        min: 6,
                        message: "A senha é obrigatória."
                      },
                      {
                        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!%;._-])[0-9a-zA-Z$*&@#!%;._-]{6,}$/,
                        message: "A senha não está seguindo os requisitos minimos",
                      }
                    ]}
                    className={cx({ "mb-12": isMobile, "mb-32": !isMobile })}
                  >
                    <Input.Password
                      placeholder="Digite sua nova senha"
                      disabled={loading ? true : false}
                      className={cx("input-base", styles.input)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirme sua nova senha"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'A confirmação da senha é obrigatória.' }, validateConfirmPassword]}
                    className="mb-0"
                  >
                    <Input.Password
                      placeholder="Repita sua nova senha"
                      disabled={loading ? true : false}
                      className={cx("input-base", styles.input)}
                    />
                  </Form.Item>
                  <div className={cx("flex flex-column mt-4 mb-12", styles.passwordInfo)}>
                    <p className="f12 mine-shaft mb-6">
                      A senha deve ter 6 ou mais caracteres, sendo pelo menos:
                    </p>
                    <ul className={styles.list}>
                      <div>
                        <li className="f12 mine-shaft">1 letra minúscula</li>
                        <li className="f12 mine-shaft">1 letra maiúscula</li>
                      </div>
                      <div>
                        <li className="f12 mine-shaft">1 número</li>
                        <li className="f12 mine-shaft">1 caractere especial</li>
                      </div>
                    </ul>
                  </div>
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                      className={styles.btnNew}
                    >
                      Continuar
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </>
          ) : (
            <UserMessage
              title={message.title_new_message}
              description={message.description_new_message}
              label={message.label_new_message}
              image={expired}
              route={ROUTES.HOME}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPasswordForm;