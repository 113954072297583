import { Modal, Button } from 'antd';
import { FiChevronRight, FiArrowRight, FiX } from 'react-icons/fi';
import cx from 'classnames';
import { arckBlack, arkcoins } from 'assets';
import styles from '../../TrailLessonForm.module.less';

const CompletedModal = ({ backDetails, viewPlan, color, lessonCompleted, imageLesson, isMobile, advertising, lesson, visible }) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={backDetails}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="flex justify-center relative">
        <div className={styles.iconBg}>
          <div className={styles.bgDynamic} style={{ background: color }}>
            <div className="flex justify-center item-center h-100">
              <img
                src={imageLesson}
                alt="complete-icon"
                className={styles.iconModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={backDetails}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center">
        <h5 className={styles.modalTitle}>Parabéns, lição <span className="b">{`${lesson}`}</span> concluída!</h5>
        <span className="f16 mine-shaft b">Você ganhou</span>
        <div className={cx("flex flex-row justify-between item-center mt-16", styles.badgeModal)}>
          <div className="flex justify-center item-center ml-24">
            <img src={arkcoins} alt="arkcoins" className={styles.iconsArkc} />
            <span className="f14 black b ml-12">Arkcoins</span>
          </div>
          <div className="mr-24">
            <span className="f24 b heliotrope">{!lessonCompleted ? `+ ${1}` : `${0}`}</span>
          </div>
        </div>
        <hr className={cx("divider", styles.dividerModal)} />
        <Button type="primary" className={cx("flex justify-center item-center", styles.btnNext)} onClick={backDetails}>
          Próxima trilha
          <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
        </Button>
        <div className={cx({ "mt-24": !isMobile, "mt-32": isMobile })}>
          {(advertising?.banner && advertising?.bannerURL ? (
            <a href={advertising?.bannerURL} target="_blank">
              <img src={advertising?.banner} className={styles.bannerAnnouncement} />
            </a>
          ) : (
            <div className={styles.bgAnnouncement}>
              <div className={cx("p-16", styles.bgImage)}>
                <div className="flex justify-end w-100">
                  <button className="arkc-btn-link flex item-center" onClick={viewPlan}>
                    <span className="f12 b white">Saiba mais</span>
                    <FiChevronRight className="i-24 white ml-8" />
                  </button>
                </div>
                <div className={styles.bgBlack}>
                  <div className="flex justify-center item-center h-100">
                    <img src={arckBlack} />
                  </div>
                </div>
                <p className={cx("f18 white mt-16", styles.descriptionAnnouncement)}>
                  Aprenda sobre os melhores investimentos com o <span className="b">Arkc Black</span>.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CompletedModal;