import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import Loading from 'components/Loading';
import WhereInvestForm from 'components/WhereInvestForm';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { message } from 'helpers/messages';

const WhereInvest = (props) => {
  const [loading, setLoading] = useState(false);
  const [walletId, setWalletId] = useState();
  const [inviteId, setInviteId] = useState();
  const [name, setName] = useState();
  const [actualPlan, setActualPlan] = useState();
  const [logo, setLogo] = useState();
  const [investData, setInvestData] = useState({ logo: "", name: "", invests: [] });
  const [category, setCategory] = useState();
  const { plansArkc } = useStore();
  const { investmentsType } = plansArkc;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.id && state.name && state.category && state.actualPlan && state.invite && state.logo) {
      setWalletId(state.id);
      setInviteId(state.invite);
      setName(state.name);
      setActualPlan(state.actualPlan);
      setLogo(state.logo)
      setCategory(state.category);
      retrieveTypeInvestments(state.name, state.category, state.actualPlan, state.logo);
    };
  }, []);

  const retrieveTypeInvestments = async (name, category, actualPlan, logo) => {
    setLoading(true);

    const response = await investmentsType({ category: category, identifier: actualPlan })

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setInvestData({ ...investData, logo: logo, name: name, invests: response.data });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleBackDashboard = () => {
    history.push(ROUTES.DASHBOARD_WALLET, { id: walletId, invite: inviteId, });
  };

  const handleViewHowBuy = () => {
    history.push(ROUTES.HOW_BUY, { id: walletId, name: name, category: category, actualPlan: actualPlan, invite: inviteId, logo: logo });
  };

  const handleViewTutorialBuy = (ticker) => {
    history.push(ROUTES.TUTORIAL_BUY, { ticker: ticker, id: walletId, name: name, category: category, actualPlan: actualPlan, invite: inviteId, logo: logo });
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <WhereInvestForm
          investData={investData}
          viewHowBuy={handleViewHowBuy}
          viewTutorialBuy={handleViewTutorialBuy}
          backDashboard={handleBackDashboard}
        />
      )}
    </div>
  );
};

export default observer(WhereInvest);