import { Button, Progress } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import { FaLock, FaPlay } from 'react-icons/fa';
import _map from 'lodash/map';
import _size from 'lodash/size';
import useMedia from 'use-media';
import cx from 'classnames';
import { concluded, replay } from 'assets';
import styles from '../../TrailChallengeForm.module.less';

const Challenges = ({ doWorkChallenge, handleMyArea, challengesTotal, challengeData, percentage }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  const renderButtonsStatus = (item) => {
    if (item.available && !item.completed) {
      return (
        <button className="arkc-btn-link arkc-btn-play" onClick={() => doWorkChallenge(item)}>
          <FaPlay className="i-16 white" />
        </button>
      );
    } else if (item.completed && item.available) {
      return (
        <button className="arkc-btn-link arkc-btn-replay" onClick={() => doWorkChallenge(item)}>
          <img src={replay} alt="replay" />
        </button>
      );
    } else {
      return (
        <button className="arkc-btn-link arkc-btn-blocked">
          <FaLock className="i-16 silver" />
        </button>
      );
    };
  };

  return (
    <>
      <div className={cx("flex", { "flex-column justify-center item-center mt-24 mb-24": isMobile, "flex-row justify-center item-center mt-88": !isMobile })}>
        <div className="flex flex-column justify-center item-center">
          <div className="flex flex-column justify-center item-center mb-32">
            <Progress strokeLinecap="square" type="dashboard" percent={percentage} />
            <p className="f16 mine-shaft">
              <span className="b">{challengesTotal.finished}</span> de {challengesTotal.total} para concluir
            </p>
          </div>
          <h5 className={styles.titleChallenge}>Realize todos os passos para finalizar o desafio</h5>
        </div>
        <div className={styles.spaceBloc}>
          {_map(challengeData, (item, index) => (
            <div key={index} className={cx(styles.badgeChallenge, { [styles.badgeChallengeConcluded]: item.completed, "mb-12": index !== _size(challengeData) - 1 })}>
              <div className={cx("flex flex-row justify-between item-center h-100", { "pl-16 pr-16": isMobile, "pl-24 pr-24": !isMobile })}>
                <div className="flex justify-between item-center">
                  {item.completed && (
                    <div className="relative">
                      <div className={styles.iconConcluded}>
                        <img src={concluded} alt="concluded" />
                      </div>
                    </div>
                  )}
                  <div
                    style={{ background: item.color ? item.color : "#F6E4FF" }}
                    className={cx(styles.bgPhoto, { [styles.bgPhotoBlock]: !item.available })}
                  >
                    <div className="flex justify-center item-center h-100">
                      <img
                        src={item.logo}
                        alt="icon-challenges"
                        className={cx("i-24", { "o-4": !item.available })}
                      />
                    </div>
                  </div>
                  <p className={cx("f18 mine-shaft b", styles.nameBadge)}>{item.name}</p>
                </div>
                <div>
                  {renderButtonsStatus(item)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {!isMobile && (
        <hr className={cx("divider mb-16", styles.dividerBottom)} />
      )}
      <div className={cx("flex", { "flex-column mt-12": isMobile, "flex-row justify-center item-center mb-24": !isMobile })}>
        {!isMobile && (
          <button className={cx("arkc-btn-link  flex justify-center item-center mr-24", styles.btnJump)} onClick={handleMyArea}>
            <span className="heliotrope b">Pular desafio</span>
            <FiArrowRight className="heliotrope ml-12 i-16" />
          </button>
        )}
        <Button type="primary" className={styles.btnFinish} onClick={handleMyArea}>Finalizar desafio</Button>
        {isMobile && (
          <button className={cx("arkc-btn-link  flex justify-center item-center mt-16", styles.btnJump)} onClick={handleMyArea}>
            <span className="heliotrope b">Pular desafio</span>
            <FiArrowRight className="heliotrope ml-12 i-16" />
          </button>
        )}
      </div>
    </>
  );
};

export default Challenges;