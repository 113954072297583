import { useEffect } from 'react';
import LandingPageBeginnerForm from '../../../components/LandingPageForm/LandingPageBeginnerForm';
import { arkcBeginner } from 'mock';

const LandingPageBeginner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);;
  }, []);

  const handleBuyPlan = () => {
    window.open("https://pay.kiwify.com.br/43v6Tma", "_blank");
  };

  return (
    <div>
      <LandingPageBeginnerForm
        benefits={arkcBeginner.benefits}
        planBeginner={arkcBeginner.planBeginner}
        worksBeginner={arkcBeginner.worksBeginner}
        graphicImage={arkcBeginner.graphicImage}
        price={9.90}
        buyPlan={handleBuyPlan}
      />
    </div>
  );
};

export default LandingPageBeginner;