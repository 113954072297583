import { Button } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import _map from 'lodash/map';
import cx from 'classnames';
import useMedia from 'use-media';
import AccordionArkc from '../AccordionArkc';
import { contactSupport, logoColor, supportArkc } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './SupportForm.module.less';

const SupportForm = ({ supportQuestions }) => {
  let userArkc = JSON.parse(localStorage.getItem('userArkc'));
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className={cx({ "mb-32": isMobile, "mb-64": !isMobile })}>
      <div className="container">
        <div className="content mt-24 mb-24">
          <div className="flex flex-row">
            <button className="arkc-btn-link flex flex-row justify-center item-center" onClick={() => history.push(userArkc ? ROUTES.DASHBOARD : ROUTES.HOME)}>
              <FiArrowLeft className="i-24 heliotrope" />
            </button>
            <div className="flex justify-center item-center w-100">
              <img src={logoColor} alt="logo-color" className="pointer" />
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <div className="flex flex-column justify-center item-center mt-24">
            <img src={supportArkc} alt="support-arkc" />
            {isMobile ? (
              <h2 className="mt-16 mb-8">Suporte</h2>
            ) : (
              <h4 className="mt-16 mb-8">Suporte</h4>
            )}
            <span className="f16 gray">Como podemos te ajudar?</span>
            <h5 className="mt-40 mb-24">Perguntas frequentes</h5>
            <div className={styles.gridAccordions}>
              {_map(supportQuestions, (item, index) => (
                <AccordionArkc title={item.title}>
                  <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.description}</p>
                  {item.descriptionSub && (
                    <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.descriptionSub}</p>
                  )}
                </AccordionArkc>
              ))}
            </div>
            <div className={cx({ "mt-40": isMobile, "mt-48": !isMobile })}>
              <div className={styles.badgeContact}>
                <div className={styles.badgeContactPadding}>
                  <div className={cx("flex", { "flex-column justify-center item-center": isMobile, "flex-row item-center": !isMobile })}>
                    <img src={contactSupport} alt="contact-support" />
                    <p className={cx("f18 mine-shaft b ml-32 mr-42", styles.contactDescription)}>
                      Para tirar suas dúvidas ou reportar algum problema, entre em contato nosso suporte
                    </p>
                    <a href="mailto:suporte@arkc.com.br?subject=Preciso de ajuda" className={styles.btnContact}>
                      suporte@arkc.com.br
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportForm;