import { Modal, Button } from 'antd';
import { FiX } from 'react-icons/fi';
import cx from 'classnames';
import styles from '../DeleteAccountForm.module.less';

const ModalConfirm = ({ deleteMyAccount, setVisible, isMobile, visible, loading }) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => setVisible(!visible)}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={() => setVisible(!visible)}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center mt-48">
        {isMobile ? (
          <h3 className={cx("mb-24", styles.titleModal)}>
            No Arkc temos uma equipe especializada para fornecer o melhor conteúdo educacional,
            sempre atualizado de acordo com o momento do mercado, você deseja cancelar?
          </h3>
        ) : (
          <h5 className={cx("mb-24", styles.titleModal)}>
            No Arkc temos uma equipe especializada para fornecer o melhor conteúdo educacional,
            sempre atualizado de acordo com o momento do mercado, você deseja cancelar?
          </h5>
        )}
        <div className="flex flex-row item-center">
          <Button
            type="default"
            disabled={loading ? true : false}
            className={styles.btnNo}
            onClick={() => setVisible(!visible)}
          >
            Não
          </Button>
          <Button
            type="default"
            disabled={loading ? true : false}
            className={styles.btnYes}
            onClick={deleteMyAccount}
          >
            Sim
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;