import { useEffect, useState } from 'react';
import { Button } from 'antd';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../LevelingForm.module.less';

const TakeRisks = ({ changeStep, answerQuiz, quiz, investment, text }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayButton(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [displayButton]);

  return (
    <div className="flex flex-column justify-center item-center mt-56 mb-70">
      <h1 className={cx("f24 mine-shaft mb-32 text-center", styles.lineInvesting)}>
        {text}
      </h1>
      <div className={cx("flex flex-column", { "mb-32": isMobile, "mb-64": !isMobile })}>
        {_map(investment, (item, index) => (
          <button key={index} className={cx("arkc-btn-link b", styles.bagde, {
            "mb-12": index !== _size(investment) - 1,
            [styles.bagdeSelect]: quiz.investment === item.id
          })}
            onClick={() => answerQuiz("investment", item.id)}
          >
            {item.name}
          </button>
        ))}
      </div>
      {displayButton && (
        <>
          <hr className="divider-internal" />
          <Button
            type="primary"
            disabled={quiz.investment !== "" ? false : true}
            className={cx("mt-16", styles.btnContinue)}
            onClick={() => changeStep("FinalQuestions", 100)}
          >
            Continuar
          </Button>
        </>
      )}
    </div>
  );
};

export default TakeRisks;