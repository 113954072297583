import { useEffect } from 'react';
import LandingPageTwoForm from 'components/LandingPageForm/LandingPageTwoForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { arkcTwo } from 'mock/data';
import { topicsLP } from 'mock';

const LandingPageTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStartNow = () => {
    history.push(ROUTES.LEVELING);
  };

  return (
    <div>
      <LandingPageTwoForm
        arkcTwo={arkcTwo}
        benefits={arkcTwo.benefits}
        worksBeginner={arkcTwo.worksBeginner}
        graphicImage={arkcTwo.graphicImage}
        topicsLP={topicsLP}
        supportQuestions={arkcTwo.supportQuestions}
        lessons={arkcTwo.lessons}
        startNow={handleStartNow}
      />
      <Footer />
    </div>
  );
};

export default LandingPageTwo;