import { useState } from 'react';
import { Progress, Dropdown, Menu } from 'antd';
import { FiX, FiMoreVertical } from 'react-icons/fi';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import TutorialQuestionSimple from './components/TutorialQuestionSimple';
import TutorialQuestionImage from './components/TutorialQuestionImage';
import TutorialQuestionImageItems from './components/TutorialQuestionImageItems';
import TutorialQuestionItems from './components/TutorialQuestionItems';
import TutorialCompletedModal from './components/TutorialCompletedModal';
import styles from './TutorialForm.module.less';

const TutorialForm = ({ backDetails, viewPlan, setChoiceSelected, choiceSelected, tutorial }) => {
  const [tutorialTicket, setTutorialTicket] = useState(tutorial);
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  const renderTrailQuestions = (item, index) => {
    if (tutorialTicket.actualQuestion === item.identifier && item.description && !item.image && !item.choices) {
      return (
        <TutorialQuestionSimple
          index={index}
          logo={_get(tutorialTicket, "imageLesson", "")}
          name={_get(tutorialTicket, "nameLesson", "")}
          title={_get(item, "description", "")}
          nextTrail={() => handleNextTrail(item.next)}
        />
      );
    };

    if (tutorialTicket.actualQuestion === item.identifier && item.description && item.image && !item.choices) {
      return (
        <TutorialQuestionImage
          index={index}
          logo={_get(tutorialTicket, "imageLesson", "")}
          name={_get(tutorialTicket, "nameLesson", "")}
          title={_get(item, "description", "")}
          image={_get(item, "image", "")}
          nextTrail={() => handleNextTrail(_get(item, "next", ""))}
        />
      );
    };

    if (tutorialTicket.actualQuestion === item.identifier && item.description && item.image && item.choices.length > 0) {
      return (
        <TutorialQuestionImageItems
          index={index}
          logo={_get(tutorialTicket, "imageLesson", "")}
          name={_get(tutorialTicket, "nameLesson", "")}
          title={_get(item, "description", "")}
          image={_get(item, "image", "")}
          questionsItem={_get(item, "choices", "")}
          choiceSelected={choiceSelected}
          selectOption={handleSelectOption}
          nextTrail={handleNextTrailChoices}
        />
      );
    };

    if (tutorialTicket.actualQuestion === item.identifier && item.description && item.choices.length > 0) {
      return (
        <TutorialQuestionItems
          index={index}
          logo={_get(tutorialTicket, "imageLesson", "")}
          name={_get(tutorialTicket, "nameLesson", "")}
          title={_get(item, "description", "")}
          questionsItem={_get(item, "choices", "")}
          choiceSelected={choiceSelected}
          selectOption={handleSelectOption}
          nextTrail={handleNextTrailChoices}
        />
      );
    };
  };

  const handleNextTrail = (next) => {
    const copyQuestionsTutorial = Object.assign({}, tutorialTicket);

    if (next !== "coin") {
      copyQuestionsTutorial.actualQuestion = next;

      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsTutorial.trailQuestions)).toFixed(2);
      let numberQuestions = next;

      _forEach(copyQuestionsTutorial.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "coin") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsTutorial.percentage = totalLesson;
      setTutorialTicket(copyQuestionsTutorial);
    } else {
      copyQuestionsTutorial.percentage = 100;
      setVisible(true);
    };
  };

  const handleSelectOption = (value) => {
    setChoiceSelected(value);
  };

  const handleNextTrailChoices = () => {
    const copyQuestionsTutorial = Object.assign({}, tutorialTicket);

    if (choiceSelected?.next !== "coin") {
      copyQuestionsTutorial.actualQuestion = choiceSelected.next;
      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsTutorial.trailQuestions)).toFixed(2);
      let numberQuestions = choiceSelected.next;

      _forEach(copyQuestionsTutorial.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "coin") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsTutorial.percentage = totalLesson;

      setTutorialTicket(copyQuestionsTutorial);
      setChoiceSelected();
    } else {
      copyQuestionsTutorial.percentage = 100;
      setVisible(true);
      setChoiceSelected();
    };
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={`${process.env.REACT_APP_ENVIRONMENT}/suporte-arkc`} target="_blank" rel="noopener">Reportar um erro</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="flex justify-center item-center mt-24 mb-24">
            {!isMobile && (
              <img src={logoColor} alt="logo-color" />
            )}
            {isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24" />
                </Dropdown>
              </div>
            )}
            <div className="flex justify-center item-center w-100">
              <div className={cx("flex flex-row justify-center item-center", styles.progress)}>
                <Progress percent={tutorialTicket?.percentage} showInfo={false} />
                <button className="flex arkc-btn-link ml-14 mt-2" onClick={backDetails}>
                  <FiX className="i-24" />
                </button>
              </div>
            </div>
            {!isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24" />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="divider-internal" />
      <div className={cx("container", { "mt-28": isMobile, "mt-56": !isMobile })}>
        <div className="content">
          {_map(tutorialTicket?.trailQuestions, (item, index) => {
            return (
              <div key={index}>
                {renderTrailQuestions(item, index)}
              </div>
            );
          })}
        </div>
      </div>
      <TutorialCompletedModal
        visible={visible}
        isMobile={isMobile}
        advertising={_get(tutorialTicket, "advertising", "")}
        lesson={_get(tutorialTicket, "nameLesson", "")}
        imageLesson={_get(tutorialTicket, "imageLesson", "")}
        color={_get(tutorialTicket, "color", "")}
        backDetails={backDetails}
        viewPlan={viewPlan}
      />
    </div>
  );
};

export default TutorialForm;