import { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { AiOutlineReload } from 'react-icons/ai';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import ModalWallets from '../ModalWallets';
import { blackBg, premiumBg, beginnerBg, rocketBlack, rocketPremium, rocketBeginner } from 'assets';
import styles from '../DashboardWalletForm.module.less';

const Wallets = ({ filterWallet, setVisible, walletsList, visible, walletId, distributionTotal, plan, investments }) => {
  const [isModalPieVisible, setIsModalPieVisible] = useState(false);
  const [modalPieData, setModalPieData] = useState({});
  const isMobile = useMedia({ maxWidth: 767 });
  const refGroups = useRef(null);

  const renderWallets = () => {
    const findPlan = walletsList.find(item => item.id === walletId);
    const imagesPlans = findPlan?.identifier === "arkc-beginner" ? rocketBeginner : findPlan?.identifier === "arkc-premium" ? rocketPremium : rocketBlack;
    const rocketPlans = findPlan?.identifier === "arkc-beginner" ? beginnerBg : findPlan?.identifier === "arkc-premium" ? premiumBg : blackBg;

    return (
      <div className={cx(styles.badgePlan, { [styles.badgeBeginner]: findPlan?.identifier === "arkc-beginner", [styles.badgePremium]: findPlan?.identifier === "arkc-premium", [styles.badgeBlack]: findPlan?.identifier === "arkc-black" })}>
        <div className="absolute">
          <img src={rocketPlans} />
        </div>
        <div className={cx("flex flex-row", styles.positionPlan)}>
          <div className={cx(styles.bgIconPlan, { [styles.beginnerIconPlan]: findPlan?.identifier === "arkc-beginner", [styles.premiumIconPlan]: findPlan?.identifier === "arkc-premium", [styles.blackIconPlan]: findPlan?.identifier === "arkc-black" })}>
            <img src={imagesPlans} />
          </div>
          <span className="f18 b mine-shaft ml-8">{findPlan?.name}</span>
        </div>
      </div>
    );
  };

  const handleScrollGroups = (value) => {
    refGroups.current.scrollTop += value;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className={cx("custom-tooltip", styles.customTooltip)}>
          <label className="white b">{`${payload[0].name} : ${payload[0].value / 10}%`}</label>
        </div>
      );
    }
  };

  const handleChartPieData = (data) => {
    setModalPieData(data);
    setIsModalPieVisible(true);
  };

  return (
    <div className={styles.bgWallet}>
      <div className={styles.paddingWallet}>
        <div className={styles.blocFlutTwo}>
          {isMobile ? (
            <h5 className="mb-14">Distribuição ideal</h5>
          ) : (
            <h5 className="mt-16 mb-14">Distribuição ideal</h5>
          )}
          <div className="flex justify-center item-center w-100">
            <div className={styles.bgProgress}>
              <div className="relative">
                <div className={styles.percentagePosition}>
                  <span className="f36 mine-shaft b">100%</span>
                </div>
              </div>
              <PieChart width={200} height={200}>
                <Pie
                  data={distributionTotal}
                  dataKey="value"
                  nameKey="name"
                  cx="50%" cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#82ca9d"
                  labelLine={"a"}
                >
                  {_map(distributionTotal, (item, index) => (
                    <Cell key={`cell-${index}`} fill={distributionTotal[index].color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center item-center">
          <div className={cx("w-100", styles.scrollActionsGroups)} ref={refGroups}>
            <div className={styles.gridIdeal}>
              {_map(investments?.groups, (distribution, index) => (
                <button key={index} className={cx("arkc-btn-link", styles.badgeIdeal)} onClick={() => handleChartPieData(distribution)}>
                  <div className="flex flex-row justify-between item-center h-100 p-24">
                    <span className="f16 mine-shaft">{distribution.name}</span>
                    <div className="flex flex-row item-center">
                      <div className={styles.circularSize}>
                        <CircularProgressbar
                          value={distribution.balance}
                          text={`${distribution.balance}%`}
                          styles={buildStyles({ pathColor: `${distribution?.lesson?.color}`, backgroundColor: "#F8F5FF", textColor: "#2F2F2F", textSize: "28px" })}
                        />
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
          {_size(investments?.groups) > 4 && (
            <div className="flex flex-column ml-12">
              <Button className={cx("mb-26", styles.buttonArrowTB, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollGroups(-100)}>
                <FiArrowUp />
              </Button>
              <Button className={cx(styles.buttonArrowTB, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollGroups(100)}>
                <FiArrowDown />
              </Button>
            </div>
          )}
        </div>
        <div>
          {isMobile ? (
            <h5 className="mt-22 mb-18">{_size(walletsList) > 1 ? "Minhas carteiras" : "Minha carteira"}</h5>
          ) : (
            <h5 className="mt-22 mb-14">{_size(walletsList) > 1 ? "Minhas carteiras" : "Minha carteira"}</h5>
          )}
          <div className="flex flex-column justify-center item-center">
            {renderWallets()}
            {_size(walletsList) > 1 && (
              <Button type="default" className={cx("mt-46", styles.btnReplace, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => setVisible(!visible)}>
                Trocar Carteira
                <AiOutlineReload className="i-14 ml-8 mt-2" />
              </Button>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isModalPieVisible}
        footer={null}
        onCancel={() => setIsModalPieVisible(!isModalPieVisible)}
        bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
        className="modal-complete"
        centered={!isMobile ? true : false}
      >
        <span className={styles.badgeTitle}>{_get(modalPieData, "name", "")}</span>
        <div className={cx("mt-14", { [styles.scrollModal]: isMobile })}>
          {_map(_get(modalPieData, "recommendations", ""), (item, index) => (
            <div key={index} className={cx("flex flex-row justify-between item-center", styles.tableRow)}>
              <span className={styles.tableTextTitle}>{_get(item, "ticker", "")}</span>
              <span className={styles.tableText}>{_get(item, "balance", "")}%</span>
            </div>
          ))}
        </div>
        <Button
          type="primary"
          onClick={() => setIsModalPieVisible(!isModalPieVisible)}
          className="mt-12 w-100"
        >
          Fechar
        </Button>
      </Modal>
      <ModalWallets
        visible={visible}
        walletId={walletId}
        walletsList={walletsList}
        filterWallet={filterWallet}
        setVisible={setVisible}
      />
    </div>
  );
};

export default Wallets;