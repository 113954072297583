export const message = {
  validate_name: "É permitido somente letras neste campo.",
  validate_email: "Você não digitou um e-mail válido!",
  email_registration: "E-mail já cadastrado.",
  confirm_password: "As duas senhas que você digitou não coincidem!",
  confirm_email: "Os dois emails que você digitou não coincidem!",
  title_new_message: "Este link expirou",
  description_new_message: "Seu link para recuperação de senha expirou. Por favor, solicite novo resgate de senha em “esqueci minha senha” na seção login.",
  label_new_message: "Ir para o login",
  title_recover_message: "Pronto, enviamos um link para o seu email",
  description_recover_message: "Acesse o link que enviamos para o seu e-mail e resgate a sua senha de forma rápida e segura.",
  label_recover_message: "Entendi",
  agree_terms: "Ao entrar no Arkc, você concorda com os nossos",
  political_terms_one: "Termos de Uso",
  political_terms_two: "Política de Privacidade.",
  was_problem: "Houve um problema",
  was_problem_description_generic: "Não foi possivel retornar as informações, por favor, tente novamente.",
  was_problem_description_create: "Não foi possivel realizar o seu cadastro, por favor, tente novamente.",
  was_problem_description_login: "Não foi possivel fazer o seu login, por favor, tente novamente.",
  not_found_description_login: "Não foi possível fazer o seu login, cadastre-se e tente novamente.",
  was_problem_description_recover: "Não foi possivel concluir a sua recuperação de senha, por favor, tente novamente.",
  success_create: "Cadastro realizado",
  success_create_description: "Estamos felizes por fazer parte da Arkc.",
  conquests_title_page: "Conquistas",
  conquests_title: "O que são Conquistas",
  conquests_description: "As conquistas representam sua evolução na jornada dos investimentos, avance nas trilhas e  aprenda sobre os melhores investimentos.",
  conquests_error_description: "Não conseguimos trazer nenhuma conquista :(",
  error_title: "Ops...",
  error_description: "Não encontramos nada :(",
  was_problem_description_update_profile: "Não foi possível atualizar os dados do seu perfil.",
  title_update: "Deu tudo certo!",
  name_description_update: "A alteração do seu nome foi realizada com sucesso",
  email_description_update: "A alteração do seu e-mail foi realizada com sucesso",
  password_description_update: "A alteração da sua senha foi realizada com sucesso",
  password_problem: "Ops...",
  password_problem_description: "A senha informada não é valida, por favor, tente novamente",
  was_problem_api: "Houve um problema, por favor, tente novamente mais tarde",
  was_problem_delete_account: "Não foi possível deletar sua conta, por favor, tente novamente",
  beginner_free: "Para clientes beginner o plano é gratuito",
  renovation_plan: "Ativa",
  canceled_plan: "O plano atualmente está desativado",
  was_problem_description_subscription: "Não foi possivel iniciar o seu pagamento, por favor, tente novamente.",
  was_problem_description_active: "Você já tem um contrato ativo para o plano pretendido.",
  was_problem_description_downgrade: "Você já é um assinante Arkc black, cancele sua assinatura atual para realizar uma nova.",
  was_problem_description_lack: "Por favor aguarde o término da assinatura atual para contratar novamente",
  cancel_title: "Tudo certo!",
  cancel_description_generic: "Sua assinatura foi cancelada, você poderá continuar usufruindo dela até a data de renovação."
};