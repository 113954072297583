import { Button } from 'antd';
import cx from 'classnames';
import useMedia from 'use-media';
import { funy } from 'assets';
import styles from './FunSimplicity.module.less';

const FunSimplicity = ({ startGo }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  return (
    <div className={cx({ "mb-80": isMobile || isTablet, "mb-140": !isMobile && !isTablet })}>
      <div className={cx({ "flex flex-column": isMobile || isTablet, "flex flex-row": !isMobile && !isTablet })}>
        <div className={cx("flex justify-center item-center", styles.funyContainer)}>
          <img src={funy} alt="funy" className={styles.imageFuny} />
        </div>
        <div className={styles.space}>
          <h2 className={cx("mine-shaft mb-16", styles.titleFuny, { "f40": isMobile, "f56": !isMobile })}>
            Comece a investir
          </h2>
          <p className={cx("f24 gray", styles.p1, { "mb-32": isMobile, "mb-40": !isMobile })}>
            Existem várias fontes de informações e termos técnicos que podem desmotivar o aprendizado
            sobre investimentos logo no início.<br /><br />
            Por isso, desenvolvemos o Arkc, uma plataforma de educação financeira diferente,
            mais simples e divertida, para que você possa evoluir, de forma personalizada,
            em uma jornada completa.
          </p>
          <Button type="primary" className={styles.startNow} onClick={startGo}>Começar agora</Button>
        </div>
      </div>
    </div>
  );
};

export default FunSimplicity;