import { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import LandingPageFourForm from 'components/LandingPageForm/LandingPageFourForm';
import Footer from 'components/Footer';
import { arkcFour } from 'mock/data';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

ReactPixel.init("963609121887430", { autoConfig: true });

const LandingPageFour = () => {
  useEffect(() => {
    ReactPixel.pageView();
    window.scrollTo(0, 0);
  }, []);

  const handleStartNow = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })

    history.push(ROUTES.LEVELING);
  };

  return (
    <div>
      <LandingPageFourForm
        guide={arkcFour.guide}
        you={arkcFour.you}
        lessons={arkcFour.lessons}
        secrets={arkcFour.secrets}
        investors={arkcFour.investors}
        peoples={arkcFour.peoples}
        noArkc={arkcFour.noArkc}
        withArkc={arkcFour.withArkc}
        informationsOne={arkcFour.informationsOne}
        informationsTwo={arkcFour.informationsTwo}
        supportQuestions={arkcFour.supportQuestions}
        startNow={handleStartNow}
      />
      <Footer />
    </div>
  );
};

export default LandingPageFour;