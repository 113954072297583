import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../TrailLessonForm.module.less';

const QuestionSimple = ({ nextTrail, title, index, name, logo }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayButton(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [displayButton]);

  return (
    <div key={index}>
      {isMobile ? (
        <div className="container">
          <div className="content">
            <div className="flex flex-column justify-center item-center">
              <h5 className={cx("text-center", styles.titleSimple)}>{title}</h5>
              {displayButton && (
                <>
                  <hr className="divider-internal mb-16" />
                  <Button
                    type="primary"
                    className={cx("flex justify-center item-center", styles.btnContinue)}
                    onClick={nextTrail}
                  >
                    Continuar
                    <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-column justify-center item-center mb-16">
          <div className={styles.bgQuestion}>
            <div className="flex flex-row item-center p-16">
              <img src={logo} alt="logo-question" className="i-32" />
              <span className="f18 b mine-shaft ml-16">{name}</span>
            </div>
          </div>
          <h5 className={cx("text-center", styles.titleSimple)}>{title}</h5>
          {displayButton && (
            <>
              <hr className="divider-internal mb-16" />
              <Button
                type="primary"
                className={cx("flex justify-center item-center", styles.btnContinue)}
                onClick={nextTrail}
              >
                Continuar
                <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
              </Button>
            </>
          )}
        </div>
      )}
    </div >
  );
};

export default QuestionSimple;