import { useEffect } from 'react';
import PrivacyPoliciesForm from 'components/PrivacyPoliciesForm';

const PrivacyPolicies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PrivacyPoliciesForm />
  );
};

export default PrivacyPolicies;