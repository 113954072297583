import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import Loading from 'components/Loading';
import TutorialForm from 'components/TutorialForm';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const Tutorial = (props) => {
  const [loading, setLoading] = useState(true);
  const [tutorial, setTutorial] = useState({});
  const [walletId, setWalletId] = useState();
  const [inviteId, setInviteId] = useState();
  const [name, setName] = useState();
  const [actualPlan, setActualPlan] = useState();
  const [logo, setLogo] = useState();
  const [category, setCategory] = useState();
  const [dataPlan, setDataPlan] = useState();
  const [choiceSelected, setChoiceSelected] = useState();
  const { plansArkc, lessons } = useStore();
  const { tutorialById } = lessons;
  const { planById } = plansArkc;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.ticker && state.id && state.name && state.category && state.actualPlan && state.invite && state.logo) {
      setWalletId(state.id);
      setInviteId(state.invite);
      setName(state.name);
      setActualPlan(state.actualPlan);
      setLogo(state.logo)
      setCategory(state.category);
      retrieveTutorial(state.ticker);
      retrievePlanData();
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveTutorial = async (ticker) => {
    setLoading(true);

    const response = await tutorialById({ ticker: ticker });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const tickerTutorial = response.data;
      const newResponse = {
        advertising: tickerTutorial.lessons[0].advertising,
        nameLesson: tickerTutorial.lessons[0].name,
        imageLesson: tickerTutorial.lessons[0].logo,
        actualQuestion: tickerTutorial.lessons[0].content[0]?.identifier,
        percentage: 0,
        color: tickerTutorial.lessons[0].color,
        trailQuestions: tickerTutorial.lessons[0].content
      };

      setTutorial(newResponse);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };
  
  const retrievePlanData = async () => {
    const response = await planById({ identifier: "arkc-black" });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setDataPlan(response.data);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };
  
  const handleBackDetails = () => {
    history.push(ROUTES.WHERE_INVEST, { id: walletId, name: name, category: category, actualPlan: actualPlan, invite: inviteId, logo: logo });
  };

  const handleViewPlan = () => {
    if (!dataPlan) return;

    let userData = JSON.parse(localStorage.getItem('userData'));

    if (userData && userData?.referralCode) {
      history.push(ROUTES.ARKC_BLACK, { dataPlan: dataPlan, invite: userData?.referralCode });
    };
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <TutorialForm
          tutorial={tutorial}
          choiceSelected={choiceSelected}
          setChoiceSelected={setChoiceSelected}
          backDetails={handleBackDetails}
          viewPlan={handleViewPlan}
        />
      )}
    </div>
  );
};

export default observer(Tutorial);