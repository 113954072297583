import { useEffect, useState } from 'react';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
// import { Avatar } from 'antd';
import _get from 'lodash/get';
import cx from 'classnames';
import useMedia from 'use-media';
import ArkcoinsModal from './components/ArkcoinsModal';
// import GemsModal from './components/GemsModal';
import { conquests, arkcoins, gemsLarge } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './Achievements.module.less';
import "@egjs/flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import PlanModal from './components/PlanModal';

const Achievements = ({ userData }) => {
  const [visibleArkcoins, setVisibleArkcoins] = useState(false);
  // const [visibleGems, setVisibleGems] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [plugins, setPlugins] = useState([]);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
  }, []);

  const handleSelectAchievements = (name) => {
    if (name === "Investimentos") {
      history.push(ROUTES.DASHBOARD_WALLET, { id: _get(userData, "wallet", ""), invite: _get(userData, "referralCode", "") });
    } else if (name === "Conquistas") {
      history.push(ROUTES.CONQUESTS);
    } else {
      setVisibleArkcoins(true);
    };
  };

  return (
    <>
      <div className={cx("flex", { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
        <div className={cx("flex justify-center item-center", { "flex-column mb-24": isMobile || isTablet, "flex-row": !isMobile && !isTablet })}>
          {/* <Avatar
            src={userData.avatar ? userData.avatar : avatarUser}
            alt="avatar"
            size={64}
            className={cx({ "mb-12": isMobile || isTablet, "mr-24": !isMobile && !isTablet })}
          /> */}
          <h2 className={cx("mine-shaft", styles.titleSize)}>Olá, <span className="melrose">{userData?.name}.</span></h2>
        </div>
        {isMobile ? (
          <div className="flicking-view-achievements">
            <Flicking circular={true} plugins={plugins} align="center">
              <div className={styles.cardEmphasis}>
                <button className={cx("arkc-btn-link mr-18", styles.cardBase)} onClick={() => handleSelectAchievements("Investimentos")}>
                  <img src={conquests} alt="investiments" className={styles.iconsAchievements} />
                  <span className="f16 mine-shaft mt-4 mb-4">Investimentos</span>
                  <span className="f32 text-left b heliotrope">{_get(userData, "investiments", 0)}</span>
                </button>
              </div>
              <div className={styles.cardEmphasis}>
                <button className={cx("arkc-btn-link mr-18", styles.cardBase)} onClick={() => handleSelectAchievements("Conquistas")}>
                  <img src={gemsLarge} alt="conquests" className={styles.iconsAchievements} />
                  <span className="f16 mine-shaft mt-4 mb-4">Conquistas</span>
                  <span className="f32 text-left b heliotrope">{_get(userData, "trophies", 0)}</span>
                </button>
              </div>
              <div className={styles.cardEmphasis}>
                <button className={cx("arkc-btn-link mr-18", styles.cardBase)} onClick={() => handleSelectAchievements("Arkcoins")}>
                  <img src={arkcoins} alt="arkcoins" className={styles.iconsAchievements} />
                  <span className="f16 mine-shaft mt-4 mb-4">Arkcoins</span>
                  <span className="f32 text-left b heliotrope">{_get(userData, "coins", 0)}</span>
                </button>
              </div>
              <ViewportSlot>
                <div className="flicking-pagination"></div>
              </ViewportSlot>
            </Flicking>
          </div>
        ) : (
          <div className={cx({ "flex justify-center items-center": isMobile })}>
            <div className="flex flex-row justify-center items-center">
              <div className={styles.cardEmphasis}>
                <button className={cx("arkc-btn-link", { "mr-16": !isMobile })} onClick={() => handleSelectAchievements("Investimentos")}>
                  <div className={styles.cardBase}>
                    <div className={cx("flex justify-content item-center", { "flex-row pt-18 pb-18 pl-24 pr-24": isMobile, "flex-column pt-40 pb-40 pl-48 pr-48": !isMobile })}>
                      <div className={cx({ "mr-16": isMobile })}>
                        <img src={conquests} alt="investiments" className={styles.iconsAchievements} />
                      </div>
                      <div className={cx("flex flex-column", { "justify-content item-center": !isMobile })}>
                        <span className={cx("mine-shaft", { "f18": isMobile, "f16 mt-16": !isMobile })}>Investimentos</span>
                        <span className={cx("b heliotrope", { "f16 text-left": isMobile, "f32": !isMobile })}>{_get(userData, "investiments", 0)}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className={styles.cardEmphasis}>
                <button className={cx("arkc-btn-link", { "mr-16": !isMobile })} onClick={() => handleSelectAchievements("Conquistas")}>
                  <div className={styles.cardBase}>
                    <div className={cx("flex justify-content item-center", { "flex-row pt-18 pb-18 pl-24 pr-24": isMobile, "flex-column pt-40 pb-40 pl-48 pr-48": !isMobile })}>
                      <div className={cx({ "mr-16": isMobile })}>
                        <img src={gemsLarge} alt="conquests" className={styles.iconsAchievements} />
                      </div>
                      <div className={cx("flex flex-column", { "justify-content item-center": !isMobile })}>
                        <span className={cx("mine-shaft", { "f18": isMobile, "f16 mt-16": !isMobile })}>Conquistas</span>
                        <span className={cx("b heliotrope", { "f16 text-left": isMobile, "f32": !isMobile })}>{_get(userData, "trophies", 0)}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className={styles.cardEmphasis}>
                <button className={cx("arkc-btn-link", styles.cardEmphasis, { "mr-16": !isMobile })} onClick={() => handleSelectAchievements("Arkcoins")}>
                  <div className={styles.cardBase}>
                    <div className={cx("flex justify-content item-center", { "flex-row pt-18 pb-18 pl-24 pr-24": isMobile, "flex-column pt-40 pb-40 pl-48 pr-48": !isMobile })}>
                      <div className={cx({ "mr-16": isMobile })}>
                        <img src={arkcoins} alt="arkcoins" className={styles.iconsAchievements} />
                      </div>
                      <div className={cx("flex flex-column", { "justify-content item-center": !isMobile })}>
                        <span className={cx("mine-shaft", { "f18": isMobile, "f16 mt-16": !isMobile })}>Arkcoins</span>
                        <span className={cx("b heliotrope", { "f16 text-left": isMobile, "f32": !isMobile })}>{_get(userData, "coins", 0)}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              {/* <div className={styles.cardEmphasis}>
              <button className={cx("arkc-btn-link", styles.cardEmphasis,)} onClick={() => handleSelectAchievements("Diamantes")}>
                <div className={styles.cardBase}>
                  <div className={cx("flex justify-content item-center", { "flex-row pt-18 pb-18 pl-24 pr-24": isMobile, "flex-column pt-40 pb-40 pl-48 pr-48": !isMobile })}>
                    <div className={cx({ "mr-16": isMobile })}>
                      <img src={gemsLarge} alt="gems-large" className={styles.iconsAchievements} />
                    </div>
                    <div className={cx("flex flex-column", { "justify-content item-center": !isMobile })}>
                      <span className={cx("mine-shaft", { "f18": isMobile, "f16 mt-16": !isMobile })}>Diamantes</span>
                      <span className={cx("b heliotrope", { "f16 text-left": isMobile, "f32": !isMobile })}>{userData?.diamonds}</span>
                    </div>
                  </div>
                </div>
              </button>
            </div> */}
            </div>
          </div>
        )}
      </div>
      {/* <PlanModal
        visible={visible}
        setVisible={setVisible}
      /> */}
      <ArkcoinsModal
        visibleArkcoins={visibleArkcoins}
        arkcoins={arkcoins}
        balance={_get(userData, "coins", 0)}
        isMobile={isMobile}
        setVisibleArkcoins={setVisibleArkcoins}
      />
      {/* <GemsModal
        visibleGems={visibleGems}
        gemsLarge={gemsLarge}
        balance={userData?.diamonds}
        isMobile={isMobile}
        setVisibleGems={setVisibleGems}
      /> */}
    </>
  );
};

export default Achievements;