import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import Loading from 'components/Loading';
import TrailLessonForm from 'components/TrailLessonForm';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const TrailLesson = (props) => {
  const [loading, setLoading] = useState(true);
  const [lessonId, setLessonId] = useState();
  const [identifierLesson, setiIdentifierLesson] = useState();
  const [typeLesson, setTypeLesson] = useState();
  const [lessonData, setLessonData] = useState();
  const [lessonCompleted, setLessonCompleted] = useState(false);
  const [dataPlan, setDataPlan] = useState();
  const [choiceSelected, setChoiceSelected] = useState();
  const [subChoiceSelected, setSubChoiceSelected] = useState();
  const { plansArkc, lessons } = useStore();
  const { lessonRegisterProgress, lessonByProgress, lessonById } = lessons;
  const { planById } = plansArkc;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.id && state.identifier && state.lessonType) {
      retrieveLessonById(state.id, state.identifier);
      retrievePlanData();
      setLessonId(state.id);
      setiIdentifierLesson(state.identifier);
      setTypeLesson(state.lessonType);
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveLessonById = async (id, identifier) => {
    setLoading(true);

    const response = await lessonById(id);
    const responseProgress = await lessonByProgress(id, identifier);

    if (response.status === 200 && responseProgress.status === 200) {
      const actualLesson = response.data?.lessons.find(item => item.identifier === identifier);

      if (!_get(responseProgress.data, "completed", "") || !_get(responseProgress.data, "rewarded", "")) {        
        let totalLesson = 0;
        let percentageQuestion = (100 / _size(actualLesson.content)).toFixed(2);
        let numberQuestions = _get(responseProgress.data, "current", "");

        _forEach(actualLesson.content, (item, index) => {
          if (item.identifier === numberQuestions) {
            totalLesson = percentageQuestion * index;
          }
        });

        const newResponse = {
          advertising: _get(actualLesson, "advertising", ""),
          nameLesson: _get(actualLesson, "name", ""),
          imageLesson: _get(actualLesson, "logo", ""),
          actualQuestion: _get(responseProgress.data, "current", "") ? _get(responseProgress.data, "current", "") : _get(actualLesson.content[0], "identifier", ""),
          percentage: totalLesson,
          color: _get(actualLesson, "color", ""),
          trailQuestions: _get(actualLesson, "content", [])
        };

        setLessonData(newResponse);
        setLoading(false);
      } else {
        let totalLesson = 0;
        let percentageQuestion = (100 / _size(actualLesson.content)).toFixed(2);
        let numberQuestions = _get(responseProgress.data, "current", "");

        _forEach(actualLesson.content, (item, index) => {
          if (item.identifier === numberQuestions) {
            totalLesson = percentageQuestion * index;
          }
        });
        
        const newResponse = {
          advertising: _get(actualLesson, "advertising", ""),
          nameLesson: _get(actualLesson, "name", ""),
          imageLesson: _get(actualLesson, "logo", ""),
          actualQuestion: _get(actualLesson.content[0], "identifier", ""),
          percentage: totalLesson,
          color: _get(actualLesson, "color", ""),
          trailQuestions: _get(actualLesson, "content", [])
        };

        setLessonData(newResponse);
        setLessonCompleted(_get(responseProgress.data, "rewarded", false));
        setLoading(false);
      };
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const retrievePlanData = async () => {
    const response = await planById({ identifier: "arkc-black" });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setDataPlan(response.data);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };

  const handleRegisterMyProgress = async (values) => {
    const body = {
      trail: lessonId,
      lesson: identifierLesson,
      content: values.identifier,
      type: typeLesson ? typeLesson : "LESSON",
      choice: {
        identifier: values.identifier,
        next: values.next
      }
    };

    const response = await lessonRegisterProgress(lessonId, identifierLesson, body);

    if (response.status !== 204) {
      retrieveLessonById(lessonId, identifierLesson);
    } else {
      window.scrollTo(0, 0);
    };
  };

  const handleRegisterMyProgressChoices = async (values, option, subOption) => {
    const body = {
      trail: lessonId,
      lesson: identifierLesson,
      content: values?.identifier,
      type: typeLesson ? typeLesson : "LESSON",
      choice: {
        identifier: subOption ? subOption?.identifier : option?.identifier,
        next: subOption ? subOption?.next : option?.next
      }
    };

    const response = await lessonRegisterProgress(lessonId, identifierLesson, body);

    if (response.status !== 204) {
      retrieveLessonById(lessonId, identifierLesson);
      setChoiceSelected();
      setSubChoiceSelected();
    } else {
      setChoiceSelected();
      setSubChoiceSelected();
      window.scrollTo(0, 0);
    };
  };

  const handleBackDetails = () => {
    history.push(ROUTES.LESSON_DETAIL, { id: lessonId });
  };

  const handleViewPlan = () => {
    if (!dataPlan) return;

    let userData = JSON.parse(localStorage.getItem('userData'));

    if (userData && userData?.referralCode) {
      history.push(ROUTES.ARKC_BLACK, { dataPlan: dataPlan, invite: userData?.referralCode });
    };
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <>
          {lessonData && (
            <TrailLessonForm
              lessonData={lessonData}
              lessonCompleted={lessonCompleted}
              choiceSelected={choiceSelected}
              subChoiceSelected={subChoiceSelected}
              setChoiceSelected={setChoiceSelected}
              setSubChoiceSelected={setSubChoiceSelected}
              backDetails={handleBackDetails}
              registerMyProgress={handleRegisterMyProgress}
              registerMyProgressChoices={handleRegisterMyProgressChoices}
              viewPlan={handleViewPlan}
            />
          )}
        </>
      )}
    </div>
  );
};

export default observer(TrailLesson);