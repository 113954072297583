import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import useMedia from 'use-media';
import cx from 'classnames';
import Loading from 'components/Loading';
import MenuWallet from 'components/DashboardWalletForm/MenuWallet';
import Wallets from 'components/DashboardWalletForm/Wallets';
import Investments from 'components/DashboardWalletForm/Investments';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { message } from 'helpers/messages';

const DashboardWallet = (props) => {
  const [loading, setLoading] = useState(false);
  const [walletId, setWalletId] = useState();
  const [inviteId, setInviteId] = useState();
  const [actualPlan, setActualPlan] = useState();
  const [walletsList, setWalletsList] = useState({ wallets: [], investmentsType: [], walletOptions: {}, distributionTotal: [], profitability: [] });
  const [visible, setVisible] = useState(false);
  const { plansArkc } = useStore();
  const { userWalletsAll, investmentsAll } = plansArkc;
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 });

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.id && state.invite) {
      setWalletId(state.id);
      setInviteId(state.invite)
      retrieveUserWalletsAll(state.id);
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveUserWalletsAll = async (id) => {
    setLoading(true);

    const response = await userWalletsAll();
    const responseInvestments = await investmentsAll();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const optionsResponse = response.data.find(item => item._id === id);
      const chart = _get(optionsResponse, "chart", "");
      const walletsReponse = [];
      const typeInvestments = _size(responseInvestments.data) > 0 ? responseInvestments.data : [];
      const newTypeInvestments = [];
      const distributionTotal = [];
      const filterProfitability = { typeOne: _get(optionsResponse, "name", ""), typeTwo: "Poupança", data: [] };

      _forEach(chart.walletx, (item, index) => {
        const data = {
          name: item,
          [optionsResponse.name]: chart.wallety[index],
          ["Poupança"]: chart.referencey[index],
        };

        filterProfitability.data.push(data);
      });

      _forEach(response.data, (item, index) => {
        const newItem = {
          id: item._id,
          name: item.name,
          identifier: item.identifier,
          selected: item._id === id ? true : false
        };

        walletsReponse.push(newItem);
      });

      _forEach(typeInvestments, (type, index) => {
        _forEach(optionsResponse.groups, (groups, index) => {
          if (type.identifier === groups.category) {
            const data = {
              ...type,
              totalInvestments: _size(groups.recommendations) > 0 ? _size(groups.recommendations) : 0
            };
            newTypeInvestments.push(data);
          }
        });
      });

      _forEach(optionsResponse.groups, (groups) => {
        const body = {
          name: groups.name,
          value: groups.balance * 10,
          color: groups.lesson.color,
        };
        distributionTotal.push(body);
      });

      setActualPlan(optionsResponse?.identifier);
      setWalletsList({ ...walletsList, wallets: walletsReponse, investmentsType: newTypeInvestments, walletOptions: optionsResponse, distributionTotal: distributionTotal, profitability: filterProfitability });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const retrieveFilterWallet = async (id) => {
    if (walletId === id) {
      return;
    };

    setLoading(true);

    const response = await userWalletsAll();
    const responseInvestments = await investmentsAll();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const optionsResponse = response.data.find(item => item._id === id);
      const chart = _get(optionsResponse, "chart", "");
      const walletsReponse = [];
      const typeInvestments = _size(responseInvestments.data) > 0 ? responseInvestments.data : [];
      const newTypeInvestments = [];
      const distributionTotal = [];
      const filterProfitability = { typeOne: _get(optionsResponse, "name", ""), typeTwo: "Poupança", data: [] };

      _forEach(chart.walletx, (item, index) => {
        const data = {
          name: item,
          [optionsResponse.name]: chart.wallety[index],
          ["Poupança"]: chart.referencey[index],
        };

        filterProfitability.data.push(data);
      });

      _forEach(response.data, (item, index) => {
        const newItem = {
          id: item._id,
          name: item.name,
          identifier: item.identifier,
          selected: item._id === id ? true : false
        };

        walletsReponse.push(newItem);
      });

      _forEach(typeInvestments, (type, index) => {
        _forEach(optionsResponse.groups, (groups, index) => {
          if (type.identifier === groups.category) {
            const data = {
              ...type,
              totalInvestments: _size(groups.recommendations) > 0 ? _size(groups.recommendations) : 0
            };
            newTypeInvestments.push(data);
          }
        });
      });

      _forEach(optionsResponse.groups, (groups) => {
        const body = {
          name: groups.name,
          value: groups.balance * 10,
          color: groups.lesson.color,
        };
        distributionTotal.push(body);
      });

      setActualPlan(optionsResponse?.identifier);
      setWalletsList({ ...walletsList, wallets: walletsReponse, investmentsType: newTypeInvestments, walletOptions: optionsResponse, distributionTotal: distributionTotal, profitability: filterProfitability });
      setWalletId(id);
      setLoading(false);
      setVisible(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
      setVisible(false);
    };
  };

  const handleViewInvestment = (name, identifier, logo) => {
    history.push(ROUTES.WHERE_INVEST, { id: walletId, name: name, category: identifier, actualPlan: actualPlan, invite: inviteId, logo: logo });
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <MenuWallet plan={actualPlan} invite={inviteId}>
          <div className={cx({ "flex flex-column": isMobile || isTablet || isDesktop, "flex flex-row": !isMobile && !isTablet && !isDesktop })}>
            <Investments
              investments={walletsList?.walletOptions}
              investmentsType={walletsList?.investmentsType}
              profitability={walletsList?.profitability}
              plan={actualPlan}
              viewInvestment={handleViewInvestment}
            />
            <div className={cx({ "mt-20": isMobile || isTablet, "ml-20": !isMobile && !isTablet })}>
              <Wallets
                distributionTotal={walletsList?.distributionTotal}
                investments={walletsList?.walletOptions}
                walletId={walletId}
                walletsList={walletsList?.wallets}
                name={walletsList.walletOptions?.name}
                visible={visible}
                plan={actualPlan}
                setVisible={setVisible}
                filterWallet={retrieveFilterWallet}
              />
            </div>
          </div>
        </MenuWallet>
      )}
    </div>
  );
};

export default observer(DashboardWallet);