export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  RECOVER_PASSWORD: "/recuperar-senha",
  NEW_PASSWORD: "/nova-senha/:token",
  LEVELING: "/experiencia-investimentos",
  LEVELING_INVITE: "/experiencia-investimentos/:invite",
  CREATE_ACCOUNT: "/criar-conta",
  DASHBOARD: "/minha-area",
  DASHBOARD_WALLET: "/minhas-carteiras",
  CONQUESTS: "/minhas-conquistas",
  LESSON_DETAIL: "/licao-detalhe",
  TRAIL_LESSON: "/licao-trilha",
  CHALLENGE_LESSON: "/licao-desafio",
  LESSON_CHALLENGE: "/desafio-licao",
  ARKC_BEGINNER: "/plano/beginner",
  ARKC_PREMIUM: "/plano/premium",
  ARKC_BLACK: "/plano/black",
  PROFILE: "/meu-perfil",
  SIGNATURES: "/minhas-assinaturas",
  CREDIT_CARDS: "/meus-cartoes",
  SUPPORT: "/suporte-arkc",
  INVITE_FRIENDS: "/convidar-amigos",
  REGISTER_CREDIT_CARD: "/novo-cartao",
  CHECKOUT: "/checkout-plano",
  PAYMENT: "/pagamento",
  PAYMENT_SUCCESS: "/pagamento-realizado/:identifier/:invite",
  WHERE_INVEST: "/onde-investir",
  DELETE_ACCOUNT: "/deletar-conta",
  PRIVACY_POLICIES: "/politicas-privacidade",
  TERMS_USE: "/termos-de-uso",
  HOW_BUY: "/como-comprar",
  TUTORIAL_BUY: "/como-comprar-tutorial",
  LP_ONE_ARCK: "/conheca-o-arkc-lp",
  LP_TWO_ARCK: "/conheca-o-arck-lp-renda-extra",
  LP_THREE_ARCK: "/conheca-o-arck-lp-aprenda-a-investir",
  LP_FOUR_NEW: "/comece-fazer-dinheiro",
  LP_ARKC_BEGINNER: "/conheca-o-arkc-beginner",
  LP_ARKC_PREMIUM: "/conheca-o-arkc-premium",
  LP_ARKC_BLACK: "/conheca-o-arkc-black",
};