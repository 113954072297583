import { Breadcrumb, Button } from 'antd';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import cx from 'classnames';
import useMedia from 'use-media';
import Header from '../Header';
import { safeBuy, cancelWant, privacyGuaranteed } from 'assets'
import { ROUTES } from 'routes/routes';
import styles from './PaymentForms.module.less';
import { useEffect } from 'react';

const PaymentForms = ({ returnMessage, setLoading, clientSecret, messages, identifier, invite, loading }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe) {
      return;
    };

    if (!clientSecret) {
      return;
    };

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          returnMessage("Pagamento efetuado com sucesso!")
          break;
        case "processing":
          returnMessage("Seu pagamento está sendo processado.");
          break;
      }
    });
  }, [stripe]);


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    };

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_ENVIRONMENT}/pagamento-realizado/${identifier}/${invite}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      returnMessage(error.message);
    } else {
      returnMessage("Um erro inesperado ocorreu.");
    };

    setLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
    <div className="mb-64">
      <div className="container">
        <div className="content mt-24 mb-24">
          <Header route={ROUTES.DASHBOARD} />
        </div>
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <Breadcrumb separator="">
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Planos</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Pagamento do plano</Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex flex-column justify-center item-center mt-24">
            <div className="text-center mb-32">
              {!isMobile ? (
                <h4>Finalizar pagamento</h4>
              ) : (
                <h5>Finalizar pagamento</h5>
              )}
              <p className={cx("f16 mine-shaft mt-8", styles.sizeDescription)}>
                Preencha seu nome completo e os dados do seu cartão para iniciar sua assinatura!
              </p>
            </div>
            <form className={styles.formContainer} onSubmit={handleSubmit}>
              <div>
                <label className="f16 mine-shaft mb-8">Dados do cartão</label>
                <div className={styles.containerPay}>
                  <div className="p-12">
                    <PaymentElement options={paymentElementOptions} />
                  </div>
                </div>
              </div>
              <div className="mt-24">
                <Button type="primary" htmlType="submit" className={styles.btnFinishPay} disabled={loading ? true : false}>
                  Finalizar pagamento
                </Button>
              </div>
              {messages && (
                <div className="mt-18">
                  <span className="f16 sunset-orange">{messages}</span>
                </div>
              )}
            </form>
          </div>
          <div className="flex justify-center item-center">
            <div className={styles.blocWidth}>
              <div className={cx("flex flex-row justify-between", { "mt-32": isMobile, "mt-48": !isMobile })}>
                <div className={cx("flex flex-column justify-center item-center", styles.containerInfos)}>
                  <img src={safeBuy} className={styles.imageLogos}/>
                  <span className={cx("f18 mine-shaft mt-6", {"text-center": isMobile, "b": !isMobile})}>Compra segura</span>
                </div>
                <div className={cx("flex flex-column justify-center item-center", styles.containerInfos)}>
                  <img src={cancelWant} className={styles.imageLogos}/>
                  <span className={cx("f18 mine-shaft mt-6", {"text-center": isMobile, "b": !isMobile})}>Cancele quando quiser</span>
                </div>
                <div className={cx("flex flex-column justify-center item-center", styles.containerInfos)}>
                  <img src={privacyGuaranteed} className={styles.imageLogos}/>
                  <span className={cx("f18 mine-shaft mt-6", {"text-center": isMobile, "b": !isMobile})}>Privacidade garantida</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForms;