import { useState } from 'react';
import { Progress, Dropdown, Menu } from 'antd';
import { FiX, FiMoreVertical } from 'react-icons/fi';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import QuestionSimpleChallenge from './components/QuestionSimpleChallenge';
import QuestionImageChallenge from './components/QuestionImageChallenge';
import QuestionItemsImageChallenge from './components/QuestionItemsImageChallenge';
import QuestionItemsChallenge from './components/QuestionItemsChallenge';
import ChallengeModal from './components/ChallengeModal';
import styles from './LessonChallengeForm.module.less';

const LessonChallengeForm = ({ backDetails, handleMyArea, finishMyArea, registerMyProgressChoices, registerMyProgress, setQuestionValue, setQuestionChoice, totalChallenges, lessonCompleted, challengeData, questionChoice, questionValue, saveValue }) => {
  const [questionsChallenge, setQuestionsChallenge] = useState(challengeData);
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  const renderChallengeQuestions = (item, index) => {
    if (questionsChallenge.actualQuestion === item.identifier && item.description && !item.image && !item?.choices) {
      return (
        <QuestionSimpleChallenge
          index={index}
          logo={questionsChallenge.imageLesson}
          name={questionsChallenge.nameLesson}
          title={item.description}
          saveValue={saveValue}
          nextTrail={() => handleNextChallenge(item, item.next)}
        />
      );
    };

    if (questionsChallenge.actualQuestion === item.identifier && item.description && item.image && !item?.choices) {
      return (
        <QuestionImageChallenge
          index={index}
          logo={questionsChallenge.imageLesson}
          name={questionsChallenge.nameLesson}
          title={item.description}
          image={item.image}
          saveValue={saveValue}
          nextTrail={() => handleNextChallenge(item, item.next)}
        />
      );
    };

    if (questionsChallenge.actualQuestion === item.identifier && item.description && item.image && item?.choices?.length > 0) {
      return (
        <QuestionItemsImageChallenge
          index={index}
          logo={questionsChallenge.imageLesson}
          name={questionsChallenge.nameLesson}
          title={item.description}
          image={item.image}
          questionsItem={item.choices}
          questionValue={questionValue}
          changeQuestionValue={handleChangeQuestionValue}
          nextTrail={() => handleNextChallengeChoices(item)}
        />
      );
    };

    if (questionsChallenge.actualQuestion === item.identifier && item.description && item?.choices?.length > 0) {
      return (
        <QuestionItemsChallenge
          index={index}
          logo={questionsChallenge.imageLesson}
          name={questionsChallenge.nameLesson}
          title={item.description}
          questionsItem={item.choices}
          questionValue={questionValue}
          changeQuestionValue={handleChangeQuestionValue}
          nextTrail={() => handleNextChallengeChoices(item)}
        />
      );
    };
  };

  const handleChangeQuestionValue = (type, value, actualChoice) => {
    if (type === "number") {
      if (!value) {
        setQuestionValue("");
        setQuestionChoice("");
        return;
      };

      const newValue = value?.replace(/[\D]+/g, '');
      setQuestionValue(newValue);
      setQuestionChoice(actualChoice);
    } else {
      setQuestionValue(value);
      setQuestionChoice(actualChoice);
    };
  };

  const handleNextChallenge = (item, next) => {
    const copyQuestionsChallenge = Object.assign({}, questionsChallenge);

    if (next !== "coin") {
      copyQuestionsChallenge.actualQuestion = next;

      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsChallenge.trailQuestions)).toFixed(2);
      let numberQuestions = next;

      _forEach(copyQuestionsChallenge.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "coin") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsChallenge.percentage = totalLesson;

      setQuestionsChallenge(copyQuestionsChallenge);
      registerMyProgress(item);
    } else if (totalChallenges.finished === totalChallenges.total - 1) {
      copyQuestionsChallenge.percentage = 100;
      setVisible(true);
      registerMyProgress(item);
    } else if (totalChallenges.finished === totalChallenges.total) {
      finishMyArea();
    } else {
      registerMyProgress(item);
      finishMyArea();
    };
  };

  const handleNextChallengeChoices = (item) => {
    const copyQuestionsChallenge = Object.assign({}, questionsChallenge);

    if (questionChoice.next !== "coin") {
      copyQuestionsChallenge.actualQuestion = questionChoice.next;

      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsChallenge.trailQuestions)).toFixed(2);
      let numberQuestions = questionChoice.next;

      _forEach(copyQuestionsChallenge.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "coin") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsChallenge.percentage = totalLesson;

      setQuestionsChallenge(copyQuestionsChallenge);
      registerMyProgressChoices(item, questionChoice, questionValue);
    } else if (totalChallenges.finished === totalChallenges.total - 1) {
      copyQuestionsChallenge.percentage = 100;
      setVisible(true);
      registerMyProgressChoices(item, questionChoice, questionValue);
    } else if (totalChallenges.finished === totalChallenges.total) {
      finishMyArea();
    } else {
      registerMyProgressChoices(item, questionChoice, questionValue);
      finishMyArea();
    };
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={`${process.env.REACT_APP_ENVIRONMENT}/suporte-arkc`} target="_blank" rel="noopener">Reportar um erro</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="flex justify-center item-center mt-24 mb-24">
            {!isMobile && (
              <img src={logoColor} alt="logo-color" />
            )}
            {isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24" />
                </Dropdown>
              </div>
            )}
            <div className="flex justify-center item-center w-100">
              <div className={cx("flex flex-row justify-center item-center", styles.progress)}>
                <Progress percent={questionsChallenge?.percentage} showInfo={false} />
                <button className="flex arkc-btn-link ml-14 mt-2" onClick={backDetails}>
                  <FiX className="i-24" />
                </button>
              </div>
            </div>
            {!isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24" />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="divider-internal" />
      <div className="container">
        <div className="content">
          <div className={cx({ "mt-28": isMobile, "mt-56": !isMobile })}>
            {_map(questionsChallenge?.trailQuestions, (item, index) => {
              return (
                <div key={index}>
                  {renderChallengeQuestions(item, index)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ChallengeModal
        visible={visible}
        challengeName={challengeData?.nameLesson}
        lessonCompleted={lessonCompleted}
        imageLesson={challengeData?.imageLesson}
        color={challengeData?.color}
        isMobile={isMobile}
        level={challengeData?.level}
        backDetails={backDetails}
        handleMyArea={handleMyArea}
      />
    </div>
  );
};

export default LessonChallengeForm;