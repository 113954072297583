// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footerDark__zt8I3 {\n  height: 100%;\n  background: #393939;\n}\n.Footer_footerSize__QbjNv {\n  height: 32px;\n  width: 82px;\n}\n.Footer_waterImage__ElExn {\n  position: absolute;\n  z-index: -999;\n  margin-top: -39px;\n  margin-left: 200px;\n}\n.Footer_gridLinks__GmaS3 {\n  display: grid;\n  gap: 105px;\n  grid-template-columns: auto auto;\n}\n.Footer_footerDivider__l8A9G {\n  border-bottom: 1px solid #DFDFDF;\n}\n@media only screen and (max-width: 767px) {\n  .Footer_gridLinks__GmaS3 {\n    gap: 72px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;EACA,WAAA;AAAF;AAGA;EACE,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;AADF;AAIA;EACE,aAAA;EACA,UAAA;EACA,gCAAA;AAFF;AAKA;EACE,gCAAA;AAHF;AAMA;EACE;IACE,SAAA;EAJF;AACF","sourcesContent":[".footerDark {\n  height: 100%;\n  background: #393939;\n}\n\n.footerSize {\n  height: 32px;\n  width: 82px;\n}\n\n.waterImage {\n  position: absolute;\n  z-index: -999;\n  margin-top: -39px;\n  margin-left: 200px;\n}\n\n.gridLinks {\n  display: grid;\n  gap: 105px;\n  grid-template-columns: auto auto;\n}\n\n.footerDivider {\n  border-bottom: 1px solid #DFDFDF;\n}\n\n@media only screen and (max-width: 767px) {\n  .gridLinks {\n    gap: 72px;\n  }\n}\n@primary-color: #8C52FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerDark": "Footer_footerDark__zt8I3",
	"footerSize": "Footer_footerSize__QbjNv",
	"waterImage": "Footer_waterImage__ElExn",
	"gridLinks": "Footer_gridLinks__GmaS3",
	"footerDivider": "Footer_footerDivider__l8A9G"
};
export default ___CSS_LOADER_EXPORT___;
