import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import CreateAccountForm from 'components/CreateAccountForm';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { message } from 'helpers/messages';
import Footer from 'components/Footer';

const CreateAccount = (props) => {
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState();
  const [referral, setReferral] = useState();
  const { login } = useStore();
  const {
    registerAccountFacebook,
    registerAccountGoogle,
    registerAccount,
    authUser,
    authUserGoogle,
    authUserFacebook
  } = login;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state) {
      const actualAnswers = _get(state, "quiz", "");
      const actualInvite = _get(state, "invite", "");

      setAnswers(actualAnswers);
      setReferral(actualInvite);
    };
  }, []);

  const handleCreateAccount = async (params) => {
    const body = {
      ...params,
      phone: _get(params, "phone", "").replace('(', '').replace(')', '').replace(' ', '').replace('-', ''),
      profile: answers,
      registerSource: "web"
    };

    const response = await registerAccount(body, referral);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      notification.success({
        message: message.success_create,
        description: message.success_create_description,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });

      const responseLogin = await authUser({ email: params.email, password: params.password });

      if (responseLogin.status === 200 || responseLogin.status === 201 || responseLogin.status === 204) {
        history.push(ROUTES.DASHBOARD);
        setLoading(false);
      } else {
        notification.error({
          message: message.was_problem,
          description: responseLogin.message,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
        setLoading(false);
      }
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleCreateAccountGoogle = async (token) => {
    if (token) {
      const body = { access: token, profile: answers, registerSource: "web" };

      const response = await registerAccountGoogle(body, referral);

      if (response.status === 200 || response.status === 201 || response.status === 204) {
        notification.success({
          message: message.success_create,
          description: message.success_create_description,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });

        const body = { access: token };
        const responseLoginGoogle = await authUserGoogle(body);

        if (responseLoginGoogle.status === 200 || responseLoginGoogle.status === 201 || responseLoginGoogle.status === 204) {
          history.push(ROUTES.DASHBOARD);
          setLoading(false);
        } else {
          notification.error({
            message: message.was_problem,
            description: responseLoginGoogle.message,
            placement: "bottom",
            duration: 4,
            closeIcon: <FiX className="i-24 white" />
          });
          setLoading(false);
        };
      } else {
        notification.error({
          message: message.was_problem,
          description: response.message,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
        setLoading(false);
      };
    };
  };

  const handleCreateAccountFacebook = async (token) => {
    if (token) {
      const body = { access: token, profile: answers, registerSource: "web" };

      const response = await registerAccountFacebook(body, referral);

      if (response.status === 200 || response.status === 201 || response.status === 204) {
        notification.success({
          message: message.success_create,
          description: message.success_create_description,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });

        const body = { access: token };
        const responseLoginFacebook = await authUserFacebook(body);

        if (responseLoginFacebook.status === 200 || responseLoginFacebook.status === 201 || responseLoginFacebook.status === 204) {
          history.push(ROUTES.DASHBOARD);
          setLoading(false);
        } else {
          setLoading(false);
          notification.error({
            message: message.was_problem,
            description: responseLoginFacebook.message,
            placement: "bottom",
            duration: 4,
            closeIcon: <FiX className="i-24 white" />
          });
        };
      } else {
        notification.error({
          message: message.was_problem,
          description: response.message,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
        setLoading(false);
      };
    };
  };

  return (
    <div>
      <CreateAccountForm
        loading={loading}
        setLoading={setLoading}
        accountCreate={handleCreateAccount}
        createAccountGoogle={handleCreateAccountGoogle}
        createAccountFacebook={handleCreateAccountFacebook}
      />
      <Footer />
    </div>
  );
};

export default observer(CreateAccount);