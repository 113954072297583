import { useState } from 'react';
import { Progress } from 'antd';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import Introduction from './components/Introduction';
import TypeSituation from './components/TypeSituation';
import StartInvesting from './components/StartInvesting';
import TakeRisks from './components/TakeRisks';
import FinalQuestions from './components/FinalQuestions';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './LevelingForm.module.less';

const LevelingForm = ({ situation, goal, investment, invite }) => {
  const [component, setComponent] = useState("Introduction");
  const [quiz, setQuiz] = useState({ situation: "", goal: "", investment: "" });
  const [count, setCount] = useState(0);
  const isMobile = useMedia({ maxWidth: 767 });

  const handleChangeStep = (value, count) => {
    setComponent(value);
    setCount(count);
  };

  const handleBackStep = () => {
    if (component === "StartInvesting") {
      setComponent("TypeSituation");
      setCount(0);
    } else {
      setComponent("StartInvesting");
      setCount(33);
    };
  };

  const handleAnswerQuiz = (name, value) => {
    setQuiz({ ...quiz, [name]: value });
  };

  const handleNewAccount = () => {
    if (invite) {
      history.push(ROUTES.CREATE_ACCOUNT, { quiz: quiz, invite: invite });
    } else {
      history.push(ROUTES.CREATE_ACCOUNT, { quiz: quiz });
    };
  };

  const renderComponents = () => {
    switch (component) {
      case "Introduction":
        return <Introduction changeStep={handleChangeStep} />;
      case "TypeSituation":
        return <TypeSituation
          text="Com qual das opções abaixo você mais se identifica?"
          situation={situation}
          quiz={quiz}
          answerQuiz={handleAnswerQuiz}
          changeStep={handleChangeStep}
        />;
      case "StartInvesting":
        return <StartInvesting
          text="Por que você quer começar a investir?"
          goal={goal}
          quiz={quiz}
          answerQuiz={handleAnswerQuiz}
          changeStep={handleChangeStep}
        />;
      case "TakeRisks":
        return <TakeRisks
          text="Quando o assunto é correr riscos, você se considera:"
          investment={investment}
          quiz={quiz}
          answerQuiz={handleAnswerQuiz}
          changeStep={handleChangeStep}
        />;
      case "FinalQuestions":
        return <FinalQuestions newAccount={handleNewAccount} />;
    };
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className={cx({ "mt-24 mb-24 flex justify-center item-center": (!isMobile && (component === "Introduction" || component === "FinalQuestions")), "mt-24 mb-24 flex flex-row": component !== "Introduction" && component !== "FinalQuestions" })}>
            {(!isMobile && (component === "Introduction" || component === "FinalQuestions")) && (
              <img src={logoColor} alt="logo-color" className="pointer" onClick={() => history.push(ROUTES.HOME)} />
            )}
            {(component !== "Introduction" && component !== "FinalQuestions") && (
              <div className={cx("flex justify-center item-center w-100", { "ml-24 mr-24": isMobile })}>
                <div className={cx("flex flex-row justify-center item-center", styles.progress)}>
                  {component !== "TypeSituation" && (
                    <button className="arkc-btn-link mr-14 mt-10" onClick={handleBackStep}>
                      <FiArrowLeft className="i-24" />
                    </button>
                  )}
                  <Progress percent={count} showInfo={false} />
                  <button className="arkc-btn-link ml-14 mt-10" onClick={() => setComponent("Introduction")}>
                    <FiX className="i-24" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isMobile && (
        <hr className="divider-internal" />
      )}
      <div className="container">
        <div className="content">
          {renderComponents()}
        </div>
      </div>
    </div>
  );
};

export default LevelingForm;