import { Modal, Button, Input, Form } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import styles from '../../ProfileForm.module.less';

const ModalPassword = ({ closePassword, updateMyProfile, setLoading, formPassword, visiblePassword, isMobile, loading }) => {
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value) {
        if (getFieldValue("password") !== value) {
          return Promise.reject(message.confirm_password)
        }
      };

      return Promise.resolve()
    },
  })

  const handleUpdateProfile = (value) => {
    const body = { password: value.password };

    updateMyProfile(body);
  };

  return (
    <Modal
      visible={visiblePassword}
      footer={null}
      onCancel={closePassword}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={closePassword}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center mt-32">
        {isMobile ? (
          <h2 className="text-center mb-40">Editar informações</h2>
        ) : (
          <h5 className="text-center mb-40">Editar informações</h5>
        )}
        <Form
          name="edit-password"
          layout="vertical"
          form={formPassword}
          onFinish={handleUpdateProfile}
          onFinishFailed={() => setLoading(false)}
          autoComplete="off"
        >
          <Form.Item
            label="Digite sua nova senha"
            name="password"
            rules={[
              {
                type: "string",
                required: true,
                min: 6,
                message: 'A senha é obrigatória.'
              },
              {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{6,}$/,
                message: 'A senha não está seguindo os requisitos minimos',
              }
            ]}
            className="mb-12"
          >
            <Input.Password placeholder="Nova senha" className="input-base" disabled={loading ? true : false} />
          </Form.Item>
          <Form.Item
            label="Confirme sua nova senha"
            name="confirmPassword"
            rules={[{ required: true, message: 'A confirmação da senha é obrigatória.' }, validateConfirmPassword]}
          >
            <Input.Password placeholder="Confirmar senha" className="input-base" disabled={loading ? true : false} />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
              className={styles.btnSave}
            >
              Salvar alteração
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalPassword;