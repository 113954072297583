import { useEffect, useState } from 'react';
import { Button } from 'antd';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import cx from 'classnames';
import { black, premium, beginner } from 'assets';
import styles from './Plans.module.less';
import "@egjs/flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";

const Plans = ({ createSubscription, viewPlan, isMobile, plansList }) => {
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
  }, []);

  const renderImagePlan = (item) => {
    switch (item) {
      case "beginner":
        return beginner;
      case "premium":
        return premium;
      case "black":
        return black;
      default:
        return beginner;
    };
  };

  const renderPricePlan = (item, value, id, planData) => {
    switch (item) {
      case "arkc-beginner":
        return (
          <div className={cx("flex flex-column justify-center item-center", styles.priceTopBeginner)}>
            <span className="f18 mine-shaft mb-2">Valor da assinatura</span>
            <h2 className={cx("melrose mb-24", { "f38": isMobile })}>Grátis</h2>
            <Button type="primary" className={styles.btnSize} onClick={() => viewPlan("beginner", id)}>Acessar agora</Button>
          </div>
        );
      case "arkc-premium":
        return (
          <div className={cx("flex justify-center item-center absolute", styles.priceBottom)}>
            <div className="flex flex-column justify-center item-center">
              <span className="f18 mine-shaft mb-2">Valor da assinatura por mês</span>
              <div className="flex flex-row justify-center">
                <span className="f14 mine-shaft mr-12 mt-12">R$</span>
                <h2 className={cx("texas-rose mb-24", { "f38": isMobile })}>{value.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 })}</h2>
              </div>
              <Button type="primary" className={styles.btnPremium} onClick={() => viewPlan("premium", id, planData)}>Ver mais</Button>
            </div>
          </div>
        );
      case "arkc-black":
        return (
          <div className={cx("flex justify-center item-center absolute", styles.priceBottom)}>
            <div className="flex flex-column justify-center item-center">
              <span className="f18 white mb-2">Valor da assinatura por mês</span>
              <div className="flex flex-row justify-center">
                <span className="f14 melrose mr-12 mt-12">R$</span>
                <h2 className={cx("melrose mb-24", { "f38": isMobile })}>{value.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 })}</h2>
              </div>
              <Button type="primary" className={styles.btnSize} onClick={() => viewPlan("black", id, planData)}>Ver mais</Button>
            </div>
          </div>
        );
      default:
        return (
          <div className={cx("flex justify-center item-center absolute", styles.priceBottom)}>
            <div className="flex flex-column justify-center item-center">
              <span className="f18 mine-shaft mb-2">Valor da assinatura por mês</span>
              <div className="flex flex-row justify-center">
                <span className="f14 mine-shaft mr-12 mt-12">R$</span>
                <h2 className={cx("melrose mb-24", { "f38": isMobile })}>{value.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 })}</h2>
              </div>
              <Button type="primary" className={styles.btnSize} onClick={() => createSubscription(planData)}>Ver mais</Button>
            </div>
          </div>
        );
    };
  };

  const renderDescriptionPlan = (name, description) => {
    switch (name) {
      case "arkc-beginner":
        return (
          <p className={cx("mb-32", styles.descriptionSize, { "f16": isMobile })}>
            Os investimentos do Arkc para iniciantes, comece por aqui
          </p>
        );
      case "arkc-premium":
        return (
          <p className={cx("mb-32", styles.descriptionSize, { "f16": isMobile })}>
            Os investimentos intermediários do Arkc, com foco em diversificação internacional
          </p>
        );
      case "arkc-black":
        return (
          <p className={cx("mb-32", styles.descriptionSize, { "f16": isMobile })}>
            A carteira avançada do Arkc, com foco em alta diversificação de investimentos e alta rentabilidade
          </p>
        );
      default:
        return (
          <p className={cx("mb-32", styles.descriptionSize, { "f16": isMobile })}>{description}</p>
        );
    };
  };

  return (
    <div className="mb-80">
      <div className="container">
        <div className="content">
          <div className="flex justify-center item-center mb-56">
            <h3 className={cx("mine-shaft text-center", styles.titleSize)}>
              Aprenda a investir na prática
            </h3>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className="flicking-view-plan">
          <Flicking circular={true} plugins={plugins} align="center">
            {_map(plansList, (item, index) => (
              <div key={index} className={cx("mr-24", {
                [styles.cardWhite]: item.identifier !== "arkc-black",
                [styles.cardBlack]: item.identifier === "arkc-black"
              })}
              >
                <div className="p-24 h-100 relative">
                  <div>
                    <img src={renderImagePlan(item.logo)} alt="icon-plans" className={styles.imageSize} />
                    <h5 className={cx("mt-24 mb-12", styles.titleMobile, { "mine-shaft": item.identifier !== "arkc-black", "white": item.identifier === "arkc-black" })}>
                      {`Assine agora o ${item.name}`}
                    </h5>
                    {renderDescriptionPlan(item.identifier, item.description)}
                    <h6 className={cx("mb-26", {
                      "mine-shaft": item.identifier !== "arkc-black",
                      "white": item.identifier === "arkc-black"
                    })}>
                      Investimentos da carteira
                    </h6>
                    {_map(item.features, (feature, index) => (
                      <div key={index}>
                        <span className={cx("f16", {
                          "mine-shaft": item.identifier !== "arkc-black",
                          "white": item.identifier === "arkc-black"
                        })}>
                          {feature.name}
                        </span>
                        <hr className={cx("divider mt-8", {
                          "mb-8": index !== _size(feature.features) - 1,
                          [styles.dividerColor]: item.identifier !== "arkc-black",
                          [styles.dividerBlack]: item.identifier === "arkc-black"
                        })} />
                      </div>
                    ))}
                  </div>
                  {renderPricePlan(item?.identifier, _get(item?.pricing, "value", ""), _get(item?.provisioning, "wallet", ""), item)}
                </div>
              </div>
            ))}
            <ViewportSlot>
              <div className="flicking-pagination"></div>
            </ViewportSlot>
          </Flicking>
        </div>
      ) : (
        <div className="flex justify-center item-center w-100">
          <div className={cx("flex flex-row", styles.containerPlans)}>
            {_map(plansList, (item, index) => (
              <div key={index} className={cx("p-24 relative", {
                "mr-24": index !== _size(plansList) - 1,
                [styles.cardWhite]: item.identifier !== "arkc-black",
                [styles.cardBlack]: item.identifier === "arkc-black"
              })}
              >
                <img src={renderImagePlan(item.logo)} alt="icon-plans" className={styles.imageSize} />
                <h5 className={cx("mt-24 mb-12", { "mine-shaft": item.identifier !== "arkc-black", "white": item.identifier === "arkc-black" })}>
                  {`Assine agora o ${item.name}`}
                </h5>
                {renderDescriptionPlan(item.identifier, item.description)}
                <h6 className={cx("mb-26", {
                  "mine-shaft": item.identifier !== "arkc-black",
                  "white": item.identifier === "arkc-black"
                })}>
                  Investimentos da carteira
                </h6>
                {_map(item.features, (feature, index) => (
                  <div key={index}>
                    <span className={cx("f16", {
                      "mine-shaft": item.identifier !== "arkc-black",
                      "white": item.identifier === "arkc-black"
                    })}>
                      {feature.name}
                    </span>
                    <hr className={cx("divider mt-8", {
                      "mb-8": index !== _size(feature.features) - 1,
                      [styles.dividerColor]: item.identifier !== "arkc-black",
                      [styles.dividerBlack]: item.identifier === "arkc-black"
                    })} />
                  </div>
                ))}
                {renderPricePlan(item?.identifier, _get(item?.pricing, "value", ""), _get(item?.provisioning, "wallet", ""), item)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Plans;