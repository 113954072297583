import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../LevelingForm.module.less';

const FinalQuestions = ({ newAccount }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className="flex flex-column justify-center item-center mt-56 mb-70">
      <img src={require("assets/finalForm.png")} alt="final-form" className={cx({ "mb-60": isMobile, "mb-40": !isMobile })} />
      <div className="flex flex-column justify-center item-center">
        <h1 className={cx("f24 mine-shaft text-center", styles.lineTitleFinal, { "mb-16": isMobile, "mb-12": !isMobile })}>
          Pronto, chegamos ao final das perguntas
        </h1>
        <p className={cx("gray text-center", styles.lineDescription, { "f18 mb-40": isMobile, "f16 mb-32": !isMobile })}>
          Agora que sabemos dos seus objetivos e da sua experiência com investimentos, você já pode começar a trilhar a sua jornada personalizada
        </p>
        <Button type="primary" className={cx("flex justify-center item-center", styles.btnFinish)} onClick={newAccount}>
          Começar agora
          <FiArrowRight className={cx("ml-14", styles.iconStart)} />
        </Button>
      </div>
    </div>
  );
};

export default FinalQuestions;