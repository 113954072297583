import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { logoBeginner, arckBeginner, exclusiveBeginner, graphicPizzaBeginner, opportunitiesBeginner, logoColor } from 'assets';
import styles from './LandingPageBeginnerForm.module.less';
import "@egjs/flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";

const LandingPageBeginnerForm = ({ buyPlan, price, worksBeginner, planBeginner, graphicImage, benefits }) => {
  const [plugins, setPlugins] = useState([]);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 });

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
  }, []);

  return (
    <div>
      <div className={cx("container", styles.headerBeginner)}>
        <div className="content mt-24 mb-24">
          <div className="flex flex-row justify-center item-center">
            <img src={logoColor} alt="logo-color" className="pointer" />
          </div>
        </div>
      </div>
      <div className={styles.imageBackground}>
        <img src={logoBeginner} alt="logbeginner" className={styles.imageBgSize} />
      </div>
      <div className="flex flex-column justify-center item-center mt-82">
        <div className={styles.badgeBeginner}>
          <div className="flex flex-row item-center p-12">
            <img src={arckBeginner} alt="arck-beginner" />
            <span className="f18 mine-shaft b ml-8">Arkc Beginner</span>
          </div>
        </div>
        <h2 className={cx("text-center mt-24 mb-24", styles.titleBeginner)}>
          O essencial pra você começar a investir
        </h2>
        <p className={cx("f18 text-center mb-40", styles.descriptionBeginner)}>
          Assine gratuitamente a Arkc Beginner e aprenda a investir na prática com a carteira para iniciantes do Arkc.
        </p>
        <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Assinar</Button>
        <div className="container">
          <div className="content">
            {isMobile ? (
              <div className="flicking-view-plans">
                <Flicking circular={true} plugins={plugins} align="prev">
                  {_map(benefits, (item, index) => (
                    <div key={index} className={cx(styles.cardBeginner, "mr-24")}>
                      <div className="p-24">
                        <div className="mb-12">
                          <div className={styles.bgIcon}>
                            <div className="flex justify-center item-center h-100">
                              <img src={item.path} alt="benefits-plan" />
                            </div>
                          </div>
                        </div>
                        <span className="f18 b mine-shaft">{item.title}</span>
                        <p className={cx("f16 mine-shaft mt-6", styles.cardDescription)}>{item.description}</p>
                      </div>
                    </div>
                  ))}
                  <ViewportSlot>
                    <div className="flicking-pagination"></div>
                  </ViewportSlot>
                </Flicking>
              </div>
            ) : (
              <div className={styles.blockCards}>
                {_map(benefits, (item, index) => (
                  <div key={index} className={cx(styles.cardBeginner, { "mr-24": isMobile || isTablet && index !== _size(benefits) - 1, "mr-30": !isMobile && !isTablet && index !== _size(benefits) - 1 })}>
                    <div className="p-24">
                      <div className="mb-12">
                        <div className={styles.bgIcon}>
                          <div className="flex justify-center item-center h-100">
                            <img src={item.path} alt="benefits-plan" />
                          </div>
                        </div>
                      </div>
                      <span className="f18 b mine-shaft">{item.title}</span>
                      <p className={cx("f16 mine-shaft mt-6", styles.cardDescription)}>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.bgMagnolia}>
          <div className="container mt-40 mb-40">
            <div className="content">
              <div className={cx("flex", { "flex-column": isMobile || isTablet, "flex-row": !isMobile && !isTablet })}>
                <div className={cx({ "flex flex-column justify-center item-center": isMobile || isTablet })}>
                  {isMobile ? (
                    <>
                      <h6 className="fw4 mine-shaft mb-24">Rentabilidade de 5 anos</h6>
                      <h2 className={cx("mb-16", styles.historyBeginner)}>Rentabilidade histórica Arkc Beginner</h2>
                    </>
                  ) : (
                    <>
                      <h5 className="mine-shaft mb-24">Rentabilidade de 5 anos</h5>
                      <h3 className={cx("mb-48", styles.historyBeginner)}>Rentabilidade histórica Arkc Beginner</h3>
                    </>
                  )}
                  <div className={cx("mb-16", styles.badgeProfitability)}>
                    <div className="flex justify-center item-center h-100">
                      <span className="f16 b white">Arkc Beginner +41,52%</span>
                    </div>
                  </div>
                  <div className={styles.badgeSavings}>
                    <div className="flex justify-center item-center h-100">
                      <span className="f16 b mine-shaft">Poupança +19,31%</span>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.bgGraphic, { "mt-40": isMobile || isTablet, "ml-66": !isMobile && !isTablet && !isDesktop })}>
                  <div className={styles.paddingGraphic}>
                    {isMobile ? (
                      <div className="flex flex-row justify-between item-center mb-40">
                        <p className="f16 mine-shaft">Rentabilidade Poupança + 19,31%</p>
                        <h6 className="f16 heliotrope"> Carteira Arkc Beginner + 41,52%</h6>
                      </div>
                    ) : (
                      <>
                        <h5 className="heliotrope"> Carteira Arkc Beginner + 41,52%</h5>
                        <p className="f18 b mine-shaft mt-12">Rentabilidade Poupança + 19,31%</p>
                      </>
                    )}
                    <div className={cx({ "flex justify-center item-center": isTablet, "mt-32": !isMobile && !isTablet })}>
                      <img src={graphicImage} alt="graphic" className={styles.graphicImage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("flex justify-center item-center", { "flex-column mt-64 mb-64": isMobile || isTablet, "flex-row mt-120 mb-120": !isMobile && !isTablet })}>
          <div className={cx({ "mb-40": isMobile || isTablet, "mr-78": !isMobile && !isTablet })}>
            <img src={opportunitiesBeginner} alt="opportunities-beginner" className={styles.opportunitiesImage} />
          </div>
          <div className={cx({ "flex flex-column justify-center item-center": isMobile })}>
            {isMobile ? (
              <h3 className={cx("mb-12", styles.opportunitiesTitle)}>Alertas sobre o momento de mercado</h3>
            ) : (
              <h4 className={cx("mb-24", styles.opportunitiesTitle)}>Alertas sobre o momento de mercado</h4>
            )}
            <p className={cx("f16", styles.opportunitiesDescription, { "mb-24": isMobile || isTablet, "mb-40": !isMobile && !isTablet })}>
              Conheça os investimentos do Arkc de acordo com o momento do mercado, com o Arkc Beginner você fica sempre informado de forma rápida e simplificada.
            </p>
            <Button type="primary" className={styles.btnSignTwo} onClick={buyPlan}>Assinar</Button>
          </div>
        </div>
        <div className={styles.badgeWorks}>
          <div className={cx("flex flex-column justify-center item-center h-100", styles.paddingBadge)}>
            <div className={styles.badgeBeginner}>
              <div className="flex flex-row item-center p-12">
                <img src={arckBeginner} alt="arck-beginner" />
                <span className="f18 mine-shaft b ml-8">Arkc Beginner</span>
              </div>
            </div>
            {isMobile ? (
              <h2 className="mt-32 mb-12">Como funciona?</h2>
            ) : (
              <h4 className="mt-24 mb-12">Como funciona?</h4>
            )}
            <p className={cx("f18 mine-shaft text-center", styles.badgeDescription)}>
              Ao assinar Arkc Beginner você ganha acesso a área de membros exclusiva dos assinantes
            </p>
            <div className={cx("flex", { "flex-column mt-32": isMobile, "flex-row item-center mt-70": !isMobile })}>
              {_map(worksBeginner, (item, index) => (
                <div key={index} className={cx({ "mb-48": isMobile && index !== _size(worksBeginner) - 1, "mr-48": !isMobile && index !== _size(worksBeginner) - 1 })}>
                  <img src={item.id === 1 ? exclusiveBeginner : graphicPizzaBeginner} alt="type-area" />
                  <div className={cx({ "mt-16": isMobile, "mt-32": !isMobile })}>
                    <h5 className="mine-shaft mb-12">{item.title}</h5>
                    <p className={cx("f16", styles.descriptionWorks)}>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={cx("flex flex-column justify-center item-center", { "mt-64 mb-24": isMobile || isTablet, "mt-72 mb-80": !isMobile && !isTablet })}>
          <span className="f18 melrose mb-8">Aprenda a investir com o Arkc Beginner</span>
          {isMobile ? (
            <h1 className={cx("text-center mb-24", styles.titlePlanBeginner)}>Investimentos da carteira</h1>
          ) : (
            <h2 className={cx("text-center mb-32", styles.titlePlanBeginner)}>Investimentos da carteira</h2>
          )}
          {_map(planBeginner, (item, index) => (
            <div key={index}>
              <div className="flex flex-row justify-between item-center">
                <span className="f14 mine-shaft">{item.title}</span>
                <span className="silver">{item.description}</span>
              </div>
              <hr className={cx("divider mt-8 mb-8", styles.dividerBeginner)} />
            </div>
          ))}
          <div className={cx("flex flex-column justify-center item-center mt-40", styles.price)}>
            <span className={cx("f18 mine-shaft", { "mb-8": isMobile || isTablet, "mb-2": !isMobile && !isTablet })}>Valor da assinatura</span>
            <div className="flex flex-row justify-center mb-24">
              <span className={cx("f14 mine-shaft mr-12", { "b": isMobile || isTablet, "mt-12": !isMobile && !isTablet })}>R$</span>
              <h2 className={cx("melrose", { "f56": isMobile || isTablet })}>{price.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 })}</h2>
              <span className={cx("f14 mine-shaft ml-8", { "mt-20": isMobile, "mt-36": !isMobile })}>por mês</span>
            </div>
            <Button type="primary" className={styles.btnSize} onClick={buyPlan}>Assinar</Button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LandingPageBeginnerForm;