import React from 'react';
import { create } from 'mobx-persist';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import { Login } from 'hooks/loginStore';
import { Lessons } from 'hooks/lessonsStore';
import { PlansArkc } from 'hooks/plansStore';
import { User } from 'hooks/userStore';

export const stores = {
  login: new Login(),
  lessons: new Lessons(),
  plansArkc: new PlansArkc(),
  user: new User(),
};

const hydrate = create();

const blacklist = ["lessons", "plansArkc", "user"];

_map(stores, (store, name) => {
  if (_includes(blacklist, name)) {
    return;
  };

  hydrate(name, store)
});

export const StoresContext = React.createContext(stores);