import { Modal, Button, Input, Form } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import styles from '../../ProfileForm.module.less';

const ModalEmail = ({ closeEmail, updateMyProfile, setLoading, formEmail, visibleEmail, isMobile, loading }) => {
  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const regexEmailOnlyCharacters = new RegExp("[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ@. ]+$");
        const regexEmailAddress = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!regexEmailOnlyCharacters.test(value)) {
          return Promise.reject(message.validate_name);
        };

        if (!regexEmailAddress.test(value)) {
          return Promise.reject(message.validate_email)
        };
      }

      return Promise.resolve()
    }
  });

  const validateConfirmEmail = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value) {
        if (getFieldValue("email") !== value) {
          return Promise.reject(message.confirm_email)
        }
      };

      return Promise.resolve()
    },
  })

  const handleUpdateEmail = (value) => {
    const body = { email: value.email };

    updateMyProfile(body);
  };

  return (
    <Modal
      visible={visibleEmail}
      footer={null}
      onCancel={closeEmail}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={closeEmail}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center mt-32">
        {isMobile ? (
          <h2 className="text-center mb-40">Editar informações</h2>
        ) : (
          <h5 className="text-center mb-40">Editar informações</h5>
        )}
        <Form
          name="edit-email"
          layout="vertical"
          form={formEmail}
          onFinish={handleUpdateEmail}
          onFinishFailed={() => setLoading(false)}
          autoComplete="off"
        >
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, message: 'O email é obrigatório.' }, validateEmail]}
            className="mb-12"
          >
            <Input placeholder="Seu e-mail" className="input-base" disabled={loading ? true : false} />
          </Form.Item>
          <Form.Item
            name="emailConfirm"
            rules={[{ required: true, message: 'O email é obrigatório.' }, validateConfirmEmail]}
          >
            <Input placeholder="Confirme seu e-mail" className="input-base" disabled={loading ? true : false} />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setLoading(true)}
              loading={loading}
              className={styles.btnSave}
            >
              Salvar alteração
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalEmail;