import Lottie from 'react-lottie';
import * as animationData from './rocket.json'

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <Lottie options={defaultOptions}
        height={120}
        width={120}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export default Loading;