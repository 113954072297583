import { action } from 'mobx';
import _get from 'lodash/get';
import { handlerTokenExpired, authHeader } from 'helpers/serviceHelpers';
import { axiosInstace } from 'service';

export class PlansArkc {
  @action
  plansAll = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get("/billing/product/findAll/", requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  userWalletsAll = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get("/content/wallet/getUserWallets/", requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  investmentsAll = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get("/content/investmentCategory/findAll/", requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  investmentsType = async (category) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/content/wallet/getUserWalletsRecommendation/", category, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  mySubscriptions = async () => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/billing/contract/getCards/", {}, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  activateSubscriptions = async (id) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.put(`/billing/contract/activateAutoRenewal/${id}/`, {}, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  disableSubscriptions = async (id) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.put(`/billing/contract/disableAutoRenewal/${id}/`, {}, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  cancelSubscription = async (contract) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/billing/subscription/cancel/", contract, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  plansArkcPayment = async (body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.get("/billing/checkout/", body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };

  @action
  createSubscription = async (body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/billing/subscription/", body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: error.response.status, messages: error.response.data?.message };
      };
    };
  };

  @action
  planById = async (body) => {
    try {
      const token = authHeader();
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json',
          ...token
        }
      };

      const response = await axiosInstace.post("/billing/product/findByIdentifier/", body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      if (error.response.status === 401) {
        handlerTokenExpired();
      } else {
        return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
      };
    };
  };
};