import { Breadcrumb, Button } from 'antd';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import Header from '../Header';
import { arckBlack, arckPremium } from 'assets';
import { ROUTES } from 'routes/routes';
import styles from './CheckoutForm.module.less';

const CheckoutForm = ({ createSubscription, plansList, loadingSub }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div>
      <div className="container">
        <div className="content mt-24 mb-24">
          <Header route={ROUTES.DASHBOARD} />
        </div>
      </div>
      <hr className="divider-internal mb-38" />
      <div className="container">
        <div className="content">
          <Breadcrumb separator="">
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Planos</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Selecionar plano</Breadcrumb.Item>
          </Breadcrumb>
          <div className="mt-24">
            {!isMobile ? (
              <h4 className="text-center">Escolha sua carteira de investimento</h4>
            ) : (
              <h5 className="text-center">Escolha sua carteira de investimento</h5>
            )}
            <div className={cx("flex", { "flex-column justify-center item-center mt-24": isMobile, "flex-row justify-center item-center mt-32": !isMobile })}>
              {_map(plansList, (item, index) => (
                <div key={index} className={cx(styles.badgePlan, { "mb-24": index !== _size(plansList) - 1 && isMobile, "mr-32": index !== _size(plansList) - 1 && !isMobile })}>
                  <div className={styles.badgePlanPadding}>
                    <div className="flex flex-column justify-center item-center">
                      <div className={cx({ [styles.badgePremium]: item.lookup_key === "arkc-premium", [styles.badgeBlack]: item.lookup_key === "arkc-black" })}>
                        <div className="flex flex-row item-center p-12">
                          <img src={item.lookup_key === "arkc-premium" ? arckPremium : arckBlack} alt="arck-premium" />
                          <span className={cx("f18 b ml-8", { "mine-shaft": item.lookup_key === "arkc-premium", "white": item.lookup_key === "arkc-black" })}>
                            {item.lookup_key === "arkc-premium" ? "Arkc Premium" : "Arkc Black"}
                          </span>
                        </div>
                      </div>
                      <p className={cx("f16 mine-shaft mt-16 mb-24 text-center", styles.descriptionSize)}>
                        {item.lookup_key === "arkc-premium" ? "Os investimentos intermediários do Arkc, com foco em diversificação internacional." : "A carteira avançada do Arkc, com foco em alta diversificação de investimentos e alta rentabilidade."}
                      </p>
                      <span className="f22 b mine-shaft mb-16">
                        {(item.unit_amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}
                      </span>
                      <Button
                        type="default"
                        disabled={loadingSub ? true : false}
                        className={styles.btnSelect}
                        onClick={() => createSubscription(item.id, item.lookup_key)}
                      >
                        Selecionar
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;