import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import DeleteAccountForm from 'components/DeleteAccountForm';
import Footer from 'components/Footer';

const DeleteAccount = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [optionSelected, setOptionSelected] = useState({ predefined: 1, other: "" });
  const { login, user } = useStore();
  const { userDeleteProfile } = user;
  const { logout } = login;

  const handleChangeValue = (event) => {
    setOptionSelected({ ...optionSelected, predefined: event.target.value });
  };

  const handleChangeValueOther = (event) => {
    setOptionSelected({ ...optionSelected, other: event.target.value });
  };

  const handleDeleteMyAccount = async () => {
    setLoading(true);

    const response = await userDeleteProfile(optionSelected);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setLoading(false);
      logout();
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  return (
    <div>
      <DeleteAccountForm
        loading={loading}
        visible={visible}
        optionSelected={optionSelected}
        setVisible={setVisible}
        setOptionSelected={setOptionSelected}
        changeValue={handleChangeValue}
        changeValueOther={handleChangeValueOther}
        deleteMyAccount={handleDeleteMyAccount}
      />
      <Footer />
    </div>
  );
};

export default observer(DeleteAccount);