import { Button } from 'antd';
import _map from 'lodash/map';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from './Portfolios.module.less';

const Portfolios = ({ startGo, topics }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  return (
    <div className="bg-magnolia">
      <div className={cx("flex flex-column", { "ml-32 mr-32": isMobile, "ml-24 mr-24": isTablet, "justify-center item-center": !isMobile })}>
        <span className={cx("f14 heliotrope b mb-8", { "mt-80": isMobile, "mt-96": !isMobile })}>Invista na prática</span>
        <h2 className={cx("mine-shaft", styles.titlePort, { "f40 mb-16": isMobile, "text-center mb-8": !isMobile })}>
          Comece a investir agora!
        </h2>
        <p className={cx("f24 fw4 gray mb-40", styles.p1, { "text-center": !isMobile })}>
          Imagine alcançar seus sonhos, ter segurança e tranquilidade no futuro, sem se preocupar com dinheiro.
          Isso é possível, Aprenda de um forma divertida as melhores e mais lucrativas formas de investir.
        </p>
        <div className="mb-64">
          <div className={styles.gridContainer}>
            {_map(topics, (item, index) => (
              <div key={index} className={styles.cardBase}>
                <div className={cx({ "p-24": isMobile || isTablet, "p-32": !isMobile && !isTablet })}>
                  <div className={styles.backgroundTopic}>
                    <span className="f16 fw5 white">{item.topic}</span>
                  </div>
                  <p className={cx("f18 fw4 black mt-16", styles.descriptionTopic)}>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center item-center mt-32">
            <Button type="primary" className={styles.startNow} onClick={startGo}>Começar agora</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolios;