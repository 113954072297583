import { Button } from 'antd';
import { history } from 'helpers/history';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from './UserMessage.module.less';

const UserMessage = ({ title, description, route, label, image }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  const handleGoRoute = () => {
    history.push(route);
  };

  return (
    <div className={cx("flex flex-column justify-center item-center", styles.topTitle)}>
      <img src={image} alt="image-message" />
      <h5 className={cx("mt-40 mb-12", styles.titleSize)}>{title}</h5>
      <p className={cx("f16 mine-shaft", styles.description, { "mb-32": isMobile, "mb-40": !isMobile })}>{description}</p>
      <Button type="primary" className={styles.btnUnderstood} onClick={handleGoRoute}>{label}</Button>
    </div>
  );
};

export default UserMessage;