import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import Loading from 'components/Loading';
import LessonDetailForm from 'components/LessonDetailForm';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const LessonDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [lessonId, setLessonId] = useState();
  const [lessonDetails, setLessonDetails] = useState();
  const [percentage, setPercentage] = useState(0);
  const [lessonListDetails, setLessonListDetails] = useState([]);
  const { lessons } = useStore();
  const { detailsLesson, detailsListLesson, lessonProgress } = lessons;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.id) {
      retrieveDetailsLesson(state.id);
      retrieveDetailsListLesson(state.id);
      retrieveProgressLesson(state.id);
      setLessonId(state.id);
    } else {
      history.push(ROUTES.DASHBOARD)
    };
  }, []);

  const retrieveDetailsLesson = async (id) => {
    setLoading(true);

    const response = await detailsLesson(id);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const newReponse = {
        id: response.data._id,
        logo: response.data.logo,
        name: response.data.name,
        level: response.data.level,
        color: response.data.color
      };

      setLessonDetails(newReponse);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const retrieveProgressLesson = async (id) => {
    const response = await lessonProgress(id);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      if (response.data?.total) {
        setPercentage(response.data.total);
      };
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };

  const retrieveDetailsListLesson = async (id) => {
    setLoading(true);

    const response = await detailsListLesson({ trail: id });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setLessonListDetails(response.data);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false)
    };
  };

  const handleDoWork = (identifier, type) => {
    if (lessonId) {
      history.push(ROUTES.TRAIL_LESSON, { id: lessonId, identifier: identifier, lessonType: type });
    }
  };

  const handleMakeChallengeLesson = (challenge) => {
    history.push(ROUTES.CHALLENGE_LESSON, { id: lessonId, challenge: challenge });
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <LessonDetailForm
          loading={loading}
          lessonDetails={lessonDetails}
          percentage={percentage}
          lessonListDetails={lessonListDetails}
          doWork={handleDoWork}
          makeChallenge={handleMakeChallengeLesson}
        />
      )}
    </div>
  );
};

export default observer(LessonDetail);