import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import LevelingForm from 'components/LevelingForm';
import { situation, goal, investment } from 'mock';

const Leveling = () => {
  const param = useParams();

  return (
    <LevelingForm
      situation={situation}
      goal={goal}
      investment={investment}
      invite={_get(param, "invite", "")}
    />
  );
};

export default Leveling;