import { Drawer, Form, Input, Button } from 'antd';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FiX } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor, facebook, google } from 'assets';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './LoginForm.module.less';

const LoginForm = ({ loginFacebook, loginGoogle, login, setVisible, setLoading, visible, loading }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const regexEmailOnlyCharacters = new RegExp("[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ@. ]+$");
        const regexEmailAddress = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!regexEmailOnlyCharacters.test(value)) {
          return Promise.reject(message.validate_name);
        };

        if (!regexEmailAddress.test(value)) {
          return Promise.reject(message.validate_email)
        };
      }

      return Promise.resolve()
    }
  });

  const handleRecoverPassword = () => {
    history.push(ROUTES.RECOVER_PASSWORD);
  };

  const callbackGoogle = (response) => {
    const body = { access: response?.accessToken };
    loginGoogle(body);
  };

  const callbackFacebook = (response) => {
    const body = { access: response?.accessToken };
    loginFacebook(body);
  };

  return (
    <div className={styles.drawerTeste}>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setVisible(!visible)}
        visible={visible}
        key="left"
        width={!isMobile ? 475 : "100%"}
        className={styles.drawer}
        bodyStyle={isMobile ? { padding: "32px 24px" } : { padding: "40px 48px" }}
      >
        <div className="flex flex-row">
          <button className="arkc-btn-link" onClick={() => setVisible(!visible)}>
            <FiX className="i-24" />
          </button>
          <div className="flex justify-center item-center w-100">
            <img src={logoColor} alt="logo-login" />
          </div>
        </div>
        <div className={styles.topTitle}>
          <p className="f24 b mine-shaft">Conecte a sua conta</p>
        </div>
        <div className="flex flex-column justify-center item-center">
          <Form
            name="login"
            onFinish={login}
            onFinishFailed={() => setLoading(false)}
            autoComplete="off"
            className="w-100"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'O email é obrigatório.' }, validateEmail]}
              className="mb-12"
            >
              <Input
                placeholder="Digite seu email"
                disabled={loading ? true : false}
                className="input-base"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  type: "string",
                  required: true,
                  min: 6,
                  message: 'A senha é obrigatória.'
                }
              ]}
              className={cx({ "mb-26": isMobile, "mb-32": !isMobile })}
            >
              <Input.Password
                placeholder="Digite sua senha"
                disabled={loading ? true : false}
                className="input-base"
              />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setLoading(true)}
                loading={loading}
                className={styles.btnLogin}
              >
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </div>
        <button className="arkc-btn-link mt-12" onClick={handleRecoverPassword}>
          <span className="f16 b heliotrope underline">Esqueceu sua senha?</span>
        </button>
        <hr className="divider mt-24 mb-24" />
        <div className={cx("flex flex-row", { "justify-between": isMobile })}>
          {/* <FacebookLogin
            appId={process.env.REACT_APP_ID_FACEBOOK}
            isMobile={false}
            disableMobileRedirect={true}
            callback={callbackFacebook}
            render={renderProps => (
              <button className={cx("arkc-btn-link", styles.btnFake, { "mr-18": !isMobile })} onClick={renderProps.onClick}>
                <span className="f14 b mine-shaft pointer">Facebook</span>
                <img src={facebook} alt="facebook" className="pointer ml-14" />
              </button>
            )}
          /> */}
          <GoogleLogin
            clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}
            render={renderProps => (
              <button className={cx("arkc-btn-link", styles.btnFake)} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                <span className="f14 b mine-shaft">Google</span>
                <img src={google} alt="google" className="ml-14" />
              </button>
            )}
            buttonText="Login"
            onSuccess={callbackGoogle}
            onFailure={callbackGoogle}
            cookiePolicy={'single_host_origin'}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default LoginForm;