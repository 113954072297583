import { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { FiArrowLeft, FiArrowRight, FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-circular-progressbar/dist/styles.css';
import styles from '../DashboardWalletForm.module.less';

const Investments = ({ viewInvestment, profitability, investmentsType, plan, investments }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [detailData, seDetailData] = useState();

  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const refInvest = useRef(null);
  const refLast = useRef(null);

  const handleScrollWhereInvest = (value) => {
    refInvest.current.scrollLeft += value;
  };

  const handleScrollLastOpportunities = (value) => {
    refLast.current.scrollTop += value;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className={cx("custom-tooltip", styles.tooltipProfitability)}>
          {_map(payload, (item, index) => (
            <div key={index} className={cx("flex flex-row item-center", { "mb-8": index !== _size(payload) - 1 })}>
              <div className={styles.teste} style={{ backgroundColor: `${item.color}`, borderColor: `${item.color}` }} />
              <label className="white ml-6">
                {`${item.name}: ${item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`}
              </label>
            </div>
          ))}
        </div>
      );
    }
  };

  const handleModalDetails = (data) => {
    setIsModalVisible(true)

    const newData = { ...data, detail: _get(data, "detail", "") };

    seDetailData(newData);
  };

  return (
    <>
      <div className={styles.bgInvestments}>
        <div className={cx({ "p-24": isMobile, "p-18": !isMobile })}>
          <div className={styles.blocFlut}>
            {isMobile ? (
              <h5>Como investir</h5>
            ) : (
              <div className="flex flex-row justify-between item-center">
                <h5>Como investir</h5>
                {_size(investmentsType) > 4 && (
                  <div className="flex flex-row">
                    <Button className={cx("mr-20", styles.buttonArrow, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollWhereInvest(-100)}>
                      <FiArrowLeft />
                    </Button>
                    <Button className={cx(styles.buttonArrow, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollWhereInvest(100)}>
                      <FiArrowRight />
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div className="flex justify-center item-center w-100">
              <div className={styles.scrollActions} ref={refInvest}>
                <div className="p-4">
                  <div className="flex flex-row item-center mt-24">
                    {_map(investmentsType, (item, index) => (
                      <div key={index} className={styles.cardEmphasis}>
                        <button
                          className={cx("arkc-btn-link", { "mr-16": index !== _size(investmentsType) - 1 })}
                          onClick={() => viewInvestment(item.name, item.identifier, item.logo)}
                        >
                          <div className={styles.cardInvestment} style={{ background: `${item.color}` }}>
                            <div className="p-16">
                              <h5 className="f18 text-start">{item.name}</h5>
                              <div className="flex flex-row justify-between mt-34">
                                <h4 className="f28">{item?.totalInvestments}</h4>
                                <img
                                  src={item.logo}
                                  alt="actual-investiments"
                                  className={styles.imageInvestments}
                                />
                              </div>
                              <div className="flex flex-row justify-between item-center">
                                <span className="f18 gray">{item?.totalInvestments <= 1 ? "Investimento" : "Investimentos"}</span>
                                <FiArrowRight className="i-14 mine-shaft" />
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            {(isMobile || isTablet) ? (
              <div className="mb-32">
                <h5>Rentabilidade</h5>
              </div>
            ) : (
              <div className="mb-12">
                <h5>Rentabilidade</h5>
              </div>
            )}
            <div className="flex justify-center item-center">
              <AreaChart width={isMobile ? (window.screen.width - 48) : isTablet ? 600 : 750} height={250} data={profitability.data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#A4FFE6" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#A4FFE6" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#C9B3FF"} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#C9B3FF"} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis hide={true} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={36} />
                <Area type="monotone" dataKey={profitability.typeOne} stroke={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#C9B3FF"} fillOpacity={1} fill="url(#colorPv)" />
                <Area type="monotone" dataKey={profitability.typeTwo} stroke="#A4FFE6" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
            </div>
          </div>
          <div className="mt-24">
            {(isMobile || isTablet) ? (
              <div className="mb-32">
                <h5>Últimas atualizações</h5>
              </div>
            ) : (
              <div className="mb-12">
                <h5>Últimas atualizações</h5>
              </div>
            )}
            {isMobile ? (
              <div className="flex flex-row justify-center item-center">
                <div className={cx("w-100", styles.scrollActionsLast)} ref={refLast}>
                  {_map(investments?.lastOpportunities, (opportunities, index) => (
                    <button key={index} className="arkc-btn-link w-100" onClick={() => handleModalDetails(opportunities)}>
                      <div className="flex flex-row justify-between item-center">
                        <div className="flex flex-row item-center">
                          <div className="mr-8">
                            <div className={styles.bgImage}>
                              <img src={opportunities.logo} className="i-24" />
                            </div>
                          </div>
                          <span className="f22 mine-shaft b">{opportunities?.name}</span>
                        </div>
                        <div>
                          <p className="f14 gray">{moment(opportunities.date).format("LL")}</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between item-center mt-24">
                        <div>
                          <span className="f16 gray">Oportunidade</span>
                          <p className="f16 mine-shaft mt-4">{opportunities?.instruction}</p>
                        </div>
                        <div>
                          <span className="f16 gray">Motivo</span>
                          <p className={cx("f16 mine-shaft mt-4", styles.reasonSize)}>{opportunities?.reason}</p>
                        </div>
                      </div>
                      {index !== _size(investments?.lastOpportunities) - 1 && (
                        <hr className={cx("divider mt-24 mb-24", styles.divider)} />
                      )}
                    </button>
                  ))}
                </div>
                {_size(investments?.lastOpportunities) > 4 && (
                  <div className="flex flex-column ml-12">
                    <Button className={cx("mb-26", styles.buttonArrowTB, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollLastOpportunities(-100)}>
                      <FiArrowUp />
                    </Button>
                    <Button className={cx(styles.buttonArrowTB, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollLastOpportunities(100)}>
                      <FiArrowDown />
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-row justify-center item-center">
                <div className={cx("w-100", styles.scrollActionsLast)} ref={refLast}>
                  <div className="flex flex-column item-center">
                    {_map(investments?.lastOpportunities, (opportunities, index) => (
                      <button
                        key={index}
                        className={cx("flex arkc-btn-link", { "w-100": isTablet, "mb-12": index !== _size(investments?.lastOpportunities) - 1 }, styles.borderLastOpportunities)}
                        onClick={() => handleModalDetails(opportunities)}
                      >
                        <div className={styles.paddingLast}>
                          <div className="flex flex-row">
                            <div className="mr-16">
                              <div className={styles.bgImage}>
                                <img src={opportunities.logo} className="i-24" />
                              </div>
                            </div>
                            <div className="text-center">
                              <span className="f18 mine-shaft b">{opportunities?.name}</span>
                              <p className="f12 gray mt-4">{moment(opportunities.date).format("LL")}</p>
                            </div>
                          </div>
                          <div className="text-center">
                            <span className="f16 gray">O que fazer</span>
                            <p className="f16 mine-shaft mt-4">{opportunities?.instruction}</p>
                          </div>
                          <div className="text-center">
                            <span className="f16 gray">Motivo</span>
                            <p className={cx("f16 mine-shaft mt-4", styles.reasonSize)}>{opportunities?.reason}</p>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
                {_size(investments?.lastOpportunities) > 3 && (
                  <div className="flex flex-column ml-12">
                    <Button className={cx("mb-26", styles.buttonArrowTB, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollLastOpportunities(-100)}>
                      <FiArrowUp />
                    </Button>
                    <Button className={cx(styles.buttonArrowTB, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => handleScrollLastOpportunities(100)}>
                      <FiArrowDown />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(!isModalVisible)}
        bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
        className="modal-complete"
        centered={!isMobile ? true : false}
      >
        <span className={styles.badgeTitle}>Detalhes {" "} {_get(detailData, "name", "") || "Sem detalhe"}</span>
        <div className={cx("mt-14", { [styles.scrollModal]: isMobile })}>
          <span className={styles.detailText}>{_get(detailData, "detail", "") || "Sem detalhes."}</span>
        </div>
        <Button
          type="primary"
          onClick={() => setIsModalVisible(!isModalVisible)}
          className="mt-12 w-100"
        >
          Fechar
        </Button>
      </Modal>
    </>
  );
};

export default Investments;