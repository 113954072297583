import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import FormLogin from 'components/FormLogin';
import { message } from 'helpers/messages';
import { ROUTES } from 'routes/routes';
import { history } from 'helpers/history';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useStore();
  const { authUserGoogle, authUserFacebook, authUser } = login;

  const handleUserLogin = async (params) => {
    const response = await authUser(params);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      history.push(ROUTES.DASHBOARD);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleUserLoginFacebook = async (body) => {
    const response = await authUserFacebook(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      history.push(ROUTES.DASHBOARD);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleUserLoginGoogle = async (body) => {
    const response = await authUserGoogle(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      history.push(ROUTES.DASHBOARD);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  return (
    <div>
      <FormLogin
        loading={loading}
        setLoading={setLoading}
        login={handleUserLogin}
        loginFacebook={handleUserLoginFacebook}
        loginGoogle={handleUserLoginGoogle}
      />
    </div>
  );
};

export default observer(Login);