import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import _get from 'lodash/get';
import { axiosInstace } from 'service';

export class Login {
  @persist('object')
  @observable
  userLogged = null;

  @action
  authUser = async (body) => {
    try {
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json'
        }
      };

      const response = await axiosInstace.post("/auth/login/", body, requestOptions)

      this.userLogged = response.data;
      localStorage.setItem("userArkc", JSON.stringify(response.data));
      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  authUserFacebook = async (body) => {
    try {
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json'
        }
      };

      const response = await axiosInstace.post("/auth/oauth2/callback/facebook/login/", body, requestOptions)

      this.userLogged = response.data;
      localStorage.setItem("userArkc", JSON.stringify(response.data));
      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  authUserGoogle = async (body) => {
    try {
      const requestOptions = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Content-Type': 'application/json'
        }
      };

      const response = await axiosInstace.post("/auth/oauth2/callback/google/login/", body, requestOptions)

      this.userLogged = response.data;
      localStorage.setItem("userArkc", JSON.stringify(response.data));
      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  registerAccount = async (body, referral) => {
    const requestOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    };

    try {
      const haveReferral = referral ? `/auth/register/?referral=${referral}` : "/auth/register/";
      const response = await axiosInstace.post(haveReferral, body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  registerAccountGoogle = async (body, referral) => {
    const requestOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    };

    try {
      const haveReferral = referral ? `/auth/oauth2/callback/google/register/?referral=${referral}` : "/auth/oauth2/callback/google/register/";
      const response = await axiosInstace.post(haveReferral, body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  registerAccountFacebook = async (body, referral) => {
    const requestOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    };

    try {
      const haveReferral = referral ? `/auth/oauth2/callback/facebook/register/?referral=${referral}` : "/auth/oauth2/callback/facebook/register/";
      const response = await axiosInstace.post(haveReferral, body, requestOptions)

      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  recoverPasswordAccount = async (body) => {
    const requestOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstace.post("/auth/forgotPassword/", body, requestOptions)
      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  newPasswordAccount = async (token, body) => {
    const requestOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axiosInstace.post(`/auth/resetPassword/?token=${token}`, body, requestOptions)
      return { data: response.data, status: response.status, error: false }
    } catch (error) {
      return { status: _get(error.response, "status", ""), message: _get(error.response.data, "message", "") };
    };
  };

  @action
  logout = () => {
    this.userLogged = null;
    localStorage.clear();
    window.location.reload();
  };
};