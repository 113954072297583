import cx from 'classnames';
import useMedia from 'use-media';
import { education } from 'assets';
import styles from './Mission.module.less';

const Mission = () => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  return (
    <div className={cx({ "mt-72 mb-80": isMobile || isTablet, "mt-134 mb-136": !isMobile && !isTablet })}>
      <div className={cx({ "flex flex-column": isMobile || isTablet, "flex flex-row": !isMobile && !isTablet })}>
        <div className={styles.space}>
          <span className="f14 heliotrope b mb-8">Missão</span>
          <h2 className={cx("mine-shaft", styles.titleMission, { "f48 mb-48": isMobile, "f56 mb-48": isTablet, "f56 mb-24": !isMobile && !isTablet })}>
            Aprendizado Personalizado
          </h2>
          {(isMobile || isTablet) && (
            <img src={education} alt="education" className={styles.imageEducation} />
          )}
          <p className={cx("f24 fw4 gray", styles.p1, { "mb-16": isMobile, "mb-24": !isMobile })}>
            Com a tecnologia do Arkc, simplificamos o mundo dos investimentos para todos, independentemente da idade, nível de conhecimento e de acordo com os objetivos de cada um.
          </p>
          <p className={cx("f24 fw4 gray", styles.p1, { "mb-16": isMobile, "mb-24": !isMobile })}>
            Nós usamos informações para fazer com que a jornada dos nossos usuários no mundo dos investimentos seja super fácil e prática.
          </p>
        </div>
        {(!isMobile && !isTablet) && (
          <img src={education} alt="education" className={styles.imageEducation} />
        )}
      </div>
    </div>
  );
};

export default Mission;