import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiCheck, FiArrowLeft, FiX, FiArrowRight } from 'react-icons/fi';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins";
import AccordionArkc from '../../AccordionArkc';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor, arkc, supportArkc, userSales, salesPhoneMobile, salesPhone, phoneSalesMobile, oneSales, secretsSale, gameSales, salesNew, phoneSales } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes'
import styles from './LandingPageFourForm.module.less';

const LandingPageFourForm = ({ startNow, supportQuestions, informationsTwo, informationsOne, withArkc, noArkc, peoples, secrets, investors, lessons, you, guide }) => {
  const [plugins, setPlugins] = useState([]);
  const [pluginsTwo, setPluginsTwo] = useState([]);
  const [pluginsThree, setPluginsThree] = useState([]);
  const [pluginsFour, setPluginsFour] = useState([]);
  const [pluginsFive, setPluginsFive] = useState([]);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
    setPluginsTwo([new Pagination({ type: 'bullet' })]);
    setPluginsThree([new Pagination({ type: 'bullet' }), new AutoPlay({ duration: 2500, direction: "NEXT", stopOnHover: false })]);
    setPluginsFour([new Pagination({ type: 'bullet' })]);
    setPluginsFive([new Pagination({ type: 'bullet' })]);
  }, []);

  return (
    <div>
      <div className="container mt-24 mb-24">
        <div className="content-sales">
          <div className="flex flex-row">
            <button className="arkc-btn-link flex flex-row justify-center item-center" onClick={() => history.push(ROUTES.HOME)}>
              <FiArrowLeft className="i-24 heliotrope" />
              {!isMobile && (
                <span className="f16 b heliotrope ml-14">Voltar</span>
              )}
            </button>
            <div className="flex justify-center item-center w-100">
              <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
                <img src={logoColor} alt="logo-color" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-internal mb-16" />
      <div className="container">
        <div className="content-sales">
          <div className={cx("flex item-center", { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
            <div>
              <h1 className={cx("mine-shaft mb-16", styles.titleOne)}>
                Comece a fazer seu dinheiro trabalhar para você!
              </h1>
              <p className={cx("f18", styles.descriptionOne)}>
                Aprenda a investir e alcance a tranquilidade financeira, mesmo que você não saiba nada sobre o assunto.
              </p>
              <p className={cx("f18 b", styles.descriptionSubOne)}>
                Descobrir onde guardar seu dinheiro não precisa ser chato.
              </p>
              <Button type="primary" className={styles.btnFree} onClick={startNow.bind(this, "comece-fazer-dinheiro", "experiencia-investimentos", "Começar gratuitamente")}>Começar gratuitamente</Button>
            </div>
            <div>
              <img src={salesNew} className={styles.imageBlocOne} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bgBloc}>
        <div className="container">
          <div className="content-sales">
            <div className={cx("flex item-center", { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
              {!isMobile && (
                <div>
                  <img src={phoneSales} className={styles.imgPhoneSales} alt="" />
                </div>
              )}
              <div>
                <h1 className={cx("f40 mine-shaft mb-16", styles.titleTwo)}>
                  Você pode começar a investir com pouco dinheiro
                </h1>
                <p className={cx("f18", styles.descriptionTwo)}>
                  Com apenas R$ 10 para investir, você já pode começar a multiplicar sua renda. Basta aprender qual é o melhor lugar para deixar seu dinheiro.
                </p>
                <p className={cx("f18 b", styles.descriptionSubTwo)}>
                  E o Arkc está aqui para te guiar nessa jornada!
                </p>
                {_map(guide, (item, index) => (
                  <div key={index} className={cx(styles.badge, { "mb-16": index !== _size(guide) - 1 })}>
                    <div className={styles.bgNumber}>
                      <span className="f16 white b">{item.id}</span>
                    </div>
                    <span className="f18 black">{item.description}</span>
                  </div>
                ))}
              </div>
              {isMobile && (
                <div className="mt-46">
                  <img src={phoneSalesMobile} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content-sales">
          <div className={cx("flex item-center", styles.spaceBloc, { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
            <div>
              <div className={styles.badgeArkc}>
                <img src={gameSales} alt="" />
                <span className={styles.titleArkc}>O Arkc foi feito para você que</span>
              </div>
            </div>
            <div>
              {_map(you, (item, index) => (
                <div key={index} className={cx(styles.bgOptions, { "mb-16": index !== _size(you) - 1 })}>
                  <p className={styles.bgOptionsText}>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isMobile || isTablet ? (
        <div className={styles.bgLessons}>
          <div className="container">
            <div className="content-sales">
              <p className={styles.descriptionLessons}>
                Quer aprender a diversificar seus investimentos?
              </p>
              <h1 className={styles.titleLessons}>
                Conheça alguns que você vai dominar com o Arkc:
              </h1>
              <div className="flicking-view-peoples">
                <Flicking circular={true} plugins={plugins} align="prev">
                  {_map(lessons, (item, index) => (
                    <div key={index} className={cx(styles.badgeLessons, "mr-16")}>
                      <div className={styles.bgImage} style={{ background: `${item.color}` }}>
                        <img src={item.logo} alt="" className={styles.imgLessons} />
                      </div>
                      <p className="f16 b mine-shaft text-center mt-12">{item.name}</p>
                      <p className={styles.badgeLessonsDescription}>{item.description}</p>
                    </div>
                  ))}
                  <ViewportSlot>
                    <div className="flicking-pagination"></div>
                  </ViewportSlot>
                </Flicking>
              </div>
              <div className="flex justify-center item-center mt-40">
                <Button type="primary" className={styles.btnFree} onClick={startNow.bind(this, "comece-fazer-dinheiro", "experiencia-investimentos", "Começar gratuitamente")}>Começar gratuitamente</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="content-sales">
            <div className={styles.bgLessons}>
              <p className={styles.descriptionLessons}>
                Quer aprender a diversificar seus investimentos?
              </p>
              <h1 className={styles.titleLessons}>
                Conheça alguns que você vai dominar com o Arkc:
              </h1>
              <div className={styles.gridLessons}>
                {_map(lessons, (item, index) => (
                  <div key={index} className={styles.badgeLessons}>
                    <div className={styles.bgImage} style={{ background: `${item.color}` }}>
                      <img src={item.logo} alt="" className={styles.imgLessons} />
                    </div>
                    <p className="f16 b mine-shaft text-center mt-12">{item.name}</p>
                    <p className={styles.badgeLessonsDescription}>{item.description}</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-center item-center mt-40">
                <Button type="primary" className={styles.btnFree} onClick={startNow.bind(this, "comece-fazer-dinheiro", "experiencia-investimentos", "Começar gratuitamente")}>Começar gratuitamente</Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="content-sales">
          <div className={cx("flex item-center", styles.spaceBloc, { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet, [styles.blocPadding]: !isMobile && !isTablet })}>
            {isMobile || isTablet ? (
              <>
                <div className="mb-46">
                  <div className={styles.badgeSecretsTwo}>
                    <div className={styles.badgePosition}>
                      <img src={secretsSale} alt="" />
                      <span className={styles.titleSecrets}>Nada de letras pequenas Sem segredos</span>
                      <p className={styles.secretsDescriptions}>
                        O nosso método te permite ter <span className="b heliotrope">rentabilidade igual aos maiores investidores do mercado</span>,
                        sem precisar passar o dia todo
                        lendo relatórios difíceis de entender.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.flickWidth}>
                  <div className="flicking-view-peoples">
                    <Flicking circular={true} plugins={pluginsTwo} align="prev">
                      {_map(secrets, (item, index) => (
                        <div key={index} className={cx(styles.badgeSecrets, "mr-16")}>
                          <div className={styles.bgImageSecrets}>
                            <img src={item.icon} alt="" />
                          </div>
                          <p className="f18 b mine-shaft mt-16">{item.title}</p>
                          <p className={styles.descriptionSecrets}>{item.description}</p>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination"></div>
                      </ViewportSlot>
                    </Flicking>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.gridSecrets}>
                  {_map(secrets, (item, index) => (
                    <div key={index} className={styles.badgeSecrets}>
                      <div className={styles.bgImageSecrets}>
                        <img src={item.icon} alt="" />
                      </div>
                      <p className="f18 b mine-shaft mt-16">{item.title}</p>
                      <p className={styles.descriptionSecrets}>{item.description}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <div className={styles.badgeSecretsTwo}>
                    <div className={styles.badgePosition}>
                      <img src={secretsSale} alt="" />
                      <span className={styles.titleSecrets}>Nada de letras pequenas Sem segredos</span>
                      <p className={styles.secretsDescriptions}>
                        O nosso método te permite ter <span className="b heliotrope">rentabilidade igual aos maiores investidores do mercado</span>,
                        sem precisar passar o dia todo
                        lendo relatórios difíceis de entender.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isMobile || isTablet ? (
        <div className={styles.bgInfos}>
          <div className="container">
            <div className="content-sales">
              <div className="flex flex-column justify-start">
                <h1 className={styles.titleInfo}>
                  No Brasil, 46% dos aposentados dependem de parentes para sobreviver, 25% ainda precisam trabalhar, 48% vive de caridade e
                  {" "}
                  <span className="heliotrope">apenas 1% são financeiramente independentes</span>
                </h1>
                <p className={styles.msgOne}>
                  Você vai escolher fazer parte do 1% ou vai viver trabalhando o resto da sua vida?
                </p>
                <img src={oneSales} className={styles.imgOneSales} alt="" />
                <p className={styles.msgTwo}>Escolha fazer parte dos 1%</p>
                <p className={styles.msgThree}>
                  Escolha aprender a investir enquanto
                  se diverte com o Arkc!
                </p>
                <div className="flex justify-center item-center mt-40">
                  <Button type="primary" className={styles.btnFree} onClick={startNow.bind(this, "comece-fazer-dinheiro", "experiencia-investimentos", "Começar gratuitamente")}>Começar gratuitamente</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="content-sales">
            <div className={styles.bgInfos}>
              <div className={cx("flex item-center", { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
                <div>
                  <h1 className={styles.titleInfo}>
                    No Brasil, 46% dos aposentados dependem de parentes para sobreviver, 25% ainda precisam trabalhar, 48% vive de caridade e
                    {" "}
                    <span className="heliotrope">apenas 1% são financeiramente independentes</span>
                  </h1>
                </div>
                <div className="flex flex-column justify-center item-center">
                  <p className={styles.msgOne}>
                    Você vai escolher fazer parte do 1% ou vai viver trabalhando o resto da sua vida?
                  </p>
                  <img src={oneSales} alt="" />
                  <p className={styles.msgTwo}>Escolha fazer parte dos 1%</p>
                  <p className={styles.msgThree}>
                    Escolha aprender a investir enquanto
                    se diverte com o Arkc!
                  </p>
                  <div className="flex justify-center item-center mt-40">
                    <Button type="primary" className={styles.btnFree} onClick={startNow.bind(this, "comece-fazer-dinheiro", "experiencia-investimentos", "Começar gratuitamente")}>Começar gratuitamente</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.spaceBloc}>
        <div className="flex justify-center item-center">
          <h1 className={styles.titleInvestors}>
            Junte-se aos milhares de <span className="heliotrope">futuros investidores</span> que aprenderam com o Arkc!
          </h1>
        </div>
        <div className={cx("flex item-center", { "flex-column": isMobile, "flex-row": !isMobile })}>
          <div className={cx({ "flex justify-start w-100": isMobile, })}>
            <div className={styles.bgUsers}>
              <p className={styles.textPeople}>“67% das pessoas não conseguem poupar dinheiro”</p>
              <p className={styles.textPoup}>
                Eles não só poupam, como investem seus rendimentos
                {(!isMobile && !isTablet) && (
                  <>
                    {" "}
                    <FiArrowRight className="f14" />
                  </>
                )}
              </p>
              <p className={styles.textBR}>“Mais de 70% dos brasileiros estão endividados”</p>
              <p className={styles.textPoupTwo}>
                Eles não estão!
                {(!isMobile && !isTablet) && (
                  <>
                    {" "}
                    <FiArrowRight className="f14" />
                  </>
                )}
              </p>
            </div>
          </div>
          {isMobile || isTablet ? (
            <div className={styles.newPostion}>
              <div className="container">
                <div className="content-sales">
                  <div className="flicking-view-peoples">
                    <Flicking circular={true} plugins={pluginsThree} align="prev">
                      {_map(peoples, (item, index) => (
                        <div key={index} className={styles.badgePeoples}>
                          <img src={userSales} alt="" />
                          <p className={styles.peopleDep}>{item.testimony}</p>
                          <p className="f18 b mine-shaft">{item.name}</p>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination"></div>
                      </ViewportSlot>
                    </Flicking>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.relativePeoples}>
              <div className={styles.absolutePeoples}>
                <div className="flicking-view-peoples">
                  <Flicking circular={true} plugins={pluginsThree} align="prev">
                    {_map(peoples, (item, index) => (
                      <div key={index} className={styles.badgePeoples}>
                        <img src={userSales} alt="" />
                        <p className={styles.peopleDep}>{item.testimony}</p>
                        <p className="f18 b mine-shaft">{item.name}</p>
                      </div>
                    ))}
                    <ViewportSlot>
                      <div className="flicking-pagination"></div>
                    </ViewportSlot>
                  </Flicking>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <div className="content-sales">
          <div className={cx("flex item-center", styles.blocPaddingMobile, { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet, [styles.blocPadding]: !isMobile && !isTablet })}>
            <div>
              <div className="flex flex-row item-center mb-40">
                <FiX className={styles.iconX} />
                <span className={styles.titleDif}>Sem o Arkc</span>
              </div>
              {isMobile || isTablet ? (
                <div className={styles.flickWidth}>
                  <div className="flicking-view-peoples">
                    <Flicking circular={true} plugins={pluginsFour} align="prev">
                      {_map(noArkc, (item, index) => (
                        <div key={index} className={cx(styles.badgeNoArkc, "mr-16")}>
                          <div className={styles.bgNo}>
                            <FiX className={styles.badgeNoYesArkcIcon} />
                          </div>
                          <p className={styles.badgeNoYesArkcText}>{item.description}</p>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination"></div>
                      </ViewportSlot>
                    </Flicking>
                  </div>
                </div>
              ) : (
                <>
                  {_map(noArkc, (item, index) => (
                    <div key={index} className={cx(styles.badgeNoArkc, { "mb-32": index !== _size(noArkc) - 1 })}>
                      <div className={styles.bgNo}>
                        <FiX className={styles.badgeNoYesArkcIcon} />
                      </div>
                      <p className={styles.badgeNoYesArkcText}>{item.description}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div>
              <div className="flex flex-row item-center mb-40">
                <img src={arkc} className={styles.iconArkc} alt="" />
                <span className={styles.titleDif}>Com o Arkc</span>
              </div>
              {isMobile || isTablet ? (
                <div className={styles.flickWidth}>
                  <div className="flicking-view-peoples">
                    <Flicking circular={true} plugins={pluginsFive} align="prev">
                      {_map(withArkc, (item, index) => (
                        <div key={index} className={cx(styles.badgeYesArkc, "mr-16")}>
                          <div className={styles.bgYes}>
                            <FiCheck className={styles.badgeNoYesArkcIcon} />
                          </div>
                          <p className={styles.badgeNoYesArkcText}>{item.description}</p>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination"></div>
                      </ViewportSlot>
                    </Flicking>
                  </div>
                </div>
              ) : (
                <>
                  {_map(withArkc, (item, index) => (
                    <div key={index} className={cx(styles.badgeYesArkc, { "mb-32": index !== _size(withArkc) - 1 })}>
                      <div className={styles.bgYes}>
                        <FiCheck className={styles.badgeNoYesArkcIcon} />
                      </div>
                      <p className={styles.badgeNoYesArkcText}>{item.description}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobile || isTablet ? (
        <div className={styles.bgProducts}>
          <div className="container">
            <div className="content-sales">
              <h1 className={styles.titleProducts}>Conheça todos os recursos que você terá acesso de forma gratuita no Arkc</h1>
              <div className="flex flex-column item-center">
                <div>
                  {_map(informationsOne, (item, index) => (
                    <div key={index} className={cx({ "mb-40": index !== _size(informationsOne) - 1 && !isMobile, "flex flex-column justify-center item-center mb-24": isMobile })}>
                      <div className={styles.badgeIcons} style={{ background: `${item.color}` }}>
                        <img src={item.icon} alt="" />
                      </div>
                      <p className="f18 b mt-16 mb-8">{item.title}</p>
                      <p className={styles.descriptionProduct}>{item.description}</p>
                    </div>
                  ))}
                </div>
                <div className="mt-24 mb-24">
                  <img src={salesPhoneMobile} />
                </div>
                <div>
                  {_map(informationsTwo, (item, index) => (
                    <div key={index} className={cx({ "mb-40": index !== _size(informationsTwo) - 1 && !isMobile, "flex flex-column justify-center item-center mb-24": isMobile })}>
                      <div className={styles.badgeIcons} style={{ background: `${item.color}` }}>
                        <img src={item.icon} alt="" />
                      </div>
                      <p className="f18 b mt-16 mb-8">{item.title}</p>
                      <p className={styles.descriptionProduct}>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="content-sales">
            <div className={styles.bgProducts}>
              <h1 className={styles.titleProducts}>Conheça todos os recursos que você terá acesso de forma gratuita no Arkc</h1>
              <div className={cx("flex item-center", { "flex-column": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
                <div>
                  {_map(informationsOne, (item, index) => (
                    <div key={index} className={cx({ "mb-40": index !== _size(informationsOne) - 1 })}>
                      <div className={styles.badgeIcons} style={{ background: `${item.color}` }}>
                        <img src={item.icon} alt="" />
                      </div>
                      <p className="f18 b mt-16 mb-8">{item.title}</p>
                      <p className={styles.descriptionProduct}>{item.description}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <img src={salesPhone} />
                </div>
                <div>
                  {_map(informationsTwo, (item, index) => (
                    <div key={index} className={cx({ "mb-40": index !== _size(informationsOne) - 1 })}>
                      <div className={styles.badgeIcons} style={{ background: `${item.color}` }}>
                        <img src={item.icon} alt="" />
                      </div>
                      <p className="f18 b mt-16 mb-8">{item.title}</p>
                      <p className={styles.descriptionProduct}>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="content-sales">
          <div className={cx("flex flex-column justify-center item-center", styles.spaceBloc)}>
            <img src={supportArkc} alt="support-arkc" />
            <h1 className={styles.titleQuestions}>Perguntas frequentes</h1>
            <div className={styles.contentAccordions}>
              {_map(supportQuestions, (item, index) => (
                <div className={cx({ "mb-16": index !== _size(supportQuestions) - 1 })}>
                  <AccordionArkc key={index} title={item.title}>
                    <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.description}</p>
                    {item.descriptionSub && (
                      <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.descriptionSub}</p>
                    )}
                  </AccordionArkc>
                </div>
              ))}
            </div>
            <div className="flex justify-center item-center mt-40">
              <Button type="primary" className={styles.btnFree} onClick={startNow.bind(this, "comece-fazer-dinheiro", "experiencia-investimentos", "Começar gratuitamente")}>Começar gratuitamente</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageFourForm;