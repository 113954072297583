import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const PrivacyPoliciesForm = () => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className="mt-24">
      <div className="flex justify-center item-center mb-24">
        <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
          <img src={logoColor} alt="logo-color" />
        </button>
      </div>
      <hr className="divider-internal mt-24 mb-32" />
      <div className="container">
        <div className="content">
          <h2 className="text-center heliotrope">Políticas de Privacidade</h2>
          <div className={cx({ "mb-32 mt-24": isMobile, "mb-48 mt-32": !isMobile })}>
            <div>
              <h5 className="heliotrope">1. Em geral</h5>
              <p className="f16 mt-10 mine-shaft">
                No Arkc, nos preocupamos com seus dados pessoais, por isso preparamos esta Política de Privacidade para explicar como os
                coletamos, usamos e compartilhamos.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Esta Política de Privacidade (“ Política de Privacidade ”) detalha os dados pessoais que a ARKC, Inc. (“ARKC”) recebe sobre
                você, como os processamos e seus direitos e obrigações em relação a seus dados pessoais. ARKC, Inc., uma empresa registrada
                em São Paulo - SP, é o controlador de dados para os fins da Lei Geral de Proteção de Dados ("LGPD") e qualquer legislação local
                relevante (" Leis de proteção de dados ”).
              </p>
              <p className="f16 mt-10 mine-shaft">
                Ao usar ou acessar o Serviço, você concorda com os termos desta Política de Privacidade. Os termos em maiúsculas não definidos
                aqui têm os significados estabelecidos nos termos e condições (os “ Termos e Condições ”). Podemos atualizar nossa Política de
                Privacidade para refletir as mudanças em nossas práticas de informação. Se fizermos isso e as alterações forem materiais,
                publicaremos um aviso informando que fizemos alterações a esta Política de Privacidade no site por pelo menos 7 dias antes de
                as alterações serem feitas, e iremos indicar a data em que esses termos foram revisados ​​pela última vez em na parte inferior da
                Política de Privacidade. Quaisquer revisões a esta Política de Privacidade entrarão em vigor no final desse período de 7 dias.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">2. Informações que coletamos</h5>
              <p className="f16 mt-10 mine-shaft">
                Esta Política de Privacidade explica como coletamos, usamos e compartilhamos seus dados pessoais.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Informações que você fornece:</span><br />
                Através do processo de registro, você nos fornecerá seu nome, endereço de e-mail (ou endereço de e-mail dos pais)
                e idade ou data de nascimento. Você também nos fornecerá suas informações de transação de pagamento se optar por pagar
                pelos serviços Arkc.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Dados de atividade:</span><br />
                Ao usar o Serviço, você enviará informações e conteúdo para o seu perfil. Também geraremos dados sobre o uso de nossos Serviços,
                incluindo seu envolvimento em atividades educacionais no Serviço, ou o envio de mensagens e outras formas de transmissão de
                informações a outros usuários (“ Dados de Atividades ”). Também coletamos dados técnicos sobre como você interage com nossos
                Serviços; para obter mais informações, consulte Cookies.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Quando você usa o Arkc em nosso aplicativo ou site, usamos um serviço chamado "Jornada" para registrar sua atividade.
                Isso nos fornece um conjunto de dados e uma repetição da sessão de sua atividade no Arkc.
                A Jornada captura e analisa seus dados de uso para nos ajudar a tornar sua experiência no Arkc melhor.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Nós apenas coletamos os dados de que precisamos, o que significa que não registramos:</span><br />
                - Nomes de usuário<br />
                - Nomes<br />
                - Imagens de Perfil<br />
                - Endereço de e-mail<br />
                - Locais ou bios na página de perfil<br />
                - Senha
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Registramos os seguintes dados:</span><br />
                - Padrões de uso<br />
                - Cliques<br />
                - Movimentos do mouse<br />
                - Rolando<br />
                - Digitando
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Especificações técnicas:</span><br />
                - Navegador<br />
                - Tipo de dispositivo<br />
                - Sistema operacional<br />
                - Tamanho do visor<br />
                - Erros de script<br />
                - Endereço de IP
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Navegação:</span><br />
                - Páginas visitadas<br />
                - Referentes<br />
                - Parâmetros de URL<br />
                - Duração da sessão
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Atividade de aprendizagem:</span><br />
                - Progresso da sessão<br />
                - Respostas
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Pesquisa e Desenvolvimento de Produto</span><br />
                Podemos entrar em contato com você para participar de atividades de pesquisa de produtos. Isso pode incluir pesquisas,
                entrevistas e outros tipos de sessões de feedback. Quando você participa dessas atividades de pesquisa, as informações
                fornecidas serão usadas para testar, melhorar e desenvolver nossos produtos. Gravaremos as transcrições de vídeo, áudio e
                texto deste feedback junto com qualquer informação de contato adicional que você fornecer e manteremos esses dados por dois anos.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <span className="b heliotrope">Entre em contato conosco em suporte@arkc.com para:</span><br />
                - Solicite mais informações sobre as atividades de pesquisa para as quais você foi voluntário.<br />
                - Optar por não ser contatado para atividades de pesquisa.<br />
                - Solicite uma cópia de todos os dados relacionados à pesquisa que você nos forneceu.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">3. Cookies</h5>
              <p className="f16 mt-10 mine-shaft">
                Quando você entra no site, coletamos o tipo do seu navegador e o seu endereço IP (um endereço exclusivo que identifica o
                seu computador na Internet). Quando você usa nosso aplicativo móvel, coletamos o tipo do seu dispositivo, o ID do seu
                dispositivo e o seu endereço IP.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Além disso, armazenamos certas informações do seu navegador usando "cookies". Um cookie é um dado armazenado no computador do
                usuário vinculado a informações sobre o usuário. Usamos cookies de ID de sessão para confirmar que os usuários estão logados.
                Se você não deseja que as informações sejam coletadas por meio do uso de cookies, existe um procedimento simples na maioria
                dos navegadores que permite negar ou aceitar o recurso de cookie; no entanto, você deve observar que os cookies podem ser
                necessários para fornecer a você alguns recursos disponíveis no site (por exemplo, entrega personalizada de informações).
              </p>
              <p className="f16 mt-10 mine-shaft">
                Você pode encontrar mais informações sobre cookies e como gerenciá-los em http://www.allaboutcookies.org.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">4. Informações obtidas por terceiros</h5>
              <p className="f16 mt-10 mine-shaft">
                Para apoiar e aprimorar o Serviço, trabalhamos com uma variedade de redes de publicidade de terceiros, provedores de serviços
                de análise de marketing e empresas de análise de sites, como Google e Facebook.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Provedores de Serviços de Publicidade de Terceiros ”). Esses provedores de serviços de publicidade terceirizados coletam e
                usam dados pessoais sobre suas visitas e uso do site e do aplicativo móvel, bem como outros sites, a fim de servir a você
                anúncios e conteúdo adaptados para atender às suas preferências e prováveis ​​interesses ou compreender melhor o anúncio eficácia. Dados demográficos e de interesse agregados também serão usados ​​para fins de pesquisa de mercado.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Usamos o Google Analytics, que é uma ferramenta de análise da web que nos ajuda a entender como os usuários se envolvem com o
                site. Como muitos serviços, o Google Analytics usa cookies primários para rastrear as interações do usuário como em nosso caso,
                onde são usados ​​para coletar informações sobre como os usuários usam nosso site. Essas informações são usadas para compilar
                relatórios e nos ajudar a melhorar nosso site. Os relatórios divulgam tendências do site sem identificar visitantes individuais.
                Você pode desativar o Google Analytics sem afetar a forma como visita nosso site - para obter mais informações sobre como
                desativar o rastreamento do Google Analytics em todos os sites que você usa, visite esta página do Google: https://tools.google.com/dlpage/gaoptout.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Você pode optar por não receber anúncios personalizados de anunciantes e redes de anúncios que sejam membros da
                Network Advertising Initiative (“NAI”) ou que sigam os Princípios de Autorregulamentação da
                DAA para Publicidade Comportamental Online usando suas respectivas ferramentas de cancelamento.
                A ferramenta de opt-out do NAI pode ser encontrada aqui http://www.networkadvertising.org/choices/ e a
                ferramenta de opt-out do DAA pode ser encontrada aqui http://www.aboutads.info/choices/ .
              </p>
              <p className="f16 mt-10 mine-shaft">
                Além disso, seus dispositivos móveis podem oferecer configurações que permitem que você faça escolhas sobre a coleta,
                uso ou transferência de informações de aplicativos móveis para publicidade comportamental online
                (por exemplo, ID de publicidade do iOS da Apple e ID de publicidade do Google Android). Observe que a desativação não impede a
                exibição de todos os anúncios para você.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">5. Uso das informações obtidas pelo Arkc</h5>
              <p className="f16 mt-10 mine-shaft">
                O Arkc pode, ocasionalmente, enviar a você anúncios relacionados a serviços e alterações de produtos por meio da operação
                geral do Serviço. Enviaremos notificações de lembrete para apoiar a metodologia de ensino do Arkc, reforçando a cadência de
                aprendizagem e ajudando a manter sua sequência de aprendizagem. Processamos seus dados para ajudar a lhe fornecer o Serviço e
                oferecer recursos personalizados, para entender e melhorar nosso Serviço e para mantê-lo seguro e protegido.
              </p>
              <p className="f16 mt-10 mine-shaft">
                O Arkc pode usar ou compartilhar dados anônimos coletados através do Serviço, incluindo Dados de Atividades, sem limitação.
                Como empresa, é fundamental que executemos nosso contrato com você com o melhor serviço possível, e é nosso legítimo interesse
                executar essas funções de processamento e habilitar e-mails de serviço por padrão para manter seus dados seguros e fornecer
                nosso Serviço . Você pode cancelar o recebimento de quaisquer e-mails de serviço não essenciais a qualquer momento.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Também podemos usar suas informações de contato para enviar notificações sobre novos serviços, ofertas e promoções oferecidas
                pelo ARKC se você consentir afirmativamente em receber tais comunicações.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Quando você se registra no ARKC, algumas de suas informações são tornadas públicas e disponíveis em vários locais, incluindo
                arkc.com/meu-perfil. Isso incluirá os dados de seu perfil e conta do a ARKC (nome, local, biografia, nome de usuário,
                ID de usuário, foto do perfil e status de membro do ARKC Black); seus seguidores e as pessoas que você segue; e seu progresso
                de aprendizagem (Tokens, Progresso Diário, Investimentos sendo aprendidos, nível de progresso, diamantes, Habilidades, Lições realizadas).
              </p>
              <p className="f16 mt-10 mine-shaft">
                Em alguns casos, outros usuários podem complementar seu perfil, inclusive enviando comentários (que podem ser excluídos por você).
                O Arkc usa essas informações para fornecer suporte, enviar notificações essenciais, fazer cumprir nossos termos, condições e
                políticas, comunicar-se com você, administrar os Serviços e operações internas, incluindo solução de problemas, análise de dados,
                testes, pesquisas, para fins estatísticos e de pesquisa. Os Termos e Condições do Arkc fornecem a base legal contratual para
                essas atividades de processamento.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">6. Compartilhamento de dados pessoais com terceiros</h5>
              <p className="f16 mt-10 mine-shaft">
                O Arkc compartilha seus dados pessoais apenas quando for necessário para oferecer o Serviço, legalmente exigido ou permitido por você.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Forneceremos dados pessoais a provedores de hospedagem como Amazon Web Services, provedores de mecanismo de pesquisa como o
                Google, provedores de análise como Crashlytics e provedores de suporte como ZenDesk.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Esses processadores de dados nos ajudam a oferecer o Serviço a você. Por exemplo, podemos compartilhar suas informações para
                detectar onde ou como você encontrou um bug ao usar nosso aplicativo móvel. Em conexão com essas operações, nossos provedores
                de serviço terão acesso aos dados pessoais por um período limitado. Quando utilizamos provedores de serviços para processar
                quaisquer dados pessoais, implementamos proteções contratuais que limitam o uso desses dados pessoais para a prestação de
                serviços para o Arkc.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Seremos obrigados a acessar e divulgar dados pessoais em resposta a solicitações legais, como intimações ou ordens judiciais,
                ou em conformidade com as leis aplicáveis. Além disso, acessaremos e compartilharemos a conta ou outros dados pessoais quando
                acreditarmos que seja necessário para cumprir a lei, para proteger nossos interesses ou propriedade, para evitar fraude ou
                outra atividade ilegal perpetrada através do Serviço ou usando o nome ARKC, ou para prevenir dano iminente. Isso incluirá o
                acesso e compartilhamento de dados pessoais com outras empresas, advogados, agentes ou agências governamentais.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Se a propriedade de todos ou substancialmente todos os negócios do Arkc, ou unidades de negócios individuais ou ativos
                pertencentes ao Arkc que estão relacionados ao Serviço, mudar, seus dados pessoais serão transferidos para o novo proprietário.
                Em qualquer transferência de informações, seus dados pessoais permanecerão sujeitos a esta seção.
              </p>
              <p className="f16 mt-10 mine-shaft">
                O Arkc irá compartilhar dados agregados ou anônimos coletados através do Serviço, incluindo Dados de Atividade, para fins de
                compreensão ou melhoria do serviço.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">7. Compartilhamento de dados pessoais com terceiros</h5>
              <p className="f16 mt-10 mine-shaft">
                Você tem os seguintes direitos em relação aos dados pessoais que mantemos sobre você, a menos que disposto de outra forma pela lei local:
              </p>
              <p className="f16 mt-10 mine-shaft">
                O Arkc irá reter seus dados até que sua conta seja excluída, após o qual iremos reter dados anônimos coletados através do
                Serviço, incluindo Dados de Atividades, que podem ser usados ​​pelo Arkc e compartilhados com terceiros de qualquer maneira.
                As informações associadas aos desafios e lições do Arkc, incluindo os resultados, serão coletivamente excluídas de sua conta
                do Arkc após cinco anos da data de upload do seu teste, mas os dados do exame serão anônimos, e será mantido indefinidamente
                pelo Arkc para melhorar as lições e para pesquisas e análises.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Observe que alguns desses direitos não são absolutos. Em alguns casos, podemos recusar uma solicitação para exercer direitos
                específicos se cumprir com isso significar que não somos mais capazes de cumprir nossa obrigação contratual de fornecer a você
                produtos e serviços específicos. Vamos mantê-lo informado sobre as ações que podemos tomar quando você fizer sua solicitação.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Você também pode ter o direito de fazer uma reclamação de LGPD à Autoridade Supervisora ​​relevante.
                Se precisar de mais ajuda em relação aos seus direitos, entre em contato conosco usando as informações de contato fornecidas
                abaixo e consideraremos sua solicitação de acordo com a legislação aplicável. Em alguns casos, nossa capacidade de defender
                esses direitos para você pode depender de nossas obrigações de processar informações pessoais por motivos de
                segurança, proteção, prevenção de fraude, conformidade com requisitos regulamentares ou legais ou porque o processamento é
                necessário para fornecer os serviços que você solicitou. Quando for o caso, iremos informá-lo de detalhes específicos em
                resposta ao seu pedido.
              </p>
              <p className="f16 mt-10 mine-shaft">
                - Para solicitar acesso ou eliminação de dados pessoais que mantemos sobre você.<br />
                - Para nos solicitar a restrição do processamento dos dados pessoais que mantemos sobre você.<br />
                - Para se opor ao processamento de dados pessoais relacionados a você.<br />
                - Nos casos em que nos deu consentimento para processar seus dados pessoais, você tem o direito de retirar esse consentimento a qualquer momento.<br />
                - Para exportar os dados pessoais que você forneceu ao Arkc em um formato que pode ser transferido eletronicamente a terceiros.<br />
                - Para deletar sua conta no Arkc seguindo as instruções disponíveis no Serviço.<br />
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">8. Compartilhamento de dados pessoais com terceiros</h5>
              <p className="f16 mt-10 mine-shaft">
                Você pode ter cookies colocados em seu computador por sites de terceiros que o direcionam ao nosso Serviço.
                Embora não compartilhemos seus dados pessoais com esses sites de terceiros, a menos que seja razoavelmente necessário
                para oferecer o Serviço, eles podem ser capazes de vincular certas informações não pessoalmente identificáveis
                que transferimos para eles com dados pessoais que coletaram anteriormente de você. Reveja as políticas de privacidade de
                cada site que você visita para entender melhor suas práticas de privacidade. Além disso, o ARKC informa que sempre que clicar
                em links (incluindo banners publicitários) que o levem a sites de terceiros, você estará sujeito às políticas de privacidade
                de terceiros.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Nossos serviços contêm links para outros sites operados por terceiros. O ARKC não controla esses outros sites e não é
                responsável por seu conteúdo, suas políticas de privacidade ou seu uso de dados pessoais, incluindo quaisquer informações
                pessoais ou financeiras coletadas por nosso processador de pagamento terceirizado para processar pagamentos para compras no
                aplicativo. A inclusão de tais links no ARKC não implica qualquer endosso do conteúdo de tais sites ou de seus proprietários
                ou operadores, exceto conforme divulgado nos Serviços. Qualquer informação enviada por você diretamente a esses terceiros está
                sujeita à política de privacidade desses terceiros.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">9. Links</h5>
              <p className="f16 mt-10 mine-shaft">
                O Serviço pode conter links para outros sites. Não somos responsáveis ​​pelas práticas de privacidade de outros sites.
                Nós encorajamos os usuários a estarem atentos quando saírem do Serviço para ler as declarações de privacidade de outros sites
                que coletam informações de identificação pessoal. Esta Política de Privacidade se aplica apenas às informações coletadas pelo
                ARKC por meio do Serviço.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">10. Contate-nos</h5>
              <p className="f16 mt-10 mine-shaft">
                Para todas as consultas de privacidade de dados e quaisquer perguntas ou dúvidas que você tenha sobre esta Política de Privacidade,
                entre em contato com nosso time em suporte@arkc.com.br
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPoliciesForm;