import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'routes/routes';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      if (!localStorage.getItem("userArkc")) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: ROUTES.HOME, state: { from: props.location } }} />
      };

      // logged in so return component
      return <Component {...props} />
    }} />
  );
};

export default PrivateRoute;