import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import useMedia from 'use-media';
import cx from 'classnames';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import Achievements from 'components/Achievements';
import Lessons from 'components/Lessons';
import Plans from 'components/Plans';
import Footer from 'components/Footer';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const { lessons, login, plansArkc, user } = useStore();
  const { logout } = login;
  const { lessonsAll } = lessons;
  const { createSubscription, plansAll } = plansArkc;
  const { userAll } = user;
  const isMobile = useMedia({ maxWidth: 767 });

  const retrieveAllData = async () => {
    setLoading(true);

    try {
      const userAllPromise = userAll();
      const lessonsAllPromise = lessonsAll();
      const plansAllPromise = plansAll();

      const [responseUser, responseLessons, responsePlans] = await Promise.all([userAllPromise, lessonsAllPromise, plansAllPromise]);

      if (responseUser.status === 200 || responseUser.status === 201 || responseUser.status === 204) {
        setUserData(responseUser.data);
      } else {
        notification.error({
          message: message.was_problem,
          description: responseUser.message,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      }

      if (responseLessons.status === 200 || responseLessons.status === 201 || responseLessons.status === 204) {
        setLessonsList(responseLessons.data);
      } else {
        notification.error({
          message: message.was_problem,
          description: responseLessons.message,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      };

      if (responsePlans.status === 200 || responsePlans.status === 201 || responsePlans.status === 204) {
        setPlansList(responsePlans.data);
      } else {
        notification.error({
          message: message.was_problem,
          description: responsePlans.message,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      };
    } catch (error) {
      notification.error({
        message: message.was_problem,
        description: error.response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    } finally {
      setLoading(false);
    };
  };

  useEffect(() => {
    retrieveAllData();
  }, []);

  const handleGoLesson = (id) => {
    history.push(ROUTES.LESSON_DETAIL, { id: id });
  };

  const handleViewPlan = (plan, id, dataPlan) => {
    history.push(`/plano/${plan}`, { id: id, dataPlan: dataPlan, invite: _get(userData, "referralCode", "") });
  };

  const handleCreateSubscription = async (actualPlan) => {
    const userEmail = JSON.parse(localStorage.getItem('userEmail'));

    if (!userEmail) return;

    setLoading(true);

    const body = {
      email: userEmail,
      priceID: _get(actualPlan, "pricing.externalCode", ""),
      intendedPlan: _get(actualPlan, "identifier", "")
    };

    const response = await createSubscription(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const data = response.data;
      setLoading(false);
      history.push(ROUTES.PAYMENT, { subscriptionId: data.subscriptionId, clientSecret: data.clientSecret, lookupKey: _get(actualPlan, "identifier", ""), invite: _get(userData, "referralCode", "") });
    } else if (response.status === 400 && response.message === "user already have an active contract to intended plan") {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_active,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else if (response.status === 400 && response.message === "user cannot downgrade his plan") {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_downgrade,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else if (response.status === 400 && response.message === "user cannot downgrade his plan") {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_lack,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <>
          <div className="color-block">
            <div className="container">
              <div className="content mt-24">
                <Menu
                  wallet={_get(userData, "wallet", "")}
                  invite={_get(userData, "referralCode", "")}
                  logout={logout}
                />
              </div>
            </div>
            <div className={cx({ "container": !isMobile })}>
              <div className={cx({ "mt-20 pb-48": isMobile, "content mt-64 mb-64": !isMobile })}>
                <Achievements userData={userData} />
              </div>
            </div>
          </div>
          <Lessons
            lessonsList={lessonsList}
            goLesson={handleGoLesson}
          />
          <hr className="dividerDash mt-72 mb-72" />
          <Plans
            plansList={plansList}
            isMobile={isMobile}
            viewPlan={handleViewPlan}
            createSubscription={handleCreateSubscription}
          />
          <Footer />
        </>
      )}
    </div>
  );
};

export default observer(Dashboard);