import { Modal, Button } from 'antd';
import { FiX } from 'react-icons/fi';
import cx from 'classnames';
import styles from '../../Achievements.module.less';

const ArkcoinsModal = ({ setVisibleArkcoins, isMobile, balance, arkcoins, visibleArkcoins }) => {
  return (
    <Modal
      visible={visibleArkcoins}
      footer={null}
      onCancel={() => setVisibleArkcoins(!visibleArkcoins)}
      bodyStyle={{ height: "466px", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="absolute arkc-btn-link" onClick={() => setVisibleArkcoins(!visibleArkcoins)}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className={cx("flex flex-column", { "justify-center item-center": !isMobile })}>
        <div className={cx("flex item-center", { "mt-46 mb-32": isMobile, "justify-center mt-24 mb-40": !isMobile })}>
          <img src={arkcoins} alt="arkcoins" className={styles.arkcoinsImage} />
          <h5 className="ml-12">Arkcoins</h5>
        </div>
        <div className={styles.badgeSale}>
          <div className="flex flex-row justify-between item-center h-100 p-24">
            <span className="f16 mine-shaft">Saldo em Arkcoins</span>
            <span className="f24 b heliotrope">{balance}</span>
          </div>
        </div>
        <div className={cx("mt-32 mb-40", styles.widthBlock)}>
          <span className="f16 b mine-shaft">O que são Arkcoins</span>
          <p className={cx("f16 gray mt-8", styles.descriptionWidth)}>
            Finalize lições e trilhas de aprendizado para ganhar Arkcoins, guarde elas bem,
            as Arkcoins podem se valorizar muito no futuro.
          </p>
        </div>
        <Button type="default" className={styles.btnClose} onClick={() => setVisibleArkcoins(!visibleArkcoins)}>Fechar</Button>
      </div>
    </Modal>
  );
};

export default ArkcoinsModal;