import { Button } from 'antd';
import _get from 'lodash/get';
import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/pt-br';
import Header from '../Header';
import { arckBlack, arckPremium, arckBeginner, paymentSuccess } from 'assets';
import { ROUTES } from 'routes/routes';
import styles from './PaymentSuccessForm.module.less';

const PaymentSuccessForm = ({ viewMyWallet, myPlan }) => {
  const renderDescriptionWallet = (wallet) => {
    switch (wallet) {
      case "arkc-beginner":
        return (
          <p className={cx("f16 mine-shaft text-center mt-16 mb-32", styles.descriptionSize)}>
            Carteira ARKC para iniciantes, comece por aqui.
          </p>
        );
      case "arkc-premium":
        return (
          <p className={cx("f16 mine-shaft text-center mt-16 mb-32", styles.descriptionSize)}>
            Os investimentos intermediários do Arkc, com foco em diversificação internacional.
          </p>
        );
      case "arkc-black":
        return (
          <p className={cx("f16 mine-shaft text-center mt-16 mb-32", styles.descriptionSize)}>
            A carteira avançada do Arkc, com foco em alta diversificação de investimentos e alta rentabilidade.
          </p>
        );
    };
  };

  return (
    <div>
      <div className="container">
        <div className="content mt-24 mb-24">
          <Header route={ROUTES.DASHBOARD} />
        </div>
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <div className="flex flex-column justify-center item-center">
            <img src={paymentSuccess} alt="payment-success" />
            <h4 className="mt-24">Pagamento realizado com sucesso</h4>
            <p className="f16 gray mt-12 mb-24">Agradeçemos pela sua compra</p>
            <div className={styles.badgeWallet}>
              <div className="flex flex-column justify-center item-center p-24">
                <h5 className="mb-32">Carteira adquirida</h5>
                <div className={cx({
                  [styles.badgeBeginner]: _get(myPlan, "identifier", "") === "arkc-beginner",
                  [styles.badgePremium]: _get(myPlan, "identifier", "") === "arkc-premium",
                  [styles.badgeBlack]: _get(myPlan, "identifier", "") === "arkc-black"
                })}>
                  <div className="flex flex-row item-center p-12">
                    <img src={_get(myPlan, "identifier", "") === "arkc-beginner" ? arckBeginner : _get(myPlan, "identifier", "") === "arkc-premium" ? arckPremium : arckBlack} alt="arck-my-plan" />
                    <span className={cx("f18 b ml-8", { "mine-shaft": _get(myPlan, "identifier", "") === "arkc-beginner" || _get(myPlan, "identifier", "") === "arkc-premium", "white": _get(myPlan, "identifier", "") === "arkc-black" })}>
                      {_get(myPlan, "name", "")}
                    </span>
                  </div>
                </div>
                {renderDescriptionWallet(_get(myPlan, "identifier", ""))}
                <Button type="primary" className={styles.btnAccess} onClick={() => viewMyWallet(_get(myPlan, "wallet", ""))}>Acessar carteira</Button>
              </div>
            </div>
            <div className="mt-32 text-center">
              <h5>Detalhes da compra</h5>
              <p className="f16 gray mt-6">{moment(_get(myPlan, "orderDate", "")).format("LL")}</p>
            </div>
            <div className={cx("flex flex-row justify-between item-center mt-24", styles.sizeInfos)}>
              <div>
                <label className="f16 mine-shaft b">Valor</label>
                <p className="f16 mine-shaft">Mensalidades de <span className="heliotrope">{_get(myPlan, "price", "")}</span></p>
              </div>
              <div>
                <label className="f16 mine-shaft b">Tipo de assinatura</label>
                <p className="f16 mine-shaft">Mensal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessForm;