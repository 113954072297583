import _map from 'lodash/map';
import cx from 'classnames';
import useMedia from 'use-media';
import Header from '../Header';
import { ROUTES } from 'routes/routes';
import { gemsLarge } from 'assets';
import { message } from 'helpers/messages';
import styles from './ConquestsForm.module.less';

const ConquestsForm = ({ conquestsList }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className={cx({ "pb-42": isMobile, "pb-72": !isMobile })}>
      <div className="mt-24 mb-24">
        <Header route={ROUTES.DASHBOARD} />
      </div>
      <hr className="divider-internal mb-24" />
      <div className={cx("container", { "mt-32": isMobile, "mt-72": !isMobile })}>
        <div className="content">
          <div className={cx("flex flex-column", { "justify-center item-center": !isMobile })}>
            <div className={cx("flex flex-row item-center", { "mb-24": isMobile, "mb-40": !isMobile })}>
              <img src={gemsLarge} alt="conquests" className={styles.conquestsImage} />
              <h5 className="ml-12">{message.conquests_title_page}</h5>
            </div>
            <span className={cx("b mine-shaft", { "f18": isMobile, "f16": !isMobile })}>{message.conquests_title}</span>
            <p className={cx("gray mt-8", styles.descripton, { "f18 mb-24": isMobile, "f16 mb-32 text-center": !isMobile })}>
              {message.conquests_description}
            </p>
            <div className={styles.gridConquests}>
              {_map(conquestsList, (item, index) => (
                <div key={index} className={styles.badgeConquests}>
                  <div className={cx("flex flex-column justify-center item-center p-24", styles.widthMobile)}>
                    <img src={item.logo} alt="trophie" className={cx(styles.imageConquests, { [styles.disabledConquests]: !item.conquered })} />
                    <p className={cx("mine-shaft mt-16 b", { "f16 text-center": isMobile, "f14": !isMobile })}>{item.name}</p>
                    <p className={cx("f12 gray mt-4", { "f14 text-center": isMobile, "f12": !isMobile })}>{`Conclusão do Nível ${item.level}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConquestsForm;