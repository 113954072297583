import { useState } from 'react';
import { Input, Button, Form } from 'antd';
import { GoogleLogin } from 'react-google-login';
import InputMask from 'react-input-mask';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import cx from 'classnames';
import { logoColor, facebook, google } from 'assets';
import { message } from 'helpers/messages';
import ModalPoliticalTerms from './ModalPoliticalTerms';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './CreateAccountForm.module.less';

const CreateAccountForm = ({ createAccountFacebook, createAccountGoogle, accountCreate, setLoading, loading }) => {
  const [visible, setVisible] = useState({ open: false, type: "" });

  const validateName = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp("[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$");

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(message.validate_name)
        };
      };

      return Promise.resolve()
    }
  });

  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const regexEmailOnlyCharacters = new RegExp("[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ@. ]+$");
        const regexEmailAddress = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!regexEmailOnlyCharacters.test(value)) {
          return Promise.reject(message.validate_name);
        };

        if (!regexEmailAddress.test(value)) {
          return Promise.reject(message.validate_email)
        };
      }

      return Promise.resolve()
    }
  });

  const callbackGoogle = (response) => {
    createAccountGoogle(response?.accessToken);
  };

  const callbackFacebook = (response) => {
    createAccountFacebook(response?.accessToken);
  };

  return (
    <div>
      <div className="flex justify-center item-center mt-24 mb-24">
        <img src={logoColor} alt="logo-color" className="pointer" onClick={() => history.push(ROUTES.HOME)} />
      </div>
      <hr className="divider-internal" />
      <div className="container">
        <div className="content">
          <div className={styles.topTitle}>
            <p className="f24 b mine-shaft">Crie sua conta</p>
          </div>
          <div className="flex flex-column justify-center item-center">
            <Form
              name="account"
              onFinish={accountCreate}
              onFinishFailed={() => setLoading(false)}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'O nome é obrigatório.' }, validateName]}
                className="mb-12"
              >
                <Input
                  placeholder="Digite seu nome"
                  disabled={loading ? true : false}
                  className="input-base"
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'O email é obrigatório.' }, validateEmail]}
                className="mb-12"
              >
                <Input
                  type="email"
                  placeholder="Digite seu email"
                  disabled={loading ? true : false}
                  className="input-base"
                />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: 'O telefone é obrigatório.' }]}
                className="mb-12"
              >
                <InputMask
                  mask="(99) 99999-9999"
                  maskChar={null}
                  name="phone"
                  placeholder="Digite seu telefone"
                  disabled={loading ? true : false}
                  className="input-base"
                >
                  {(inputProps) => <Input  {...inputProps} disabled={loading ? true : false} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    type: "string",
                    required: true,
                    min: 6,
                    message: "A senha é obrigatória."
                  },
                  {
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!%;._-])[0-9a-zA-Z$*&@#!%;._-]{6,}$/,
                    message: "A senha não está seguindo os requisitos minimos",
                  }
                ]}
                className="mb-0"
              >
                <Input.Password
                  placeholder="Digite sua senha"
                  disabled={loading ? true : false}
                  className="input-base"
                />
              </Form.Item>
              <div className={cx("flex flex-column mt-4 mb-12", styles.passwordInfo)}>
                <p className="f12 mine-shaft mb-6">
                  A senha deve ter 6 ou mais caracteres, sendo pelo menos:
                </p>
                <ul className={styles.list}>
                  <div>
                    <li className="f12 mine-shaft">1 letra minúscula</li>
                    <li className="f12 mine-shaft">1 letra maiúscula</li>
                  </div>
                  <div>
                    <li className="f12 mine-shaft">1 número</li>
                    <li className="f12 mine-shaft">1 caractere especial</li>
                  </div>
                </ul>
              </div>
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setLoading(true)}
                  loading={loading}
                  className={styles.btnCreate}
                >
                  Conectar
                </Button>
              </Form.Item>
            </Form>
            <hr className={cx("divider mt-16 mb-16", styles.dividerBottom)} />
          </div>
          <div className="flex flex-row justify-center item-center">
            {/* <FacebookLogin
              appId={process.env.REACT_APP_ID_FACEBOOK}
              isMobile={false}
              disableMobileRedirect={true}
              callback={callbackFacebook}
              render={renderProps => (
                <button className={cx("arkc-btn-link mr-18", styles.btnFake)} onClick={renderProps.onClick} disabled={renderProps.isDisabled}>
                  Facebook
                  <img src={facebook} alt="facebook" className="ml-14" />
                </button>
              )}
            /> */}
            <GoogleLogin
              clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}
              render={renderProps => (
                <button className={cx("arkc-btn-link", styles.btnFake)} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  Google
                  <img src={google} alt="google" className="ml-14" />
                </button>
              )}
              buttonText="Login"
              onSuccess={callbackGoogle}
              onFailure={callbackGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <div className="flex flex-column justify-center item-center mt-32 mb-60">
            <span className="f14 mine-shaft mt-8">{message.agree_terms}</span>
            <div className="flex flex-row item-center">
              <button className="arkc-btn-link mr-4" onClick={() => setVisible({ open: !visible.open, type: "terms" })}>
                <span className="f14 heliotrope underline b">{message.political_terms_one}</span>
              </button>
              e
              <button className="arkc-btn-link ml-4" onClick={() => setVisible({ open: !visible.open, type: "political" })}>
                <span className="f14 heliotrope underline b">{message.political_terms_two}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalPoliticalTerms
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
};

export default CreateAccountForm;