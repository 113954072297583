import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import Loading from 'components/Loading';
import ConquestsForm from 'components/ConquestsForm';
import { message } from 'helpers/messages';

const Conquests = () => {
  const [loading, setLoading] = useState(true);
  const [conquestsList, setConquestsList] = useState([]);
  const { lessons } = useStore();
  const { conquestsUserAll } = lessons;

  useEffect(() => {
    retrieveConquestsAll();
  }, []);

  const retrieveConquestsAll = async () => {
    setLoading(true);

    const response = await conquestsUserAll();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setConquestsList(response.data);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <ConquestsForm conquestsList={conquestsList} />
      )}
    </div>
  );
};

export default observer(Conquests);