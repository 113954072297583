import { Button } from 'antd';
import cx from 'classnames';
import useMedia from 'use-media';
import { logo } from 'assets';
import styles from './HeaderHome.module.less';

const HeaderHome = ({ startNow, openLogin }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <section className={styles.mainSection}>
      <div className={styles.gradient}>
        <div className={styles.gradientUpper} />
        <div className={styles.gradientBottom} />
      </div>
      <div className={styles.indexContent}>
        <div className="container">
          <div className="content-lp">
            <img src={logo} alt="logo" className={cx("mt-32", styles.iconLogo)} />
            <div className={cx("flex justify-center item-center", { "mb-40": isMobile, "mt-70 mb-48 text-center": !isMobile })}>
              <h1 className={cx("white", styles.titleHeader)}>
                Cadastre-se grátis e comece a investir na prática
              </h1>
            </div>
            <div className={cx("justify-center item-center", { "flex flex-column": isMobile, "flex flex-row": !isMobile })}>
              <Button type="primary" className={styles.startNow} onClick={startNow}>Começar agora</Button>
              <Button type="primary" className={styles.haveAccount} onClick={openLogin}>Já tenho uma conta</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderHome;