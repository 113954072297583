import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification, Spin } from 'antd';
import { FiX } from 'react-icons/fi';
import CheckoutForm from 'components/CheckoutForm';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [plansList, setPlansList] = useState([]);
  const { plansArkc } = useStore();
  const { createSubscription, plansArkcPayment } = plansArkc;

  const retrievePlansArkc = async () => {
    setLoading(true);

    const response = await plansArkcPayment();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const newResponse = response.data.prices.sort(function compare(a, b) {
        if (a.unit_amount < b.unit_amount) return -1;
        if (a.unit_amount > b.unit_amount) return 1;
        return 0;
      });

      setPlansList(newResponse);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  useEffect(() => {
    retrievePlansArkc();
  }, []);

  const handleCreateSubscription = async (priceId, lookupKey) => {
    const userEmail = JSON.parse(localStorage.getItem('userEmail'));

    if (!userEmail) return;

    setLoadingSub(true);

    const response = await createSubscription({ email: userEmail, priceID: priceId, intendedPlan: lookupKey });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const data = response.data;
      setLoadingSub(false);
      history.push(ROUTES.PAYMENT, { subscriptionId: data.subscriptionId, clientSecret: data.clientSecret, lookupKey: lookupKey });
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoadingSub(false);
    };
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Spin size="large" />
        </div>
      ) : (
        <CheckoutForm
          loadingSub={loadingSub}
          plansList={plansList}
          createSubscription={handleCreateSubscription}
        />
      )}
    </div>
  );
};

export default observer(Checkout);