import { Button, Breadcrumb } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import _map from 'lodash/map';
import cx from 'classnames';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { mastercard, logoColor } from 'assets';
import styles from './CreditCardsForm.module.less';

const CreditCardsForm = ({ registerCard, cardsList }) => {
  return (
    <div className="mb-40">
      <div className="container">
        <div className="content mt-24 mb-24">
          <div className="flex flex-row">
            <button className="arkc-btn-link flex flex-row justify-center item-center" onClick={() => history.push(ROUTES.DASHBOARD)}>
              <FiArrowLeft className="i-24 heliotrope" />
              <span className="f16 b heliotrope ml-14">Voltar</span>
            </button>
            <div className="flex justify-center item-center w-100">
              <img src={logoColor} alt="logo-color" />
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <div className="flex flex-row justify-between item-center">
            <Breadcrumb separator="">
              <Breadcrumb.Item>Inicio</Breadcrumb.Item>
              <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
              <Breadcrumb.Item>Meus cartões</Breadcrumb.Item>
            </Breadcrumb>
            <div className={styles.badgeBorder}>
              <div className="flex flex-row justify-betwenn item-center p-8">
                <div className={cx("flex flex-row justify-center item-center pointer", styles.widthPage)} onClick={() => history.push(ROUTES.SIGNATURES)}>
                  <span className="f14 mine-shaft b">Assinaturas</span>
                </div>
                <div className={styles.badgeSelected}>
                  <div className="flex justify-center item-center h-100">
                    <span className="f14 b white">Meus cartões</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <h4>Meus cartões</h4>
            <div className={cx("mt-32", styles.gridCards)}>
              {_map(cardsList, (item, index) => (
                <div className={cx(styles.badgeCard, { [styles.cardUsing]: item.status })}>
                  <div className={styles.badgePadding}>
                    <div className="flex flex-row item-center">
                      <div className="mr-32">
                        <img src={mastercard} alt="mastercard" />
                      </div>
                      <div className="mr-92">
                        <span className="f14 mine-shaft mr-24">{item.name}</span>
                        <span className="f14 mine-shaft mr-22 b">****</span>
                        <span className="f14 mine-shaft b">{item.finalCard}</span>
                      </div>
                      {item.status ? (
                        <div className={styles.badgeUsing}>
                          <div className={styles.badgeUsingPadding}>
                            <span className="f12 silver-tree b">Em uso</span>
                          </div>
                        </div>
                      ) : (
                        <Button type="link" className="p-0 b">
                          Ativar
                        </Button>
                      )}
                      <Button type="link" className={cx("p-0 b", { "ml-32": !item.status, "ml-40": item.status })}>
                        Desativar
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center item-center mt-72">
              <Button type="default" className={styles.btnAdd} onClick={registerCard}>
                Adicionar um novo cartão
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardsForm;