import { useEffect, useRef } from 'react';
import { Modal } from 'antd';
import { FiX } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../CreateAccountForm.module.less';

const ModalPoliticalTerms = ({ setVisible, visible }) => {
  const ref = useRef();
  const isMobile = useMedia({ maxWidth: 767 });

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.scrollTo(0, 0);
    };
  }, [visible.open]);

  return (
    <Modal
      visible={visible.open}
      footer={null}
      onCancel={() => setVisible({ open: false, type: "" })}
      bodyStyle={{ height: isMobile ? "408px" : "550px", padding: "24px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={() => setVisible(!visible.open)}>
          <FiX className="i-24" />
        </button>
      </div>
      {isMobile ? (
        <h3 className="text-center heliotrope mt-24">
          {visible.type === "political" ? "Políticas de Privacidade" : "Arkc Termos de uso Arkc"}
        </h3>
      ) : (
        <h4 className="text-center heliotrope mt-24">
          {visible.type === "political" ? "Políticas de Privacidade" : "Arkc Termos de uso Arkc"}
        </h4>
      )}
      <div className={cx("mt-24", styles.scrollModal)} ref={ref}>
        <div className={styles.contetModal}>
          {visible.type === "political" ? (
            <>
              <div>
                <h5 className="heliotrope">1. Em geral</h5>
                <p className="f16 mt-10 mine-shaft">
                  No Arkc, nos preocupamos com seus dados pessoais, por isso preparamos esta Política de Privacidade para explicar como os
                  coletamos, usamos e compartilhamos.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Esta Política de Privacidade (“ Política de Privacidade ”) detalha os dados pessoais que a ARKC, Inc. (“ARKC”) recebe sobre
                  você, como os processamos e seus direitos e obrigações em relação a seus dados pessoais. ARKC, Inc., uma empresa registrada
                  em São Paulo - SP, é o controlador de dados para os fins da Lei Geral de Proteção de Dados ("LGPD") e qualquer legislação local
                  relevante (" Leis de proteção de dados ”).
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Ao usar ou acessar o Serviço, você concorda com os termos desta Política de Privacidade. Os termos em maiúsculas não definidos
                  aqui têm os significados estabelecidos nos termos e condições (os “ Termos e Condições ”). Podemos atualizar nossa Política de
                  Privacidade para refletir as mudanças em nossas práticas de informação. Se fizermos isso e as alterações forem materiais,
                  publicaremos um aviso informando que fizemos alterações a esta Política de Privacidade no site por pelo menos 7 dias antes de
                  as alterações serem feitas, e iremos indicar a data em que esses termos foram revisados ​​pela última vez em na parte inferior da
                  Política de Privacidade. Quaisquer revisões a esta Política de Privacidade entrarão em vigor no final desse período de 7 dias.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">2. Informações que coletamos</h5>
                <p className="f16 mt-10 mine-shaft">
                  Esta Política de Privacidade explica como coletamos, usamos e compartilhamos seus dados pessoais.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Informações que você fornece:</span><br />
                  Através do processo de registro, você nos fornecerá seu nome, endereço de e-mail (ou endereço de e-mail dos pais)
                  e idade ou data de nascimento. Você também nos fornecerá suas informações de transação de pagamento se optar por pagar
                  pelos serviços Arkc.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Dados de atividade:</span><br />
                  Ao usar o Serviço, você enviará informações e conteúdo para o seu perfil. Também geraremos dados sobre o uso de nossos Serviços,
                  incluindo seu envolvimento em atividades educacionais no Serviço, ou o envio de mensagens e outras formas de transmissão de
                  informações a outros usuários (“ Dados de Atividades ”). Também coletamos dados técnicos sobre como você interage com nossos
                  Serviços; para obter mais informações, consulte Cookies.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Quando você usa o Arkc em nosso aplicativo ou site, usamos um serviço chamado "Jornada" para registrar sua atividade.
                  Isso nos fornece um conjunto de dados e uma repetição da sessão de sua atividade no Arkc.
                  A Jornada captura e analisa seus dados de uso para nos ajudar a tornar sua experiência no Arkc melhor.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Nós apenas coletamos os dados de que precisamos, o que significa que não registramos:</span><br />
                  - Nomes de usuário<br />
                  - Nomes<br />
                  - Imagens de Perfil<br />
                  - Endereço de e-mail<br />
                  - Locais ou bios na página de perfil<br />
                  - Senha
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Registramos os seguintes dados:</span><br />
                  - Padrões de uso<br />
                  - Cliques<br />
                  - Movimentos do mouse<br />
                  - Rolando<br />
                  - Digitando
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Especificações técnicas:</span><br />
                  - Navegador<br />
                  - Tipo de dispositivo<br />
                  - Sistema operacional<br />
                  - Tamanho do visor<br />
                  - Erros de script<br />
                  - Endereço de IP
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Navegação:</span><br />
                  - Páginas visitadas<br />
                  - Referentes<br />
                  - Parâmetros de URL<br />
                  - Duração da sessão
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Atividade de aprendizagem:</span><br />
                  - Progresso da sessão<br />
                  - Respostas
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Pesquisa e Desenvolvimento de Produto</span><br />
                  Podemos entrar em contato com você para participar de atividades de pesquisa de produtos. Isso pode incluir pesquisas,
                  entrevistas e outros tipos de sessões de feedback. Quando você participa dessas atividades de pesquisa, as informações
                  fornecidas serão usadas para testar, melhorar e desenvolver nossos produtos. Gravaremos as transcrições de vídeo, áudio e
                  texto deste feedback junto com qualquer informação de contato adicional que você fornecer e manteremos esses dados por dois anos.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <span className="b heliotrope">Entre em contato conosco em suporte@arkc.com para:</span><br />
                  - Solicite mais informações sobre as atividades de pesquisa para as quais você foi voluntário.<br />
                  - Optar por não ser contatado para atividades de pesquisa.<br />
                  - Solicite uma cópia de todos os dados relacionados à pesquisa que você nos forneceu.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">3. Cookies</h5>
                <p className="f16 mt-10 mine-shaft">
                  Quando você entra no site, coletamos o tipo do seu navegador e o seu endereço IP (um endereço exclusivo que identifica o
                  seu computador na Internet). Quando você usa nosso aplicativo móvel, coletamos o tipo do seu dispositivo, o ID do seu
                  dispositivo e o seu endereço IP.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Além disso, armazenamos certas informações do seu navegador usando "cookies". Um cookie é um dado armazenado no computador do
                  usuário vinculado a informações sobre o usuário. Usamos cookies de ID de sessão para confirmar que os usuários estão logados.
                  Se você não deseja que as informações sejam coletadas por meio do uso de cookies, existe um procedimento simples na maioria
                  dos navegadores que permite negar ou aceitar o recurso de cookie; no entanto, você deve observar que os cookies podem ser
                  necessários para fornecer a você alguns recursos disponíveis no site (por exemplo, entrega personalizada de informações).
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Você pode encontrar mais informações sobre cookies e como gerenciá-los em http://www.allaboutcookies.org.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">4. Informações obtidas por terceiros</h5>
                <p className="f16 mt-10 mine-shaft">
                  Para apoiar e aprimorar o Serviço, trabalhamos com uma variedade de redes de publicidade de terceiros, provedores de serviços
                  de análise de marketing e empresas de análise de sites, como Google e Facebook.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Provedores de Serviços de Publicidade de Terceiros ”). Esses provedores de serviços de publicidade terceirizados coletam e
                  usam dados pessoais sobre suas visitas e uso do site e do aplicativo móvel, bem como outros sites, a fim de servir a você
                  anúncios e conteúdo adaptados para atender às suas preferências e prováveis ​​interesses ou compreender melhor o anúncio eficácia. Dados demográficos e de interesse agregados também serão usados ​​para fins de pesquisa de mercado.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Usamos o Google Analytics, que é uma ferramenta de análise da web que nos ajuda a entender como os usuários se envolvem com o
                  site. Como muitos serviços, o Google Analytics usa cookies primários para rastrear as interações do usuário como em nosso caso,
                  onde são usados ​​para coletar informações sobre como os usuários usam nosso site. Essas informações são usadas para compilar
                  relatórios e nos ajudar a melhorar nosso site. Os relatórios divulgam tendências do site sem identificar visitantes individuais.
                  Você pode desativar o Google Analytics sem afetar a forma como visita nosso site - para obter mais informações sobre como
                  desativar o rastreamento do Google Analytics em todos os sites que você usa, visite esta página do Google: https://tools.google.com/dlpage/gaoptout.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Você pode optar por não receber anúncios personalizados de anunciantes e redes de anúncios que sejam membros da
                  Network Advertising Initiative (“NAI”) ou que sigam os Princípios de Autorregulamentação da
                  DAA para Publicidade Comportamental Online usando suas respectivas ferramentas de cancelamento.
                  A ferramenta de opt-out do NAI pode ser encontrada aqui http://www.networkadvertising.org/choices/ e a
                  ferramenta de opt-out do DAA pode ser encontrada aqui http://www.aboutads.info/choices/ .
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Além disso, seus dispositivos móveis podem oferecer configurações que permitem que você faça escolhas sobre a coleta,
                  uso ou transferência de informações de aplicativos móveis para publicidade comportamental online
                  (por exemplo, ID de publicidade do iOS da Apple e ID de publicidade do Google Android). Observe que a desativação não impede a
                  exibição de todos os anúncios para você.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">5. Uso das informações obtidas pelo Arkc</h5>
                <p className="f16 mt-10 mine-shaft">
                  O Arkc pode, ocasionalmente, enviar a você anúncios relacionados a serviços e alterações de produtos por meio da operação
                  geral do Serviço. Enviaremos notificações de lembrete para apoiar a metodologia de ensino do Arkc, reforçando a cadência de
                  aprendizagem e ajudando a manter sua sequência de aprendizagem. Processamos seus dados para ajudar a lhe fornecer o Serviço e
                  oferecer recursos personalizados, para entender e melhorar nosso Serviço e para mantê-lo seguro e protegido.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  O Arkc pode usar ou compartilhar dados anônimos coletados através do Serviço, incluindo Dados de Atividades, sem limitação.
                  Como empresa, é fundamental que executemos nosso contrato com você com o melhor serviço possível, e é nosso legítimo interesse
                  executar essas funções de processamento e habilitar e-mails de serviço por padrão para manter seus dados seguros e fornecer
                  nosso Serviço . Você pode cancelar o recebimento de quaisquer e-mails de serviço não essenciais a qualquer momento.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Também podemos usar suas informações de contato para enviar notificações sobre novos serviços, ofertas e promoções oferecidas
                  pelo ARKC se você consentir afirmativamente em receber tais comunicações.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Quando você se registra no ARKC, algumas de suas informações são tornadas públicas e disponíveis em vários locais, incluindo
                  arkc.com/meu-perfil. Isso incluirá os dados de seu perfil e conta do a ARKC (nome, local, biografia, nome de usuário,
                  ID de usuário, foto do perfil e status de membro do ARKC Black); seus seguidores e as pessoas que você segue; e seu progresso
                  de aprendizagem (Tokens, Progresso Diário, Investimentos sendo aprendidos, nível de progresso, diamantes, Habilidades, Lições realizadas).
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Em alguns casos, outros usuários podem complementar seu perfil, inclusive enviando comentários (que podem ser excluídos por você).
                  O Arkc usa essas informações para fornecer suporte, enviar notificações essenciais, fazer cumprir nossos termos, condições e
                  políticas, comunicar-se com você, administrar os Serviços e operações internas, incluindo solução de problemas, análise de dados,
                  testes, pesquisas, para fins estatísticos e de pesquisa. Os Termos e Condições do Arkc fornecem a base legal contratual para
                  essas atividades de processamento.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">6. Compartilhamento de dados pessoais com terceiros</h5>
                <p className="f16 mt-10 mine-shaft">
                  O Arkc compartilha seus dados pessoais apenas quando for necessário para oferecer o Serviço, legalmente exigido ou permitido por você.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Forneceremos dados pessoais a provedores de hospedagem como Amazon Web Services, provedores de mecanismo de pesquisa como o
                  Google, provedores de análise como Crashlytics e provedores de suporte como ZenDesk.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Esses processadores de dados nos ajudam a oferecer o Serviço a você. Por exemplo, podemos compartilhar suas informações para
                  detectar onde ou como você encontrou um bug ao usar nosso aplicativo móvel. Em conexão com essas operações, nossos provedores
                  de serviço terão acesso aos dados pessoais por um período limitado. Quando utilizamos provedores de serviços para processar
                  quaisquer dados pessoais, implementamos proteções contratuais que limitam o uso desses dados pessoais para a prestação de
                  serviços para o Arkc.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Seremos obrigados a acessar e divulgar dados pessoais em resposta a solicitações legais, como intimações ou ordens judiciais,
                  ou em conformidade com as leis aplicáveis. Além disso, acessaremos e compartilharemos a conta ou outros dados pessoais quando
                  acreditarmos que seja necessário para cumprir a lei, para proteger nossos interesses ou propriedade, para evitar fraude ou
                  outra atividade ilegal perpetrada através do Serviço ou usando o nome ARKC, ou para prevenir dano iminente. Isso incluirá o
                  acesso e compartilhamento de dados pessoais com outras empresas, advogados, agentes ou agências governamentais.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Se a propriedade de todos ou substancialmente todos os negócios do Arkc, ou unidades de negócios individuais ou ativos
                  pertencentes ao Arkc que estão relacionados ao Serviço, mudar, seus dados pessoais serão transferidos para o novo proprietário.
                  Em qualquer transferência de informações, seus dados pessoais permanecerão sujeitos a esta seção.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  O Arkc irá compartilhar dados agregados ou anônimos coletados através do Serviço, incluindo Dados de Atividade, para fins de
                  compreensão ou melhoria do serviço.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">7. Compartilhamento de dados pessoais com terceiros</h5>
                <p className="f16 mt-10 mine-shaft">
                  Você tem os seguintes direitos em relação aos dados pessoais que mantemos sobre você, a menos que disposto de outra forma pela lei local:
                </p>
                <p className="f16 mt-10 mine-shaft">
                  O Arkc irá reter seus dados até que sua conta seja excluída, após o qual iremos reter dados anônimos coletados através do
                  Serviço, incluindo Dados de Atividades, que podem ser usados ​​pelo Arkc e compartilhados com terceiros de qualquer maneira.
                  As informações associadas aos desafios e lições do Arkc, incluindo os resultados, serão coletivamente excluídas de sua conta
                  do Arkc após cinco anos da data de upload do seu teste, mas os dados do exame serão anônimos, e será mantido indefinidamente
                  pelo Arkc para melhorar as lições e para pesquisas e análises.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Observe que alguns desses direitos não são absolutos. Em alguns casos, podemos recusar uma solicitação para exercer direitos
                  específicos se cumprir com isso significar que não somos mais capazes de cumprir nossa obrigação contratual de fornecer a você
                  produtos e serviços específicos. Vamos mantê-lo informado sobre as ações que podemos tomar quando você fizer sua solicitação.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Você também pode ter o direito de fazer uma reclamação de LGPD à Autoridade Supervisora ​​relevante.
                  Se precisar de mais ajuda em relação aos seus direitos, entre em contato conosco usando as informações de contato fornecidas
                  abaixo e consideraremos sua solicitação de acordo com a legislação aplicável. Em alguns casos, nossa capacidade de defender
                  esses direitos para você pode depender de nossas obrigações de processar informações pessoais por motivos de
                  segurança, proteção, prevenção de fraude, conformidade com requisitos regulamentares ou legais ou porque o processamento é
                  necessário para fornecer os serviços que você solicitou. Quando for o caso, iremos informá-lo de detalhes específicos em
                  resposta ao seu pedido.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  - Para solicitar acesso ou eliminação de dados pessoais que mantemos sobre você.<br />
                  - Para nos solicitar a restrição do processamento dos dados pessoais que mantemos sobre você.<br />
                  - Para se opor ao processamento de dados pessoais relacionados a você.<br />
                  - Nos casos em que nos deu consentimento para processar seus dados pessoais, você tem o direito de retirar esse consentimento a qualquer momento.<br />
                  - Para exportar os dados pessoais que você forneceu ao Arkc em um formato que pode ser transferido eletronicamente a terceiros.<br />
                  - Para deletar sua conta no Arkc seguindo as instruções disponíveis no Serviço.<br />
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">8. Compartilhamento de dados pessoais com terceiros</h5>
                <p className="f16 mt-10 mine-shaft">
                  Você pode ter cookies colocados em seu computador por sites de terceiros que o direcionam ao nosso Serviço.
                  Embora não compartilhemos seus dados pessoais com esses sites de terceiros, a menos que seja razoavelmente necessário
                  para oferecer o Serviço, eles podem ser capazes de vincular certas informações não pessoalmente identificáveis
                  que transferimos para eles com dados pessoais que coletaram anteriormente de você. Reveja as políticas de privacidade de
                  cada site que você visita para entender melhor suas práticas de privacidade. Além disso, o ARKC informa que sempre que clicar
                  em links (incluindo banners publicitários) que o levem a sites de terceiros, você estará sujeito às políticas de privacidade
                  de terceiros.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  Nossos serviços contêm links para outros sites operados por terceiros. O ARKC não controla esses outros sites e não é
                  responsável por seu conteúdo, suas políticas de privacidade ou seu uso de dados pessoais, incluindo quaisquer informações
                  pessoais ou financeiras coletadas por nosso processador de pagamento terceirizado para processar pagamentos para compras no
                  aplicativo. A inclusão de tais links no ARKC não implica qualquer endosso do conteúdo de tais sites ou de seus proprietários
                  ou operadores, exceto conforme divulgado nos Serviços. Qualquer informação enviada por você diretamente a esses terceiros está
                  sujeita à política de privacidade desses terceiros.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">9. Links</h5>
                <p className="f16 mt-10 mine-shaft">
                  O Serviço pode conter links para outros sites. Não somos responsáveis ​​pelas práticas de privacidade de outros sites.
                  Nós encorajamos os usuários a estarem atentos quando saírem do Serviço para ler as declarações de privacidade de outros sites
                  que coletam informações de identificação pessoal. Esta Política de Privacidade se aplica apenas às informações coletadas pelo
                  ARKC por meio do Serviço.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">10. Contate-nos</h5>
                <p className="f16 mt-10 mine-shaft">
                  Para todas as consultas de privacidade de dados e quaisquer perguntas ou dúvidas que você tenha sobre esta Política de Privacidade,
                  entre em contato com nosso time em suporte@arkc.com.br
                </p>
              </div>
            </>
          ) : (
            <>
              <p className="f16 mine-shaft">
                Por meio deste documento (os “Termos de Uso”), ficam estabelecidas as regras a respeito do uso das soluções oferecidas pela empresa ARKC LTDA, inscrita no CNPJ sob nº 42.230.146/0001-73, com sede no VD Nove de Julho, nº 200, Centro, São Paulo – SP, CEP 01.050-060 (a “Arkc”) por meio de seu site, softwares e integrações (a “Plataforma”).
              </p>
              <p className="f16 mt-10 mine-shaft">
                O uso de todas as funcionalidades oferecidas está condicionado à aceitação dos Termos de Uso por você, no momento de seu cadastro, por meio do aceite via “checkbox”. Portanto, ao realizar seu cadastro, você concorda com os presentes Termos de Uso, bem como com as demais políticas complementares a este instrumento, que estabelecem o relacionamento contratual com a Arkc.
              </p>
              <p className="f16 mt-10 mine-shaft">
                Sempre que você visualizar palavras com iniciais maiúsculas, tais palavras representam uma definição realizada dentro deste documento. Essas definições podem ocorrer por meio de palavras entre parênteses, entre aspas e sublinhado (tais como as definidas acima) ou por meio do glossário ao final destes Termos de Uso.
              </p>
              <p className="f16 mt-10 mine-shaft">
                A seguir, você terá acesso a todas as regras a respeito da sua relação com a Arkc e de uso da Plataforma, bem como regras de pagamento, suporte, entre outras, sendo sua leitura obrigatória antes da realização do aceite do cadastro para a utilização da Plataforma e contratação dos planos oferecidos pela Arkc.
              </p>
              <div className="mt-20">
                <h5 className="heliotrope">1. Objeto</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>1.1 Licenciamento Arkc.</b> O objeto destes Termos de Uso é o licenciamento não definitivo, não exclusivo, não transferível e revogável da Plataforma, que poderá ser acessada por meio de <strong>Aplicativo Mobile ou Aplicativo Web</strong>, para utilização voltada à prospecção e gerenciamento de atendimentos
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">2. A Plataforma</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>2.1 Educação Financeira.</b> Por meio da Plataforma, a Arkc fornecerá trilhas de conhecimento sobre educação financeira, formadas por lições interativas e gamificadas. Você poderá acessar a Plataforma de maneira gratuita, bastando que realize o cadastro, conforme descrito na cláusula 4. Cadastro do Usuário. E, caso queira determinados benefícios, poderá optar pela utilização de planos pagos, conforme 8. Políticas Comerciais, destes Termos de Uso.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>2.2 Exposição Estrita de Informações Educacionais.</b> Todos os conteúdos dispostos dentro da Plataforma são de caráter estritamente educacional, e não representam indicação ou sugestão de investimento em empresas, direitos ou quaisquer outros tipos de negócios.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>2.3 Impossibilidade de Investimento</b> A Plataforma não possibilita, de maneira alguma, que você realize investimentos de qualquer natureza, tratando-se apenas de um software voltado para o ensino de educação financeira.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>2.4 Arkcoins</b> Ao completar avançar nas trilhas de conhecimento ou completar desafios, você poderá adquirir moedas virtuais (“Arkcoins”). As Arkcoins poderão ser utilizadas dentro da Plataforma, não sendo possível a sua troca ou resgate em valores financeiros ou por meio de quaisquer direitos passíveis de quantificação econômica.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>2.5 Novas Funcionalidades</b> A Arkc poderá adicionar novas funcionalidades à Plataforma, a seu critério, o que será informado a você na mesma oportunidade em que serão enviadas informações para a sua utilização.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">3. Políticas de Privacidade</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>3.1 Regras de Privacidade.</b> O usuário poderá encontrar todas as regras e esclarecimentos sobre privacidade e proteção de dados através da Política de Privacidade em https://www.arkc.com.br/politicas-privacidade.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">4. Cadastro do usuário</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>4.1 Cadastro na Plataforma Arkc</b> Para a utilização da Plataforma, você deverá realizar seu cadastrando, o que poderá ocorrer por meio do fornecimento dos dados solicitados ou da autorização de acesso da Arkc aos dados já fornecidos a outras plataformas, como o Google e Facebook.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>4.2 Suspensão de Cadastros pela Arkc</b> Caso seja identificado que você tenha ferido alguma das obrigações destes Termos de Uso, seu cadastro poderá ser suspenso, sem aviso prévio, de maneira temporária. A suspensão do cadastro não permitirá que determinadas funções possam ser utilizadas até que as obrigações passem a ser cumpridas, conforme informado no momento da suspensão. Ter sua conta suspensa não quer dizer que houve a rescisão contratual em relação a você, portanto, as cobranças continuarão a ser realizadas.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>4.3 Exclusão</b> A critério da Arkc, você ou seus usuários poderão ser excluídos da Plataforma, sem aviso prévio, sendo impossibilitados completamente de utilizá-la, por ferirem os deveres previstos nestes Termos de Uso. A exclusão fará com que nenhuma das funcionalidades da Plataforma Arkc possa ser utilizada, sem prejuízo da cobrança, pela Arkc, de valores eventualmente devidos em decorrência destes Termos de Uso.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">5. Manutenção da plataforma</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>5.1 Disponibilidade</b>A Arkc empregará todos os seus esforços para que a Plataforma se mantenha a todo 24h (vinte e quatro horas) em funcionamento, contudo, não pode garantir 100% (cem por cento) desta disponibilidade. Para tanto, a Arkc deixa fixado como acordo de SLA uma garantia de desempenho equivalente a 98% (noventa e oito por cento) de disponibilidade de tempo para uso da Plataforma.
                </p>
                <p className="f16 mt-12 mine-shaft">
                  <b>5.2 Manutenções</b> A Plataforma, está sujeita a atualizações e manutenções periódicas, que ocorrerão preferencialmente fora do horário comercial, por volta das 22h. Havendo instabilidades no todo ou em parte da Plataforma, a Arkc resguarda para si o direito de correção das instabilidades em tempo hábil sem que acarrete qualquer prejuízo na relação contratual.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">6. Ausência de responsabilidade</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.1 Regras de Plataforma Terceiros</b> Ao utilizar integrações de terceiros à Plataforma, você deverá ter lido e aceito todo o regulamento que envolver tais soluções. Portanto, a Arkc não será responsabilizada pelos atos que você venha a praticar que infrinjam regulamentos da Plataforma ou de soluções de terceiros que sejam integradas à Plataforma, cabendo direito de regresso por parte da Arkc em casos como os mencionados.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.2 Disponibilidade de Plataforma Terceiros</b> A Arkc também não será responsabilizada pela indisponibilidade ou mau funcionamento das plataformas de terceiros integrados à Plataforma, de forma que, caso você seja prejudicado de alguma maneira, deverá buscar o atendimento do terceiro proprietário da respectiva plataforma integrada à Plataforma.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.3 Impossibilidade de Abatimentos</b> Caso a indisponibilidade, mau funcionamento, suspensão de contas de usuários da plataforma de terceiros venha prejudicar você ou os usuários cadastrados na Plataforma Arkc ou as respectivas integrações, não será possível a realização de qualquer abatimento quanto aos valores pagos para a utilização da Plataforma.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.4 Atos de Usuários</b> Quaisquer atos que você pratique contra terceiros, que venham a prejudicá-los, serão de sua própria responsabilidade. Também, serão de sua responsabilidade quaisquer atos contrários à legislação brasileira ou internacional praticados por meio da Plataforma.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.5 Impossibilidade Técnica, Fática e Jurídica</b> Por motivos de impossibilidade técnica, fática e jurídica, a Arkc não pode garantir que: (i) fiscalizará os atos dos usuários no âmbito da Plataforma ou fora dela; (ii) verificará o cumprimento das obrigações por parte dos usuários; (iii) verificará veracidade de todas as informações fornecidas ou publicadas pelos usuários na Plataforma; (iv) garantirá o cumprimento das responsabilidades e obrigações assumidas pelos usuários perante outros usuários.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.6 Demais Casos</b> Entre outras situações, a Arkc não se responsabiliza por eventuais danos, prejuízos ou ocorrências derivadas de caso fortuito, força maior ou da ação fraudulenta de terceiros, tampouco da apreensão, cooptação, eliminação, modificação ou uso indevido de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações e/ou executar atos fraudulentos.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>6.7 Compartilhamento de Dados</b> Você é o único responsável pelo compartilhamento de seus próprios dados de acesso à Plataforma, por conta e vontade própria, a terceiros.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">7. Propriedade Intelectual</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>7.1 Propriedade</b> A Arkc é proprietária de todos os direitos de propriedade intelectual sobre seus sites, todo seu conteúdo, serviços, produtos, marcas, nomes comerciais, logotipos, desenhos, imagens, frases publicitárias, direitos autorais, domínios, programas de computação, códigos, desenvolvimentos, software, bancos de dados, informações, tecnologia, patentes e modelos de utilidade, desenhos e modelos industriais, segredos comerciais, entre outros, que estão protegidos por leis nacionais e internacionais.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>7.2 Vedações</b> Não é permitido a você ou a quaisquer usuários (i) redistribuir, vender, alugar ou sublicenciar a Plataforma, bem como de copiar, acessar e/ou realizar engenharia reversa no código-fonte da Plataforma; (ii) acessar o código-fonte ou realizar engenharia reversa da Plataforma, bem como tentar quebrar qualquer medida de segurança relativa à Plataforma; (iii) realizar atos de hacking de qualquer natureza; (iv) acessar ou tentar obter acesso a dados exclusivos da, ou de seus usuários; (v) utilizar os serviços fornecidos para quaisquer fins ilegais; (vi) utilizar-se da Propriedade Intelectual de maneira a causar confusão em usuários ou possíveis usuários e clientes da Arkc, entre outros atos que possam infringir os direitos de propriedade intelectual da Arkc.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>7.3 Sanções</b> Caso o usuário infrinja os direitos de propriedade intelectual da Arkc, poderá ter seu cadastro excluído da Plataforma, bem como sofrer com as demais sanções previstas na legislação brasileira.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">8. Políticas Comerciais</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>8.1 Planos de Assinatura</b> A utilização da Plataforma é gratuita. Contudo, você poderá realizar a contratação do “Plano Black” ou “Plano Premium”, que possibilitarão uma série de benefícios específicos, de acordo com a oferta aceita no momento da contratação.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>8.2 Contratação</b> Os planos de assinatura poderão ser contratados na modalidade pré-paga, por meio de assinaturas mensais ou anuais. Após o seu pagamento, os benefícios do plano contratado serão disponibilizados imediatamente na Plataforma.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>8.3 Não Utilização</b> Você não ficará isento do pagamento da assinatura contratada caso não venha a acessar a Plataforma e/ou que não venha cumprir com as trilhas de conhecimento ou desafios, sendo o pagamento devido pela simples contratação de quaisquer dos planos disponibilizados.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>8.4 Formas de Pagamento</b> Você poderá contratar os planos realizando o pagamento pelos meios disponibilizados na Plataforma. Para tanto, a Arkc realiza integração com o sistema de pagamentos XXXXXXXX. Portanto, caso tenha alguma dúvida ou problema quanto ao pagamento, poderá entrar em contato direto com a XXXXXXXXX para que possam te ajudar a resolver a questão levantada, não tendo a Arkc qualquer responsabilidade quanto a essas questões, por não ser a proprietária do sistema de pagamento disponibilizado.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>8.5 Cancelamento</b> O cancelamento do plano contratado poderá ser feito a qualquer momento, sem necessidade de justificativa, por meio da Plataforma ou via solicitação de cancelamento por e-mail direcionado à Arkc. A solicitação de cancelamento deverá ser feita por meio de contato com a equipe de suporte, via suporte@arkc.com.br. Após o pedido de cancelamento, o acesso continuará liberado até o final do último mês adimplido.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>8.6 Renovação</b> O plano será renovado automaticamente a cada mês, exceto se o cliente solicitar o cancelamento, conforme descrito na cláusula 8.5. acima, com 30 (trinta) dias de antecedência.
                </p>
              </div>
              <div className="mt-16">
                <h5 className="heliotrope">9. Disposições Gerais</h5>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.1 Suporte</b> Caso você tenha alguma dúvida ou necessidade que requeira o suporte da Arkc, poderá enviar uma solicitação de suporte ao e-mail suporte@arkc.com.br, com o título “SOLICITAÇÃO DE SUPORTE”. Após o envio de pedido de suporte, a Arkc terá o prazo de resposta de até 72h (setenta e duas horas).
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.2 Fornecimento de Dados e Informações</b> A Arkc não se responsabiliza por quaisquer informações ou dados imprecisos ou errados fornecidos por você ou por seus usuários à própria Arkc ou a terceiros dentro ou fora da Plataforma.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.3 Prerrogativas</b> A Arkc reserva-se o direito de, a seu critério e a qualquer tempo: (i) alterar, remover ou adicionar novas funcionalidades à Plataforma Arkc; (ii) alterar as regras destes Termos de Uso e documentos complementares; (iii) interromper a disponibilização da Plataforma.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.4 Terceirização</b> A Arkc poderá terceirizar ou se apoiar em serviço de terceiros, com o objetivo de suportar o funcionamento e/ou o fornecimento da Plataforma a você e aos demais usuários.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.5 Não Afetação</b> Caso alguma disposição destes Termos de Uso venha a ser declarada inválida, ilegal ou inexequível, as demais disposições não serão afetadas ou prejudicadas.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.6 Legislação Aplicável</b> Todos os itens deste acordo serão regidos pelas leis vigentes no Brasil.
                </p>
                <p className="f16 mt-10 mine-shaft">
                  <b>9.7 Foro</b> Fica eleito o foro da comarca de São Paulo – SP, para dirimir quaisquer controvérsias, dúvidas, desentendimentos, litígios ou questões advindas da relação entre você e a Arkc.
                </p>
              </div>
              <div className="flex justify-center item-center mt-18">
                <p className="f16 mt-10 mine-shaft text-center">
                  Agradecemos pela leitura destes Termos de Uso.<br />
                  Data da Publicação 18/05/2023
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalPoliticalTerms;