import { Form, Input, Button, } from 'antd';
import { sendSuccess, logoColor } from 'assets';
import { FiArrowLeft } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import UserMessage from '../UserMessage';
import { message } from 'helpers/messages';
import styles from './RecoverPasswordForm.module.less';

const RecoverPasswordForm = ({ passwordRecover, setLoading, success, loading }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const regexEmailOnlyCharacters = new RegExp("[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ@. ]+$");
        const regexEmailAddress = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!regexEmailOnlyCharacters.test(value)) {
          return Promise.reject(message.validate_name);
        };

        if (!regexEmailAddress.test(value)) {
          return Promise.reject(message.validate_email)
        };
      };

      return Promise.resolve()
    }
  });

  return (
    <div className={styles.bottomFooter}>
      <div className="container">
        <div className="content">
          <div className={cx("mt-24 mb-24", { "flex flex-row justify-between item-center": isMobile, "flex justify-center item-center": !isMobile })}>
            {(isMobile || isTablet) ? (
              <>
                <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
                  <FiArrowLeft className="i-24" />
                </button>
                <div className="flex justify-center item-center w-100">
                  <img src={logoColor} alt="logo-recover" />
                </div>
              </>
            ) : (
              <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
                <img src={logoColor} alt="logo-recover" />
              </button>
            )}
          </div>
        </div>
      </div>
      <hr className={cx("divider", styles.dividerTop)} />
      <div className="container">
        <div className="content">
          {!success ? (
            <>
              <div className={cx("flex flex-column justify-center item-center", styles.topTitle)}>
                <h5 className={cx("mb-12", styles.titleSize)}>Recuperação de senha</h5>
                <p className={cx("f18 mine-shaft", styles.description)}>
                  Digite o e-mail que você usou para criar sua conta
                </p>
              </div>
              <div className="flex flex-column justify-center item-center mt-40">
                <Form
                  name="recover-password"
                  onFinish={passwordRecover}
                  onFinishFailed={() => setLoading(false)}
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'O email é obrigatório.' }, validateEmail]}
                    className="mb-74"
                  >
                    <Input
                      type="email"
                      placeholder="Digite seu email"
                      disabled={loading ? true : false}
                      className="input-base"
                    />
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setLoading(true)}
                      loading={loading}
                      className={styles.btnRecover}
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </>
          ) : (
            <UserMessage
              title={message.title_recover_message}
              description={message.description_recover_message}
              label={message.label_recover_message}
              image={sendSuccess}
              route={ROUTES.HOME}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RecoverPasswordForm;