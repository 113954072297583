import { useEffect, useState } from 'react';
import InviteFriendsForm from 'components/InviteFriendsForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const InviteFriends = (props) => {
  const [actualInvite, setActualInvite] = useState("");

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.invite) {
      setActualInvite(state.invite);
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  return (
    <div>
      <InviteFriendsForm actualInvite={actualInvite} />
      <Footer />
    </div>
  );
};

export default InviteFriends;