import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { logoPremium, arckPremium, exclusivePremium, unlockPremium, graphicPizzaPremium, opportunitiesPremium, logoColor } from 'assets';
import styles from './LandingPagePremiumForm.module.less';
import "@egjs/flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";

const LandingPagePremiumForm = ({ buyPlan, worksPremium, planPremium, graphicImage, price, benefits }) => {
  const [plugins, setPlugins] = useState([]);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 });

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
  }, []);

  return (
    <div>
      <div className={cx("container", styles.headerPremium)}>
        <div className="content mt-24 mb-24">
          <div className="flex flex-row justify-center item-center">
            <img src={logoColor} alt="logo-color" className="pointer" />
          </div>
        </div>
      </div>
      <div className={styles.imageBackground}>
        <img src={logoPremium} alt="logo-premium" className={styles.imageBgSize} />
      </div>
      <div className="flex flex-column justify-center item-center mt-82">
        <div className={styles.badgePremium}>
          <div className="flex flex-row item-center p-12">
            <img src={arckPremium} alt="arck-premium" />
            <span className="f18 mine-shaft b ml-8">Arkc Premium</span>
          </div>
        </div>
        <h2 className={cx("text-center mine-shaft mt-24 mb-24", styles.titlePremium)}>
          Seja sócio das maiores empresas do mundo.
        </h2>
        <p className={cx("f18 text-center mb-40", styles.descriptionPremium)}>
          Assine os investimentos Arkc Premium e aprenda na prática como se tornar sócio das maiores empresas do mundo e
          receber aluguéis em dólares todos os trimestres
        </p>
        <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Assinar</Button>
        <div className="container">
          <div className="content">
            <div className="mt-80 mb-80">
              {isMobile ? (
                <div className="flicking-view-plans">
                  <Flicking circular={true} plugins={plugins} align="prev">
                    {_map(benefits, (item, index) => (
                      <div key={index} className={cx(styles.cardPremium, "mr-24")}>
                        <div className="p-24">
                          <div className="mb-12">
                            <div className={styles.bgIcon}>
                              <div className="flex justify-center item-center h-100">
                                <img src={item.path} alt="type-investiments" />
                              </div>
                            </div>
                          </div>
                          <span className="f18 b mine-shaft">{item.title}</span>
                          <p className={cx("f16 mt-16", styles.cardDescription)}>{item.description}</p>
                        </div>
                      </div>
                    ))}
                    <ViewportSlot>
                      <div className="flicking-pagination"></div>
                    </ViewportSlot>
                  </Flicking>
                </div>
              ) : (
                <div className={cx({ [styles.horizontalScroll]: isMobile, [styles.gridBenefits]: !isMobile })}>
                  {_map(benefits, (item, index) => (
                    <div key={index} className={cx(styles.cardPremium, { "mr-24": isMobile && index !== _size(benefits) - 1 })}>
                      <div className="p-24">
                        <div className="mb-12">
                          <div className={styles.bgIcon}>
                            <div className="flex justify-center item-center h-100">
                              <img src={item.path} alt="type-investiments" />
                            </div>
                          </div>
                        </div>
                        <span className="f18 b mine-shaft">{item.title}</span>
                        <p className={cx("f16 mt-16", styles.cardDescription)}>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.bgTravertine}>
          <div className="container mt-40 mb-40">
            <div className="content">
              <div className={cx("flex", { "flex-column": isMobile || isTablet, "flex-row": !isMobile && !isTablet })}>
                <div className={cx({ "flex flex-column justify-center item-center": isMobile || isTablet })}>
                  {isMobile ? (
                    <>
                      <h6 className="fw4 mine-shaft mb-24">Rentabilidade 62,19%</h6>
                      <h2 className="mb-16 text-center">Rentabilidade histórica Arkc Premium</h2>
                    </>
                  ) : (
                    <>
                      <h5 className="mine-shaft mb-24">Rentabilidade 62,19%</h5>
                      <h3 className="mb-48">Rentabilidade histórica Arkc Premium</h3>
                    </>
                  )}
                  <div className={cx("mb-16", styles.badgeProfitability)}>
                    <div className="flex justify-center item-center h-100">
                      <span className="f16 b mine-shaft">Arkc Premium + 62,19%</span>
                    </div>
                  </div>
                  <div className={styles.badgeSavings}>
                    <div className="flex justify-center item-center h-100">
                      <span className="f16 b mine-shaft">Poupança + 19,31%</span>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.bgGraphic, { "mt-40": isMobile || isTablet, "ml-66": !isMobile && !isTablet && isDesktop })}>
                  <div className={styles.paddingGraphic}>
                    {isMobile ? (
                      <div className="flex flex-row justify-between item-center mb-40">
                        <p className="f16 mine-shaft">Investimento Poupança + 19,31%</p>
                        <h6 className="f16 texas-rose">Investimentos Arkc Premium + 62,19%</h6>
                      </div>
                    ) : (
                      <>
                        <h5 className="texas-rose">Investimentos Arkc Premium + 62,19%</h5>
                        <p className="f18 b mine-shaft mt-12">Investimento Poupança + 19,31%</p>
                      </>
                    )}
                    <div className={cx({ "flex justify-center item-center": isTablet, "mt-32": !isMobile && !isTablet })}>
                      <img src={graphicImage} alt="graphic-premium" className={styles.graphicImage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("flex justify-center item-center", { "flex-column mt-64 mb-64": isMobile || isTablet, "flex-row mt-120 mb-120": !isMobile && !isTablet })}>
          <div className={cx({ "mb-40": isMobile || isTablet, "mr-78": !isMobile && !isTablet })}>
            <img src={opportunitiesPremium} alt="opportunities-premium" className={styles.opportunitiesImage} />
          </div>
          <div className={cx({ "flex flex-column justify-center item-center": isMobile || isTablet })}>
            {isMobile ? (
              <h3 className={cx("mb-12", styles.opportunitiesTitle)}>Notificações sobre o mercado</h3>
            ) : (
              <h4 className={cx("mb-24", styles.opportunitiesTitle)}>Notificações sobre o mercado</h4>
            )}
            <p className={cx("f16", styles.opportunitiesDescription, { "mb-24": isMobile || isTablet, "mb-40": !isMobile && !isTablet })}>
              Te informamos sobre os momentos do mercado que podem impactar seus investimentos, para você poder tomar as melhores decisões na hora certa
            </p>
            <Button type="primary" className={styles.btnSignTwo} onClick={buyPlan}>Assinar</Button>
          </div>
        </div>
        <div className={styles.badgeWorks}>
          <div className={cx("flex flex-column justify-center item-center", styles.paddingBadge)}>
            <div className={styles.badgePremium}>
              <div className="flex flex-row item-center p-12">
                <img src={arckPremium} alt="arck-premium" />
                <span className="f18 mine-shaft b ml-8">Arkc Premium</span>
              </div>
            </div>
            {isMobile ? (
              <h2 className="mt-32 mb-12">Como funciona?</h2>
            ) : (
              <h4 className="mt-24 mb-12">Como funciona?</h4>
            )}
            <p className={cx("f18 mine-shaft text-center", styles.badgeDescription)}>
              Ao assinar a carteira Arkc Premium, você ganha acesso a área exclusiva de assinantes
            </p>
            <div className={cx("flex", { "flex-column mt-32": isMobile, "flex-row item-center mt-70": !isMobile })}>
              {_map(worksPremium, (item, index) => (
                <div key={index} className={cx({ "mb-48": isMobile && !isTablet && index !== _size(worksPremium) - 1, "mr-12": isTablet && index !== _size(worksPremium) - 1, "mr-48": !isMobile && !isTablet && index !== _size(worksPremium) - 1 })}>
                  <img src={item.id === 1 ? exclusivePremium : item.id === 2 ? graphicPizzaPremium : unlockPremium} alt="type-area" />
                  <div className={cx({ "mt-16": isMobile, "mt-32": !isMobile })}>
                    <h5 className="mine-shaft mb-12">{item.title}</h5>
                    <p className={cx("f16", styles.descriptionWorks)}>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={cx("flex flex-column justify-center item-center", { "mt-64 mb-24": isMobile || isTablet, "mt-72 mb-80": !isMobile && !isTablet })}>
          <span className={cx("f18 texas-rose mb-8", styles.titlePlan)}>Aprenda a investir na prática por meio de lições baseadas na carteira Arkc Premium</span>
          {isMobile ? (
            <h1 className={cx("text-center mine-shaft mb-24", styles.titlePlanPremium)}>Investimentos Arkc Premium</h1>
          ) : (
            <h2 className={cx("text-center mine-shaft mb-32", styles.titlePlanPremium)}>Investimentos Arkc Premium</h2>
          )}
          {_map(planPremium, (item, index) => (
            <div key={index}>
              <div className="flex flex-row justify-between item-center">
                <span className="f14 mine-shaft">{item.title}</span>
                <span className="silver">{item.description}</span>
              </div>
              <hr className={cx("divider mt-8 mb-8", styles.dividerPremium)} />
            </div>
          ))}
          <div className={cx("flex flex-column justify-center item-center mt-40", styles.price)}>
            <span className={cx("f18 mine-shaft", { "mb-8": isMobile || isTablet, "mb-2": !isMobile && !isTablet })}>Valor da assinatura</span>
            <div className="flex flex-row justify-center mb-24">
              <span className={cx("f14 mine-shaft mr-12", { "b": isMobile || isTablet, "mt-12": !isMobile && !isTablet })}>R$</span>
              <h2 className={cx("texas-rose", { "f56": isMobile || isTablet })}>{price.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 })}</h2>
              <span className={cx("f14 mine-shaft ml-8", { "mt-20": isMobile, "mt-36": !isMobile })}>por mês</span>
            </div>
            <Button type="primary" className={styles.btnSize} onClick={buyPlan}>Assinar</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPagePremiumForm;