import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import moment from 'moment';
import useMedia from 'use-media';
import { waterMark, footerDark, footer } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './Footer.module.less';

const Footer = ({ clickPage, isDark, isHome }) => {
  let userArkc = JSON.parse(localStorage.getItem('userArkc'));
  const isMobile = useMedia({ maxWidth: 767 });
  const actualYear = moment().format("YYYY");
  const pagesRoutes = {
    links: [
      { title: "Home", route: ROUTES.HOME, param: "home" },
      { title: "Sobre", route: ROUTES.HOME, param: "about" },
      { title: "Aprender", route: userArkc ? ROUTES.DASHBOARD : ROUTES.LOGIN },
    ],
    support: [
      { title: "Suporte", route: ROUTES.SUPPORT },
      { title: "Termos de uso", route: ROUTES.TERMS_USE },
      { title: "Politicas de privacidade", route: ROUTES.PRIVACY_POLICIES },
    ],
  };

  const handleRedirect = (param, route) => {
    if (isHome && param) {
      clickPage(param);
    } else if (!isHome && param) {
      history.push(route, { topic: param });
    } else {
      history.push(route);
    };
  };

  return (
    <footer className={cx({ [styles.footerDark]: isDark })}>
      <div className={cx("container", { "pb-24": isDark })}>
        <div className="content">
          <div className={cx("flex", { "flex-column mt-40": isMobile, "flex-row justify-between mt-62": !isMobile })}>
            <div className={cx({ "mb-60": isMobile, "flex flex-column": !isMobile })}>
              {!isDark ? (
                <img src={footer} alt="footer" className={styles.footerSize} />
              ) : (
                <img src={footerDark} alt="footer" className={styles.footerSize} />
              )}
              {!isMobile && (
                <span className={cx("f14 fw4 mt-20", { "melrose": isDark, "mine-shaft": !isDark })}>
                  {`© ${actualYear} ARKC LTDA. · CNPJ 42.230.146/0001-73`}
                </span>
              )}
            </div>
            {(!isMobile && !isDark) && (
              <img src={waterMark} alt="water-mark" className={styles.waterImage} />
            )}
            <div className={styles.gridLinks}>
              <div>
                <div className="mb-14">
                  <label className={cx("f18 b", { "melrose": isDark, "black": !isDark })}>Links</label>
                </div>
                <div className="flex flex-column">
                  {_map(pagesRoutes.links, (item, index) => (
                    <button
                      key={index}
                      className={cx("arkc-btn-link text-left", {
                        "mb-8": isMobile && index !== _size(pagesRoutes.links) - 1,
                        "mb-12": !isMobile && index !== _size(pagesRoutes.links) - 1
                      })}
                      onClick={() => handleRedirect(item.param, item.route)}
                    >
                      <span className={cx("f14 fw4 pointer", { "melrose": isDark, "black": !isDark })}>{item.title}</span>
                    </button>
                  ))}
                </div>
              </div>
              <div>
                <div className="mb-14">
                  <label className={cx("f18 b pb-14", { "melrose": isDark, "black": !isDark })}>Suporte e outros</label>
                </div>
                <div className="flex flex-column justify-start">
                  {_map(pagesRoutes.support, (item, index) => (
                    <button
                      key={index}
                      className={cx("arkc-btn-link text-left", { "mb-12": index !== _size(pagesRoutes.links) - 1 })}
                      onClick={() => history.push(item.route)}
                    >
                      <span className={cx("f14 fw4 pointer", { "melrose": isDark, "black": !isDark })}>{item.title}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="text-center pb-24">
          <hr className={cx("divider mt-32 mb-24", styles.footerDivider)} />
          <span className={cx("f14", { "melrose": isDark, "mine-shaft": !isDark })}>
            {`© ${actualYear} ARKC LTDA. · CNPJ 42.230.146/0001-73`}
          </span>
        </div>
      )}
    </footer>
  );
};

export default Footer;