import { useEffect } from 'react';
import TermsUseForm from 'components/TermsUseForm';

const TermsUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TermsUseForm />
  );
};

export default TermsUse;