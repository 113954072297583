import { useEffect } from 'react';
import LandingPageThreeForm from 'components/LandingPageForm/LandingPageThreeForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { arkcThree } from 'mock/data';
import { topicsLP } from 'mock';

const LandingPageThree = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStartNow = () => {
    history.push(ROUTES.LEVELING);
  };

  return (
    <div>
      <LandingPageThreeForm
        arkcThree={arkcThree}
        benefits={arkcThree.benefits}
        worksBeginner={arkcThree.worksBeginner}
        graphicImage={arkcThree.graphicImage}
        topicsLP={topicsLP}
        supportQuestions={arkcThree.supportQuestions}
        lessons={arkcThree.lessons}
        startNow={handleStartNow}
      />
      <Footer />
    </div>
  );
};

export default LandingPageThree;