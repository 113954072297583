import { useEffect } from 'react';
import SupportForm from 'components/SupportForm';
import Footer from 'components/Footer';
import { supportQuestions } from '../../mock';

const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <SupportForm supportQuestions={supportQuestions} />
      <Footer />
    </div>
  );
};

export default Support;