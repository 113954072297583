import { Modal, Button, Input, Form } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import styles from '../../ProfileForm.module.less';

const ModalSimple = ({ closeSimple, updateMyProfile, setLoading, formSimple, isMobile, visibleSimple, loading }) => {
  const validateName = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp("[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$");

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(message.validate_name)
        };
      };

      return Promise.resolve()
    }
  });

  return (
    <Modal
      visible={visibleSimple}
      footer={null}
      onCancel={closeSimple}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={closeSimple}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center mt-32">
        {isMobile ? (
          <h2 className="text-center mb-40">Editar informações</h2>
        ) : (
          <h4 className="text-center mb-40">Editar informações</h4>
        )}
        <Form
          name="edit-name"
          layout="vertical"
          form={formSimple}
          onFinish={updateMyProfile}
          onFinishFailed={() => setLoading(false)}
          autoComplete="off"
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'O nome é obrigatório.' }, validateName]}
          >
            <Input placeholder="Digite seu nome" className="input-base" disabled={loading ? true : false} />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit" className={styles.btnSave} onClick={() => setLoading(true)} loading={loading}>
              Salvar alteração
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalSimple;