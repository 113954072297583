import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import CurrencyInput from 'react-currency-input-field';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../LessonChallengeForm.module.less';

const QuestionItemsImageChallenge = ({ changeQuestionValue, nextTrail, questionValue, questionsItem, title, image, index, name, logo }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayButton(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [displayButton]);

  return (
    <div key={index}>
      {isMobile || isTablet ? (
        <div className="container">
          <div className="content">
            <div className="flex flex-column justify-center item-center">
              {/* <div className={styles.bgQuestion}>
                <div className="flex flex-row item-center p-16">
                  <img src={logo} alt="logo-question" className="i-32" />
                  <span className="f18 b mine-shaft ml-16">{name}</span>
                </div>
              </div> */}
              <div className="mt-32">
                <img src={image} alt="" className={styles.imageSize} />
              </div>
              <h5 className={cx("text-center", styles.titleItems)}>{title}</h5>
              {_map(questionsItem, (item, index) => {
                if (item.type.toLowerCase() !== "options") {
                  return (
                    <div key={index} className={styles.inputContainer}>
                      <CurrencyInput
                        className="input-currency"
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL', style: 'currency' }}
                        placeholder="Digite o valor"
                        onValueChange={(value) => changeQuestionValue("number", value, item)}
                      />
                    </div>
                  );
                };

                if (item.type.toLowerCase() === "options") {
                  return (
                    <button
                      key={index}
                      className={cx("arkc-btn-link", styles.bagdeOptions, {
                        [styles.bagdeOptionsSelect]: questionValue === item.identifier,
                        "mb-16": index !== _size(questionsItem) - 1
                      })}
                      onClick={() => changeQuestionValue("options", item.identifier, item)}
                    >
                      {item.name}
                    </button>
                  );
                };
              })}
              {displayButton && (
                <>
                  <hr className={cx("divider mb-16", styles.dividerBottom)} />
                  <Button
                    type="primary"
                    className={cx("flex justify-center item-center mb-24", styles.btnContinue)}
                    disabled={!questionValue}
                    onClick={nextTrail}
                  >
                    Continuar
                    <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-column justify-center item-center">
          <div className="flex flex-row justify-center">
            <div className={styles.spaceImage}>
              <div className={styles.bgQuestion}>
                <div className="flex flex-row item-center p-16">
                  <img src={logo} alt="logo-question" className="i-32" />
                  <span className="f18 b mine-shaft ml-16">{name}</span>
                </div>
              </div>
              <h5 className={styles.titleItems}>{title}</h5>
              <div className="flex flex-column">
                {_map(questionsItem, (item, index) => {
                  if (item.type.toLowerCase() !== "options") {
                    return (
                      <div key={index} className={styles.inputContainer}>
                        <CurrencyInput
                          className="input-currency"
                          intlConfig={{ locale: 'pt-BR', currency: 'BRL', style: 'currency' }}
                          placeholder="Digite o valor"
                          onValueChange={(value) => changeQuestionValue("number", value, item)}
                        />
                      </div>
                    );
                  };

                  if (item.type.toLowerCase() === "options") {
                    return (
                      <button
                        key={index}
                        className={cx("arkc-btn-link", styles.bagdeOptions, {
                          [styles.bagdeOptionsSelect]: questionValue === item.identifier,
                          "mb-16": index !== _size(questionsItem) - 1
                        })}
                        onClick={() => changeQuestionValue("options", item.identifier, item)}
                      >
                        {item.name}
                      </button>
                    );
                  };
                })}
              </div>
            </div>
            <div>
              <img src={image} alt="" className={styles.imageSize} />
            </div>
          </div>
          {displayButton && (
            <>
              <hr className={cx("divider mb-16", styles.dividerBottom)} />
              <Button
                type="primary"
                className={cx("flex justify-center item-center mb-16", styles.btnContinue)}
                disabled={!questionValue}
                onClick={nextTrail}
              >
                Continuar
                <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionItemsImageChallenge;