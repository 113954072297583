import { useState } from 'react';
import { Button, Drawer, Layout, Tooltip } from 'antd';
import { FiX, FiMenu, FiGrid } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { dashboard, supportNew, dashboardArkc, profileUser, inviteNew } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from '../DashboardWalletForm.module.less';

const { Content } = Layout;

const MenuWallet = ({ children, plan, invite }) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div>
      {isMobile ? (
        <div>
          <div className="header-dash">
            <div className="flex justify-center item-center pt-20 pb-20">
              <button className="arkc-btn-link" onClick={() => setVisible(!visible)}>
                <FiMenu className="ml-24 i-24" />
              </button>
              <div className="flex justify-center item-center w-100">
                <img src={dashboard} alt="header-logo" />
              </div>
            </div>
          </div>
          <div>
            {children}
          </div>
          <Drawer
            placement="left"
            closable={false}
            onClose={() => setVisible(!visible)}
            visible={visible}
            key="left"
            width={322}
            className={styles.drawer}
            bodyStyle={{ padding: "24px" }}
          >
            <div className="mb-16">
              <button className="arkc-btn-link" onClick={() => setVisible(!visible)}>
                <FiX className="i-24" />
              </button>
            </div>
            <div className={cx("flex flex-column item-center mt-24", styles.badgeExit)}>
              <h5 className="mb-12">Investimentos</h5>
              <Button type="default" className={styles.btnExit} onClick={() => history.push(ROUTES.DASHBOARD)}>
                Sair do Dashboard
                <FiGrid className="ml-8" />
              </Button>
            </div>
            <div className={styles.topMenus}>
              <button className="arkc-btn-link flex item-center mb-20" onClick={() => history.push(ROUTES.DASHBOARD)}>
                <img src={dashboardArkc} alt="dashboard-arkc" className={styles.hoverImg} />
                <span className="f18 mine-shaft b ml-24">Lições</span>
              </button>
              <button className="arkc-btn-link flex item-center mb-20" onClick={() => history.push(ROUTES.PROFILE)}>
                <img src={profileUser} alt="profile-user" className={styles.hoverImg} />
                <span className="f18 mine-shaft b ml-24">Meu Perfil</span>
              </button>
              <button className="arkc-btn-link flex item-center mb-20" onClick={() => history.push(ROUTES.INVITE_FRIENDS, { invite: invite })}>
                <img src={inviteNew} alt="invite-friend" className={styles.hoverImg} />
                <span className="f18 mine-shaft b ml-24">Convidar amigo</span>
              </button>
              <button className="arkc-btn-link flex item-center" onClick={() => history.push(ROUTES.SUPPORT)}>
                <img src={supportNew} alt="support-new" className={styles.hoverImg} />
                <span className="f18 mine-shaft b ml-24">Suporte</span>
              </button>
            </div>
          </Drawer>
        </div>
      ) : (
        <Layout className="flex flex-row bg-mercury">
          <aside className={styles.menuAside}>
            <div className={styles.menuList}>
              <div className="p-18 h-100">
                <button  className="arkc-btn-link pointer" onClick={() => history.push(ROUTES.DASHBOARD)}>
                  <img src={require("assets/logo-investments.png")} alt="logo-investments" className={cx({ [styles.colorPremium]: plan === "arkc-premium", [styles.colorBlack]: plan === "arkc-black" })} />
                </button>
                <div className={styles.topMenus}>
                  <Tooltip title="Lições" color={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#8C52FF"} overlayInnerStyle={{ borderRadius: 12, padding: 8 }}>
                    <button className="arkc-btn-link flex item-center mb-20" onClick={() => history.push(ROUTES.DASHBOARD)}>
                      <img src={dashboardArkc} alt="dashboard-arkc" className={styles.hoverImg} />
                    </button>
                  </Tooltip>
                  <Tooltip title="Meu Perfil" color={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#8C52FF"} overlayInnerStyle={{ borderRadius: 12, padding: 8 }}>
                    <button className="arkc-btn-link flex item-center mb-20" onClick={() => history.push(ROUTES.PROFILE)}>
                      <img src={profileUser} alt="profile-user" className={styles.hoverImg} />
                    </button>
                  </Tooltip>
                  <Tooltip title="Convidar" color={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#8C52FF"} overlayInnerStyle={{ borderRadius: 12, padding: 8 }}>
                    <button className="arkc-btn-link flex item-center mb-20" onClick={() => history.push(ROUTES.INVITE_FRIENDS, { invite: invite })}>
                      <img src={inviteNew} alt="invite-friend" className={styles.hoverImg} />
                    </button>
                  </Tooltip>
                  <Tooltip title="Suporte" color={plan === "arkc-black" ? "#2F2F2F" : plan === "arkc-premium" ? "#FFAB57" : "#8C52FF"} overlayInnerStyle={{ borderRadius: 12, padding: 8 }}>
                    <button className="arkc-btn-link flex item-center" onClick={() => history.push(ROUTES.SUPPORT)}>
                      <img src={supportNew} alt="support-new" className={styles.hoverImg} />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </aside>
          <Layout>
            <div className={styles.header}>
              <h5>Investimentos</h5>
              <Button type="default" className={cx(styles.btnExit, { [styles.btnBlack]: plan === "arkc-black", [styles.btnPremium]: plan === "arkc-premium" })} onClick={() => history.push(ROUTES.DASHBOARD)}>
                {isMobile ? `Sair` : `Sair do Dashboard`}
                <FiGrid className="ml-8" />
              </Button>
            </div>
            <Content className={cx("p-16", { "bg-mercury": plan === "arkc-beginner", "bg-magnolia": plan === "arkc-black", "bg-travertine": plan === "arkc-premium" })}>
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default MenuWallet;