import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins";
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import {
  arckBlack,
  supportArkc,
  freeSeven,
  freeSevenMobile,
  userSales,
  salesPhoneBlack,
  logoWhite,
  exclusiveContent,
  marketNotifications,
  learningTrails,
  portfolioInvestments,
  whatsApp,
  email,
  checkBlack
} from 'assets';
import styles from './LandingPageBlackForm.module.less';
import AccordionArkc from '../../AccordionArkc';

const LandingPageBlackForm = ({ buyPlan, price, graphicImage, informationsTwo, informationsOne, supportQuestions, planBlack, peoples, benefits }) => {
  const [plugins, setPlugins] = useState([]);
  const [pluginsThree, setPluginsThree] = useState([]);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 });

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
    setPluginsThree([new Pagination({ type: 'bullet' }), new AutoPlay({ duration: 2500, direction: "NEXT", stopOnHover: false })]);
  }, []);

  return (
    <div>
      <div className={cx("container z-index", styles.headerBlack)}>
        <div className="content-sales mt-24 mb-24">
          <div className="flex flex-row justify-center item-center">
            <img src={logoWhite} alt="logo-white" className="pointer" />
          </div>
        </div>
      </div>
      <div className="flex flex-column justify-center item-center mt-82">
        <div className="container">
          <div className="content-sales">
            <div className="flex flex-column justify-center item-center">
              <div className={styles.badgeBlack}>
                <div className="flex flex-row item-center p-12">
                  <img src={arckBlack} alt="arck-black" />
                  <span className="f18 white b ml-8">Arkc Black</span>
                </div>
              </div>
              <h2 className={cx("text-center white mt-24 mb-24", styles.titleBlack)}>
                Aprenda a investir de maneira simples e segura enquanto se diverte com o Arkc Black
              </h2>
              <p className={cx("f18 text-center mb-40", styles.descriptionBlack)}>
                Descubra como fazer seu dinheiro <span className="b">render até 5x mais que a poupança</span>, de  <span className="b">forma segura e 100% gamificada</span>, com o método ARKC.
              </p>
              <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Assinar agora</Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="content-sales">
            <div className={cx("flex mt-48", { "flex-column justify-center item-center mt-32": isMobile, "flex-row justify-between": !isMobile })}>
              {isMobile && (
                <span className="f32 white b mb-24">Segurança garantida</span>
              )}
              <div>
                <img src={!isMobile ? freeSeven : freeSevenMobile} className={styles.freeSevenImg} />
              </div>
              <div className="flex flex-column justify-center">
                {!isMobile && (
                  <span className="f32 white b">Segurança garantida</span>
                )}
                <p className={cx("f16 mt-32 mb-32", styles.freeTitle)}>
                  Você tem 7 dias para testar se o <span className="b">Arkc Black</span> é para você.
                  Se, durante esse período, decidir que não é para você, basta solicitar o cancelamento pelo próprio aplicativo e nós devolvemos 100% do seu dinheiro.
                </p>
                <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Quero aprender a investir</Button>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className={styles.bgProducts}>
            <h1 className={styles.titleProducts}>Com a metodologia <span className="melrose">Arkc Black</span> você vai aprender a fazer os melhores investimentos com lições simples e práticas.</h1>
            <p className={styles.descriptionProducts}>Nossos conteúdos exclusivos e o <span className="b">acesso privilegiado à nossa carteira de investimentos avançados</span> irão capacitar você a alcançar seus objetivos financeiros com maior segurança e sucesso</p>
            <div className={cx("flex item-center", { "flex-column mt-24 p-24": isMobile || isTablet, "flex-row justify-between": !isMobile && !isTablet })}>
              <div>
                {_map(informationsOne, (item, index) => (
                  <div key={index} className={cx({ "mb-40": index !== _size(informationsOne) - 1 })}>
                    <div className={styles.badgeIcons} style={{ background: `${item.color}` }}>
                      <img src={item.icon} alt="" />
                    </div>
                    <p className="f18 b mt-16 mb-8 melrose">{item.title}</p>
                    <p className={styles.descriptionProduct}>{item.description}</p>
                  </div>
                ))}
              </div>
              <div>
                <img src={salesPhoneBlack} />
              </div>
              <div className={cx({ "mt-24": isTablet })}>
                {_map(informationsTwo, (item, index) => (
                  <div key={index} className={cx({ "mb-40": index !== _size(informationsOne) - 1 })}>
                    <div className={styles.badgeIcons} style={{ background: `${item.color}` }}>
                      <img src={item.icon} alt="" />
                    </div>
                    <p className="f18 b mt-16 mb-8 melrose">{item.title}</p>
                    <p className={styles.descriptionProduct}>{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <section>
            <div className={cx({ "mt-32 mb-32": isMobile, "mt-80 mb-80": !isMobile })}>
              <div className="container">
                <div className="content-sales">
                  <h1 className={styles.titleProducts}>Com a metodologia <span className="melrose">Arkc Black</span> você vai aprender a fazer os melhores investimentos com lições simples e práticas.</h1>
                  <p className={styles.descriptionProducts}>Nossos conteúdos exclusivos e o <span className="b">acesso privilegiado à nossa carteira de investimentos avançados</span> irão capacitar você a alcançar seus objetivos financeiros com maior segurança e sucesso</p>
                  <div className="flicking-view-plans">
                    <Flicking circular={true} plugins={plugins} align="prev">
                      {_map([...informationsOne, ...informationsTwo], (item, index) => (
                        <div key={index} className={cx(styles.cardBlack, "mr-24")}>
                          <div className="p-24">
                            <div className="mb-12">
                              <div className={styles.bgIcon}>
                                <div className="flex justify-center item-center h-100">
                                  <img src={item.icon} alt="type-investiments" />
                                </div>
                              </div>
                            </div>
                            <span className="f18 b white">{item.title}</span>
                            <p className={cx("f16 white mt-6", styles.cardDescription)}>{item.description}</p>
                          </div>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination"></div>
                      </ViewportSlot>
                    </Flicking>
                  </div>
                  <div className="flex justify-center item-center">
                    <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Quero fazer o meu dinheiro render</Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className="w-100">
          <div className={styles.bgMine}>
            <div className={cx("flex mt-40 mb-40", { "flex-column": isMobile || isTablet, "flex-row justify-center item-center p-24": !isMobile && !isTablet })}>
              <div className={cx({ "flex flex-column justify-center item-center mt-40": isMobile || isTablet })}>
                {isMobile ? (
                  <>
                    <h6 className="fw4 melrose mb-24">Rentabilidade nos últimos 5 anos</h6>
                    <h2 className="mb-16 text-center white">Rentabilidade histórica Arkc Black</h2>
                  </>
                ) : (
                  <>
                    <h5 className="melrose mb-24">Rentabilidade nos últimos 5 anos</h5>
                    <h3 className="mb-48 white">Rentabilidade histórica Arkc Black</h3>
                  </>
                )}
                <div className={cx("mb-16", styles.badgeProfitability)}>
                  <div className="flex justify-center item-center h-100">
                    <span className="f16 b mine-shaft">Arkc Black + 119,91%</span>
                  </div>
                </div>
                <div className={styles.badgeSavings}>
                  <div className="flex justify-center item-center h-100">
                    <span className="f16 b mine-shaft">Poupança + 19,31%</span>
                  </div>
                </div>
              </div>
              <div className={cx({ "flex justify-center item-center": isMobile })}>
                <div className={cx(styles.bgGraphic, { "mt-40 mb-40": isMobile || isTablet || isDesktop, "ml-66": !isMobile && !isTablet && !isDesktop })}>
                  <div className={styles.paddingGraphic}>
                    {isMobile ? (
                      <div className="flex flex-row justify-between item-center mb-40">
                        <p className="f16 white">Investimento Poupança + 19,31%</p>
                        <h6 className="f16 melrose">Investimentos Arkc Black + 119,91%</h6>
                      </div>
                    ) : (
                      <>
                        <h5 className="melrose">Investimentos Arkc Black + 119,91%</h5>
                        <p className="f18 b white mt-12">Investimento Poupança + 19,31%</p>
                      </>
                    )}
                    <div className={cx({ "flex justify-center item-center": isTablet, "mt-32": !isMobile && !isTablet })}>
                      <img
                        src={graphicImage}
                        alt="graphic-black"
                        className={styles.graphicImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="content-sales">
              <h1 className={styles.titleMethod}>O que você vai aprender com a metodologia Arkc Black:</h1>
              <div className={cx({ "mt-40 mb-40": isMobile, "mt-80 mb-80": !isMobile })}>
                {isMobile ? (
                  <div className="flicking-view-plans">
                    <Flicking circular={true} plugins={plugins} align="prev">
                      {_map(benefits, (item, index) => (
                        <div key={index} className={cx(styles.cardBlack, "mr-24")}>
                          <div className="p-24">
                            <div className="mb-12">
                              <div className={styles.bgIcon}>
                                <div className="flex justify-center item-center h-100">
                                  <img src={item.path} alt="type-investiments" />
                                </div>
                              </div>
                            </div>
                            <span className="f18 b white">{item.title}</span>
                            <p className={cx("f16 white mt-6", styles.cardDescription)}>{item.description}</p>
                          </div>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination"></div>
                      </ViewportSlot>
                    </Flicking>
                  </div>
                ) : (
                  <div className={cx({ [styles.horizontalScroll]: isMobile, [styles.gridBenefits]: !isMobile })}>
                    {_map(benefits, (item, index) => (
                      <div key={index} className={cx(styles.cardBlack, { "mr-24": isMobile && index !== _size(benefits) - 1 })}>
                        <div className="p-24">
                          <div className="mb-12">
                            <div className={styles.bgIcon}>
                              <div className="flex justify-center item-center h-100">
                                <img src={item.path} alt="type-investiments" />
                              </div>
                            </div>
                          </div>
                          <span className="f18 b white">{item.title}</span>
                          <p className={cx("f16 white mt-6", styles.cardDescription)}>{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-center item-center">
                <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Quero fazer o meu dinheiro render</Button>
              </div>
            </div>
          </div>
        </section>
        {!isMobile ? (
          <div className="container">
            <div className="content-sales">
              <section className="flex flex-column justify-center item-center">
                <h1 className={styles.howWorks}>Como funciona?</h1>
                <p className={styles.howWorksDescription}>Ao assinar o plano Arkc Black você ganha acesso a área da membros exclusiva dos assinantes</p>
                <div className={cx("mb-32", styles.badgeMember)}>
                  <div className={cx("flex h-100", { "flex-column justify-center item-center": isMobile, "p-12": isTablet, "flex-row justify-center item-center ": !isMobile })}>
                    <div className="mr-32">
                      <span className={styles.badgeMemberTitle}>Conteúdo exclusivo</span>
                      <p className={styles.badgeMemberDescription}>Aprenda por meio de lições interativas e divertidas sobre os investimentos mais diversificados e rentáveis do mercado.</p>
                    </div>
                    <div>
                      <img src={exclusiveContent} alt="" className={styles.imgMember} />
                    </div>
                  </div>
                </div>
                <div className={cx("mb-32", styles.badgeMember)}>
                  <div className={cx("flex h-100", { "flex-column justify-center item-center": isMobile, "p-12": isTablet, "flex-row justify-center item-center ": !isMobile })}>
                    <div className="mr-32">
                      <img src={portfolioInvestments} alt="" className={styles.imgMember} />
                    </div>
                    <div>
                      <span className={styles.badgeMemberTitle}>Carteira de Investimentos</span>
                      <p className={styles.badgeMemberDescription}>Ganhe acesso a carteira de investimentos que rende até 5x mais que a poupança.</p>
                    </div>
                  </div>
                </div>
                <div className={cx("mb-32", styles.badgeMember)}>
                  <div className={cx("flex h-100", { "flex-column justify-center item-center": isMobile, "p-12": isTablet, "flex-row justify-center item-center ": !isMobile })}>
                    <div className="mr-32">
                      <span className={styles.badgeMemberTitle}>Trilhas de Aprendizado</span>
                      <p className={styles.badgeMemberDescription}>Desbloqueie todas as trilhas de aprendizado para acelerar sua tranquilidade financeira e complementar sua renda.</p>
                    </div>
                    <div>
                      <img src={learningTrails} alt="" className={styles.imgMember} />
                    </div>
                  </div>
                </div>
                <div className={styles.badgeMember}>
                  <div className={cx("flex h-100", { "flex-column justify-center item-center": isMobile, "p-12": isTablet, "flex-row justify-center item-center ": !isMobile })}>
                    <div className="mr-32">
                      <img src={marketNotifications} alt="" className={styles.imgMember} />
                    </div>
                    <div>
                      <span className={styles.badgeMemberTitle}>Notificações sobre o mercado</span>
                      <p className={styles.badgeMemberDescription}>Receba notícias que podem impactar seus investimentos e escolha o melhor caminho para proteger seu dinheiro.</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        ) : (
          <section className={styles.bgMine}>
            <div className="p-24">
              <div className="flex flex-column justify-center item-center">
                <div className={styles.badgeBlack}>
                  <div className="flex flex-row item-center p-12">
                    <img src={arckBlack} alt="arck-black" />
                    <span className="f18 white b ml-8">Arkc Black</span>
                  </div>
                </div>
                <h1 className={styles.howWorks}>Como funciona?</h1>
                <p className={styles.howWorksDescription}>Ao assinar o plano Arkc Black você ganha acesso a área da membros exclusiva dos assinantes</p>
              </div>
              <div className="flex flex-column mt-28">
                <img src={exclusiveContent} alt="" className={styles.imgHowMobile} />
                <span className={styles.badgeMemberTitle}>Conteúdo exclusivo</span>
                <p className={styles.badgeMemberDescription}>Aprenda por meio de lições interativas e divertidas sobre os investimentos mais diversificados e rentáveis do mercado.</p>
              </div>
              <div className="flex flex-column mt-28">
                <img src={portfolioInvestments} alt="" className={styles.imgHowMobile} />
                <span className={styles.badgeMemberTitle}>Carteira de Investimentos</span>
                <p className={styles.badgeMemberDescription}>Ganhe acesso a carteira de investimentos que rende até 5x mais que a poupança.</p>
              </div>
              <div className="flex flex-column mt-28">
                <img src={learningTrails} alt="" className={styles.imgHowMobile} />
                <span className={styles.badgeMemberTitle}>Trilhas de Aprendizado</span>
                <p className={styles.badgeMemberDescription}>Desbloqueie todas as trilhas de aprendizado para acelerar sua tranquilidade financeira e complementar sua renda.</p>
              </div>
              <div className="flex flex-column mt-28">
                <img src={marketNotifications} alt="" className={styles.imgHowMobile} />
                <span className={styles.badgeMemberTitle}>Notificações sobre o mercado</span>
                <p className={styles.badgeMemberDescription}>Receba notícias que podem impactar seus investimentos e escolha o melhor caminho para proteger seu dinheiro.</p>
              </div>
            </div>
          </section>
        )}
        <section className={cx("w-100", styles.spaceBloc)}>
          <div className={cx("flex justify-center item-center", { "flex-column": isMobile })}>
            <h1 className={styles.titleInvestors}>
              Investidores iniciantes e experientes estão investindo com mais segurança
            </h1>
            {isMobile && (
              <p className={styles.descriptionInvestors}>Veja como a metodologia ARKC Black ajudou diversos investidores a garantirem rentabilidade 5x maior que a poupança</p>
            )}
          </div>
          {!isMobile ? (
            <div className="flex flex-row item-center">
              <div className={styles.bgUsers}>
                <p className={styles.textPoup}>
                  Veja como a metodologia ARKC Black ajudou diversos investidores a garantirem rentabilidade 5x maior que a poupança
                  {(!isMobile && !isTablet) && (
                    <>
                      {" "}
                      <FiArrowRight className="f14" />
                    </>
                  )}
                </p>
              </div>
              {isTablet ? (
                <div className={styles.newPostion}>
                  <div className="container">
                    <div className="content-sales">
                      <div className={styles.widthFlicking}>
                        <div className="flicking-view-peoples">
                          <Flicking circular={true} plugins={pluginsThree} align="prev">
                            {_map(peoples, (item, index) => (
                              <div key={index} className={styles.badgePeoples}>
                                <img src={userSales} alt="" />
                                <p className={styles.peopleDep}>{item.testimony}</p>
                                <p className="f18 b melrose">{item.name}</p>
                              </div>
                            ))}
                            <ViewportSlot>
                              <div className="flicking-pagination"></div>
                            </ViewportSlot>
                          </Flicking>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.relativePeoples}>
                  <div className={styles.absolutePeoples}>
                    <div className="flicking-view-peoples">
                      <Flicking circular={true} plugins={pluginsThree} align="prev">
                        {_map(peoples, (item, index) => (
                          <div key={index} className={styles.badgePeoples}>
                            <img src={userSales} alt="" />
                            <p className={styles.peopleDep}>{item.testimony}</p>
                            <p className="f18 b melrose">{item.name}</p>
                          </div>
                        ))}
                        <ViewportSlot>
                          <div className="flicking-pagination"></div>
                        </ViewportSlot>
                      </Flicking>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="container">
              <div className="content-sales">
                <div className="flicking-view-peoples">
                  <Flicking circular={true} plugins={pluginsThree} align="prev">
                    {_map(peoples, (item, index) => (
                      <div key={index} className={styles.badgePeoples}>
                        <img src={userSales} alt="" />
                        <p className={styles.peopleDep}>{item.testimony}</p>
                        <p className="f18 b melrose">{item.name}</p>
                      </div>
                    ))}
                    <ViewportSlot>
                      <div className="flicking-pagination"></div>
                    </ViewportSlot>
                  </Flicking>
                </div>
              </div>
            </div>
          )}
        </section>
        <section className="flex flex-column justify-center item-center">
          <div className="container">
            <div className="content-sales">
              <div className="flex flex-column justify-center item-center">
                <h1 className={styles.staticIBGE}>
                  Segundo o IBGE, 67% dos brasileiros não conseguem poupar dinheiro e mais de 70% estão endividados.
                </h1>
                <p className={styles.staticIBGEDescription}>
                  Enquanto isso, os usuários do Arkc Black aproveitam os benefícios dos investimentos com alta diversificação e rentabilidade
                </p>
                <div className={styles.gridType}>
                  <div className={styles.badgeType}>
                    <div className="flex flex-row item-center">
                      <img src={checkBlack} alt="" />
                      <p className={styles.badgeTypeDescription}>As criptomoedas tiveram crescimento de 300% em 2022</p>
                    </div>
                  </div>
                  <div className={styles.badgeType}>
                    <div className="flex flex-row item-center">
                      <img src={checkBlack} alt="" />
                      <p className={styles.badgeTypeDescription}>As ações das empresas de tecnologia, como Amazon valorizaram mais de 2.000%</p>
                    </div>
                  </div>
                  <div className={styles.badgeType}>
                    <div className="flex flex-row item-center">
                      <img src={checkBlack} alt="" />
                      <p className={styles.badgeTypeDescription}>O Mercado de ETFs cresceu 210% nos últimos 5 anos</p>
                    </div>
                  </div>
                  <div className={styles.badgeType}>
                    <div className="flex flex-row item-center">
                      <img src={checkBlack} alt="" />
                      <p className={styles.badgeTypeDescription}>REITs (Fundos Imobiliários Americanos), como o Prologis, cresceram mais de 150% nos últimos 5 anos</p>
                    </div>
                  </div>
                </div>
                <p className={styles.descriptionTypes}>
                  Com o Arkc Black você terá acesso a todas as ferramentas para aprender a identificar investimentos com alto potencial de crescimento, aumentar seus ganhos e garantir sua tranquilidade financeira.
                </p>
                <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Assinar o Arkc Black</Button>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.bgYou}>
          <div className="container">
            <div className="content-sales">
              <div className="flex flex-column justify-center item-center">
                <h1 className={styles.titleYou}>Agora, você tem dois caminhos</h1>
                <p className={styles.descriptionYouOne}>
                  Você pode viver igual a 70% dos brasileiros: sem investir seu dinheiro, endividado, sem ter tranquilidade financeira e sem conseguir dar segurança para si mesmo e sua família.
                </p>
                <p className={styles.descriptionYouTwo}>
                  Ou, você pode assinar o Arkc Black e aprender a colocar seu dinheiro nos investimentos mais rentáveis e diversificados do mercado sem risco algum durante os 7 dias de garantia.
                </p>
                <h1 className={styles.titleYouTwo}>O risco é nosso.</h1>
                <p className={styles.descriptionYouThree}>Você testa nossa metodologia e se achar que aprender sobre investimentos de forma simples e gamificada não é pra você, basta cancelar pelo próprio aplicativo.</p>
                <div className="mb-32">
                  <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Quero ter tranquilidade financeira</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={cx("flex flex-column justify-center item-center", { "mb-32": isMobile || isTablet, "mb-80": !isMobile && !isTablet })}>
            <span className="f18 melrose mb-8">Valor Mensal</span>
            {isMobile ? (
              <h1 className={cx("text-center white mb-24", styles.titlePlanBlack)}>Veja tudo o que você terá de acesso no Arkc Black</h1>
            ) : (
              <h2 className={cx("f40 text-center white mb-32", styles.titlePlanBlack)}>Veja tudo o que você terá de acesso no Arkc Black</h2>
            )}
            {_map(planBlack, (item, index) => (
              <div key={index}>
                <div className="flex flex-row justify-between item-center">
                  <span className="f14 white">{item.title}</span>
                  <span className="silver">{item.description}</span>
                </div>
                <hr className={cx("divider mt-8 mb-8", styles.dividerBlack)} />
              </div>
            ))}
            <div className={cx("flex flex-column justify-center item-center mt-40", styles.price)}>
              <span className={cx("f18 white", { "mb-8": isMobile, "mb-2": !isMobile })}>Garanta tudo isso por apenas</span>
              <div className="flex flex-row justify-center mb-24">
                <span className={cx("f14 white mr-12", { "b": isMobile || isTablet, "mt-12": !isMobile && !isTablet })}>R$</span>
                <h2 className={cx("melrose", { "f56": isMobile || isTablet })}>{price.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 })}</h2>
                <span className={cx("f14 white ml-8", { "mt-20": isMobile, "mt-36": !isMobile })}>por mês</span>
              </div>
              <span className={cx("f18 white", { "mb-8 text-center": isMobile, "mb-8": !isMobile })}>Sua tranquilidade financeira por menos que uma pizza no mês.</span>
              <Button type="primary" className={styles.btnSize} onClick={buyPlan}>Quero assinar</Button>
            </div>
          </div>
        </section>
        <section className={styles.containerFreeBG}>
          <div className="container">
            <div className="content-sales">
              <div className={styles.containerFreeTwo}>
                {isMobile && (
                  <span className="f28 white b mb-24">O risco é nosso</span>
                )}
                <div className={styles.spaceFreeTwo}>
                  <img src={!isMobile ? freeSeven : freeSevenMobile} className={styles.freeSevenImg} />
                </div>
                <div className="flex flex-column justify-center">
                  {!isMobile && (
                    <span className="f32 white b">O risco é nosso</span>
                  )}
                  {!isMobile ? (
                    <p className={cx("f16 mt-32 mb-32", styles.freeTitle)}>
                      Você tem 7 dias para testar se o <span className="b">Arkc Black</span> é para você.
                      Se, durante esse período, decidir que não é para você, basta solicitar o cancelamento pelo próprio aplicativo e nós devolvemos 100% do seu dinheiro.
                    </p>
                  ) : (
                    <>
                      <p className="f16 text-center b gray-two mt-12">Você tem 7 dias para testar o Arkc Black.</p>
                      <p className="f16 text-center gray-two mt-18 mb-18">Se, durante esse período, decidir que não é para você, basta solicitar o cancelamento pelo próprio aplicativo e nós devolvemos 100% do seu dinheiro.</p>
                      <p className="f16 text-center gray-two mb-24">Não precisa enviar e-mail ou entrar em contato conosco, o cancelamento pode ser realizado pelo próprio aplicativo da Arkc, sem burocracia.</p>
                    </>
                  )}
                  <Button type="primary" className={styles.btnSign} onClick={buyPlan}>Quero aprender a investir</Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="content-sales">
              {!isMobile ? (
                <div className={cx("flex flex-column justify-center item-center", styles.spaceBloc)}>
                  <img src={supportArkc} alt="support-arkc" />
                  <h1 className={styles.titleQuestions}>Perguntas frequentes</h1>
                  <div className={styles.contentAccordions}>
                    {_map(supportQuestions, (item, index) => (
                      <div className={cx({ "mb-16": index !== _size(supportQuestions) - 1 })}>
                        <AccordionArkc key={index} title={item.title} isDark={true}>
                          <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.description}</p>
                          {item.descriptionSub && (
                            <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.descriptionSub}</p>
                          )}
                        </AccordionArkc>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center item-center mt-40">
                    <Button type="primary" className={styles.btnFree} onClick={buyPlan}>Experimentar agora</Button>
                  </div>
                </div>
              ) : (
                <div className="mb-32">
                  <h1 className="f28 white mb-28 text-center">Alguma dúvida?</h1>
                  <p className={styles.descriptionSupport}>
                    Fale com nossa equipe pelo <span className="melrose underline">WhatsApp</span> ou nos envie um <span className="melrose underline">e-mail</span>
                  </p>
                  <div className="flex flex-row justify-between item-center">
                    <a href="https://wa.me//5511919193686?text=Gostaria%20de%20tirar%20minha%20duvida%20sobre%20o%20Arkc" target="_blank">
                      <div className={styles.bgSupport}>
                        <img src={whatsApp} alt="whatsApp" />
                      </div>
                    </a>
                    <a href="mailto:suporte@arkc.com.br?subject=Preciso de ajuda" target="_blank">
                      <div className={styles.bgSupport}>
                        <img src={email} alt="email" />
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LandingPageBlackForm;