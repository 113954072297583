import { Modal, Button } from 'antd';
import { FiX } from 'react-icons/fi';
import cx from 'classnames';
import { arckBlack, arckPremium, arckBeginner } from 'assets';
import styles from '../SignaturesForm.module.less';

const CancelPlan = ({ cancelSubscription, setVisible, isMobile, plan, visible, id }) => {
  const renderTypePlan = () => {
    switch (plan) {
      case "arkc-beginner":
        return (
          <div className={styles.badgeBeginner}>
            <div className="flex flex-row item-center p-12">
              <img src={arckBeginner} alt="arck-beginner" />
              <span className="f18 mine-shaft b ml-8">Arkc Beginner</span>
            </div>
          </div>
        );
      case "arkc-premium":
        return (
          <div className={styles.badgePremium}>
            <div className="flex flex-row item-center p-12">
              <img src={arckPremium} alt="arck-premium" />
              <span className="f18 mine-shaft b ml-8">Arkc Premium</span>
            </div>
          </div>
        );
      case "arkc-black":
        return (
          <div className={styles.badgeBlack}>
            <div className="flex flex-row item-center p-12">
              <img src={arckBlack} alt="arck-black" />
              <span className="f18 white b ml-8">Arkc Black</span>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => setVisible(!visible)}
      bodyStyle={{ height: isMobile ? "100%" : "408px", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={() => setVisible(!visible)}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center h-100">
        {renderTypePlan()}
        {isMobile ? (
          <h3 className={cx("mt-32 mb-24", styles.titleModal)}>Tem certeza que gostaria de cancelar sua assinatura?</h3>
        ) : (
          <h4 className={cx("mt-32 mb-56", styles.titleModal)}>Tem certeza que gostaria de cancelar sua assinatura?</h4>
        )}
        <div className="flex flex-row item-center">
          <Button type="default" className={styles.btnNo} onClick={() => setVisible(!visible)}>Não</Button>
          <Button type="default" className={styles.btnYes} onClick={() => cancelSubscription(id)}>Sim</Button>
        </div>
      </div>
    </Modal >
  );
};

export default CancelPlan;