import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import NewPasswordForm from 'components/NewPasswordForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { message } from 'helpers/messages';

const NewPassword = () => {
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(true);
  const param = useParams();
  const { login } = useStore();
  const { newPasswordAccount } = login;

  const handleCreateNewPassword = async (props) => {
    const response = await newPasswordAccount(param?.token, { password: props, resetToken: param?.token })

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setLoading(false);
      history.push(ROUTES.HOME);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setValidToken(false);
      setLoading(false);
    };
  };

  return (
    <div>
      <NewPasswordForm
        loading={loading}
        token={validToken}
        setLoading={setLoading}
        passwordNew={handleCreateNewPassword}
      />
      <Footer />
    </div>
  );
};

export default observer(NewPassword);