import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForms from 'components/PaymentForms';
import Footer from 'components/Footer';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = (props) => {
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [name, setName] = useState("");
  const [messages, setMessages] = useState("");
  const [inviteId, setInviteId] = useState();

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.clientSecret && state.lookupKey && state.invite) {
      setIdentifier(state.lookupKey);
      setInviteId(state.invite);
      setClientSecret(state.clientSecret);
    };
  }, []);

  const handleReturnMessage = (message) => {
    setMessages(message);
  };

  const appearance = {
    theme: 'flat',
    variables: {
      fontFamily: ' "TT Commons", sans-serif',
      borderRadius: '12px',
      colorBackground: '#F6F8FA',
      colorPrimaryText: '#262626'
    },
    rules: {
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px'
      },
      '.Input': {
        padding: '11.5px 16px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #8C52FF',
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray'
      },
      '.p-PaymentMethodSelector': {
        display: "none"
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none'
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Label': {
        fontWeight: '500'
      }
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForms
            loading={loading}
            clientSecret={clientSecret}
            name={name}
            messages={messages}
            identifier={identifier}
            invite={inviteId}
            setLoading={setLoading}
            setName={setName}
            returnMessage={handleReturnMessage}
          />
          <Footer />
        </Elements>
      )}
    </div>
  );
};

export default observer(Payment);