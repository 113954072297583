// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormLogin_topTitle__OT7kA {\n  margin-top: 50px;\n  margin-bottom: 20px;\n  text-align: center;\n}\n.FormLogin_btnLogin__vD40E {\n  width: 380px;\n}\n.FormLogin_btnLogin__vD40E:hover {\n  background: transparent;\n  color: #8C52FF;\n}\n.FormLogin_btnLogin__vD40E:active {\n  background: transparent;\n  color: #8C52FF;\n}\n.FormLogin_btnFake__tXAOS {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 57px;\n  width: 188px;\n  background: #ffffff;\n  border: 2px solid #2F2F2F;\n  box-sizing: border-box;\n  border-radius: 16px;\n}\n@media only screen and (max-width: 767px) {\n  .FormLogin_btnLogin__vD40E {\n    width: 312px;\n  }\n  .FormLogin_btnFake__tXAOS {\n    width: 148px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/FormLogin/FormLogin.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAEA;EACE,YAAA;AAAF;AAEE;EACE,uBAAA;EACA,cAAA;AAAJ;AAGE;EACE,uBAAA;EACA,cAAA;AADJ;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;AAHF;AAMA;EACE;IACE,YAAA;EAJF;EAOA;IACE,YAAA;EALF;AACF","sourcesContent":[".topTitle {\n  margin-top: 50px;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.btnLogin {\n  width: 380px;\n\n  &:hover {\n    background: transparent;\n    color: #8C52FF;\n  }\n\n  &:active {\n    background: transparent;\n    color: #8C52FF;\n  }\n}\n\n.btnFake {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 57px;\n  width: 188px;\n  background: #ffffff;\n  border: 2px solid #2F2F2F;\n  box-sizing: border-box;\n  border-radius: 16px;\n}\n\n@media only screen and (max-width: 767px) {\n  .btnLogin {\n    width: 312px;\n  }\n\n  .btnFake {\n    width: 148px;\n  }\n}\n@primary-color: #8C52FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topTitle": "FormLogin_topTitle__OT7kA",
	"btnLogin": "FormLogin_btnLogin__vD40E",
	"btnFake": "FormLogin_btnFake__tXAOS"
};
export default ___CSS_LOADER_EXPORT___;
