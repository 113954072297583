import { FiArrowLeft } from 'react-icons/fi';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes'
import { logoColor } from 'assets';

const Header = ({ route }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className="container">
      <div className="content">
        <div className="flex flex-row">
          <button className="arkc-btn-link flex flex-row justify-center item-center" onClick={() => history.push(route)}>
            <FiArrowLeft className="i-24 heliotrope" />
            {!isMobile && (
              <span className="f16 b heliotrope ml-14">Voltar</span>
            )}
          </button>
          <div className="flex justify-center item-center w-100">
            <button className="arkc-btn-link" onClick={() => history.push(ROUTES.DASHBOARD)}>
              <img src={logoColor} alt="logo-color" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;