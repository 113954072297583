// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserMessage_topTitle__\\+O2QX {\n  margin-top: 128px;\n  margin-bottom: 138px;\n  text-align: center;\n}\n.UserMessage_titleSize__WU6wz {\n  width: 218px;\n}\n.UserMessage_description__x2rQ3 {\n  width: 311px;\n  line-height: 19px;\n  color: #909090;\n}\n.UserMessage_btnUnderstood__Ns9lK {\n  width: 166px;\n}\n@media only screen and (max-width: 767px) {\n  .UserMessage_topTitle__\\+O2QX {\n    margin-top: 60px;\n    margin-bottom: 60px;\n  }\n  .UserMessage_description__x2rQ3 {\n    width: 251px;\n  }\n  .UserMessage_btnUnderstood__Ns9lK {\n    width: 312px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/UserMessage/UserMessage.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;EACA,kBAAA;AACF;AAEA;EACE,YAAA;AAAF;AAGA;EACE,YAAA;EACA,iBAAA;EACA,cAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE;IACE,gBAAA;IACA,mBAAA;EAHF;EAMA;IACE,YAAA;EAJF;EAOA;IACE,YAAA;EALF;AACF","sourcesContent":[".topTitle {\n  margin-top: 128px;\n  margin-bottom: 138px;\n  text-align: center;\n}\n\n.titleSize {\n  width: 218px;\n}\n\n.description {\n  width: 311px;\n  line-height: 19px;\n  color: #909090;\n}\n\n.btnUnderstood {\n  width: 166px;\n}\n\n@media only screen and (max-width: 767px) {\n  .topTitle {\n    margin-top: 60px;\n    margin-bottom: 60px;\n  }\n\n  .description {\n    width: 251px;\n  }\n\n  .btnUnderstood {\n    width: 312px;\n  }\n}\n@primary-color: #8C52FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topTitle": "UserMessage_topTitle__+O2QX",
	"titleSize": "UserMessage_titleSize__WU6wz",
	"description": "UserMessage_description__x2rQ3",
	"btnUnderstood": "UserMessage_btnUnderstood__Ns9lK"
};
export default ___CSS_LOADER_EXPORT___;
