import { useState } from 'react';
import { Progress, Dropdown, Menu } from 'antd';
import { FiX, FiMoreVertical } from 'react-icons/fi';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import BuyQuestionSimple from './components/BuyQuestionSimple';
import BuyQuestionImage from './components/BuyQuestionImage';
import BuyQuestionImageItems from './components/BuyQuestionImageItems';
import BuyQuestionItems from './components/BuyQuestionItems';
import BuyCompletedModal from './components/BuyCompletedModal';
import styles from './HowBuyForm.module.less';

const HowBuyForm = ({ backDetails, viewPlan, setChoiceSelected, choiceSelected, tutorial }) => {
  const [tutorialTrail, setTutorialTrail] = useState(tutorial);
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  const renderTrailQuestions = (item, index) => {
    if (tutorialTrail.actualQuestion === item.identifier && item.description && !item.image && !item.choices) {
      return (
        <BuyQuestionSimple
          index={index}
          logo={tutorialTrail.imageLesson}
          name={tutorialTrail.nameLesson}
          title={item.description}
          nextTrail={() => handleNextTrail(item.next)}
        />
      );
    };

    if (tutorialTrail.actualQuestion === item.identifier && item.description && item.image && !item.choices) {
      return (
        <BuyQuestionImage
          index={index}
          logo={tutorialTrail.imageLesson}
          name={tutorialTrail.nameLesson}
          title={item.description}
          image={item.image}
          nextTrail={() => handleNextTrail(item.next)}
        />
      );
    };

    if (tutorialTrail.actualQuestion === item.identifier && item.description && item.image && item.choices.length > 0) {
      return (
        <BuyQuestionImageItems
          index={index}
          logo={tutorialTrail.imageLesson}
          name={tutorialTrail.nameLesson}
          title={item.description}
          image={item.image}
          questionsItem={item.choices}
          choiceSelected={choiceSelected}
          selectOption={handleSelectOption}
          nextTrail={handleNextTrailChoices}
        />
      );
    };

    if (tutorialTrail.actualQuestion === item.identifier && item.description && item.choices.length > 0) {
      return (
        <BuyQuestionItems
          index={index}
          logo={tutorialTrail.imageLesson}
          name={tutorialTrail.nameLesson}
          title={item.description}
          questionsItem={item.choices}
          choiceSelected={choiceSelected}
          selectOption={handleSelectOption}
          nextTrail={handleNextTrailChoices}
        />
      );
    };
  };

  const handleNextTrail = (next) => {
    const copyQuestionsTutorial = Object.assign({}, tutorialTrail);

    if (next !== "free") {
      copyQuestionsTutorial.actualQuestion = next;

      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsTutorial.trailQuestions)).toFixed(2);
      let numberQuestions = next;

      _forEach(copyQuestionsTutorial.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "free") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsTutorial.percentage = totalLesson;
      setTutorialTrail(copyQuestionsTutorial);
    } else {
      copyQuestionsTutorial.percentage = 100;
      setVisible(true);
    };
  };

  const handleSelectOption = (value) => {
    setChoiceSelected(value);
  };

  const handleNextTrailChoices = () => {
    const copyQuestionsTutorial = Object.assign({}, tutorialTrail);

    if (choiceSelected?.next !== "free") {
      copyQuestionsTutorial.actualQuestion = choiceSelected.next;
      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsTutorial.trailQuestions)).toFixed(2);
      let numberQuestions = choiceSelected.next;

      _forEach(copyQuestionsTutorial.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "free") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsTutorial.percentage = totalLesson;

      setTutorialTrail(copyQuestionsTutorial);
      setChoiceSelected();
    } else {
      copyQuestionsTutorial.percentage = 100;
      setVisible(true);
      setChoiceSelected();
    };
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={`${process.env.REACT_APP_ENVIRONMENT}/suporte-arkc`} target="_blank" rel="noopener">Reportar um erro</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="flex justify-center item-center mt-24 mb-24">
            {!isMobile && (
              <img src={logoColor} alt="logo-color" />
            )}
            {isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24" />
                </Dropdown>
              </div>
            )}
            <div className="flex justify-center item-center w-100">
              <div className={cx("flex flex-row justify-center item-center", styles.progress)}>
                <Progress percent={tutorialTrail?.percentage} showInfo={false} />
                <button className="flex arkc-btn-link ml-14 mt-2" onClick={backDetails}>
                  <FiX className="i-24" />
                </button>
              </div>
            </div>
            {!isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24" />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="divider-internal" />
      <div className={cx("container", { "mt-28": isMobile, "mt-56": !isMobile })}>
        <div className="content">
          {_map(tutorialTrail?.trailQuestions, (item, index) => {
            return (
              <div key={index}>
                {renderTrailQuestions(item, index)}
              </div>
            );
          })}
        </div>
      </div>
      <BuyCompletedModal
        visible={visible}
        isMobile={isMobile}
        advertising={tutorialTrail?.advertising}
        lesson={tutorialTrail?.nameLesson}
        imageLesson={tutorialTrail?.imageLesson}
        color={tutorialTrail?.color}
        backDetails={backDetails}
        viewPlan={viewPlan}
      />
    </div>
  );
};

export default HowBuyForm;