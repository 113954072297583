import { Modal } from 'antd';
import { FiArrowRight, FiX } from 'react-icons/fi';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { arckBlack, arckPremium, arckBeginner } from 'assets';
import styles from '../DashboardWalletForm.module.less';

const ModalWallets = ({ filterWallet, setVisible, walletsList, walletId, visible }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => setVisible(!visible)}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={() => setVisible(!visible)}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="text-center mt-32 mb-24">
        <h3>Selecione uma carteira</h3>
      </div>
      <div className="flex flex-column justify-center item-center">
        {_map(walletsList, (item, index) => {
          if (item.id !== walletId) {
            return (
              <button className={cx("arkc-btn-link", styles.bagdePlanModal, {
                [styles.planBeginner]: item.identifier === "arkc-beginner",
                [styles.planPremium]: item.identifier === "arkc-premium",
                [styles.planBlack]: item.identifier === "arkc-black",
                "mb-16": index !== _size(walletsList) - 1,
              })}
                onClick={() => filterWallet(item.id)}
              >
                <div className="flex flex-row item-center">
                  <img
                    src={item.identifier === "arkc-beginner" ? arckBeginner : item.identifier === "arkc-premium" ? arckPremium : arckBlack}
                    alt="plan-wallets" className="i-32"
                  />
                  <span className={cx("f18 b ml-8", { "white": item.identifier === "arkc-black", "mine-shaft": item.identifier !== "arkc-black" })}>
                    {item.name}
                  </span>
                </div>
                <FiArrowRight className={cx("i-24 mt-8", { "white": item.identifier === "arkc-black", "mine-shaft": item.identifier !== "arkc-black" })} />
              </button>
            );
          };
        })}
      </div>
    </Modal>
  );
};

export default ModalWallets;