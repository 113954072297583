import CreditCardsForm from 'components/CreditCardsForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { cardsUser } from '../../mock';

const CreditCards = () => {
  const handleRegisterCard = () => {
    history.push(ROUTES.REGISTER_CREDIT_CARD);
  };

  return (
    <div>
      <CreditCardsForm
        cardsList={cardsUser}
        registerCard={handleRegisterCard}
      />
      <Footer />
    </div>
  );
};

export default CreditCards;