const lessonsList = [
  {
    id: 1,
    photo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    percentage: 75,
    title: "Reserva de Emergência",
    level: 1,
    open: true
  },
  {
    id: 2,
    photo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Renda%20Fixa%2Frenda-fixa-0.svg?alt=media&token=0b6ab21a-3ffd-4002-a504-b6a616171f4e",
    percentage: 0,
    title: "Renda Fixa",
    level: 2,
    open: false
  },
  {
    id: 3,
    photo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Fundos%20Imobili%C3%A1rios%2Ffundos-imobiliarios-0.svg?alt=media&token=5fe2a5e0-dc51-4525-baba-67d2e67b3a41",
    percentage: 0,
    title: "Fundos Imobiliários",
    level: 3,
    open: false
  },
  {
    id: 4,
    photo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/ETFs%2Fetfs-0.svg?alt=media&token=1e924eff-04e7-4944-98d4-251fe938825a",
    percentage: 0,
    title: "ETFs",
    level: 4,
    open: false
  },
];

const detailLesson = {
  nameLesson: "Reserva de Emergência",
  imageLesson: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
  level: 1,
  lessons: [
    {
      id: 1,
      photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-sua-reserva.svg?alt=media&token=3471937e-5306-44f9-90f6-ef188f5ca281",
      number: 1,
      name: "Sua reserva",
      status: "complete",
      percentage: 100
    },
    {
      id: 2,
      photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-corretoras.svg?alt=media&token=70023847-47a5-4e53-9087-6cb8a8f56831",
      number: 2,
      name: "Corretoras",
      status: "to-do",
      percentage: 10
    },
    {
      id: 3,
      photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-reserva-ideal.svg?alt=media&token=b967ca6e-303e-4bd6-9dc1-ded5bf08d6e8",
      number: 3,
      name: "Reserva Ideal",
      status: "blocked",
      percentage: 0
    },
    {
      id: 4,
      photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-investimentos.svg?alt=media&token=2510846c-3cd5-425b-a08b-863d30fd8f15",
      number: 4,
      name: "Investimentos",
      status: "blocked",
      percentage: 0
    },
    {
      id: 5,
      photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-lucros.svg?alt=media&token=06508a73-c7b3-4e22-a37e-52366efbf5b9",
      number: 5,
      name: "Lucros da sua Rerserva",
      status: "blocked",
      percentage: 0
    },
    {
      id: 6,
      photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-desafio.svg?alt=media&token=1038b976-08f1-4163-9c0a-d9f73e4c853c",
      name: "Trail challenge",
      status: "blocked",
      percentage: 0
    },
  ]
};

const lesson = {
  nameLesson: "Reserva de Emergência",
  imageLesson: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
  actualQuestion: 1,
  percentage: 0,
  trailQuestions: [
    {
      id: 1,
      title: "A Reserva de Emergência é a coisa mais importante antes de pensar em começar a investir, ela é utilizada em momentos de dificuldade financeira"
    },
    {
      id: 2,
      title: "Perder um emprego, internação por motivos de saúde, situações onde você precisa gastar mais do que o habitual são situações para uso da reserva."
    },
    {
      id: 3,
      title: "Por isso ela é tão importante. Você já possui uma Reserva de Emergência?",
      questionsItem: [
        {
          id: 1, name: "Sim."
        },
        {
          id: 2, name: "Não."
        },
      ]
    },
    {
      id: 4,
      title: "A Reserva de Emergência é a coisa mais importante antes de pensar em começar a investir, ela é utilizada em momentos de dificuldade financeira"
    },
    {
      id: 5,
      title: "A Reserva de Emergência é a coisa mais importante antes de pensar em começar a investir, ela é utilizada em momentos de dificuldade financeira"
    },
  ]
};

const signatures = [
  {
    id: 1,
    plan: "beginner",
    price: "Grátis",
    date: "2022-06-01",
    renovation: true,
    status: true,
  },
  {
    id: 2,
    plan: "premium",
    price: "R$ 16,99",
    date: "2023-12-01",
    renovation: false,
    status: false,
  },
  {
    id: 3,
    plan: "black",
    price: "R$ 46,99",
    date: "2024-12-01",
    renovation: true,
    status: true,
  },
];

const cardsUser = [
  {
    id: 1,
    flag: "mastercard",
    name: "Felipe Nunes",
    finalCard: "3232",
    status: true,
  },
  {
    id: 1,
    flag: "mastercard",
    name: "Felipe Nunes",
    finalCard: "5678",
    status: false,
  },
];

const supportQuestions = [
  {
    id: 1,
    title: "O que é o Arkc?",
    description: "O aplicativo de educação financeira Arkc é o jeito mais fácil de aprender sobre investimentos. A missão da empresa é melhorar a educação financeira e torná-la disponível a todos.",
    descriptionSub: "Aprender com o Arkc é divertido, com lições rápidas e curtinhas, você ganha pontos e desbloqueia novos níveis enquanto aprende a investir e cuidar do seu dinheiro na vida real."
  },
  {
    id: 2,
    title: "Como eu posso aprender com o Arkc?",
    description: `Conforme você avança nas lições, você ganha Arkcoins. Existem variados níveis em cada lição, você ganha 1 Arkcoin ao dominar uma lição e 1 Diamante ao concluir um desafio. Quer continuar a aprender mesmo depois de finalizar a lição? Sem problemas! É só tocar em "Play" para continuar aprendendo o conteúdo finalizado.`,
    descriptionSub: ""
  },
  {
    id: 3,
    title: "Quanto custa o Arkc?",
    description: "Você pode aprender a investir no Arkc completamente de graça. Você pode usá-lo no seu computador ou aplicativo mobile, sem custo, sem pegadinha.",
    descriptionSub: "Nós temos assinaturas disponíveis para os usuários do Arkc chamada Arkc Premium e Black. Com a assinatura você tem uma experiência livre de anúncios com acesso a conteúdos exclusivos de investimentos mais rentáveis ao longo prazo."
  },
  {
    id: 4,
    title: "Posso investir pelo Arkc?",
    description: "Não, o Arkc tem como objetivo trazer educação financeira aos nossos usuários, todos os investimentos são realizados através de bancos e corretoras parceiros ou de sua escolha.",
    descriptionSub: ""
  },
  {
    id: 5,
    title: "O Arkc recomenda investimentos?",
    description: "Não, as carteiras do Arck tem caráter exclusivamente educativo, sua finalidade é mostrar os benefícios que uma diversificação bem feita em bons ativos pode trazer ao longo do tempo, os ativos relacionados às carteiras não são recomendação de investimento, estude antes de realizar qualquer tipo de investimento ou procure um profissional credenciado para te auxiliar, caso necessário.",
    descriptionSub: ""
  },
  {
    id: 6,
    title: "Como cancelar uma assinatura?",
    description: "Acesse o menu no canto superior esquerdo da tela de lições do Arkc, clique em “Assinaturas” e identifique qual assinatura deseja cancelar, logo em seguida basta desativar a renovação automática.",
    descriptionSub: ""
  },
];

const benefitsBeginner = [
  {
    id: 1,
    path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    title: "Criptoativos",
    description: "Toda segurança para seus investimentos"
  },
  {
    id: 2,
    path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    title: "REITs",
    description: "Toda segurança para seus investimentos"
  },
  {
    id: 3,
    path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    title: "Stocks",
    description: "Toda segurança para seus investimentos"
  },
  {
    id: 4,
    path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    title: "Ações",
    description: "Toda segurança para seus investimentos"
  },
];

const worksBeginner = [
  {
    id: 1,
    title: "Conteúdo exclusivo",
    description: "Te informamos sempre que houver uma oportunidade de investimento no mercado."
  },
  {
    id: 2,
    title: "Carteira de investimentos",
    description: "Entenda quanto e onde investir de acordo com o seu perfil, tenha acesso a uma carteira completa para você."
  }
];

const planBeginner = [
  {
    "id": 1,
    "title": "Criptoativos",
    "description": "10 Criptoativos"
  },
  {
    id: 2,
    title: "REITS",
    description: "4 REITS"
  },
  {
    id: 3,
    title: "Stocks",
    description: "15 Stocks"
  },
  {
    id: 4,
    title: "Ações",
    description: "15 Empresas"
  },
  {
    id: 5,
    title: "ETFs",
    description: "5 ETFs"
  },
  {
    id: 6,
    title: "Fundos Imobiliários",
    description: "12 FIIs"
  },
  {
    id: 7,
    title: "Renda fixa",
    description: "1 Investimento"
  }
];

const arkcBeginner = {
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-renda-fixa.svg?alt=media&token=123e21e3-aa25-428e-b7f5-6706e935b7de",
      title: "Renda Fixa",
      description: "Invista com segurança e previsibilidade"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-fundos-imobiliarios.svg?alt=media&token=40146764-b327-47c0-ade4-b1c1afb6a8a9",
      title: "FIIs",
      description: "Receba aluguéis todos os meses"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-acoes.svg?alt=media&token=7f3d1efb-ef99-4575-a2c2-ade77c534a6d",
      title: "Ações",
      description: "Seja sócio das maiores empresas do Brasil por meio da bolsa de valores"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-etfs.svg?alt=media&token=407f8592-df93-4014-8f54-c346034f5aac",
      title: "ETFs",
      description: "Diversificação e alta rentabilidade nos investimentos "
    }
  ],
  worksBeginner: [
    {
      id: 1,
      title: "Conteúdo exclusivo",
      description: "Te informamos sobre as mudanças no mercado que afetam seus investimentos"
    },
    {
      id: 2,
      title: "Carteira de investimentos",
      description: "Acesso exclusivo a uma carteira de investimentos de alta rentabilidade histórica"
    }
  ],
  planBeginner: [
    {
      id: 1,
      title: "Ações de empresas brasileiras",
      description: "5 empresas"
    },
    {
      id: 2,
      title: "Fundos de Investimento Imobiliário",
      description: "5 FIIs"
    },
    {
      id: 3,
      title: "ETFs",
      description: "1 ETFs"
    },
    {
      id: 4,
      title: "Renda Fixa",
      description: "1 investimento"
    }
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Fgrafico-rentabilidade-arck-basic.png?alt=media&token=7bdc9951-4c3a-48f5-be9f-cab077175bb5"
};

const arkcPremium = {
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Farkc-premium-stocks.svg?alt=media&token=41317c5b-88b8-4dce-81be-20572337027f",
      title: "Stocks",
      description: "Seja sócio das maiores empresas do mundo e receba dividendos em dólares"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Farck-premium-reits.svg?alt=media&token=0529d5c4-6689-4d94-a3b4-e897d2520984",
      title: "REITs",
      description: "Receba aluguéis em dólares todos os trimestres"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Farkc-premium-acoes.svg?alt=media&token=b8f88a2a-3ec8-4b86-8b0a-4de57e91720c",
      title: "Ações",
      description: "Seja sócio das maiores empresas do Brasil por meio da bolsa de valores"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Farkc-premium-etfs.svg?alt=media&token=d6c07b7c-55f7-4a46-9a95-80dd4def78f9",
      title: "ETFs",
      description: "Diversificação e alta rentabilidade nos investimentos "
    },
    {
      id: 5,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Farck-premium-fundos-imobiliarios.svg?alt=media&token=44e031da-2f41-4055-ba85-14bcdc71e3ac",
      title: "FIIs",
      description: "Receba aluguéis todos os meses"
    },
    {
      id: 6,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Farkc-premium-renda-fixa.svg?alt=media&token=eefcae49-cb0f-4e2f-adce-ff482210c9be",
      title: "Renda Fixa",
      description: "Invista com segurança e previsibilidade"
    }
  ],
  worksPremium: [
    {
      id: 1,
      title: "Conteúdo Exclusivo",
      description: "Aprenda a investir na prática por meio de lições baseadas na carteira Arkc Black"
    },
    {
      id: 2,
      title: "Carteira de Investimentos",
      description: "Acesso a uma carteira exclusiva que possui alta rentabilidade histórica"
    },
    {
      id: 3,
      title: "Desbloqueie Trilhas",
      description: "Desbloqueie todas as trilhas de aprendizado para acelerar a sua jornada como investidor"
    }
  ],
  planPremium: [
    {
      id: 1,
      title: "Ações de empresas brasileiras",
      description: "8 empresas"
    },
    {
      id: 2,
      title: "Fundos de Investimento Imobiliário",
      description: "8 FIIs"
    },
    {
      id: 3,
      title: "ETFs",
      description: "2 ETFs"
    },
    {
      id: 4,
      title: "Renda Fixa",
      description: "1 investimento"
    },
    {
      id: 5,
      title: "Stocks de empresas no exterior",
      description: "8 stocks"
    },
    {
      id: 6,
      title: "REITs de imóveis no exterior",
      description: "3 REITs"
    }
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Premium%2Fgrafico-rentabilidade-arck-premium.png?alt=media&token=2c33bfe9-6094-4a72-9999-fe210bc7c34a"
};

const arkcBlack = {
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-criptoativos.svg?alt=media&token=13f0790f-30c8-46be-b9c8-ddf6fa72473f",
      title: "Criptoativos",
      description: "Invista em criptomoedas, NFTs e DeFi com alto potencial de valorização"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-stocks.svg?alt=media&token=bcfc9fda-dc3e-46b7-a887-14c3c6daeff0",
      title: "Stocks",
      description: "Seja sócio das maiores empresas do mundo e receba dividendos em dólares"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-reits.svg?alt=media&token=1b8d9823-002a-4927-b1aa-21c74f2a8c43",
      title: "REITs",
      description: "Receba aluguéis em dólares todos os trimestres"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-acoes.svg?alt=media&token=16c5563f-d0d5-4a5e-a751-566300e77d8f",
      title: "Ações",
      description: "Seja sócio das maiores empresas do Brasil por meio da bolsa de valores"
    },
    {
      id: 5,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-etfs.svg?alt=media&token=17f29ed4-705b-4f30-b735-554b8d5f9dd3",
      title: "ETFs",
      description: "Diversificação e alta rentabilidade nos investimentos "
    },
    {
      id: 6,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-fiis.svg?alt=media&token=7627bb45-15ba-49a7-8acf-706b138065ff",
      title: "FIIs",
      description: "Receba aluguéis todos os meses"
    },
    // {
    //   id: 7,
    //   path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-renda-fixa.svg?alt=media&token=b5836761-ba8e-4370-a19e-cef39639a998",
    //   title: "Renda Fixa",
    //   description: "Invista com segurança e previsibilidade"
    // },
    // {
    //   id: 8,
    //   path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-reits.svg?alt=media&token=1b8d9823-002a-4927-b1aa-21c74f2a8c43",
    //   title: "Startups",
    //   description: "Investimentos em empresas no estágio inicial com potencial de valorização exponencial"
    // },
  ],
  worksBlack: [
    {
      id: 1,
      title: "Conteúdo Exclusivo",
      description: "Aprenda a investir na prática por meio de lições baseadas na carteira Arkc Black"
    },
    {
      id: 2,
      title: "Carteira de Investimentos",
      description: "Acesso a uma carteira exclusiva que possui alta rentabilidade histórica"
    },
    {
      id: 3,
      title: "Desbloqueie Trilhas",
      description: "Desbloqueie todas as trilhas de aprendizado para acelerar a sua jornada como investidor"
    }
  ],
  planBlack: [
    {
      id: 1,
      title: "Ações de empresas brasileiras",
      description: "10 empresas"
    },
    {
      id: 2,
      title: "Fundos de Investimento Imobiliário",
      description: "10 FIIs"
    },
    {
      id: 3,
      title: "ETFs",
      description: "3 ETFs"
    },
    {
      id: 4,
      title: "Renda Fixa",
      description: "1 investimento"
    },
    {
      id: 5,
      title: "Stocks de empresas no exterior",
      description: "10 stocks"
    },
    {
      id: 6,
      title: "Criptoativos",
      description: "10 criptoativos"
    },
    {
      id: 7,
      title: "REITs de imóveis no exterior",
      description: "5 REITs"
    },
    // {
    //   id: 8,
    //   title: "Bônus: startups",
    //   description: "3 startups"
    // },
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Fgrafico-rentabilidade-arkc-black.png?alt=media&token=8ee797f1-f9e8-4dd5-a037-ea457ec8e2dc"
};

const conquestsList = [
  {
    id: 1,
    photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    title: "Reserva de Emergência",
    description: "Conclusão do Level 1",
    conquered: true
  },
  {
    id: 2,
    photoUrl: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emerg%C3%AAncia%2Freserva-de-emergencia-0.svg?alt=media&token=42e25f08-0172-4698-b0d5-50e195274dbe",
    title: "Fundos Imobiliários",
    description: "Conclusão do Level 2",
    conquered: false
  },
];

const plansArkc = [
  {
    id: 1,
    title: "Arkc Beginner",
    name: "beginner"
  },
  {
    id: 2,
    title: "Arkc Black",
    name: "black"
  },
  {
    id: 3,
    title: "Arkc Premium",
    name: "premium"
  }
];

const topicsLP = [
  {
    topic: 1,
    description: "Simples, investir nunca foi tão fácil e acessível como é com o Arkc! Qualquer pessoa pode começar a investir sem problemas."
  },
  {
    topic: 3,
    description: "Acessível, não importa o quanto você tem ou onde você vem, você merece ter o mesmo acesso às melhores oportunidades de investimento."
  },
  {
    topic: 2,
    description: "Divertido, adeus às conversas chatas sobre investimentos, o Arkc torna tudo muito mais simples e divertido."
  },
  {
    topic: 4,
    description: "Investir não precisa ser complicado ou cheio de surpresas desagradáveis! Com a transparência do Arkc, o mundo dos investimentos é simplificado."
  }
];

const situation = [
  {
    id: 0,
    name: "Tenho dívidas atrasadas pra pagar"
  },
  {
    id: 1,
    name: "Não tenho dívidas e não guardo dinheiro"
  },
  {
    id: 2,
    name: "Junto dinheiro, mas não sei onde investir"
  },
  {
    id: 3,
    name: "Consigo juntar dinheiro e já invisto"
  }
];

const goal = [
  {
    id: 0,
    name: "Para ter uma renda extra"
  },
  {
    id: 1,
    name: "Para minha aposentadoria"
  },
  {
    id: 2,
    name: "Para multiplicar meu patrimônio"
  },
  {
    id: 3,
    name: "Para proteger meu patrimônio"
  }
];

const investment = [
  {
    id: 0,
    name: "Conservador, não tomo riscos"
  },
  {
    id: 1,
    name: "Moderado, aceito riscos em algumas situações"
  },
  {
    id: 2,
    name: "Arrojado, aceito riscos por mais retorno"
  }
];

const profitabilityBeginner = {
  typeOne: "Arkc Beginner",
  typeTwo: "Poupança",
  data: [
    {
      "name": "2019",
      "Arkc Beginner": 12.85,
      "Poupança": 3.19,
    },
    {
      "name": "2020",
      "Arkc Beginner": 19.89,
      "Poupança": 5.37,
    },
    {
      "name": "2021",
      "Arkc Beginner": 29.21,
      "Poupança": 8.07,
    },
    {
      "name": "2022",
      "Arkc Beginner": 33.81,
      "Poupança": 15.97,
    },
    {
      "name": "2023",
      "Arkc Beginner": 41.52,
      "Poupança": 19.31,
    }
  ]
};

const profitabilityPremium = {
  typeOne: "Arkc Premium",
  typeTwo: "Poupança",
  data: [
    {
      "name": "2019",
      "Arkc Premium": 16.73,
      "Poupança": 3.19,
    },
    {
      "name": "2020",
      "Arkc Premium": 41.70,
      "Poupança": 5.37,
    },
    {
      "name": "2021",
      "Arkc Premium": 67.97,
      "Poupança": 8.07,
    },
    {
      "name": "2022",
      "Arkc Premium": 56.09,
      "Poupança": 15.97,
    },
    {
      "name": "2023",
      "Arkc Premium": 62.19,
      "Poupança": 19.31,
    }
  ]
};

const profitabilityBlack = {
  typeOne: "Arkc Black",
  typeTwo: "Poupança",
  data: [
    {
      "name": "2019",
      "Arkc Black": 16.28,
      "Poupança": 3.19,
    },
    {
      "name": "2020",
      "Arkc Black": 67.68,
      "Poupança": 5.37,
    },
    {
      "name": "2021",
      "Arkc Black": 244.91,
      "Poupança": 8.07,
    },
    {
      "name": "2022",
      "Arkc Black": 96.62,
      "Poupança": 15.97,
    },
    {
      "name": "2023",
      "Arkc Black": 119.91,
      "Poupança": 19.31,
    }
  ]
};

export {
  arkcBeginner,
  arkcPremium,
  arkcBlack,
  lessonsList,
  detailLesson,
  lesson,
  signatures,
  cardsUser,
  supportQuestions,
  benefitsBeginner,
  worksBeginner,
  planBeginner,
  conquestsList,
  plansArkc,
  topicsLP,
  situation,
  goal,
  investment,
  profitabilityBeginner,
  profitabilityPremium,
  profitabilityBlack
};