const arkcOne = {
  titleLP: "O essencial para você começar a investir",
  descriptionLP: "Aprenda a investir na prática com a nova plataforma de educação sobre investimentos Arkc.",
  profitTitle: "Lucro de 5 anos",
  profitDescription: "Lucro histórico do método Arkc",
  profitMarginOne: "Método Arkc +121%",
  profitMarginTwo: "Poupança +23%",
  marginInvestmentOne: "Investindo com o método Arkc + 121%",
  marginInvestmentTwo: "Investimento na Poupança + 20%",
  liveTitle: "Está pronto para aprender a colocar seu dinheiro para trabalhar por você?",
  liveDescription: `Aprenda a investir de forma inteligente, conquiste a liberdade financeira e desbloqueie um mundo de oportunidades. No Arkc você aprende a investir de forma fácil e divertida, você terá diversão garantida enquanto aprende a aumentar seus lucros.`,
  investingNowTitle: "Comece agora!",
  investingNowDescription: `O Arkc te ensina como ter segurança e tranquilidade no futuro, sem se preocupar com dinheiro. Aprenda as melhores e mais lucrativas formas de investir.`,
  forMeTitle: "Como saber se é para mim?",
  forMeQuestions: "Perguntas frequentes",
  apprenticeshipTitle: "Aprendizado Personalizado",
  apprenticeshipDescriptionOne: "Com a tecnologia do Arkc, simplificamos o mundo dos investimentos para todos, independentemente da idade, nível de conhecimento e de acordo com os objetivos de cada um.",
  apprenticeshipDescriptionTwo: "Nós usamos informações para fazer com que a jornada dos nossos usuários no mundo dos investimentos seja super fácil e prática.",
  startInvestingTitle: "Comece a investir",
  startInvestingDescriptionOne: `
  Existem várias fontes de informações e termos técnicos que podem desmotivar o aprendizado
  sobre investimentos logo no início.`,
  startInvestingDescriptionTwo: `Por isso, desenvolvemos o Arkc, uma plataforma de educação financeira diferente,
  mais simples e divertida, para que você possa evoluir, de forma personalizada,
  em uma jornada completa.`,
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-renda-fixa.svg?alt=media&token=123e21e3-aa25-428e-b7f5-6706e935b7de",
      title: "Renda Fixa",
      description: "Aprenda a investir em lugares seguros e receber de volta com juros. É como guardar dinheirinho em um cofrinho que cresce!"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-fundos-imobiliarios.svg?alt=media&token=40146764-b327-47c0-ade4-b1c1afb6a8a9",
      title: "FIIs",
      description: "Aprenda a investir em imóveis e receber dinheiro de aluguel todo mês. É como ser dono(a) de casas e ganhar dinheiro sem precisar fazer nada!"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-acoes.svg?alt=media&token=7f3d1efb-ef99-4575-a2c2-ade77c534a6d",
      title: "Ações",
      description: "Se torne sócio(a) das grandes empresas do nosso país e ganha dinheiro quando elas vão bem, recebendo uma parte dos lucros!"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-etfs.svg?alt=media&token=407f8592-df93-4014-8f54-c346034f5aac",
      title: "ETFs",
      description: "Aprenda a investir em mais de 100 empresas com menos de R$50. Imagine ter um portfólio diversificado como os grandes especialistas!"
    },
    {
      id: 5,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Landing%20Page%2Freits.svg?alt=media&token=dc47a4b9-263b-4d2e-ba6c-04c29873f0d6",
      title: "REITs",
      description: "Aprenda a investir em imóveis nos Estados Unidos e receber aluguéis em dólar, e o melhor, você começa com menos de R$50!"
    },
    {
      id: 6,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Landing%20Page%2Fstocks.svg?alt=media&token=0dbb6732-f00a-419d-acf8-8d3e09e4b9de",
      title: "Stocks",
      description: "Aprenda a receber participação nos lucros das maiores empresas do mundo, como a Nike, Starbucks e Google."
    },
  ],
  worksBeginner: [
    {
      id: 1,
      title: "Conteúdo exclusivo",
      description: "Te informamos sobre as mudanças no mercado que afetam seus investimentos"
    },
    {
      id: 2,
      title: "Carteira de investimentos",
      description: "Acesso exclusivo a uma carteira de investimentos de alta rentabilidade histórica"
    }
  ],
  planBeginner: [
    {
      id: 1,
      title: "Ações de empresas brasileiras",
      description: "5 empresas"
    },
    {
      id: 2,
      title: "Fundos de Investimento Imobiliário",
      description: "5 FIIs"
    },
    {
      id: 3,
      title: "ETFs",
      description: "1 ETFs"
    },
    {
      id: 4,
      title: "Renda Fixa",
      description: "1 investimento"
    }
  ],
  supportQuestions: [
    {
      id: 1,
      title: "O que é o Arkc?",
      description: "O aplicativo de educação financeira Arkc é o jeito mais fácil de aprender sobre investimentos. A missão da empresa é melhorar a educação financeira e torná-la disponível a todos.",
      descriptionSub: "Aprender com o Arkc é divertido, com lições rápidas e curtinhas, você ganha pontos e desbloqueia novos níveis enquanto aprende a investir e cuidar do seu dinheiro na vida real."
    },
    {
      id: 2,
      title: "Como eu posso aprender com o Arkc?",
      description: `Conforme você avança nas lições, você ganha Arkcoins. Existem variados níveis em cada lição, você ganha 1 Arkcoin ao dominar uma lição e 1 Diamante ao concluir um desafio. Quer continuar a aprender mesmo depois de finalizar a lição? Sem problemas! É só tocar em "Play" para continuar aprendendo o conteúdo finalizado.`,
      descriptionSub: ""
    },
    {
      id: 3,
      title: "Quanto custa o Arkc?",
      description: "Você pode aprender a investir no Arkc completamente de graça. Você pode usá-lo no seu computador ou aplicativo mobile, sem custo, sem pegadinha.",
      descriptionSub: "Nós temos assinaturas disponíveis para os usuários do Arkc chamada Arkc Premium e Black. Com a assinatura você tem uma experiência livre de anúncios com acesso a conteúdos exclusivos de investimentos mais rentáveis ao longo prazo."
    },
    {
      id: 4,
      title: "Posso investir pelo Arkc?",
      description: "Não, o Arkc tem como objetivo trazer educação financeira aos nossos usuários, todos os investimentos são realizados através de bancos e corretoras parceiros ou de sua escolha.",
      descriptionSub: ""
    },
    {
      id: 5,
      title: "O Arkc recomenda investimentos?",
      description: "Não, as carteiras do Arck tem caráter exclusivamente educativo, sua finalidade é mostrar os benefícios que uma diversificação bem feita em bons ativos pode trazer ao longo do tempo, os ativos relacionados às carteiras não são recomendação de investimento, estude antes de realizar qualquer tipo de investimento ou procure um profissional credenciado para te auxiliar, caso necessário.",
      descriptionSub: ""
    },
    {
      id: 6,
      title: "E se eu não gostar do Arkc?",
      description: "O Arkc é uma plataforma de educação financeira gratuita, use quando e por quanto tempo você quiser.",
      descriptionSub: ""
    },
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Fgrafico-rentabilidade-arck-basic.png?alt=media&token=7bdc9951-4c3a-48f5-be9f-cab077175bb5",
  lessons: [
    {
      id: 1,
      name: "Reserva de emergência",
      identifier: "reserva-emergencia",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emergência%2Freserva-de-emergencia-0.svg?alt=media\u0026token=42e25f08-0172-4698-b0d5-50e195274dbe",
      level: 1,
      color: "#C7FFF0",
      total: 0,
      available: false,
      current: "calcule-sua-reserva"
    },
    {
      id: 2,
      name: "Renda Fixa",
      identifier: "renda-fixa",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Renda%20Fixa%2Frenda-fixa-0.svg?alt=media\u0026token=0b6ab21a-3ffd-4002-a504-b6a616171f4e",
      level: 2,
      color: "#BEFFE1",
      total: 0,
      available: false,
      current: "lucro-renda-fixa"
    },
    {
      id: 3,
      name: "Fundos Imobiliários",
      identifier: "fundos-imobiliarios",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Fundos%20Imobili%C3%A1rios%2Ffundos-imobiliarios-0.svg?alt=media\u0026token=5fe2a5e0-dc51-4525-baba-67d2e67b3a41",
      level: 3,
      color: "#F6E4FF",
      total: 0,
      available: false
    },
    {
      id: 4,
      name: "ETFs",
      identifier: "etfs",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/ETFs%2Fetfs-0.svg?alt=media\u0026token=1e924eff-04e7-4944-98d4-251fe938825a",
      level: 4,
      color: "#A4FFE6",
      total: 0,
      available: false
    },
    {
      id: 5,
      name: "Ações",
      identifier: "acoes",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/A%C3%A7%C3%B5es%2Facoes.svg?alt=media\u0026token=b9f3adaa-6301-4dbe-b9c1-5e2a352564a6",
      level: 5,
      color: "#E4E9FF",
      total: 0,
      available: false
    },
    {
      id: 6,
      name: "Metais Preciosos",
      identifier: "metais-preciosos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Metais%20Preciosos%2Fmetais-preciosos-0.svg?alt=media\u0026token=34a00685-96ee-451d-ba55-bb2547b4b541",
      level: 6,
      color: "#E0FFF4",
      total: 0,
      available: false
    },
    {
      id: 7,
      name: "Stocks",
      identifier: "stocks",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Stocks%2Fstocks-0.svg?alt=media\u0026token=1c0b0428-60e2-43b3-9345-8557259f88fc",
      level: 7,
      color: "#F9FFFA",
      total: 0,
      available: false
    },
    {
      id: 8,
      name: "REITs",
      identifier: "reits",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/REITs%2Freits-0.svg?alt=media\u0026token=cf2c098b-8f1b-4062-b0ce-5f87900640e1",
      level: 8,
      color: "#FFD9D9",
      total: 0,
      available: false
    },
    {
      id: 9,
      name: "Criptoativos",
      identifier: "criptoativos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Criptoativos%2Fcriptoativos.svg?alt=media\u0026token=bc8dcc1e-ea5c-4e97-9678-2e7e28e948bc",
      level: 9,
      color: "#D0EAFF",
      total: 0,
      available: false
    },
    {
      id: 10,
      name: "Startups",
      identifier: "startups",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Startups%2Frocket-launch%201.svg?alt=media\u0026token=c32dfe6b-c9ae-40df-8191-b14351328811",
      level: 10,
      color: "#F9FFFA",
      total: 0,
      available: false
    }
  ]
};

const arkcTwo = {
  titleLP: "O essencial para você começar a investir",
  descriptionLP: "Aprenda a investir na prática com o método de investimentos para iniciantes do Arkc.",
  profitTitle: "Lucro de 5 anos",
  profitDescription: "Lucro histórico do método Arkc",
  profitMarginOne: "Método Arkc +417%",
  profitMarginTwo: "Poupança +23%",
  marginInvestmentOne: "Investindo com o método Arkc + 417%",
  marginInvestmentTwo: "Investimento na Poupança + 23%",
  liveTitle: "Já pensou em viver de renda dos seus investimentos?",
  liveDescription: `
  Com o Arkc você aprende a investir seu dinheiro de um jeito simples e inteligente, usando apenas o celular.
  Você vai entender como investir em investimentos lucrativos e seguros, como Renda Fixa e Fundos Imobiliários,
  muito melhor do que deixar seu dinheiro parado na poupança. Além disso, você ganha uma renda extra todo mês! Não é
  difícil de entender, mesmo se você nunca fez isso antes.`,
  investingNowTitle: " Comece a investir agora!",
  investingNowDescription: `
  Imagine alcançar seus sonhos, ter segurança e tranquilidade no futuro, sem se preocupar com dinheiro.
  Isso é possível, Aprenda de um forma divertida as melhores e mais lucrativas formas de investir.`,
  forMeTitle: "Como saber se é para mim?",
  forMeQuestions: "Perguntas frequentes",
  apprenticeshipTitle: "Aprendizado Personalizado",
  apprenticeshipDescriptionOne: "Com a tecnologia do Arkc, simplificamos o mundo dos investimentos para todos, independentemente da idade, nível de conhecimento e de acordo com os objetivos de cada um.",
  apprenticeshipDescriptionTwo: "Fazemos com que a jornada dos nossos usuários no mundo dos investimentos seja super fácil e prática.",
  startInvestingTitle: "Comece a investir",
  startInvestingDescriptionOne: `Existem várias fontes de informações e termos técnicos que podem desmotivar o aprendizado sobre investimentos logo no início.`,
  startInvestingDescriptionTwo: `Por isso, desenvolvemos o Arkc, uma plataforma de educação financeira diferente, mais simples e divertida, para que você possa evoluir, de forma personalizada, em uma jornada completa.`,
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-renda-fixa.svg?alt=media&token=123e21e3-aa25-428e-b7f5-6706e935b7de",
      title: "Renda Fixa",
      description: "Invista com segurança e previsibilidade"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-fundos-imobiliarios.svg?alt=media&token=40146764-b327-47c0-ade4-b1c1afb6a8a9",
      title: "FIIs",
      description: "Receba aluguéis todos os meses com apenas R$ 10,00"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-acoes.svg?alt=media&token=7f3d1efb-ef99-4575-a2c2-ade77c534a6d",
      title: "Ações",
      description: "Receba participação nos lucros das maiores empresas do Brasil"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-etfs.svg?alt=media&token=407f8592-df93-4014-8f54-c346034f5aac",
      title: "ETFs",
      description: "Invista em mais de 100 investimentos com menos de R$ 50,00"
    },
    {
      id: 5,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Landing%20Page%2Freits.svg?alt=media&token=dc47a4b9-263b-4d2e-ba6c-04c29873f0d6",
      title: "REITs",
      description: "Receba aluguéis em dólares dos EUA todos os trimestres"
    },
    {
      id: 6,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Landing%20Page%2Fstocks.svg?alt=media&token=0dbb6732-f00a-419d-acf8-8d3e09e4b9de",
      title: "Stocks",
      description: "Receba participação nos lucros das maiores empresas do mundo"
    },
  ],
  worksBeginner: [
    {
      id: 1,
      title: "Conteúdo exclusivo",
      description: "Te informamos sobre as mudanças no mercado que afetam seus investimentos"
    },
    {
      id: 2,
      title: "Carteira de investimentos",
      description: "Acesso exclusivo a uma carteira de investimentos de alta rentabilidade histórica"
    }
  ],
  planBeginner: [
    {
      id: 1,
      title: "Ações de empresas brasileiras",
      description: "5 empresas"
    },
    {
      id: 2,
      title: "Fundos de Investimento Imobiliário",
      description: "5 FIIs"
    },
    {
      id: 3,
      title: "ETFs",
      description: "1 ETFs"
    },
    {
      id: 4,
      title: "Renda Fixa",
      description: "1 investimento"
    }
  ],
  supportQuestions: [
    {
      id: 1,
      title: "O que é o Arkc?",
      description: "O aplicativo de educação financeira Arkc é o jeito mais fácil de aprender sobre investimentos. A missão da empresa é melhorar a educação financeira e torná-la disponível a todos.",
      descriptionSub: "Aprender com o Arkc é divertido, com lições rápidas e curtinhas, você ganha pontos e desbloqueia novos níveis enquanto aprende a investir e cuidar do seu dinheiro na vida real."
    },
    {
      id: 2,
      title: "Como eu posso aprender com o Arkc?",
      description: `Conforme você avança nas lições, você ganha Arkcoins. Existem variados níveis em cada lição, você ganha 1 Arkcoin ao dominar uma lição e 1 Diamante ao concluir um desafio. Quer continuar a aprender mesmo depois de finalizar a lição? Sem problemas! É só tocar em "Play" para continuar aprendendo o conteúdo finalizado.`,
      descriptionSub: ""
    },
    {
      id: 3,
      title: "Quanto custa o Arkc?",
      description: "Você pode aprender a investir no Arkc completamente de graça. Você pode usá-lo no seu computador ou aplicativo mobile, sem custo, sem pegadinha.",
      descriptionSub: "Nós temos assinaturas disponíveis para os usuários do Arkc chamada Arkc Premium e Black. Com a assinatura você tem uma experiência livre de anúncios com acesso a conteúdos exclusivos de investimentos mais rentáveis ao longo prazo."
    },
    {
      id: 4,
      title: "Posso investir pelo Arkc?",
      description: "Não, o Arkc tem como objetivo trazer educação financeira aos nossos usuários, todos os investimentos são realizados através de bancos e corretoras parceiros ou de sua escolha.",
      descriptionSub: ""
    },
    {
      id: 5,
      title: "O Arkc recomenda investimentos?",
      description: "Não, as carteiras do Arck tem caráter exclusivamente educativo, sua finalidade é mostrar os benefícios que uma diversificação bem feita em bons ativos pode trazer ao longo do tempo, os ativos relacionados às carteiras não são recomendação de investimento, estude antes de realizar qualquer tipo de investimento ou procure um profissional credenciado para te auxiliar, caso necessário.",
      descriptionSub: ""
    },
    {
      id: 6,
      title: "Como cancelar uma assinatura?",
      description: "Acesse o menu no canto superior esquerdo da tela de lições do Arkc, clique em “Assinaturas” e identifique qual assinatura deseja cancelar, logo em seguida basta desativar a renovação automática.",
      descriptionSub: ""
    },
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Fgrafico-rentabilidade-arck-basic.png?alt=media&token=7bdc9951-4c3a-48f5-be9f-cab077175bb5",
  lessons: [
    {
      id: 1,
      name: "Reserva de emergência",
      identifier: "reserva-emergencia",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emergência%2Freserva-de-emergencia-0.svg?alt=media\u0026token=42e25f08-0172-4698-b0d5-50e195274dbe",
      level: 1,
      color: "#C7FFF0",
      total: 100,
      available: true,
      current: "calcule-sua-reserva"
    },
    {
      id: 2,
      name: "Renda Fixa",
      identifier: "renda-fixa",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emergência%2Freserva-de-emergencia-0.svg?alt=media\u0026token=42e25f08-0172-4698-b0d5-50e195274dbe",
      level: 2,
      color: "#BEFFE1",
      total: 0,
      available: false,
      current: "renda-fixa"
    },
    {
      id: 3,
      name: "Fundos Imobiliários",
      identifier: "fundos-imobiliarios",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Fundos%20Imobili%C3%A1rios%2Ffundos-imobiliarios-0.svg?alt=media\u0026token=5fe2a5e0-dc51-4525-baba-67d2e67b3a41",
      level: 3,
      color: "#F6E4FF",
      total: 0,
      available: false
    },
    {
      id: 4,
      name: "ETFs",
      identifier: "etfs",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/ETFs%2Fetfs-0.svg?alt=media\u0026token=1e924eff-04e7-4944-98d4-251fe938825a",
      level: 4,
      color: "#A4FFE6",
      total: 0,
      available: false
    },
    {
      id: 5,
      name: "Ações",
      identifier: "acoes",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/A%C3%A7%C3%B5es%2Facoes.svg?alt=media\u0026token=b9f3adaa-6301-4dbe-b9c1-5e2a352564a6",
      level: 5,
      color: "#E4E9FF",
      total: 0,
      available: false
    },
    {
      id: 6,
      name: "Metais Preciosos",
      identifier: "metais-preciosos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Metais%20Preciosos%2Fmetais-preciosos-0.svg?alt=media\u0026token=34a00685-96ee-451d-ba55-bb2547b4b541",
      level: 6,
      color: "#E0FFF4",
      total: 0,
      available: false
    },
    {
      id: 7,
      name: "Stocks",
      identifier: "stocks",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Stocks%2Fstocks-0.svg?alt=media\u0026token=1c0b0428-60e2-43b3-9345-8557259f88fc",
      level: 7,
      color: "#F9FFFA",
      total: 0,
      available: false
    },
    {
      id: 8,
      name: "REITs",
      identifier: "reits",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/REITs%2Freits-0.svg?alt=media\u0026token=cf2c098b-8f1b-4062-b0ce-5f87900640e1",
      level: 8,
      color: "#FFD9D9",
      total: 0,
      available: false
    },
    {
      id: 9,
      name: "Criptoativos",
      identifier: "criptoativos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Criptoativos%2Fcriptoativos.svg?alt=media\u0026token=bc8dcc1e-ea5c-4e97-9678-2e7e28e948bc",
      level: 9,
      color: "#D0EAFF",
      total: 0,
      available: false
    },
    {
      id: 10,
      name: "Startups",
      identifier: "startups",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Startups%2Frocket-launch%201.svg?alt=media\u0026token=c32dfe6b-c9ae-40df-8191-b14351328811",
      level: 10,
      color: "#F9FFFA",
      total: 0,
      available: false
    }
  ]
};

const arkcThree = {
  titleLP: "O essencial para você começar a investir",
  descriptionLP: "Aprenda a investir na prática com o método de investimentos para iniciantes do Arkc.",
  profitTitle: "Lucro de 5 anos",
  profitDescription: "Lucro histórico do método Arkc",
  profitMarginOne: "Método Arkc +417%",
  profitMarginTwo: "Poupança +23%",
  marginInvestmentOne: "Investindo com o método Arkc + 417%",
  marginInvestmentTwo: "Investimento na Poupança + 23%",
  liveTitle: "Já pensou em viver de renda dos seus investimentos?",
  liveDescription: `
  Com o Arkc você aprende a investir seu dinheiro de um jeito simples e inteligente, usando apenas o celular.
  Você vai entender como investir em investimentos lucrativos e seguros, como Renda Fixa e Fundos Imobiliários,
  muito melhor do que deixar seu dinheiro parado na poupança. Além disso, você ganha uma renda extra todo mês! Não é
  difícil de entender, mesmo se você nunca fez isso antes.`,
  investingNowTitle: " Comece a investir agora!",
  investingNowDescription: `
  Imagine alcançar seus sonhos, ter segurança e tranquilidade no futuro, sem se preocupar com dinheiro.
  Isso é possível, Aprenda de um forma divertida as melhores e mais lucrativas formas de investir.`,
  forMeTitle: "Como saber se é para mim?",
  forMeQuestions: "Perguntas frequentes",
  apprenticeshipTitle: "Aprendizado Personalizado",
  apprenticeshipDescriptionOne: "Com a tecnologia do Arkc, simplificamos o mundo dos investimentos para todos, independentemente da idade, nível de conhecimento e de acordo com os objetivos de cada um.",
  apprenticeshipDescriptionTwo: "Nós usamos informações para fazer com que a jornada dos nossos usuários no mundo dos investimentos seja super fácil e prática.",
  startInvestingTitle: "Comece a investir",
  startInvestingDescriptionOne: `
  Existem várias fontes de informações e termos técnicos que podem desmotivar o aprendizado
  sobre investimentos logo no início.`,
  startInvestingDescriptionTwo: `Por isso, desenvolvemos o Arkc, uma plataforma de educação financeira diferente,
  mais simples e divertida, para que você possa evoluir, de forma personalizada,
  em uma jornada completa.`,
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-renda-fixa.svg?alt=media&token=123e21e3-aa25-428e-b7f5-6706e935b7de",
      title: "Renda Fixa",
      description: "Invista com segurança e previsibilidade"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-fundos-imobiliarios.svg?alt=media&token=40146764-b327-47c0-ade4-b1c1afb6a8a9",
      title: "FIIs",
      description: "Receba aluguéis todos os meses com apenas R$ 10,00"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-acoes.svg?alt=media&token=7f3d1efb-ef99-4575-a2c2-ade77c534a6d",
      title: "Ações",
      description: "Receba participação nos lucros das maiores empresas do Brasil"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Farkc-beginner-etfs.svg?alt=media&token=407f8592-df93-4014-8f54-c346034f5aac",
      title: "ETFs",
      description: "Invista em mais de 100 investimentos com menos de R$ 50,00"
    },
    {
      id: 5,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Landing%20Page%2Freits.svg?alt=media&token=dc47a4b9-263b-4d2e-ba6c-04c29873f0d6",
      title: "REITs",
      description: "Receba aluguéis em dólares dos EUA todos os trimestres"
    },
    {
      id: 6,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Landing%20Page%2Fstocks.svg?alt=media&token=0dbb6732-f00a-419d-acf8-8d3e09e4b9de",
      title: "Stocks",
      description: "Receba participação nos lucros das maiores empresas do mundo"
    },
  ],
  worksBeginner: [
    {
      id: 1,
      title: "Conteúdo exclusivo",
      description: "Te informamos sobre as mudanças no mercado que afetam seus investimentos"
    },
    {
      id: 2,
      title: "Carteira de investimentos",
      description: "Acesso exclusivo a uma carteira de investimentos de alta rentabilidade histórica"
    }
  ],
  planBeginner: [
    {
      id: 1,
      title: "Ações de empresas brasileiras",
      description: "5 empresas"
    },
    {
      id: 2,
      title: "Fundos de Investimento Imobiliário",
      description: "5 FIIs"
    },
    {
      id: 3,
      title: "ETFs",
      description: "1 ETFs"
    },
    {
      id: 4,
      title: "Renda Fixa",
      description: "1 investimento"
    }
  ],
  supportQuestions: [
    {
      id: 1,
      title: "O que é o Arkc?",
      description: "O aplicativo de educação financeira Arkc é o jeito mais fácil de aprender sobre investimentos. A missão da empresa é melhorar a educação financeira e torná-la disponível a todos.",
      descriptionSub: "Aprender com o Arkc é divertido, com lições rápidas e curtinhas, você ganha pontos e desbloqueia novos níveis enquanto aprende a investir e cuidar do seu dinheiro na vida real."
    },
    {
      id: 2,
      title: "Como eu posso aprender com o Arkc?",
      description: `Conforme você avança nas lições, você ganha Arkcoins. Existem variados níveis em cada lição, você ganha 1 Arkcoin ao dominar uma lição e 1 Diamante ao concluir um desafio. Quer continuar a aprender mesmo depois de finalizar a lição? Sem problemas! É só tocar em "Play" para continuar aprendendo o conteúdo finalizado.`,
      descriptionSub: ""
    },
    {
      id: 3,
      title: "Quanto custa o Arkc?",
      description: "Você pode aprender a investir no Arkc completamente de graça. Você pode usá-lo no seu computador ou aplicativo mobile, sem custo, sem pegadinha.",
      descriptionSub: "Nós temos assinaturas disponíveis para os usuários do Arkc chamada Arkc Premium e Black. Com a assinatura você tem uma experiência livre de anúncios com acesso a conteúdos exclusivos de investimentos mais rentáveis ao longo prazo."
    },
    {
      id: 4,
      title: "Posso investir pelo Arkc?",
      description: "Não, o Arkc tem como objetivo trazer educação financeira aos nossos usuários, todos os investimentos são realizados através de bancos e corretoras parceiros ou de sua escolha.",
      descriptionSub: ""
    },
    {
      id: 5,
      title: "O Arkc recomenda investimentos?",
      description: "Não, as carteiras do Arck tem caráter exclusivamente educativo, sua finalidade é mostrar os benefícios que uma diversificação bem feita em bons ativos pode trazer ao longo do tempo, os ativos relacionados às carteiras não são recomendação de investimento, estude antes de realizar qualquer tipo de investimento ou procure um profissional credenciado para te auxiliar, caso necessário.",
      descriptionSub: ""
    },
    {
      id: 6,
      title: "Como cancelar uma assinatura?",
      description: "Acesse o menu no canto superior esquerdo da tela de lições do Arkc, clique em “Assinaturas” e identifique qual assinatura deseja cancelar, logo em seguida basta desativar a renovação automática.",
      descriptionSub: ""
    },
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Beginner%2Fgrafico-rentabilidade-arck-basic.png?alt=media&token=7bdc9951-4c3a-48f5-be9f-cab077175bb5",
  lessons: [
    {
      id: 1,
      name: "Reserva de emergência",
      identifier: "reserva-emergencia",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emergência%2Freserva-de-emergencia-0.svg?alt=media\u0026token=42e25f08-0172-4698-b0d5-50e195274dbe",
      level: 1,
      color: "#C7FFF0",
      total: 100,
      available: true,
      current: "calcule-sua-reserva"
    },
    {
      id: 2,
      name: "Renda Fixa",
      identifier: "renda-fixa",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emergência%2Freserva-de-emergencia-0.svg?alt=media\u0026token=42e25f08-0172-4698-b0d5-50e195274dbe",
      level: 2,
      color: "#BEFFE1",
      total: 0,
      available: false,
      current: "renda-fixa"
    },
    {
      id: 3,
      name: "Fundos Imobiliários",
      identifier: "fundos-imobiliarios",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Fundos%20Imobili%C3%A1rios%2Ffundos-imobiliarios-0.svg?alt=media\u0026token=5fe2a5e0-dc51-4525-baba-67d2e67b3a41",
      level: 3,
      color: "#F6E4FF",
      total: 0,
      available: false
    },
    {
      id: 4,
      name: "ETFs",
      identifier: "etfs",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/ETFs%2Fetfs-0.svg?alt=media\u0026token=1e924eff-04e7-4944-98d4-251fe938825a",
      level: 4,
      color: "#A4FFE6",
      total: 0,
      available: false
    },
    {
      id: 5,
      name: "Ações",
      identifier: "acoes",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/A%C3%A7%C3%B5es%2Facoes.svg?alt=media\u0026token=b9f3adaa-6301-4dbe-b9c1-5e2a352564a6",
      level: 5,
      color: "#E4E9FF",
      total: 0,
      available: false
    },
    {
      id: 6,
      name: "Metais Preciosos",
      identifier: "metais-preciosos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Metais%20Preciosos%2Fmetais-preciosos-0.svg?alt=media\u0026token=34a00685-96ee-451d-ba55-bb2547b4b541",
      level: 6,
      color: "#E0FFF4",
      total: 0,
      available: false
    },
    {
      id: 7,
      name: "Stocks",
      identifier: "stocks",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Stocks%2Fstocks-0.svg?alt=media\u0026token=1c0b0428-60e2-43b3-9345-8557259f88fc",
      level: 7,
      color: "#F9FFFA",
      total: 0,
      available: false
    },
    {
      id: 8,
      name: "REITs",
      identifier: "reits",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/REITs%2Freits-0.svg?alt=media\u0026token=cf2c098b-8f1b-4062-b0ce-5f87900640e1",
      level: 8,
      color: "#FFD9D9",
      total: 0,
      available: false
    },
    {
      id: 9,
      name: "Criptoativos",
      identifier: "criptoativos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Criptoativos%2Fcriptoativos.svg?alt=media\u0026token=bc8dcc1e-ea5c-4e97-9678-2e7e28e948bc",
      level: 9,
      color: "#D0EAFF",
      total: 0,
      available: false
    },
    {
      id: 10,
      name: "Startups",
      identifier: "startups",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Startups%2Frocket-launch%201.svg?alt=media\u0026token=c32dfe6b-c9ae-40df-8191-b14351328811",
      level: 10,
      color: "#F9FFFA",
      total: 0,
      available: false
    }
  ]
};

const arkcFour = {
  guide: [
    { id: 1, description: "Faça o seu cadastro gratuito em apenas 2 minutos" },
    { id: 2, description: "Comece a aprender a investir com um treinamento 100% personalizado e gamificado" },
    { id: 3, description: "Avance nas lições, ganhe pontos e descubra como fazer seu dinheiro trabalhar para você na vida real" },
    { id: 4, description: "Veja seu dinheiro se multiplicando e ganhe tranquilidade financeira ao longo dos anos" },
  ],
  you: [
    { description: "Quer começar a aprender sobre investimentos e ter mais segurança financeira, ainda que não saiba nada o assunto" },
    { description: "Deseja começar a investir e multiplicar seu dinheiro com apenas R$ 10 reais por mês" },
    { description: "Quer economizar tempo e descobrir qual a melhor estratégia de investimentos para você – sem precisar perder horas vendo vídeos aleatórios e difíceis sobre o assunto" },
    { description: "Quer ganhar independência para gerir seus próprios investimentos com segurança" },
  ],
  lessons: [
    {
      id: 1,
      name: "Reserva de emergência",
      identifier: "reserva-emergencia",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Reserva%20de%20Emergência%2Freserva-de-emergencia-0.svg?alt=media\u0026token=42e25f08-0172-4698-b0d5-50e195274dbe",
      description: "Junte dinheiro para dias chuvosos. É fácil, seguro e te deixa pronto para imprevistos!",
      color: "#C7FFF0",
    },
    {
      id: 2,
      name: "Renda Fixa",
      identifier: "renda-fixa",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Renda%20Fixa%2Frenda-fixa-0.svg?alt=media\u0026token=0b6ab21a-3ffd-4002-a504-b6a616171f4e",
      description: "Uma das formas mais seguras de começar! Empreste dinheiro para instituições financeiras, governos ou empresas e recebe o valor investido de volta com juros.",
      color: "#BEFFE1",
    },
    {
      id: 3,
      name: "Fundos Imobiliários",
      identifier: "fundos-imobiliarios",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Fundos%20Imobili%C3%A1rios%2Ffundos-imobiliarios-0.svg?alt=media\u0026token=5fe2a5e0-dc51-4525-baba-67d2e67b3a41",
      description: "Invista em imóveis e receba dinheiro de aluguel todo mês.  É como ser dono de casas e ganhar dinheiro sem precisar se preocupar com a reforma ou custos para mantê-la em funcionamento!",
      color: "#F6E4FF",
    },
    {
      id: 4,
      name: "ETFs",
      identifier: "etfs",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/ETFs%2Fetfs-0.svg?alt=media\u0026token=1e924eff-04e7-4944-98d4-251fe938825a",
      description: "Compre participações em mais de 100 empresas com menos de R$ 50 e ganhe mais segurança.",
      color: "#A4FFE6",
    },
    {
      id: 5,
      name: "Ações",
      identifier: "acoes",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/A%C3%A7%C3%B5es%2Facoes.svg?alt=media\u0026token=b9f3adaa-6301-4dbe-b9c1-5e2a352564a6",
      description: "Torne-se proprietário de grandes empresas do país e receba periodicamente parte dos lucros.",
      color: "#E4E9FF",
    },
    {
      id: 6,
      name: "Metais Preciosos",
      identifier: "metais-preciosos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Metais%20Preciosos%2Fmetais-preciosos-0.svg?alt=media\u0026token=34a00685-96ee-451d-ba55-bb2547b4b541",
      description: "Use ouro e prata para proteger e blindar seu dinheiro contra crises na economia.",
      color: "#E0FFF4",
    },
    {
      id: 7,
      name: "Stocks",
      identifier: "stocks",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Stocks%2Fstocks-0.svg?alt=media\u0026token=1c0b0428-60e2-43b3-9345-8557259f88fc",
      description: "Receba participação nos lucros das maiores empresas do mundo, como a Nike, Starbucks, Google e Apple.",
      color: "#F9FFFA",
    },
    {
      id: 8,
      name: "REITs",
      identifier: "reits",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/REITs%2Freits-0.svg?alt=media\u0026token=cf2c098b-8f1b-4062-b0ce-5f87900640e1",
      description: "Invista em imóveis nos Estados Unidos e receba aluguéis em dólar. E o melhor, você pode começar com menos de R$50!",
      color: "#FFD9D9",
    },
    {
      id: 9,
      name: "Criptoativos",
      identifier: "criptoativos",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Criptoativos%2Fcriptoativos.svg?alt=media\u0026token=bc8dcc1e-ea5c-4e97-9678-2e7e28e948bc",
      description: "Entre no jogo do dinheiro do futuro. Invista em criptoativos e veja seu dinheiro se multiplicar.",
      color: "#D0EAFF",
    },
    {
      id: 10,
      name: "Startups",
      identifier: "startups",
      logo: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Startups%2Frocket-launch%201.svg?alt=media\u0026token=c32dfe6b-c9ae-40df-8191-b14351328811",
      description: "Pequenas empresas se tornarão gigantes. Invista em startups e faça e veja seu patrimônio crescer com elas.",
      color: "#F9FFFA",
    }
  ],
  secrets: [
    {
      id: 1,
      title: "Simples",
      description: "Não importa sua idade ou nível de experiência, você conseguirá aprender a investir com facilidade.",
      icon: require("../../assets/simpels.png")
    },
    {
      id: 2,
      title: "Divertido",
      description: "Falar de investimento não precisa ser chato. Esqueça palavras difíceis e comece uma experiência prazerosa.",
      icon: require("../../assets/funny.png")
    },
    {
      id: 3,
      title: "Acessível",
      description: "Tenha acesso a formatos de investimentos que te permitem começar com apenas R$10 por mês.",
      icon: require("../../assets/accessible.png")
    },
    {
      id: 4,
      title: "100% Transparente",
      description: "Sem entrelinhas ou letras minúsculas comuns no mercado financeiro.",
      icon: require("../../assets/transparent.png")
    },
    {
      id: 5,
      title: "Hiper Personalizado",
      description: "Nossa Inteligência Artificial é treinada para te mostrar o melhor caminho para investir de acordo com sua experiência e objetivo.",
      icon: require("../../assets/custom.png")
    },
    {
      id: 6,
      title: "Centrado em você",
      description: "Sua jornada de aprendizado será construída de acordo com as suas necessidades e desejos.",
      icon: require("../../assets/centered.png")
    },
  ],
  investors: 20,
  peoples: [
    {
      id: 1,
      name: "Carlos, 21 anos.",
      testimony: "Eu tinha um medo danado de investir, achava que era só para quem entendia de gráfico, de números.  Deixar meu dinheiro na poupança era como ver ele sumir com a inflação. Agora já consigo investir com maior rentabilidade e segurança.",
    },
    {
      id: 2,
      name: " Amanda, 28 anos.",
      testimony: "Eu pensava que precisava ter muito dinheiro pra investir. Mas eu tava errada, comecei a investir com pouco dinheiro, menos de 50 reais, só pra ver como era, e estou vendo meu dinheiro render todos os meses. Nem acredito como era tão fácil e ninguém tinha me contado antes.",
    },
    {
      id: 3,
      name: "Pedro, 43 anos.",
      testimony: "Tô ganhando aluguel de imóveis todo mês sem ter que comprar um imóvel, aprendi sobre os FIIs e os REITs, agora eu recebo dinheiro todo mês sem ter que fazer nada, meu dinheiro está literalmente trabalhando pra mim.",
    },
    {
      id: 4,
      name: "Ana, 31 anos.",
      testimony: "Eu sou PJ e nunca pensei muito na aposentadoria, não sabia nem por onde começar, me convidaram para o Arkc, e com as lições aprendi o que eu precisava fazer para garantir minha aposentadoria. Agora sei que tô no caminho certo.",
    },
    {
      id: 5,
      name: " Luís, 26 anos.",
      testimony: "Eu deixava meu dinheiro parado no banco e morria de medo de perder meu dinheiro em investimentos. Agora já consigo identificar os melhores investimentos e investir com segurança e alta rentabilidade. Hoje, eu tô mais tranquilo e vejo meu dinheiro rendendo todo dia.",
    },
    {
      id: 6,
      name: "Beatriz, 34 anos.",
      testimony: "Renda passiva? Nunca tinha ouvido falar disso antes de conhecer o Arkc. Aprendi que posso ganhar dinheiro todo mês com meus investimentos, parece mágica, de tão simples que é. Hoje, recebo um extra todo mês graças ao que aprendi.",
    },
    {
      id: 7,
      name: "Rodrigo, 27 anos.",
      testimony: "Eu só sabia da renda fixa, pensava que era o único jeito seguro de investir. Tem muito mais opções, muito melhores, e eu nem sabia. Hoje, eu diversifico meus investimentos e estou vendo meu dinheiro crescer cada vez mais, é uma bola de neve.",
    },
  ],
  noArkc: [
    {
      id: 1,
      description: "Deixa o dinheiro na poupança e acaba perdendo grande parte para inflação"
    },
    {
      id: 2,
      description: "Tem medo de investir e acaba deixando seus sonhos de lado por falta de dinheiro"
    },
    {
      id: 3,
      description: "Acha que precisa guardar muito dinheiro para começar a investir"
    },
    {
      id: 4,
      description: "Vai trabalhar pelo resto da vida para sobreviver ou será sustentado pelo governo/família"
    },
  ],
  withArkc: [
    {
      id: 1,
      description: "Deixa o dinheiro na poupança e acaba perdendo grande parte para inflação"
    },
    {
      id: 2,
      description: "Tem medo de investir e acaba deixando seus sonhos de lado por falta de dinheiro"
    },
    {
      id: 3,
      description: "Acha que precisa guardar muito dinheiro para começar a investir"
    },
    {
      id: 4,
      description: "Vai trabalhar pelo resto da vida para sobreviver ou será sustentado pelo governo/família"
    },
  ],
  informationsOne: [
    {
      id: 1,
      title: "Lições interativas",
      description: "Aprenda conceitos, estratégias, análises de mercado e como investir na prática de modo simples e fácil.",
      icon: require("../../assets/interactive-lessons.png"),
      color: "#FFEBE5",
    },
    {
      id: 2,
      title: "Diversificação",
      description: "Descubra como diversificar seus investimentos e ganhar mais rentabilidade e segurança.",
      icon: require("../../assets/diversification.png"),
      color: "#E1F6B3",
    },
    {
      id: 3,
      title: "Análise de Desempenho",
      description: "Acesso a dados e relatórios para tomar decisões seguras de onde deixar seu dinheiro.",
      icon: require("../../assets/performance-analysis.png"),
      color: "#FFEA94",
    },
  ],
  informationsTwo: [
    {
      id: 1,
      title: "Insight de Especialistas",
      description: "Descubra as principais tendências do mercado e tenha mais segurança e tranquilidade para investir.",
      icon: require("../../assets/insight-experts.png"),
      color: "#C9B3FF",
    },
    {
      id: 2,
      title: "Gamificação",
      description: "Divirta-se enquanto aprende a fazer seu dinheiro trabalhar para você.",
      icon: require("../../assets/regular-updates.png"),
      color: "#C9F2D0",
    },
    {
      id: 3,
      title: "Atualizações Regulares",
      description: "Receba novidades e atualizações do mercado financeiro e proteja seus investimentos.",
      icon: require("../../assets/gamification.png"),
      color: "#D0EAFF",
    },
  ],
  supportQuestions: [
    {
      id: 1,
      title: "O que é o Arkc?",
      description: "O Arkc é um aplicativo de educação financeira que te permite aprender sobre investimentos de modo fácil e descomplicado. Nossa missão é tornar a educação financeira disponível para todos. Com lições rápidas e curtas, você aprende a cuidar do seu dinheiro enquanto se diverte.",
      descriptionSub: ""
    },
    {
      id: 2,
      title: "Quanto custa o Arkc?",
      description: "Você pode aprender a investir completamente de graça, sem custo, sem pegadinha. Basta baixar o aplicativo Arkc no seu celular ou usá-lo no seu computador.",
      descriptionSub: "Para melhorar a sua experiência, possuímos planos pagos para os usuários do Arkc. Temos o Arkc Premium e Arkc Black que te permitem navegar no app sem ser interrompido por anúncios e ter acesso a conteúdos exclusivos de investimentos mais rentáveis a longo prazo."
    },
    {
      id: 3,
      title: "O Arkc recomenda investimentos?",
      description: "Não. As carteiras do Arkc tem caráter exclusivamente educativo. Nossa finalidade é mostrar os benefícios que uma diversificação bem feita e bons ativos podem trazer ao longo do tempo, mas os ativos relacionados às carteiras não são recomendações! Orientamos que você estude antes de realizar qualquer investimento ou procure um profissional credenciado para te ajudar.",
      descriptionSub: ""
    },
    {
      id: 4,
      title: "Como eu posso aprender com o Arkc?",
      description: `Conforme avance nas lições, você ganha Arkcoins. Existem vários níveis que você pode alcançar em cada lição, sendo que você ganha 1 Arkcoin ao dominar uma lição e 10 Arkcoins ao concluir um desafio. Quer continuar a aprender mesmo depois de finalizada a lição? Toque em ''play'' para continuar aprendendo.`,
      descriptionSub: ""
    },
    {
      id: 5,
      title: "Posso investir pelo Arkc?",
      description: "Não. Temos como único objetivo te ensinar educação financeira. Todos os investimentos devem ser realizados através de bancos e corretoras parceiras ou escolhidos por você.",
      descriptionSub: ""
    },
    {
      id: 6,
      title: "E se eu não gostar do Arkc?",
      description: "Sem problemas! Somos uma plataforma de educação financeira gratuita. Use quando e por quanto tempo você julgar necessário.",
      descriptionSub: ""
    },
    {
      id: 7,
      title: "Os resultados são garantidos?",
      description: "Nenhum resultado por si só é garantido. Para você dominar o mundo dos investimentos e multiplicar seu dinheiro, precisa estar comprometido e se dedicar também. Mas não se preocupe: nós estamos aqui para facilitar o seu caminho e tornar a sua jornada de conhecimento muito mais divertida!",
      descriptionSub: ""
    },
  ],
};

const arkcBlack = {
  benefits: [
    {
      id: 1,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-criptoativos.svg?alt=media&token=13f0790f-30c8-46be-b9c8-ddf6fa72473f",
      title: "Criptoativos",
      description: "Toda segurança para seus investimentos"
    },
    {
      id: 2,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-reits.svg?alt=media&token=1b8d9823-002a-4927-b1aa-21c74f2a8c43",
      title: "REITs",
      description: "Toda segurança para seus investimentos"
    },
    {
      id: 3,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-stocks.svg?alt=media&token=bcfc9fda-dc3e-46b7-a887-14c3c6daeff0",
      title: "Stocks",
      description: "Toda segurança para seus investimentos"
    },
    {
      id: 4,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-acoes.svg?alt=media&token=16c5563f-d0d5-4a5e-a751-566300e77d8f",
      title: "Ações",
      description: "Toda segurança para seus investimentos"
    },
    {
      id: 5,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-etfs.svg?alt=media&token=17f29ed4-705b-4f30-b735-554b8d5f9dd3",
      title: "ETFs",
      description: "Toda segurança para seus investimentos"
    },
    {
      id: 6,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-fiis.svg?alt=media&token=7627bb45-15ba-49a7-8acf-706b138065ff",
      title: "Fundos Imobiliários",
      description: "Toda segurança para seus investimentos"
    },
    {
      id: 7,
      path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-renda-fixa.svg?alt=media&token=b5836761-ba8e-4370-a19e-cef39639a998",
      title: "Renda Fixa",
      description: "Toda segurança para seus investimentos"
    },
    // {
    //   id: 8,
    //   path: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Farkc-black-reits.svg?alt=media&token=1b8d9823-002a-4927-b1aa-21c74f2a8c43",
    //   title: "Startups",
    //   description: "Investimentos em empresas no estágio inicial com potencial de valorização exponencial"
    // },
  ],
  peoples: [
    {
      id: 1,
      name: "Carlos, 21 anos.",
      testimony: "Eu tinha um medo danado de investir, achava que era só para quem entendia de gráfico, de números.  Deixar meu dinheiro na poupança era como ver ele sumir com a inflação. Agora já consigo investir com maior rentabilidade e segurança.",
    },
    {
      id: 2,
      name: " Amanda, 28 anos.",
      testimony: "Eu pensava que precisava ter muito dinheiro pra investir. Mas eu tava errada, comecei a investir com pouco dinheiro, menos de 50 reais, só pra ver como era, e estou vendo meu dinheiro render todos os meses. Nem acredito como era tão fácil e ninguém tinha me contado antes.",
    },
    {
      id: 3,
      name: "Pedro, 43 anos.",
      testimony: "Tô ganhando aluguel de imóveis todo mês sem ter que comprar um imóvel, aprendi sobre os FIIs e os REITs, agora eu recebo dinheiro todo mês sem ter que fazer nada, meu dinheiro está literalmente trabalhando pra mim.",
    },
    {
      id: 4,
      name: "Ana, 31 anos.",
      testimony: "Eu sou PJ e nunca pensei muito na aposentadoria, não sabia nem por onde começar, me convidaram para o Arkc, e com as lições aprendi o que eu precisava fazer para garantir minha aposentadoria. Agora sei que tô no caminho certo.",
    },
    {
      id: 5,
      name: " Luís, 26 anos.",
      testimony: "Eu deixava meu dinheiro parado no banco e morria de medo de perder meu dinheiro em investimentos. Agora já consigo identificar os melhores investimentos e investir com segurança e alta rentabilidade. Hoje, eu tô mais tranquilo e vejo meu dinheiro rendendo todo dia.",
    },
    {
      id: 6,
      name: "Beatriz, 34 anos.",
      testimony: "Renda passiva? Nunca tinha ouvido falar disso antes de conhecer o Arkc. Aprendi que posso ganhar dinheiro todo mês com meus investimentos, parece mágica, de tão simples que é. Hoje, recebo um extra todo mês graças ao que aprendi.",
    },
    {
      id: 7,
      name: "Rodrigo, 27 anos.",
      testimony: "Eu só sabia da renda fixa, pensava que era o único jeito seguro de investir. Tem muito mais opções, muito melhores, e eu nem sabia. Hoje, eu diversifico meus investimentos e estou vendo meu dinheiro crescer cada vez mais, é uma bola de neve.",
    },
  ],
  planBlack: [
    {
      id: 1,
      title: "Criptoativos",
      description: "10 Criptoativos"
    },
    {
      id: 2,
      title: "REITS",
      description: "5 REITS"
    },
    {
      id: 3,
      title: "Stocks",
      description: "10 Stocks"
    },
    {
      id: 4,
      title: "Ações",
      description: "10 Empresas"
    },
    {
      id: 5,
      title: "ETFs",
      description: "3 ETFs"
    },
    {
      id: 6,
      title: "Fundos Imobiliários",
      description: "10 FIIs"
    },
    {
      id: 7,
      title: "Renda fixa",
      description: "1 Investimento"
    }
  ],
  supportQuestions: [
    {
      id: 1,
      title: "O que é o Arkc?",
      description: "Somos um aplicativo de educação financeira que te permite aprender sobre investimentos de modo fácil e descomplicado. Nossa missão é tornar a educação financeira disponível para todos. Com lições rápidas e curtas, você aprende a cuidar do seu dinheiro enquanto se diverte.",
      descriptionSub: ""
    },
    {
      id: 2,
      title: "Como eu posso aprender com o Arkc?",
      description: `Conforme você avança nas lições, você ganha Arkcoins. Existem variados níveis em cada lição, você ganha 1 Arkcoin ao dominar uma lição e 1 Diamante ao concluir um desafio. Quer continuar a aprender mesmo depois de finalizar a lição? Sem problemas! É só tocar em "Play" para continuar aprendendo o conteúdo finalizado.`,
      descriptionSub: ""
    },
    {
      id: 3,
      title: "Quanto custa o Arkc?",
      description: "Você pode aprender a investir no Arkc completamente de graça. Você pode usá-lo no seu computador ou aplicativo mobile, sem custo, sem pegadinha.",
      descriptionSub: "Nós temos assinaturas disponíveis para os usuários do Arkc chamada Arkc Premium e Black. Com a assinatura você tem uma experiência livre de anúncios com acesso a conteúdos exclusivos de investimentos mais rentáveis ao longo prazo."
    },
    {
      id: 4,
      title: "Posso investir pelo Arkc?",
      description: "Não, o Arkc tem como objetivo trazer educação financeira aos nossos usuários, todos os investimentos são realizados através de bancos e corretoras parceiros ou de sua escolha.",
      descriptionSub: ""
    },
    {
      id: 5,
      title: "O Arkc recomenda investimentos?",
      description: "Não, as carteiras do Arck tem caráter exclusivamente educativo, sua finalidade é mostrar os benefícios que uma diversificação bem feita em bons ativos pode trazer ao longo do tempo, os ativos relacionados às carteiras não são recomendação de investimento, estude antes de realizar qualquer tipo de investimento ou procure um profissional credenciado para te auxiliar, caso necessário.",
      descriptionSub: ""
    },
    {
      id: 6,
      title: "E se eu não gostar do Arkc?",
      description: "O Arkc é uma plataforma de educação financeira gratuita, use quando e por quanto tempo você quiser.",
      descriptionSub: ""
    },
  ],
  informationsOne: [
    {
      id: 1,
      title: "Metodologia Simples",
      description: "Domine o mundo dos investimentos mesmo sem conhecimento prévio. Chega de passar horas vendo vídeos aleatórios e difíceis que vão acabar te confundindo.",
      icon: require("../../assets/metodologia-simples.png"),
      color: "#323232",
    },
    {
      id: 2,
      title: "Feito para você",
      description: "Descubra o melhor caminho para investir de acordo com sua experiência e objetivos, graças à nossa metodologia que utiliza Inteligência Artificial treinada.",
      icon: require("../../assets/feito-voce.png"),
      color: "#323232",
    },
    {
      id: 3,
      title: "Cabe no seu bolso",
      description: "Investimentos a partir de R$10/mês, com potencial de render até 5x mais que a poupança!",
      icon: require("../../assets/cabe-bolso.png"),
      color: "#323232",
    },
  ],
  informationsTwo: [
    {
      id: 1,
      title: "Aprenda se divertindo",
      description: "Com nossa plataforma 100% gamificada, você absorverá o máximo de conteúdo enquanto se diverte!",
      icon: require("../../assets/aprenda-divertindo.png"),
      color: "#323232",
    },
    {
      id: 2,
      title: "Clareza e Confiança",
      description: "Total transparência: o que você vê é o que você leva.",
      icon: require("../../assets/clareza-confianca.png"),
      color: "#323232",
    },
    {
      id: 3,
      title: "100% Seguro e ",
      description: "Selecionamos apenas os investimentos que possuem alto potencial de valorização, histórico com renda passiva significativa e valorização considerável.",
      icon: require("../../assets/seguro.png"),
      color: "#323232",
    },
  ],
  graphicImage: "https://firebasestorage.googleapis.com/v0/b/arkc-9f40e.appspot.com/o/Pagina%20de%20Vendas%20Arkc%20Black%2Fgrafico-rentabilidade-arkc-black.png?alt=media&token=8ee797f1-f9e8-4dd5-a037-ea457ec8e2dc"
};

export {
  arkcOne,
  arkcTwo,
  arkcThree,
  arkcFour,
  arkcBlack
};