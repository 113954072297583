import { Breadcrumb } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { FaLock, FaPlay } from 'react-icons/fa';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import Header from '../Header';
import { replay } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import styles from './LessonDetailForm.module.less';

const LessonDetailForm = ({ makeChallenge, doWork, lessonDetails, percentage, lessonListDetails, loading }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  const renderButtonsStatus = (item) => {
    if (item.available && !item.completed) {
      return (
        <button className="arkc-btn-link arkc-btn-play" onClick={() => item.type === "LESSON" ? doWork(item.identifier, item.type) : makeChallenge(item)}>
          <FaPlay className="i-16 white" />
        </button>
      );
    } else if (item.completed && item.available) {
      return (
        <button className="arkc-btn-link arkc-btn-replay" onClick={() => item.type === "LESSON" ? doWork(item.identifier, item.type) : makeChallenge(item)}>
          <img src={replay} alt="replay" />
        </button>
      );
    } else {
      return (
        <button className="arkc-btn-link arkc-btn-blocked">
          <FaLock className="i-16 silver" />
        </button>
      );
    };
  };

  return (
    <div className={cx(styles.bottom, { "mt-24": !isMobile })}>
      {isMobile ? (
        <div className={styles.bgMobile} style={{ background: lessonDetails?.color }}>
          <div className="container">
            <div className="content">
              <div className="flex flex-row justify-between item-center mt-24">
                <div onClick={() => history.push(ROUTES.DASHBOARD)}>
                  <FiArrowLeft className="i-24 mine-shaft" />
                </div>
                {percentage >= 0 && (
                  <div className={styles.bgPercentage}>
                    <div className="flex justify-center item-center pt-4 pb-4">
                      <span className="f12 b white">{`${percentage}%`}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-column justify-center item-center mt-32">
            {!loading && (
              <img src={lessonDetails?.logo} alt="logo-lesson" />
            )}
            <h6 className="mt-32 mb-4">{lessonDetails?.name}</h6>
            {lessonDetails?.level && (
              <span className="f14 dusty-gray">{`Nível ${lessonDetails?.level}`}</span>
            )}
          </div>
        </div>
      ) : (
        <>
          <Header route={ROUTES.DASHBOARD} />
          <hr className="divider-internal mt-24 mb-38" />
        </>
      )}
      <div className="container">
        <div className="content">
          {isMobile ? (
            <>
              <div className="mt-24">
                <Breadcrumb separator="">
                  <Breadcrumb.Item>Inicio</Breadcrumb.Item>
                  <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
                  <Breadcrumb.Item>{lessonDetails?.name}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="w-100 mt-24">
                <h3>Lições</h3>
                <div className="mt-16">
                  {_map(lessonListDetails, (item, index) => {
                    if (item.type === "LESSON") {
                      return (
                        <div key={index} className={cx("flex flex-row justify-between item-center", { "mb-24": index !== _size(lessonListDetails) - 1 })}>
                          <div className="flex flex-row item-center">
                            <div className={cx({
                              [styles.bgPhotoBlock]: !item.available,
                              [styles.bgPhoto]: item.available
                            })}
                              style={{ background: item.color }}
                            >
                              <div className="flex justify-center item-center h-100">
                                <img src={item.logo} alt="logo-subLessons" className={cx("i-32", { "o-4": !item.available })} />
                              </div>
                            </div>
                            <span className={cx("f16", { "dusty-gray": !item.available, "mine-shaft": item.available })}>{`${item.order}. ${item.name}`}</span>
                          </div>
                          <div>
                            {renderButtonsStatus(item)}
                          </div>
                        </div>
                      );
                    };

                    return (
                      <div key={index}>
                        <hr className={cx("divider mb-24", styles.challenge)} />
                        <div key={index} className="flex flex-row justify-between item-center">
                          <div className="flex flex-row item-center">
                            <img src={item.logo} alt="logo-subLesson" className={cx(styles.iconChallenge, { "o-4": !item.available })} />
                            <span className={cx("f24 ml-16", { "dusty-gray": !item.available, "mine-shaft": item.available })}>{item.name}</span>
                          </div>
                          <div>
                            {renderButtonsStatus(item)}
                          </div>
                        </div>
                        <hr className={cx("divider mt-24", styles.challenge)} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <>
              <Breadcrumb separator="">
                <Breadcrumb.Item>Inicio</Breadcrumb.Item>
                <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
                <Breadcrumb.Item>{lessonDetails?.name}</Breadcrumb.Item>
              </Breadcrumb>
              <div className={cx("flex", { "flex-column mt-24": isTablet, "flex-row mt-24": !isTablet })}>
                <div className={cx({ "flex justify-center item-center mb-24": isTablet, "mr-48": !isTablet })}>
                  {!loading && (
                    <>
                      <div className="relative">
                        <div className={styles.positionPercentage}>
                          {percentage >= 0 && (
                            <div className={styles.bgPercentage}>
                              <div className="flex justify-center item-center pt-6 pb-6">
                                <span className="f16 b white">{`${percentage}%`}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.featuredBackground} style={{ background: lessonDetails?.color }}>
                        <div className={styles.paddingLesson}>
                          <img src={lessonDetails?.logo} alt="logo-lessons" className="mb-54" />
                          <h2 className={cx("mb-16", styles.titleName)}>{lessonDetails?.name}</h2>
                          {lessonDetails?.level && (
                            <span className="f16 dusty-gray">{`Nível ${lessonDetails?.level}`}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="w-100">
                  <h3>Lições</h3>
                  <div className="mt-24">
                    {_map(lessonListDetails, (item, index) => {
                      if (item.type === "LESSON") {
                        return (
                          <div key={index} className={cx("flex flex-row justify-between item-center", { "mb-24": index !== _size(lessonListDetails) - 1 })}>
                            <div className="flex flex-row item-center">
                              <div className={cx({
                                [styles.bgPhotoBlock]: !item.available,
                                [styles.bgPhoto]: item.available
                              })}
                                style={{ background: item.color }}
                              >
                                <div className="flex justify-center item-center h-100">
                                  <img src={item.logo} alt="sub-lesson" className={cx("i-32", { "o-4": !item.available })} />
                                </div>
                              </div>
                              <span className={cx("f16", { "dusty-gray": !item.available, "mine-shaft": item.available })}>{`${item.order}. ${item.name}`}</span>
                            </div>
                            <div>
                              {renderButtonsStatus(item)}
                            </div>
                          </div>
                        );
                      };

                      return (
                        <div key={index}>
                          <hr className={cx("divider mb-24", styles.challenge)} />
                          <div key={index} className="flex flex-row justify-between item-center">
                            <div className="flex flex-row item-center">
                              <img src={item.logo} alt="sub-lesson" className={cx(styles.iconChallenge, { "o-4": !item.available })} />
                              <span className={cx("f24 ml-16", { "dusty-gray": !item.available, "mine-shaft": item.available })}>{item.name}</span>
                            </div>
                            <div>
                              {renderButtonsStatus(item)}
                            </div>
                          </div>
                          <hr className={cx("divider mt-24", styles.challenge)} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonDetailForm;