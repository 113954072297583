import { useEffect, useState } from 'react';
import ArkcBeginner from 'components/ArkcPlansForms/ArkcBeginner';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { arkcBeginner } from 'mock';

const Beginner = (props) => {
  const [idPlan, setIdPlan] = useState();
  const [inviteId, setInviteId] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const { state } = props?.history?.location;

    if (state && state.id && state.invite) {
      setIdPlan(state.id);
      setInviteId(state.invite);
    };
  }, []);

  const handleViewWallet = () => {
    history.push(ROUTES.DASHBOARD_WALLET, { id: idPlan, invite: inviteId });
  };

  return (
    <ArkcBeginner
      benefits={arkcBeginner.benefits}
      planBeginner={arkcBeginner.planBeginner}
      worksBeginner={arkcBeginner.worksBeginner}
      graphicImage={arkcBeginner.graphicImage}
      viewWallet={handleViewWallet}
    />
  );
};

export default Beginner;