import { useEffect } from 'react';
import LandingPagePremiumForm from '../../../components/LandingPageForm/LandingPagePremiumForm';
import { arkcPremium } from '../../../mock';

const LandingPagePremium = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBuyPlan = () => {
    window.open("https://pay.kiwify.com.br/xjDdl9o", "_blank");
  };

  return (
    <div>
      <LandingPagePremiumForm
        benefits={arkcPremium.benefits}
        worksPremium={arkcPremium.worksPremium}
        planPremium={arkcPremium.planPremium}
        graphicImage={arkcPremium.graphicImage}
        price={29.90}
        buyPlan={handleBuyPlan}
      />
    </div>
  );
};

export default LandingPagePremium;