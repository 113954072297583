import { Breadcrumb, Button } from 'antd';
import cx from 'classnames';
import useMedia from 'use-media';
import _get from 'lodash/get';
import Header from '../Header';
import ModalSimple from './components/ModalSimple';
import ModalEmail from './components/ModalEmail';
import ModalPhone from './components/ModalPhone';
import ModalPassword from './components/ModalPassword';
import { ROUTES } from 'routes/routes';
import { iconProfile, iconPassword, arkcEdit } from 'assets';
import styles from './ProfileForm.module.less';

const ProfileForm = (props) => {
  const {
    loading,
    actualUser,
    visibleSimple,
    visibleEmail,
    visiblePhone,
    visiblePassword,
    formSimple,
    formEmail,
    formPhone,
    formPassword,
    setLoading,
    setVisibleSimple,
    setVisibleEmail,
    setVisiblePhone,
    setVisiblePassword,
    closeSimple,
    closePassword,
    closePhone,
    closeEmail,
    deleteMyAccount,
    updateMyProfile
  } = props;
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className={cx({ "mb-32": isMobile, "mb-140": !isMobile })}>
      <div className="mt-24 mb-24">
        <Header route={ROUTES.DASHBOARD} />
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <Breadcrumb separator="">
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Perfil</Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex flex-column justify-center item-center mt-24">
            <img src={iconProfile} alt="icon-profile" />
            {isMobile ? (
              <h2 className="mt-16 mb-40">Perfil</h2>
            ) : (
              <h4 className="mt-16 mb-40">Perfil</h4>
            )}
            <div className={cx("mb-16", styles.badgeProfile)}>
              <div className="flex flex-row justify-between pt-16 pb-16 pl-24 pr-24">
                <div className="flex flex-column">
                  <span className="f16 mine-shaft">Nome</span>
                  <span className="f16 heliotrope b">{_get(actualUser, "name", "")}</span>
                </div>
                <div className="flex justify-center item-center pointer" onClick={() => setVisibleSimple(!visibleSimple)}>
                  <img src={arkcEdit} alt="edit-name" />
                </div>
              </div>
            </div>
            <div className={cx("mb-16", styles.badgeProfile)}>
              <div className="flex flex-row justify-between pt-16 pb-16 pl-24 pr-24">
                <div className="flex flex-column">
                  <span className="f16 mine-shaft">E-mail</span>
                  <span className="f16 heliotrope b">{_get(actualUser, "email", "")}</span>
                </div>
                <div className="flex justify-center item-center pointer" onClick={() => setVisibleEmail(!visibleEmail)}>
                  <img src={arkcEdit} alt="edit-email" />
                </div>
              </div>
            </div>
            <div className={cx("mb-16", styles.badgeProfile)}>
              <div className="flex flex-row justify-between pt-16 pb-16 pl-24 pr-24">
                <div className="flex flex-column">
                  <span className="f16 mine-shaft">Telefone</span>
                  <span className="f16 heliotrope b">{_get(actualUser, "phone", "")}</span>
                </div>
                <div className="flex justify-center item-center pointer" onClick={() => setVisiblePhone(!visiblePhone)}>
                  <img src={arkcEdit} alt="edit-email" />
                </div>
              </div>
            </div>
            <div className={styles.badgeProfile}>
              <div className="flex flex-row justify-between pt-16 pb-16 pl-24 pr-24">
                <div className="flex flex-column">
                  <span className="f16 mine-shaft">Senha</span>
                  <img src={iconPassword} alt="password-edit" className="mt-8" />
                </div>
                <div className="flex justify-center item-center pointer" onClick={() => setVisiblePassword(!visiblePassword)}>
                  <img src={arkcEdit} alt="edit-password" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center item-center mt-64">
            <Button type="default" className={styles.btnDelete} onClick={deleteMyAccount}>Deletar conta</Button>
          </div>
        </div>
      </div>
      <ModalSimple
        loading={loading}
        formSimple={formSimple}
        visibleSimple={visibleSimple}
        isMobile={isMobile}
        setLoading={setLoading}
        closeSimple={closeSimple}
        updateMyProfile={updateMyProfile}
      />
      <ModalEmail
        loading={loading}
        isMobile={isMobile}
        formEmail={formEmail}
        visibleEmail={visibleEmail}
        setLoading={setLoading}
        updateMyProfile={updateMyProfile}
        closeEmail={closeEmail}
      />
      <ModalPhone
        loading={loading}
        formPhone={formPhone}
        visiblePhone={visiblePhone}
        isMobile={isMobile}
        setLoading={setLoading}
        closePhone={closePhone}
        updateMyProfile={updateMyProfile}
      />
      <ModalPassword
        loading={loading}
        visiblePassword={visiblePassword}
        formPassword={formPassword}
        isMobile={isMobile}
        setLoading={setLoading}
        updateMyProfile={updateMyProfile}
        closePassword={closePassword}
      />
    </div>
  );
};

export default ProfileForm;