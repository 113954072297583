import { Modal, Button, Input, Form } from 'antd';
import { FiX } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import styles from '../../ProfileForm.module.less';

const ModalPhone = ({ closePhone, updateMyProfile, setLoading, formPhone, isMobile, visiblePhone, loading }) => {
  return (
    <Modal
      visible={visiblePhone}
      footer={null}
      onCancel={closePhone}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={closePhone}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center mt-32">
        {isMobile ? (
          <h2 className="text-center mb-40">Editar informações</h2>
        ) : (
          <h4 className="text-center mb-40">Editar informações</h4>
        )}
        <Form
          name="edit-phone"
          layout="vertical"
          form={formPhone}
          onFinish={updateMyProfile}
          onFinishFailed={() => setLoading(false)}
          autoComplete="off"
        >
          <Form.Item
            name="phone"
            rules={[{ required: true, message: 'O telefone é obrigatório.' }]}
            className="mb-12"
          >
            <InputMask
              mask="(99) 99999-9999"
              maskChar={null}
              name="phone"
              placeholder="Digite seu telefone"
              disabled={loading ? true : false}
              className="input-base"
            >
              {(inputProps) => <Input  {...inputProps} disabled={loading ? true : false} />}
            </InputMask>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit" className={styles.btnSave} onClick={() => setLoading(true)} loading={loading}>
              Salvar alteração
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalPhone;