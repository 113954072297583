import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification, Form } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import _get from 'lodash/get';
import Loading from 'components/Loading';
import ProfileForm from 'components/ProfileForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [actualUser, setActualUser] = useState({});
  const [visibleSimple, setVisibleSimple] = useState(false);
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [visiblePhone, setVisiblePhone] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [formSimple] = Form.useForm();
  const [formEmail] = Form.useForm();
  const [formPhone] = Form.useForm();
  const [formPassword] = Form.useForm();
  const { user } = useStore();
  const { userUpdateProfile, userProfile } = user;

  useEffect(() => {
    retrieveUserProfile()
  }, []);

  const retrieveUserProfile = async () => {
    setLoadingPage(true);

    const response = await userProfile();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const phone = _get(response.data, "phone", "");
      const phoneMask = phone.replace(/\D/g, "").replace(/^(\d{2})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");

      setActualUser({ ...response.data, phone: phoneMask });
      setLoadingPage(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoadingPage(false);
    };
  };

  const handleCloseSimple = () => {
    setVisibleSimple(!visibleSimple);
  };

  const handleClosePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const handleCloseEmail = () => {
    setVisibleEmail(!visibleEmail);
  };

  const handleClosePhone = () => {
    setVisiblePhone(!visiblePhone);
  };

  const handleCloseDelete = () => {
    setVisibleDelete(!visibleDelete);
  };

  const updateMyProfile = async (value) => {
    let newValue = value;

    if (newValue.phone) {
      newValue = { phone: _get(newValue, "phone", "").replace('(', '').replace(')', '').replace(' ', '').replace('-', '') };
    };

    const response = await userUpdateProfile(actualUser?.id, newValue);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      if (value.name) {
        setVisibleSimple(false);
        formSimple.resetFields();
        notification.success({
          message: message.title_name_update,
          description: message.name_description_update,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      } else if (value.email) {
        setVisibleEmail(false);
        formEmail.resetFields();
        notification.success({
          message: message.title_update,
          description: message.email_description_update,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      } else if (value.phone) {
        setVisiblePhone(false);
        // formPhone.resetFields();
        notification.success({
          message: message.title_update,
          description: message.email_description_update,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      } else if (value.password) {
        setVisiblePassword(false);
        formPassword.resetFields();
        notification.success({
          message: message.title_update,
          description: message.password_description_update,
          placement: "bottom",
          duration: 4,
          closeIcon: <FiX className="i-24 white" />
        });
      };

      setLoading(false);
      retrieveUserProfile();
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };

  const handleDeleteMyAccount = () => {
    history.push(ROUTES.DELETE_ACCOUNT);
  };

  return (
    <div>
      {loadingPage ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <>
          <ProfileForm
            loading={loading}
            actualUser={actualUser}
            visibleSimple={visibleSimple}
            visibleEmail={visibleEmail}
            visiblePhone={visiblePhone}
            visiblePassword={visiblePassword}
            visibleDelete={visibleDelete}
            formSimple={formSimple}
            formEmail={formEmail}
            formPhone={formPhone}
            formPassword={formPassword}
            setLoading={setLoading}
            setVisibleSimple={setVisibleSimple}
            setVisibleEmail={setVisibleEmail}
            setVisiblePhone={setVisiblePhone}
            setVisiblePassword={setVisiblePassword}
            setVisibleDelete={setVisibleDelete}
            closeSimple={handleCloseSimple}
            closePhone={handleClosePhone}
            closePassword={handleClosePassword}
            closeEmail={handleCloseEmail}
            closeDelete={handleCloseDelete}
            deleteMyAccount={handleDeleteMyAccount}
            updateMyProfile={updateMyProfile}
          />
          <Footer />
        </>
      )}
    </div>
  );
};

export default observer(Profile);