import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../TrailLessonForm.module.less';

const QuestionImageItems = ({ nextTrail, selectSubOption, selectOption, subChoiceSelected, choiceSelected, title, questionsItem, index, logo, name, image }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayButton(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [displayButton]);

  return (
    <div key={index}>
      {isMobile || isTablet ? (
        <div className="container">
          <div className="content">
            <div className="flex flex-column justify-center item-center">
              {!isMobile && (
                <div className={styles.bgQuestion}>
                  <div className="flex flex-row item-center p-16">
                    <img src={logo} alt="logo-question" className="i-32" />
                    <span className="f18 b mine-shaft ml-16">{name}</span>
                  </div>
                </div>
              )}
              <div className={cx({ "mt-32": !isMobile })}>
                <img src={image} alt="" className={styles.imageSize} />
              </div>
              <h5 className={cx("text-center", styles.titleImage)}>{title}</h5>
              <div className="mt-24">
                {_map(questionsItem, (item, index) => (
                  <button
                    key={index}
                    className={cx("arkc-btn-link flex item-center", styles.bagde, {
                      "mb-12": index !== _size(questionsItem) - 1, [styles.bagdeSelect]: choiceSelected?.identifier === item.identifier
                    })}
                    onClick={() => selectOption(item)}
                  >
                    <span className="f16 b heliotrope pl-24 pr-24">{item.name}</span>
                  </button>
                ))}
              </div>
              {choiceSelected?.choices && _size(choiceSelected.choices) > 0 && (
                <div className="mt-24">
                  <p className="f16 mine-shaft mb-8">Selecionar uma das opções abaixo:</p>
                  {_map(choiceSelected.choices, (item, index) => (
                    <button
                      key={index}
                      className={cx("arkc-btn-link flex item-center", styles.bagde, {
                        "mb-12": index !== _size(choiceSelected.choices) - 1, [styles.bagdeSelect]: subChoiceSelected?.identifier === item.identifier
                      })}
                      onClick={() => selectSubOption(item)}
                    >
                      <span className="f16 b heliotrope pl-24 pr-24">{item.name}</span>
                    </button>
                  ))}
                </div>
              )}
              {displayButton && (
                <>
                  <hr className="divider-internal mt-24 mb-16" />
                  <Button
                    type="primary"
                    disabled={choiceSelected?.choices ? !subChoiceSelected : !choiceSelected}
                    className={cx("flex justify-center item-center", styles.btnContinue)}
                    onClick={nextTrail}
                  >
                    Continuar
                    <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-column justify-center item-center mb-16">
          <div className="flex flex-row justify-center">
            <div className={styles.spaceImage}>
              <div className={styles.bgQuestion}>
                <div className="flex flex-row item-center p-16">
                  <img src={logo} alt="logo-question" className="i-32" />
                  <span className="f18 b mine-shaft ml-16">{name}</span>
                </div>
              </div>
              <h5 className={styles.titleImage}>{title}</h5>
              <div className="mt-32">
                {_map(questionsItem, (item, index) => (
                  <button
                    key={index}
                    className={cx("arkc-btn-link flex item-center", styles.bagde, {
                      "mb-12": index !== _size(questionsItem) - 1, [styles.bagdeSelect]: choiceSelected?.identifier === item.identifier
                    })}
                    onClick={() => selectOption(item)}
                  >
                    <span className="f16 b heliotrope pl-24 pr-24">{item.name}</span>
                  </button>
                ))}
              </div>
              {choiceSelected?.choices && _size(choiceSelected.choices) > 0 && (
                <div className="mt-24">
                  <p className="f16 mine-shaft mb-8">Seleciona um dos itens abaixo:</p>
                  {_map(choiceSelected.choices, (item, index) => (
                    <button
                      key={index}
                      className={cx("arkc-btn-link flex item-center", styles.bagde, {
                        "mb-12": index !== _size(choiceSelected.choices) - 1, [styles.bagdeSelect]: subChoiceSelected?.identifier === item.identifier
                      })}
                      onClick={() => selectSubOption(item)}
                    >
                      <span className="f16 b heliotrope pl-24 pr-24">{item.name}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div>
              <img src={image} alt="" className={styles.imageSize} />
            </div>
          </div>
          {displayButton && (
            <>
              <hr className="divider-internal mt-56 mb-16" />
              <Button
                type="primary"
                disabled={choiceSelected?.choices ? !subChoiceSelected : !choiceSelected}
                className={cx("flex justify-center item-center", styles.btnContinue)}
                onClick={nextTrail}
              >
                Continuar
                <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionImageItems;