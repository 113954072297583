import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _forEach from 'lodash/forEach';
import moment from 'moment';
import Loading from 'components/Loading';
import SignaturesForm from 'components/SignaturesForm';
import Footer from 'components/Footer';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const Signatures = (props) => {
  const [loading, setLoading] = useState(true);
  const [signaturesData, setSignaturesData] = useState([]);
  const [inviteId, setInviteId] = useState();
  const [visible, setVisible] = useState(false);
  const { plansArkc } = useStore();
  const { cancelSubscription, activateSubscriptions, disableSubscriptions, mySubscriptions } = plansArkc;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.invite) {
      setInviteId(state.invite);
      retrieveMySignatures();
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveMySignatures = async () => {
    setLoading(true);

    const response = await mySubscriptions();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const newResponse = [];

      _forEach(response.data, (item) => {
        if (item.status !== 2 && item.status !== 3) {
          const actual = {
            id: item.id,
            wallet: item?.plan?.provisioning?.wallet,
            plan: item?.plan?.identifier,
            price: item?.plan?.identifier === "arkc-beginner" ? "Grátis" : item?.plan?.pricing?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }),
            date: moment(item?.paymentData?.expirationDate).format("YYYY-MM-DD"),
            renovation: item?.paymentData?.automaticRenewal,
            status: item.status === 1 ? true : false
          };

          newResponse.push(actual);
        }
      });

      setSignaturesData(newResponse);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleActivateRenewal = async (id) => {
    const response = await activateSubscriptions(id);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      retrieveMySignatures();
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };

  const handleDisableRenewal = async (id) => {
    const response = await disableSubscriptions(id);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      retrieveMySignatures();
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };

  const handleCancelSubscription = async (id) => {
    const response = await cancelSubscription({ contract: id });

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      notification.success({
        message: message.cancel_title,
        description: message.cancel_description_generic,
        placement: "bottom",
        duration: 6,
        closeIcon: <FiX className="i-24 white" />
      });
      retrieveMySignatures();
      setVisible(false);
    } else {
      setVisible(false);
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
    };
  };

  const handleViewBeginner = (wallet) => {
    history.push(ROUTES.DASHBOARD_WALLET, { id: wallet, invite: inviteId })
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <>
          <SignaturesForm
            loading={loading}
            signaturesData={signaturesData}
            visible={visible}
            setVisible={setVisible}
            activateRenewal={handleActivateRenewal}
            disableRenewal={handleDisableRenewal}
            cancelSubscription={handleCancelSubscription}
            viewBeginner={handleViewBeginner}
          />
          <Footer />
        </>
      )}
    </div>
  );
};

export default observer(Signatures);