import { useEffect } from 'react';
import ReactGA from 'react-ga';
import LandingPageOneForm from 'components/LandingPageForm/LandingPageOneForm';
import Footer from 'components/Footer';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { arkcOne } from 'mock/data';
import { topicsLP } from 'mock';

const LandingPageOne = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStartNow = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })

    history.push(ROUTES.LEVELING);
  };

  return (
    <div>
      <LandingPageOneForm
        arkcOne={arkcOne}
        benefits={arkcOne.benefits}
        worksBeginner={arkcOne.worksBeginner}
        graphicImage={arkcOne.graphicImage}
        topicsLP={topicsLP}
        supportQuestions={arkcOne.supportQuestions}
        lessons={arkcOne.lessons}
        startNow={handleStartNow}
      />
      <Footer />
    </div>
  );
};

export default LandingPageOne;