import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import HeaderHome from 'components/HomeForms/HeaderHome';
import Mission from 'components/HomeForms/Mission';
import FunSimplicity from 'components/HomeForms/FunSimplicity';
import Portfolios from 'components/HomeForms/Portfolios';
import LoginForm from 'components/LoginForm';
import Footer from 'components/Footer';
import { ROUTES } from 'routes/routes';
import { history } from 'helpers/history';
import { topicsLP } from 'mock';

const Home = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { login } = useStore();
  const { authUserGoogle, authUserFacebook, authUser } = login;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.topic === "home") {
      window.scrollTo(0, 0);
    } else if (state && state.topic === "about") {
      window.scrollTo(0, 700);
    };
  }, []);

  const handleOpenLogin = () => {
    setVisible(!visible);
  };

  const handleUserLogin = async (params) => {
    const response = await authUser(params);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      history.push(ROUTES.DASHBOARD);
      setLoading(false);
    } else if (response.status === 404) {
      notification.error({
        message: message.was_problem,
        description: message.not_found_description_login,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleUserLoginFacebook = async (body) => {
    const response = await authUserFacebook(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      history.push(ROUTES.DASHBOARD);
      setLoading(false);
    } else if (response.status === 404) {
      notification.error({
        message: message.was_problem,
        description: message.not_found_description_login,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleUserLoginGoogle = async (body) => {
    const response = await authUserGoogle(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      history.push(ROUTES.DASHBOARD);
      setLoading(false);
    } else if (response.status === 404) {
      notification.error({
        message: message.was_problem,
        description: message.not_found_description_login,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleStartNow = () => {
    history.push(ROUTES.LEVELING);
  };

  const handleClick = (param) => {
    if (param === "home") {
      window.scrollTo(0, 0);
    } else if (param === "about") {
      window.scrollTo(0, 700);
    };
  };

  return (
    <div>
      <HeaderHome
        startNow={handleStartNow}
        openLogin={handleOpenLogin}
      />
      <div className="container">
        <div className="content-lp">
          <Mission />
          <FunSimplicity startGo={handleStartNow} />
        </div>
      </div>
      <Portfolios topics={topicsLP} startGo={handleStartNow} />
      <Footer
        isHome={true}
        clickPage={handleClick}
      />
      <LoginForm
        loading={loading}
        visible={visible}
        setLoading={setLoading}
        setVisible={setVisible}
        login={handleUserLogin}
        loginFacebook={handleUserLoginFacebook}
        loginGoogle={handleUserLoginGoogle}
      />
    </div>
  );
};

export default observer(Home);