import { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { FaLock, FaPlay } from 'react-icons/fa';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import _map from 'lodash/map';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from './Lessons.module.less';
import "@egjs/flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";

const Lessons = ({ goLesson, lessonsList }) => {
  const [plugins, setPlugins] = useState([]);
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });

  useEffect(() => {
    setPlugins([new Pagination({ type: 'bullet' })]);
  }, []);

  return (
    <div className={styles.negativeMargin}>
      <div className="container">
        <div className="content">
          <h4 className="mb-36 mine-shaft">Trilha de lições</h4>
        </div>
      </div>
      {(isMobile || isTablet) ? (
        <div className="flicking-view-lesson">
          <Flicking circular={true} plugins={plugins}>
            {_map(lessonsList, (item, index) => {
              if (item.available) {
                return (
                  <div key={index} className={styles.cardEmphasis}>
                    <button className="arkc-btn-link mr-16" onClick={() => goLesson(item.id)}>
                      <div className={styles.cardLessons}>
                        <div className="p-12 h-100">
                          {(item.available && item.total >= 0) && (
                            <div className="relative">
                              <div className={styles.positionPercentage}>
                                <div className={styles.bgPercentage}>
                                  <div className="flex justify-center item-center">
                                    <span className="f12 b white">{`${item.total}%`}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className={styles.bgImage} style={{ background: item.color }}>
                            <div className="flex justify-center item-center h-100">
                              <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                            </div>
                          </div>
                          <div className="flex flex-column justify-center item-center">
                            <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                            <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                            <button className="arkc-btn-link arkc-btn-play">
                              <FaPlay className="i-16 white" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                );
              };

              return (
                <div key={index} className={styles.cardEmphasis}>
                  <div className={cx(styles.cardLessons, "mr-16")}>
                    <div className="p-12 h-100">
                      {(item.available && item.total >= 0) && (
                        <div className="relative">
                          <div className={styles.positionPercentage}>
                            <div className={styles.bgPercentage}>
                              <div className="flex justify-center item-center">
                                <span className="f12 b white">{`${item.total}%`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className={styles.bgImage} style={{ background: item.color }}>
                        <div className="flex justify-center item-center h-100">
                          <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                        </div>
                      </div>
                      <div className="flex flex-column justify-center item-center">
                        <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                        <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                        <Tooltip title="Lição bloqueada no momento" color="#8C52FF" overlayInnerStyle={{ borderRadius: 16, padding: 12 }}>
                          <button className="arkc-btn-link arkc-btn-blocked">
                            <FaLock className="i-16 silver" />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <ViewportSlot>
              <div className="flicking-pagination"></div>
            </ViewportSlot>
          </Flicking>
        </div>
      ) : (
        <div className="container">
          <div className="content">
            <div className={styles.gapLessons}>
              {_map(lessonsList, (item, index) => {
                if (item.available) {
                  return (
                    <div key={index} className={styles.cardEmphasis}>
                      <button className="arkc-btn-link" onClick={() => goLesson(item.id)}>
                        <div className={styles.cardLessons}>
                          <div className="p-12 h-100">
                            {(item.available && item.total >= 0) && (
                              <div className="relative">
                                <div className={styles.positionPercentage}>
                                  <div className={styles.bgPercentage}>
                                    <div className="flex justify-center item-center">
                                      <span className="f12 b white">{`${item.total}%`}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className={styles.bgImage} style={{ background: item.color }}>
                              <div className="flex justify-center item-center h-100">
                                <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                              </div>
                            </div>
                            <div className="flex flex-column justify-center item-center">
                              <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                              <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                              <button className="arkc-btn-link arkc-btn-play">
                                <FaPlay className="i-16 white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  );
                };

                return (
                  <div key={index} className={styles.cardEmphasis}>
                    <div className={styles.cardLessons}>
                      <div className="p-12 h-100">
                        {(item.available && item.total >= 0) && (
                          <div className="relative">
                            <div className={styles.positionPercentage}>
                              <div className={styles.bgPercentage}>
                                <div className="flex justify-center item-center">
                                  <span className="f12 b white">{`${item.total}%`}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className={styles.bgImage} style={{ background: item.color }}>
                          <div className="flex justify-center item-center h-100">
                            <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                          </div>
                        </div>
                        <div className="flex flex-column justify-center item-center">
                          <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                          <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                          <Tooltip title="Lição bloqueada no momento" color="#8C52FF" overlayInnerStyle={{ borderRadius: 16, padding: 12 }}>
                            <button className="arkc-btn-link arkc-btn-blocked">
                              <FaLock className="i-16 silver" />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lessons;