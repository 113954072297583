import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import LandingPageBlackForm from 'components/LandingPageForm/LandingPageBlackForm';
import Footer from 'components/Footer';
import { arkcBlack } from 'mock/data';
import { logoBlack } from 'assets';

ReactPixel.init("963609121887430", { autoConfig: true });

const LandingPageBlack = () => {
  useEffect(() => {
    ReactPixel.pageView();
    window.scrollTo(0, 0);
  }, []);

  const handleBuyPlan = () => {
    window.open("https://pay.kiwify.com.br/0fPYtPl", "_blank");
  };

  return (
    <div>
      <div className="bg-mine-shaft">
        <div className="background-black">
          <img src={logoBlack} alt="logo-black" className="image-black" />
        </div>
        <LandingPageBlackForm
          benefits={arkcBlack.benefits}
          peoples={arkcBlack.peoples}
          planBlack={arkcBlack.planBlack}
          supportQuestions={arkcBlack.supportQuestions}
          graphicImage={arkcBlack.graphicImage}
          informationsOne={arkcBlack.informationsOne}
          informationsTwo={arkcBlack.informationsTwo}
          price={49.90}
          buyPlan={handleBuyPlan}
        />
      </div>
      <Footer isDark={true} />
    </div>
  );
};

export default LandingPageBlack;