import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { challenge } from 'assets';
import styles from '../../TrailChallengeForm.module.less';

const IntroductionChallenge = ({ handleMyArea, changeNext, challengeIntro }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className={cx("flex flex-column justify-center item-center", { "mt-32 mb-60": isMobile, "mt-146": !isMobile })}>
      <div className="flex justify-center item-center relative w-100">
        <div className={styles.circularContent} />
      </div>
      <div className={cx({ "mb-24": isMobile, "mb-56": !isMobile })}>
        <img src={challengeIntro?.logo} alt="challenge" />
      </div>
      {isMobile ? (
        <h2 className={cx("mine-shaft text-center mb-16", styles.titleIntroduction)}>
          {challengeIntro?.name}
        </h2>
      ) : (
        <h4 className={cx("mine-shaft text-center mb-16", styles.titleIntroduction)}>
          {challengeIntro?.name}
        </h4>
      )}
      <p className={cx("f16 gray text-center", styles.descriptionIntroduction, { "mb-40": isMobile, "mb-32": !isMobile })}>
        {challengeIntro?.description}
      </p>
      <Button type="primary" className={styles.btnStart} onClick={() => changeNext("Challenges")}>
        Começar
      </Button>
      <button className={cx("arkc-btn-link flex justify-center item-center mt-24 mb-70", styles.btnJump)} onClick={handleMyArea}>
        <span className="heliotrope b">Pular desafio</span>
        <FiArrowRight className="heliotrope ml-12 i-16" />
      </button>
    </div>
  );
};

export default IntroductionChallenge;