import { useState } from 'react';
import { Progress, Dropdown, Menu } from 'antd';
import { FiX, FiMoreVertical } from 'react-icons/fi';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import QuestionSimple from './components/QuestionSimple';
import QuestionImage from './components/QuestionImage';
import QuestionImageItems from './components/QuestionImageItems';
import QuestionItems from './components/QuestionItems';
import CompletedModal from './components/CompletedModal';
import styles from './TrailLessonForm.module.less';

const TrailLessonForm = ({ backDetails, viewPlan, registerMyProgressChoices, registerMyProgress, setChoiceSelected, setSubChoiceSelected, choiceSelected, subChoiceSelected, lessonCompleted, lessonData }) => {
  const [questionsTrail, setQuestionsTrail] = useState(lessonData);
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  const renderTrailQuestions = (item, index) => {
    if (questionsTrail.actualQuestion === item.identifier && item.description && !item.image && !item.choices) {
      return (
        <QuestionSimple
          index={index}
          logo={questionsTrail.imageLesson}
          name={questionsTrail.nameLesson}
          title={item.description}
          nextTrail={() => handleNextTrail(item, item.next)}
        />
      );
    };

    if (questionsTrail.actualQuestion === item.identifier && item.description && item.image && !item.choices) {
      return (
        <QuestionImage
          index={index}
          logo={questionsTrail.imageLesson}
          name={questionsTrail.nameLesson}
          title={item.description}
          image={item.image}
          nextTrail={() => handleNextTrail(item, item.next)}
        />
      );
    };

    if (questionsTrail.actualQuestion === item.identifier && item.description && item.image && item.choices.length > 0) {
      return (
        <QuestionImageItems
          index={index}
          logo={questionsTrail.imageLesson}
          name={questionsTrail.nameLesson}
          title={item.description}
          image={item.image}
          questionsItem={item.choices}
          choiceSelected={choiceSelected}
          subChoiceSelected={subChoiceSelected}
          selectOption={handleSelectOption}
          selectSubOption={handleSelectSubOption}
          nextTrail={() => handleNextTrailChoices(item)}
        />
      );
    };

    if (questionsTrail.actualQuestion === item.identifier && item.description && item.choices.length > 0) {
      return (
        <QuestionItems
          index={index}
          logo={questionsTrail.imageLesson}
          name={questionsTrail.nameLesson}
          title={item.description}
          questionsItem={item.choices}
          choiceSelected={choiceSelected}
          subChoiceSelected={subChoiceSelected}
          selectOption={handleSelectOption}
          selectSubOption={handleSelectSubOption}
          nextTrail={() => handleNextTrailChoices(item)}
        />
      );
    };
  };

  const handleNextTrail = (item, next) => {
    const copyQuestionsTrail = Object.assign({}, questionsTrail);

    if (next !== "coin") {
      copyQuestionsTrail.actualQuestion = next;

      let totalLesson = 0;
      let percentageQuestion = (100 / _size(copyQuestionsTrail.trailQuestions)).toFixed(2);
      let numberQuestions = next;

      _forEach(copyQuestionsTrail.trailQuestions, (questions, index) => {
        const newIndex = index + 1;

        if (questions.identifier === numberQuestions) {
          if (questions.next !== "coin") {
            totalLesson = percentageQuestion * newIndex;
          } else {
            totalLesson = 100;
          };
        };
      });

      copyQuestionsTrail.percentage = totalLesson;

      setQuestionsTrail(copyQuestionsTrail);
      registerMyProgress(item);
    } else {
      copyQuestionsTrail.percentage = 100;
      setVisible(true);
      registerMyProgress(item);
    };
  };

  const handleSelectOption = (value) => {
    setChoiceSelected(value);
  };

  const handleSelectSubOption = (value) => {
    setSubChoiceSelected(value);
  };

  const handleNextTrailChoices = (item) => {
    const copyQuestionsTrail = Object.assign({}, questionsTrail);

    if (!subChoiceSelected) {
      if (choiceSelected?.next !== "coin") {
        copyQuestionsTrail.actualQuestion = choiceSelected.next;
        let totalLesson = 0;
        let percentageQuestion = (100 / _size(copyQuestionsTrail.trailQuestions)).toFixed(2);
        let numberQuestions = choiceSelected.next;

        _forEach(copyQuestionsTrail.trailQuestions, (questions, index) => {
          const newIndex = index + 1;

          if (questions.identifier === numberQuestions) {
            if (questions.next !== "coin") {
              totalLesson = percentageQuestion * newIndex;
            } else {
              totalLesson = 100;
            };
          };
        });

        copyQuestionsTrail.percentage = totalLesson;

        setQuestionsTrail(copyQuestionsTrail);
        registerMyProgressChoices(item, choiceSelected);
      } else {
        copyQuestionsTrail.percentage = 100;
        setVisible(true);
        registerMyProgressChoices(item, choiceSelected);
      };
    } else {
      if (choiceSelected?.next !== "coin" || subChoiceSelected?.next !== "coin") {
        copyQuestionsTrail.actualQuestion = subChoiceSelected ? subChoiceSelected.next : choiceSelected.next;
        let totalLesson = 0;
        let percentageQuestion = (100 / _size(copyQuestionsTrail.trailQuestions)).toFixed(2);
        let numberQuestions = subChoiceSelected ? subChoiceSelected.next : choiceSelected.next;

        _forEach(copyQuestionsTrail.trailQuestions, (questions, index) => {
          const newIndex = index + 1;

          if (questions.identifier === numberQuestions) {
            if (questions.next !== "coin") {
              totalLesson = percentageQuestion * newIndex;
            } else {
              totalLesson = 100;
            };
          }
        });

        copyQuestionsTrail.percentage = totalLesson;

        setQuestionsTrail(copyQuestionsTrail);
        registerMyProgressChoices(item, choiceSelected, subChoiceSelected);
      } else {
        copyQuestionsTrail.percentage = 100;
        setVisible(true);
        registerMyProgressChoices(item, choiceSelected, subChoiceSelected);
      };
    };
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={`${process.env.REACT_APP_ENVIRONMENT}/suporte-arkc`} target="_blank" rel="noopener">Reportar um erro</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="flex justify-center item-center mt-24 mb-24">
            {!isMobile && (
              <img src={logoColor} alt="logo-color" />
            )}
            {isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24 mr-8 pointer" />
                </Dropdown>
              </div>
            )}
            <div className="flex justify-center item-center w-100">
              <div className={cx("flex flex-row justify-center item-center", styles.progress)}>
                <Progress percent={questionsTrail?.percentage} showInfo={false} />
                <button className="flex arkc-btn-link ml-14 mt-2" onClick={backDetails}>
                  <FiX className="i-24" />
                </button>
              </div>
            </div>
            {!isMobile && (
              <div className="flex justify-end">
                <Dropdown overlay={menu}>
                  <FiMoreVertical className="i-24 pointer" />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="divider-internal" />
      <div className={cx("container", { "mt-28": isMobile, "mt-56": !isMobile })}>
        <div className="content">
          {_map(questionsTrail?.trailQuestions, (item, index) => {
            return (
              <div key={index}>
                {renderTrailQuestions(item, index)}
              </div>
            );
          })}
        </div>
      </div>
      <CompletedModal
        visible={visible}
        isMobile={isMobile}
        advertising={questionsTrail?.advertising}
        lesson={questionsTrail?.nameLesson}
        imageLesson={questionsTrail?.imageLesson}
        color={questionsTrail?.color}
        lessonCompleted={lessonCompleted}
        backDetails={backDetails}
        viewPlan={viewPlan}
      />
    </div>
  );
};

export default TrailLessonForm;