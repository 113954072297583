// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckoutForm_badgePlan__j50CX {\n  width: 358px;\n  border: 2px solid #F6F6F6;\n  border-radius: 12px;\n}\n.CheckoutForm_badgePlanPadding__BxB-z {\n  padding: 32px 24px 24px 24px;\n}\n.CheckoutForm_descriptionSize__P2obS {\n  width: 250px;\n}\n.CheckoutForm_badgePremium__rOtCS {\n  width: 186px;\n  background: rgba(253, 231, 139, 0.44);\n  -webkit-backdrop-filter: blur(12px);\n          backdrop-filter: blur(12px);\n  border-radius: 84px;\n}\n.CheckoutForm_badgeBlack__wt7Fw {\n  width: 186px;\n  background: #2F2F2F;\n  -webkit-backdrop-filter: blur(12px);\n          backdrop-filter: blur(12px);\n  border-radius: 84px;\n}\n.CheckoutForm_btnSelect__dwDnp {\n  height: 42px;\n  width: 100%;\n  border-radius: 12px;\n}\n@media only screen and (max-width: 767px) {\n  .CheckoutForm_badgePlan__j50CX {\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/CheckoutForm/CheckoutForm.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;EACA,mBAAA;AACF;AAEA;EACE,4BAAA;AAAF;AAGA;EACE,YAAA;AADF;AAIA;EACE,YAAA;EACA,qCAAA;EACA,mCAAA;UAAA,2BAAA;EACA,mBAAA;AAFF;AAKA;EACE,YAAA;EACA,mBAAA;EACA,mCAAA;UAAA,2BAAA;EACA,mBAAA;AAHF;AAMA;EACE,YAAA;EACA,WAAA;EACA,mBAAA;AAJF;AAOA;EACE;IACE,WAAA;EALF;AACF","sourcesContent":[".badgePlan {\n  width: 358px;\n  border: 2px solid #F6F6F6;\n  border-radius: 12px;\n}\n\n.badgePlanPadding {\n  padding: 32px 24px 24px 24px;\n}\n\n.descriptionSize {\n  width: 250px;\n}\n\n.badgePremium {\n  width: 186px;\n  background: rgba(253, 231, 139, 0.44);\n  backdrop-filter: blur(12px);\n  border-radius: 84px;\n}\n\n.badgeBlack {\n  width: 186px;\n  background: #2F2F2F;\n  backdrop-filter: blur(12px);\n  border-radius: 84px;\n}\n\n.btnSelect {\n  height: 42px;\n  width: 100%;\n  border-radius: 12px;\n}\n\n@media only screen and (max-width: 767px) {\n  .badgePlan {\n    width: 100%;\n  }\n}\n@primary-color: #8C52FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgePlan": "CheckoutForm_badgePlan__j50CX",
	"badgePlanPadding": "CheckoutForm_badgePlanPadding__BxB-z",
	"descriptionSize": "CheckoutForm_descriptionSize__P2obS",
	"badgePremium": "CheckoutForm_badgePremium__rOtCS",
	"badgeBlack": "CheckoutForm_badgeBlack__wt7Fw",
	"btnSelect": "CheckoutForm_btnSelect__dwDnp"
};
export default ___CSS_LOADER_EXPORT___;
