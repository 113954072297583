import { Modal, Button } from 'antd';
import { FiArrowRight, FiX } from 'react-icons/fi';
import cx from 'classnames';
import { arkcoins, gemsLarge } from 'assets';
import styles from '../../LessonChallengeForm.module.less';

const ChallengeModal = ({ handleMyArea, backDetails, isMobile, lessonCompleted, color, challengeName, imageLesson, level, visible }) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={backDetails}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="flex justify-center relative">
        <div className={styles.iconBg}>
          <div className={styles.bgDynamic} style={{ background: color }}>
            <div className="flex justify-center item-center h-100">
              <img
                src={imageLesson}
                alt="complete-icon"
                className={styles.iconModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={backDetails}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="flex flex-column justify-center item-center">
        <h5 className={styles.modalTitle}>Parabéns, a trilha <span className="b">{`${challengeName}`}</span> foi concluida!!</h5>
        <span className={cx("f16 mine-shaft b", { "mb-12": isMobile, "mb-16": !isMobile })}>Você ganhou</span>
        <div className={cx("flex flex-row justify-between item-center", styles.badgeModal, { "mb-8": isMobile, "mb-12": !isMobile })}>
          <div className="flex justify-center item-center ml-24">
            <img src={arkcoins} alt="arkcoins" className="i-24" />
            <span className="f14 black b ml-12">Arkcoins</span>
          </div>
          <div className="mr-24">
            <span className="f24 b heliotrope">{!lessonCompleted ? `+ ${10}` : `${0}`}</span>
          </div>
        </div>
        <div className={cx("flex flex-row justify-between item-center mb-12", styles.badgeModal)}>
          <div className="flex justify-center item-center ml-24">
            <img src={gemsLarge} alt="gems-large" className="i-24" />
            <span className="f14 black b ml-12">Conquista</span>
          </div>
          <div className="mr-24">
            <span className="f24 b heliotrope">{!lessonCompleted ? `+ ${1}` : `${0}`}</span>
          </div>
        </div>
        <div className={styles.bagdeNivel}>
          <div className="flex flex-row item-center pl-24 pr-24 h-100">
            <div className={styles.iconStar}>
              <div className={styles.positionNivel}>
                <span className="f22 b mine-shaft">{`${level}`}</span>
              </div>
            </div>
            {!lessonCompleted ? (
              <span className="f16 b mine-shaft ml-18 mb-4">{`Você subiu para o Nivel ${level}`}</span>
            ) : (
              <span className="f16 b mine-shaft ml-18 mb-4">{`Você já desbloqueou o Nivel ${level}`}</span>
            )}
          </div>
        </div>
        <hr className={cx("divider", styles.dividerModal)} />
        <Button type="primary" className={cx("flex justify-center item-center", styles.btnNext)} onClick={handleMyArea}>
          Próxima trilha
          <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
        </Button>
      </div>
    </Modal>
  );
};

export default ChallengeModal;