import { Button, Breadcrumb } from 'antd';
import cx from 'classnames';
import useMedia from 'use-media';
import Header from '../Header';
import copyToClipboard from 'utils/general';
import { ROUTES } from 'routes/routes';
import { inviteFriend, arkcoins } from 'assets';
import styles from './InviteFriendsForm.module.less';

const InviteFriendsForm = ({ actualInvite }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div>
      <div className="mt-24 mb-24">
        <Header route={ROUTES.DASHBOARD} />
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <Breadcrumb separator="">
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Convidar amigos</Breadcrumb.Item>
          </Breadcrumb>
          <div className="mt-24">
            <div className={cx("flex", { "flex-column justify-center item-center": isMobile, "flex-row item-center": !isMobile })}>
              <div className={cx({ "flex flex-column justify-center item-center": isMobile })}>
                {isMobile ? (
                  <h2 className={styles.inviteTitle}>Convide seus amigos e ganhe</h2>
                ) : (
                  <h4 className={styles.inviteTitle}>Convide seus amigos e ganhe</h4>
                )}
                <p className={cx("f16 gray mt-16 mb-40", styles.inviteDescription)}>
                  Convide seus amigos e ganhe Arkcoins.
                </p>
                <div className={styles.badgeGems}>
                  <div className="p-16">
                    <div className="flex flex-row item-center">
                      <img src={arkcoins} alt="gems-large" className={styles.iconSize} />
                      <p className="f14 mine-shaft ml-12">
                        Você e seu amigo <span className="b">ganham 10 Arkcoins</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-16">
                  <Button
                    type="primary"
                    onClick={() => copyToClipboard(`${process.env.REACT_APP_ENVIRONMENT}/experiencia-investimentos/${actualInvite}`, "Código copiado para sua área de transferência")}
                    className={styles.btnCode}
                  >
                    Copiar código
                  </Button>
                  <p className={cx("f12 gray mt-16", { "text-center": isMobile })}>Compartilhe o código copiado para convidar seus amigos</p>
                </div>
              </div>
              <div className={styles.imageLeft}>
                <img src={inviteFriend} alt="invite-friend" className={styles.inviteImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriendsForm;