import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import Loading from 'components/Loading';
import ArkcPremium from 'components/ArkcPlansForms/ArkcPremium';
import { arkcPremium } from '../../../mock';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const Premium = (props) => {
  const [loading, setLoading] = useState(false);
  const [actualPlan, setActualPlan] = useState();
  const [inviteId, setInviteId] = useState();
  const { plansArkc } = useStore();
  const { createSubscription } = plansArkc;

  useEffect(() => {
    window.scrollTo(0, 0);

    const { state } = props?.history?.location;

    if (state && state.dataPlan && state.invite) {
      setActualPlan(state.dataPlan);
      setInviteId(state.invite);
    };
  }, []);

  const handleCreateSubscription = async () => {
    const userEmail = JSON.parse(localStorage.getItem('userEmail'));

    if (!userEmail) return;

    setLoading(true);

    const body = {
      email: userEmail,
      priceID: _get(actualPlan, "pricing.externalCode", ""),
      intendedPlan: _get(actualPlan, "identifier", "")
    };

    const response = await createSubscription(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const data = response.data;
      setLoading(false);
      history.push(ROUTES.PAYMENT, { subscriptionId: data.subscriptionId, clientSecret: data.clientSecret, lookupKey: _get(actualPlan, "identifier", ""), invite: inviteId });
    } else if (response.status === 400 && response.message === "user already have an active contract to intended plan") {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_active,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else if (response.status === 400 && response.message === "user cannot downgrade his plan") {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_downgrade,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else if (response.status === 400 && response.message === "user cannot downgrade his plan") {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_lack,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <ArkcPremium
          benefits={arkcPremium.benefits}
          worksPremium={arkcPremium.worksPremium}
          planPremium={arkcPremium.planPremium}
          graphicImage={arkcPremium.graphicImage}
          price={_get(actualPlan, "pricing.value", "")}
          createSubscription={handleCreateSubscription}
        />
      )}
    </div>
  );
};

export default observer(Premium);