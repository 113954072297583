import { useEffect } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { ROUTES } from 'routes/routes';

import { history } from '../helpers/history';

import PrivateRoute from 'components/PrivateRoute';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import Home from './Home';
import Login from './Login';
import RecoverPassword from './RecoverPassword';
import Leveling from './Leveling';
import CreateAccount from './CreateAccount';
import NewPassword from './NewPassword';
import Dashboard from './Dashboard';
import LessonDetail from './LessonDetail';
import TrailLesson from './TrailLesson';
import TrailChallenge from './TrailChallenge';
import LessonChallenge from './LessonChallenge';
import Beginner from './ArkcPlans/Beginner';
import Premium from './ArkcPlans/Premium';
import Black from './ArkcPlans/Black';
import Profile from './Profile';
import Signatures from './Signatures';
import Checkout from './Checkout';
import Payment from './Payment';
import PaymentSuccess from './PaymentSuccess';
import CreditCards from './CreditCards';
import Support from './Support';
import InviteFriends from './InviteFriends';
import Conquests from './Conquests';
import DashboardWallet from './DashboardWallet';
import WhereInvest from './WhereInvest';
import DeleteAccount from './DeleteAccount';
import PrivacyPolicies from './PrivacyPolicies';
import TermsUse from './TermsUse';
import HowBuy from './HowBuy';
import Tutorial from './Tutorial';
import LandingPageOne from './LandingPage/LandingPageOne';
import LandingPageTwo from './LandingPage/LandingPageTwo';
import LandingPageThree from './LandingPage/LandingPageThree';
import LandingPageFour from './LandingPage/LandingPageFour';
import LandingPageBeginner from './LandingPage/LandingPageBeginner';
import LandingPagePremium from './LandingPage/LandingPagePremium';
import LandingPageBlack from './LandingPage/LandingPageBlack';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
    TagManager.initialize({ gtmId: process.env.REACT_APP_TAG_MANAGER });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword} />
          <Route exact path={ROUTES.LEVELING} component={Leveling} />
          <Route exact path={ROUTES.LEVELING_INVITE} component={Leveling} />
          <Route exact path={ROUTES.CREATE_ACCOUNT} component={CreateAccount} />
          <Route exact path={ROUTES.NEW_PASSWORD} component={NewPassword} />
          <Route exact path={ROUTES.PRIVACY_POLICIES} component={PrivacyPolicies} />
          <Route exact path={ROUTES.TERMS_USE} component={TermsUse} />
          <Route exact path={ROUTES.SUPPORT} component={Support} />
          <Route exact path={ROUTES.LP_ONE_ARCK} component={LandingPageOne} />
          <Route exact path={ROUTES.LP_TWO_ARCK} component={LandingPageTwo} />
          <Route exact path={ROUTES.LP_THREE_ARCK} component={LandingPageThree} />
          <Route exact path={ROUTES.LP_FOUR_NEW} component={LandingPageFour} />
          <Route exact path={ROUTES.LP_ARKC_BEGINNER} component={LandingPageBeginner} />
          <Route exact path={ROUTES.LP_ARKC_PREMIUM} component={LandingPagePremium} />
          <Route exact path={ROUTES.LP_ARKC_BLACK} component={LandingPageBlack} />
          <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          <PrivateRoute exact path={ROUTES.DASHBOARD_WALLET} component={DashboardWallet} />
          <PrivateRoute exact path={ROUTES.WHERE_INVEST} component={WhereInvest} />
          <PrivateRoute exact path={ROUTES.CONQUESTS} component={Conquests} />
          <PrivateRoute exact path={ROUTES.LESSON_DETAIL} component={LessonDetail} />
          <PrivateRoute exact path={ROUTES.TRAIL_LESSON} component={TrailLesson} />
          <PrivateRoute exact path={ROUTES.CHALLENGE_LESSON} component={TrailChallenge} />
          <PrivateRoute exact path={ROUTES.LESSON_CHALLENGE} component={LessonChallenge} />
          <PrivateRoute exact path={ROUTES.ARKC_BEGINNER} component={Beginner} />
          <PrivateRoute exact path={ROUTES.ARKC_PREMIUM} component={Premium} />
          <PrivateRoute exact path={ROUTES.ARKC_BLACK} component={Black} />
          <PrivateRoute exact path={ROUTES.PROFILE} component={Profile} />
          <PrivateRoute exact path={ROUTES.DELETE_ACCOUNT} component={DeleteAccount} />
          <PrivateRoute exact path={ROUTES.SIGNATURES} component={Signatures} />
          <PrivateRoute exact path={ROUTES.CHECKOUT} component={Checkout} />
          <PrivateRoute exact path={ROUTES.PAYMENT} component={Payment} />
          <PrivateRoute exact path={ROUTES.PAYMENT_SUCCESS} component={PaymentSuccess} />
          <PrivateRoute exact path={ROUTES.CREDIT_CARDS} component={CreditCards} />
          <PrivateRoute exact path={ROUTES.INVITE_FRIENDS} component={InviteFriends} />
          <PrivateRoute exact path={ROUTES.HOW_BUY} component={HowBuy} />
          <PrivateRoute exact path={ROUTES.TUTORIAL_BUY} component={Tutorial} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
