import { Button, Tooltip } from 'antd';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FiArrowLeft } from 'react-icons/fi';
import { FaPlay } from 'react-icons/fa';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import { logoDashboard } from 'assets';
import styles from './WhereInvestForm.module.less';

const WhereInvestForm = ({ backDashboard, viewTutorialBuy, viewHowBuy, investData }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  const renderButtonsStatus = (ticker) => {
    return (
      <button className="arkc-btn-link arkc-btn-play" onClick={() => viewTutorialBuy(ticker)}>
        <FaPlay className="i-16 white" />
      </button>
    );
  };

  return (
    <div>
      <div className="container">
        <div className="content mt-24 mb-24">
          <div className="flex flex-row">
            <button className="arkc-btn-link flex flex-row justify-center item-center" onClick={backDashboard}>
              <FiArrowLeft className="heliotrope i-24" />
              {!isMobile && (
                <span className="f16 b heliotrope ml-14">Voltar</span>
              )}
            </button>
            <div className="flex justify-center item-center w-100">
              <img src={logoDashboard} alt="logo-dashboard" />
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-internal" />
      <div className="container">
        <div className="content">
          <div className={cx("flex flex-column justify-center item-center", { "mt-24": isMobile, "mt-72": !isMobile })}>
            <div className={styles.bgImage}>
              <div className="flex justify-center item-center h-100">
                <img src={investData.logo} alt="invest-type" className="i-32" />
              </div>
            </div>
            {isMobile ? (
              <h3 className="mt-24 mb-24">{`Investimentos para ${investData.name}`}</h3>
            ) : (
              <h4 className="mt-24 mb-24">{`Investimentos para ${investData.name}`}</h4>
            )}
            <Button type="primary" className={styles.btnBuy} onClick={viewHowBuy}>Como comprar</Button>
            <div className={cx({ "mt-32 mb-24": isMobile, "mt-44 mb-32": !isMobile })}>
              {_map(investData.invests, (item, index) => (
                <div key={index} className={cx(styles.badgeInvest, { "mb-12": isMobile && index !== _size(investData.invests) - 1, "mb-24": !isMobile && index !== _size(investData.invests) - 1 })}>
                  <div className={cx("flex", { "p-16": isMobile, [styles.gapMobile]: isMobile, "flex-row justify-between item-center h-100 p-24": !isMobile })}>
                    <div className={cx("flex flex-column", styles.contentInfos)}>
                      <span className={cx("f16 mine-shaft b")}>Nome</span>
                      <span className={cx("f16 mine-shaft")}>{item.name}</span>
                    </div>
                    {item.ticker && (
                      <div className={cx("flex flex-column", styles.contentInfos)}>
                        <span className={cx("f16 mine-shaft b")}>Código na corretora</span>
                        <span className={cx("f16 mine-shaft")}>{item.ticker}</span>
                      </div>
                    )}
                    {item.type && (
                      <div className={cx("flex flex-column", styles.contentInfos)}>
                        <span className={cx("f16 mine-shaft b")}>Tipo</span>
                        <span className={cx("f16 mine-shaft")}>{item.type}</span>
                      </div>
                    )}
                    <Tooltip title="Distribuição" color="#8C52FF" overlayInnerStyle={{ borderRadius: 12, padding: 12 }}>
                      <div className="flex flex-row item-center">
                        <span className={cx("f16 mine-shaft b mr-16")}>{`${item.balance}%`}</span>
                        <div className={styles.circularSize}>
                          <CircularProgressbar
                            value={item.balance}
                            styles={buildStyles({ pathColor: "#8C52FF", backgroundColor: "#F8F5FF" })}
                          />
                        </div>
                      </div>
                    </Tooltip>
                    {!isMobile && (
                      <div>
                        {renderButtonsStatus(item.ticker)}
                      </div>
                    )}
                  </div>
                  {isMobile && (
                    <div className="flex justify-center item-center mb-16">
                      {renderButtonsStatus(item.ticker)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhereInvestForm;