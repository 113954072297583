const authHeader = () => {
  let userArkc = JSON.parse(localStorage.getItem('userArkc'));

  if (userArkc && userArkc.Access) {
    return { 'Authorization': 'Bearer ' + userArkc?.Access };
  } else {
    return {};
  }
};

const headerUserId = () => {
  let userId = JSON.parse(localStorage.getItem('userId'));

  if (userId) {
    return { id: userId };
  } else {
    return { id: "" };
  }
};

const handlerTokenExpired = () => {
  localStorage.clear();
  window.location.reload();
};

export { handlerTokenExpired, headerUserId, authHeader };