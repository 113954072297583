import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../TutorialForm.module.less';

const TutorialQuestionItems = ({ selectOption, nextTrail, choiceSelected, questionsItem, title, index, name, logo }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayButton(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [displayButton]);

  return (
    <div key={index}>
      {isMobile ? (
        <div className="container">
          <div className="content">
            <div className="flex flex-column justify-center item-center">
              <div className={styles.bgQuestion}>
                <div className="flex flex-row item-center p-16">
                  <img src={logo} alt="logo-question" className="i-32" />
                  <span className="f18 b mine-shaft ml-16">{name}</span>
                </div>
              </div>
              <h5 className={cx("text-center", styles.titleItems)}>{title}</h5>
              {_map(questionsItem, (item, index) => (
                <button
                  key={index}
                  className={cx("arkc-btn-link flex item-center", styles.bagde, {
                    "mb-12": index !== _size(questionsItem) - 1, [styles.bagdeSelect]: choiceSelected?.identifier === item.identifier
                  })}
                  onClick={() => selectOption(item)}
                >
                  <span className="f16 b heliotrope pl-24 pr-24">{item.name}</span>
                </button>
              ))}
              {displayButton && (
                <>
                  <hr className={cx("divider mb-16", styles.dividerBottom)} />
                  <Button
                    type="primary"
                    className={cx("flex justify-center item-center", styles.btnContinue)}
                    disabled={!choiceSelected}
                    onClick={nextTrail}
                  >
                    Continuar
                    <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-column justify-center item-center mb-16">
          <div className={styles.bgQuestion}>
            <div className="flex flex-row item-center p-16">
              <img src={logo} alt="logo-question" className="i-32" />
              <span className="f18 b mine-shaft ml-16">{name}</span>
            </div>
          </div>
          <h5 className={cx("text-center", styles.titleItems)}>{title}</h5>
          {_map(questionsItem, (item, index) => (
            <button
              key={index}
              className={cx("arkc-btn-link flex item-center", styles.bagde, {
                "mb-12": index !== _size(questionsItem) - 1, [styles.bagdeSelect]: choiceSelected?.identifier === item.identifier
              })}
              onClick={() => selectOption(item)}
            >
              <span className="f16 b heliotrope pl-24 pr-24">{item.name}</span>
            </button>
          ))}
          {displayButton && (
            <>
              <hr className={cx("divider mb-16", styles.dividerBottom)} />
              <Button
                type="primary"
                className={cx("flex justify-center item-center", styles.btnContinue)}
                disabled={!choiceSelected}
                onClick={nextTrail}
              >
                Continuar
                <FiArrowRight className={cx("ml-14", styles.iconContinue)} />
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TutorialQuestionItems;