import { Button } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import styles from '../../LevelingForm.module.less';

const Introduction = ({ changeStep }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className={cx("flex flex-column justify-center item-center", { "mt-48 mb-60": isMobile, "mt-56 mb-70": !isMobile })}>
      <img src={require("assets/form.png")} alt="form" className={cx({ "mb-60": isMobile, "mb-40": !isMobile })} />
      <div className="flex flex-column justify-center item-center">
        <h1 className={cx("f24 mine-shaft text-center", styles.lineTitle, { "mb-16": isMobile, "mb-12": !isMobile })}>
          Para poder te ajudar, é importante saber sobre sua experiência com investimentos.
        </h1>
        <p className={cx("gray text-center", styles.lineDescription, { "f18 mb-40": isMobile, "f16 mb-32": !isMobile })}>
          É só responder algumas perguntas. É rapidinho!
        </p>
        <Button type="primary" className={cx("flex justify-center item-center", styles.btnStart)} onClick={() => changeStep("TypeSituation")}>
          Começar
          <FiArrowRight className={cx("ml-14", styles.iconStart)} />
        </Button>
      </div>
    </div>
  );
};

export default Introduction;