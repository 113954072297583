import { useState } from 'react';
import { Button, Drawer } from 'antd';
import { FiArrowRight, FiX, FiMenu } from 'react-icons/fi';
import cx from 'classnames';
import useMedia from 'use-media';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { arkc, logoColor } from 'assets';
import styles from './Menu.module.less';

const Menu = ({ logout, wallet, invite }) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div>
      <div className="header-dash">
        <div className="flex justify-center item-center pt-20 pb-20">
          <button className="arkc-btn-link" onClick={() => setVisible(!visible)}>
            <FiMenu className="ml-24 i-24" />
          </button>
          <div className="flex justify-center item-center w-100">
            <img src={logoColor} alt="header-logo" />
          </div>
        </div>
      </div>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setVisible(!visible)}
        visible={visible}
        key="left"
        width={!isMobile ? 475 : 332}
        className={styles.drawer}
        bodyStyle={isMobile ? { padding: "24px" } : { padding: "40px 48px" }}
      >
        <div className={cx({ "mb-16": isMobile, "flex justify-center item-center mb-32": !isMobile })}>
          <button className="arkc-btn-link" onClick={() => setVisible(!visible)}>
            <FiX className="i-24" />
          </button>
          {!isMobile && (
            <div className="flex justify-center item-center w-100">
              <img src={logoColor} alt="header-logo" />
            </div>
          )}
        </div>
        {(wallet && wallet !== "") && (
          <div className={styles.badgeDashPlan}>
            <div className={styles.badgeDashPlanPadding}>
              <div className={cx("flex flex-row justify-between", { "item-center": !isMobile })}>
                <div>
                  <img src={arkc} alt="header-logo" />
                  <h5 className="mt-12">Investimentos</h5>
                  <span className="f14 mine-shaft mt-4">Acompanhe suas carteiras</span>
                </div>
                {isMobile ? (
                  <button className="arkc-btn-link" onClick={() => history.push(ROUTES.DASHBOARD_WALLET, { id: wallet, invite: invite })}>
                    <FiArrowRight className="i-24 mine-shaft" />
                  </button>
                ) : (
                  <Button type="primary" className={styles.btnView} onClick={() => history.push(ROUTES.DASHBOARD_WALLET, { id: wallet, invite: invite })}>Acessar</Button>
                )}
              </div>
            </div>
          </div>
        )}
        <div className={cx({ "mt-36 mb-40": isMobile, "mt-32 mb-16": !isMobile })}>
          <button className="arkc-btn-link flex item-center mb-24" onClick={() => history.push(ROUTES.SIGNATURES, { invite: invite })}>
            <img src={require("../../assets/signatures-menu.png")} alt="signatures-menu" />
            <span className="f18 mine-shaft b ml-24">Assinaturas</span>
          </button>
          <button className="arkc-btn-link flex item-center mb-24" onClick={() => history.push(ROUTES.PROFILE)}>
            <img src={require("../../assets/profile-menu.png")} alt="profile-menu" />
            <span className="f18 mine-shaft b ml-24">Perfil</span>
          </button>
          <button className="arkc-btn-link flex item-center mb-24" onClick={() => history.push(ROUTES.INVITE_FRIENDS, { invite: invite })}>
            <img src={require("../../assets/invite-menu.png")} alt="invite-menu" />
            <span className="f18 mine-shaft b ml-24">Convidar amigos</span>
          </button>
          <button className="arkc-btn-link flex item-center mb-24" onClick={() => history.push(ROUTES.SUPPORT)}>
            <img src={require("../../assets/support-menu.png")} alt="support-menu" />
            <span className="f18 mine-shaft b ml-24">Suporte</span>
          </button>
        </div>
        <Button type="default" className={styles.btnGoOut} onClick={logout}>
          Sair da minha conta
        </Button>
      </Drawer>
    </div>
  );
};

export default Menu;