import { notification } from 'antd';
import { FiX } from 'react-icons/fi';

const copyToClipboard = (actualValue, message) => {
  const selBox = document.createElement("textarea");
  selBox.style.position = "fixed";
  selBox.style.left = "0";
  selBox.style.top = "0";
  selBox.style.opacity = "0";
  selBox.value = actualValue;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand("copy");
  document.body.removeChild(selBox);

  notification.success({
    message: "Copiado",
    description: message || "Seu texto foi copiado para sua área de transferência",
    placement: "bottom",
    duration: 4,
    closeIcon: <FiX className="i-24 white" />
  });
};

export default copyToClipboard;