
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { useParams } from 'react-router-dom';
import { notification, Spin } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import moment from 'moment';
import PaymentSuccessForm from 'components/PaymentSuccessForm';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const PaymentSuccess = () => {
  const [loading, setLoading] = useState(false);
  const [myPlan, setMyPlan] = useState({});
  const [inviteId, setInviteId] = useState();
  const { plansArkc } = useStore();
  const { plansAll } = plansArkc;
  const param = useParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntent = urlParams.get("payment_intent")
    const paymentIntentClientSecret = urlParams.get("payment_intent_client_secret")
    const redirectStatus = urlParams.get("redirect_status")

    if (paymentIntent && paymentIntentClientSecret && redirectStatus === "succeeded") {
      setInviteId(_get(param, "invite", ""));
      retrieveSignedPlan(_get(param, "identifier", ""));
    } else {
      notification.error({
        message: message.was_problem,
        description: message.was_problem_description_generic,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveSignedPlan = async (identifier) => {
    setLoading(true);

    const response = await plansAll();

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const filterResponse = response.data.find(item => item.identifier === identifier);
      const actualDate = moment(new Date()).format("YYYY-MM-DD");
      const actualResponse = {
        id: filterResponse.id,
        name: filterResponse.name,
        identifier: filterResponse.identifier,
        wallet: filterResponse.provisioning.wallet,
        price: filterResponse.pricing.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }),
        orderDate: actualDate
      };

      setMyPlan(actualResponse);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    }
  };

  const handleViewMyWallet = (wallet) => {
    history.push(ROUTES.DASHBOARD_WALLET, { id: wallet, invite: inviteId });
  };

  return (
    <div className="pb-32">
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Spin size="large" />
        </div>
      ) : (
        <PaymentSuccessForm myPlan={myPlan} viewMyWallet={handleViewMyWallet} />
      )}
    </div>
  );
};

export default observer(PaymentSuccess);