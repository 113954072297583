import arkc from './arkc.svg';
import headerBackground from './header-background.svg';
import logo from './logo.svg';
import footer from './arkc-footer.svg';
import waterMark from './water-mark.svg';
import logoColor from './logo-color.svg';
import funy from './funy.svg';
import education from './education.svg';
import form from './form.svg';
import finalForm from './final-form.svg';
import sendSuccess from './send-success.svg'
import expired from './expired.svg';
import trophy from './trophy.svg';
import cash from './cash.svg';
import gems from './gems.svg';
import beginner from './beginner.svg';
import premium from './premium.svg';
import black from './black.svg';
import complete from './complete.svg';
import toDo from './to-do.svg';
import blocked from './blocked.svg';
import concluded from './concluded.svg';
import challenge from './challenge.svg';
import nextNivel from './next-nivel.svg';
import happy from './happy.svg';
import star from './star.svg';
import arkcoins from './arkcoins.svg';
import conquests from './conquests.svg';
import gemsLarge from './gems-large.svg';
import google from './google.svg';
import facebook from './facebook.svg';
import logoWhite from './logo-white.svg';
import arckBlack from './arck-black.svg';
import graphicBlack from './graphic-black.svg';
import opportunities from './opportunities.svg';
import exclusive from './exclusive.svg';
import graphicPizza from './graphic-pizza.svg';
import unlockBlack from './unlock-black.svg';
import graphicBeginner from './graphic-beginner.svg';
import opportunitiesBeginner from './opportunities-beginner.svg';
import exclusiveBeginner from './exclusive-beginner.svg';
import graphicPizzaBeginner from './graphic-pizza-beginner.svg';
import arckBeginner from './arck-beginner.svg';
import arckPremium from './arck-premium.svg';
import graphicPremium from './graphic-premium.svg';
import opportunitiesPremium from './opportunities-premium.svg';
import exclusivePremium from './exclusive-premium.svg';
import unlockPremium from './unlock-premium.svg';
import graphicPizzaPremium from './graphic-pizza-premium.svg';
import arkcEdit from './arkc-edit.svg';
import iconProfile from './icon-profile.svg';
import iconPassword from './icon-password.svg';
import supportMenu from './support-menu.svg';
import inviteMenu from './invite-menu.svg';
import profileMenu from './profile-menu.svg';
import signaturesMenu from './signatures-menu.svg';
import mastercard from './mastercard.svg';
import supportArkc from './support-arkc.svg';
import contactSupport from './contact-support.svg';
import inviteFriend from './invite-friend.svg';
import logoBeginner from './logo-beginner.svg';
import logoPremium from './logo-premium.svg';
import logoBlack from './logo-black.svg';
import paymentSuccess from './payment-success.svg';
import creditCard from './credit-card.svg';
import secureSite from './secure-site.svg';
import logoDashboard from './logo-dashboard.svg';
import arkcBlackBg from './arkc-black-bg.svg';
import replay from './replay.svg';
import avatarUser from './icon-avatar.svg';
import safeBuy from './safe-buy.svg';
import cancelWant from './cancel-want.svg';
import privacyGuaranteed from './privacy-guaranteed.svg';
import dashboardArkc from './dashboard-arkc.svg';
import profileUser from './profile-user.svg';
import inviteNew from './invite-new.svg';
import supportNew from './support-new.svg';
import dashboard from './dashboard.svg';
import rocketBeginner from './rocket-beginner.svg';
import rocketPremium from './rocket-premium.svg';
import rocketBlack from './rocket-black.svg';
import beginnerBg from './beginner-bg.svg';
import premiumBg from './premium-bg.svg';
import blackBg from './black-bg.svg';
import salesNew from './sale-lp-new.svg';
import phoneSales from './phone-sales.svg';
import gameSales from './game-sales.svg';
import secretsSale from './secrets-sale.svg';
import oneSales from './one-sales.svg';
import salesPhone from './sales-phone.svg';
import userSales from './user-sales.svg';
import salesPhoneMobile from './sales-phone-mobile.svg';
import phoneSalesMobile from './phone-sales-mobile.svg';
import freeSeven from './free-seven.svg';
import freeSevenMobile from './free-seven-mobile.svg';
import aboutBlack from './about-black.svg';
import salesPhoneBlack from './sales-phone-black.svg';
import salesPhoneBlackMobile from './sales-phone-black.svg';

import exclusiveContent from './exclusive-content.svg';
import marketNotifications from './market-notifications.svg';
import learningTrails from './learning-trails.svg';
import portfolioInvestments from './portfolio-investments.svg';
import checkBlack from './check-black.svg';
import footerDark from './footer-dark.svg';
import whatsApp from './whatsapp.svg';
import email from './email.svg';

export {
  arkc,
  headerBackground,
  logo,
  footer,
  dashboardArkc,
  profileUser,
  inviteNew,
  supportNew,
  waterMark,
  logoColor,
  funy,
  education,
  form,
  finalForm,
  sendSuccess,
  expired,
  trophy,
  cash,
  gems,
  beginner,
  premium,
  black,
  complete,
  toDo,
  blocked,
  concluded,
  challenge,
  nextNivel,
  happy,
  star,
  arkcoins,
  conquests,
  gemsLarge,
  google,
  facebook,
  logoWhite,
  arckBlack,
  graphicBlack,
  opportunities,
  exclusive,
  graphicPizza,
  unlockBlack,
  graphicBeginner,
  opportunitiesBeginner,
  exclusiveBeginner,
  graphicPizzaBeginner,
  arckBeginner,
  arckPremium,
  graphicPremium,
  opportunitiesPremium,
  exclusivePremium,
  unlockPremium,
  graphicPizzaPremium,
  arkcEdit,
  iconProfile,
  iconPassword,
  supportMenu,
  inviteMenu,
  profileMenu,
  signaturesMenu,
  mastercard,
  supportArkc,
  contactSupport,
  inviteFriend,
  logoBeginner,
  logoPremium,
  logoBlack,
  paymentSuccess,
  creditCard,
  secureSite,
  logoDashboard,
  arkcBlackBg,
  replay,
  avatarUser,
  safeBuy,
  cancelWant,
  privacyGuaranteed,
  dashboard,
  rocketBeginner,
  rocketPremium,
  rocketBlack,
  beginnerBg,
  premiumBg,
  blackBg,
  salesNew,
  phoneSales,
  gameSales,
  secretsSale,
  oneSales,
  salesPhone,
  userSales,
  salesPhoneMobile,
  phoneSalesMobile,
  freeSeven,
  freeSevenMobile,
  aboutBlack,
  salesPhoneBlack,
  exclusiveContent,
  marketNotifications,
  learningTrails,
  portfolioInvestments,
  checkBlack,
  footerDark,
  whatsApp,
  email
};