import cx from 'classnames';
import useMedia from 'use-media';
import { logoColor } from 'assets';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const TermsUseForm = () => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className="mt-24">
      <div className="flex justify-center item-center mb-24">
        <button className="arkc-btn-link" onClick={() => history.push(ROUTES.HOME)}>
          <img src={logoColor} alt="logo-color" />
        </button>
      </div>
      <hr className="divider-internal mt-24 mb-32" />
      <div className="container">
        <div className="content">
          <h2 className="text-center heliotrope">Termos de uso Arkc</h2>
          <div className={cx({ "mb-32 mt-24": isMobile, "mb-48 mt-32": !isMobile })}>
            <p className="f16 mine-shaft">
              Por meio deste documento (os “Termos de Uso”), ficam estabelecidas as regras a respeito do uso das soluções oferecidas pela empresa ARKC LTDA, inscrita no CNPJ sob nº 42.230.146/0001-73, com sede no VD Nove de Julho, nº 200, Centro, São Paulo – SP, CEP 01.050-060 (a “Arkc”) por meio de seu site, softwares e integrações (a “Plataforma”).
            </p>
            <p className="f16 mt-10 mine-shaft">
              O uso de todas as funcionalidades oferecidas está condicionado à aceitação dos Termos de Uso por você, no momento de seu cadastro, por meio do aceite via “checkbox”. Portanto, ao realizar seu cadastro, você concorda com os presentes Termos de Uso, bem como com as demais políticas complementares a este instrumento, que estabelecem o relacionamento contratual com a Arkc.
            </p>
            <p className="f16 mt-10 mine-shaft">
              Sempre que você visualizar palavras com iniciais maiúsculas, tais palavras representam uma definição realizada dentro deste documento. Essas definições podem ocorrer por meio de palavras entre parênteses, entre aspas e sublinhado (tais como as definidas acima) ou por meio do glossário ao final destes Termos de Uso.
            </p>
            <p className="f16 mt-10 mine-shaft">
              A seguir, você terá acesso a todas as regras a respeito da sua relação com a Arkc e de uso da Plataforma, bem como regras de pagamento, suporte, entre outras, sendo sua leitura obrigatória antes da realização do aceite do cadastro para a utilização da Plataforma e contratação dos planos oferecidos pela Arkc.
            </p>
            <div className="mt-20">
              <h5 className="heliotrope">1. Objeto</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>1.1 Licenciamento Arkc.</b> O objeto destes Termos de Uso é o licenciamento não definitivo, não exclusivo, não transferível e revogável da Plataforma, que poderá ser acessada por meio de <strong>Aplicativo Mobile ou Aplicativo Web</strong>, para utilização voltada à prospecção e gerenciamento de atendimentos
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">2. A Plataforma</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>2.1 Educação Financeira.</b> Por meio da Plataforma, a Arkc fornecerá trilhas de conhecimento sobre educação financeira, formadas por lições interativas e gamificadas. Você poderá acessar a Plataforma de maneira gratuita, bastando que realize o cadastro, conforme descrito na cláusula 4. Cadastro do Usuário. E, caso queira determinados benefícios, poderá optar pela utilização de planos pagos, conforme 8. Políticas Comerciais, destes Termos de Uso.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>2.2 Exposição Estrita de Informações Educacionais.</b> Todos os conteúdos dispostos dentro da Plataforma são de caráter estritamente educacional, e não representam indicação ou sugestão de investimento em empresas, direitos ou quaisquer outros tipos de negócios.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>2.3 Impossibilidade de Investimento</b> A Plataforma não possibilita, de maneira alguma, que você realize investimentos de qualquer natureza, tratando-se apenas de um software voltado para o ensino de educação financeira.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>2.4 Arkcoins</b> Ao completar avançar nas trilhas de conhecimento ou completar desafios, você poderá adquirir moedas virtuais (“Arkcoins”). As Arkcoins poderão ser utilizadas dentro da Plataforma, não sendo possível a sua troca ou resgate em valores financeiros ou por meio de quaisquer direitos passíveis de quantificação econômica.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>2.5 Novas Funcionalidades</b> A Arkc poderá adicionar novas funcionalidades à Plataforma, a seu critério, o que será informado a você na mesma oportunidade em que serão enviadas informações para a sua utilização.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">3. Políticas de Privacidade</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>3.1 Regras de Privacidade.</b> O usuário poderá encontrar todas as regras e esclarecimentos sobre privacidade e proteção de dados através da Política de Privacidade em https://www.arkc.com.br/politicas-privacidade.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">4. Cadastro do usuário</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>4.1 Cadastro na Plataforma Arkc</b> Para a utilização da Plataforma, você deverá realizar seu cadastrando, o que poderá ocorrer por meio do fornecimento dos dados solicitados ou da autorização de acesso da Arkc aos dados já fornecidos a outras plataformas, como o Google e Facebook.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>4.2 Suspensão de Cadastros pela Arkc</b> Caso seja identificado que você tenha ferido alguma das obrigações destes Termos de Uso, seu cadastro poderá ser suspenso, sem aviso prévio, de maneira temporária. A suspensão do cadastro não permitirá que determinadas funções possam ser utilizadas até que as obrigações passem a ser cumpridas, conforme informado no momento da suspensão. Ter sua conta suspensa não quer dizer que houve a rescisão contratual em relação a você, portanto, as cobranças continuarão a ser realizadas.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>4.3 Exclusão</b> A critério da Arkc, você ou seus usuários poderão ser excluídos da Plataforma, sem aviso prévio, sendo impossibilitados completamente de utilizá-la, por ferirem os deveres previstos nestes Termos de Uso. A exclusão fará com que nenhuma das funcionalidades da Plataforma Arkc possa ser utilizada, sem prejuízo da cobrança, pela Arkc, de valores eventualmente devidos em decorrência destes Termos de Uso.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">5. Manutenção da plataforma</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>5.1 Disponibilidade</b>A Arkc empregará todos os seus esforços para que a Plataforma se mantenha a todo 24h (vinte e quatro horas) em funcionamento, contudo, não pode garantir 100% (cem por cento) desta disponibilidade. Para tanto, a Arkc deixa fixado como acordo de SLA uma garantia de desempenho equivalente a 98% (noventa e oito por cento) de disponibilidade de tempo para uso da Plataforma.
              </p>
              <p className="f16 mt-12 mine-shaft">
                <b>5.2 Manutenções</b> A Plataforma, está sujeita a atualizações e manutenções periódicas, que ocorrerão preferencialmente fora do horário comercial, por volta das 22h. Havendo instabilidades no todo ou em parte da Plataforma, a Arkc resguarda para si o direito de correção das instabilidades em tempo hábil sem que acarrete qualquer prejuízo na relação contratual.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">6. Ausência de responsabilidade</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>6.1 Regras de Plataforma Terceiros</b> Ao utilizar integrações de terceiros à Plataforma, você deverá ter lido e aceito todo o regulamento que envolver tais soluções. Portanto, a Arkc não será responsabilizada pelos atos que você venha a praticar que infrinjam regulamentos da Plataforma ou de soluções de terceiros que sejam integradas à Plataforma, cabendo direito de regresso por parte da Arkc em casos como os mencionados.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>6.2 Disponibilidade de Plataforma Terceiros</b> A Arkc também não será responsabilizada pela indisponibilidade ou mau funcionamento das plataformas de terceiros integrados à Plataforma, de forma que, caso você seja prejudicado de alguma maneira, deverá buscar o atendimento do terceiro proprietário da respectiva plataforma integrada à Plataforma.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>6.3 Impossibilidade de Abatimentos</b> Caso a indisponibilidade, mau funcionamento, suspensão de contas de usuários da plataforma de terceiros venha prejudicar você ou os usuários cadastrados na Plataforma Arkc ou as respectivas integrações, não será possível a realização de qualquer abatimento quanto aos valores pagos para a utilização da Plataforma.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>6.4 Atos de Usuários</b> Quaisquer atos que você pratique contra terceiros, que venham a prejudicá-los, serão de sua própria responsabilidade. Também, serão de sua responsabilidade quaisquer atos contrários à legislação brasileira ou internacional praticados por meio da Plataforma.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>6.5 Impossibilidade Técnica, Fática e Jurídica</b> Por motivos de impossibilidade técnica, fática e jurídica, a Arkc não pode garantir que: (i) fiscalizará os atos dos usuários no âmbito da Plataforma ou fora dela; (ii) verificará o cumprimento das obrigações por parte dos usuários; (iii) verificará veracidade de todas as informações fornecidas ou publicadas pelos usuários na Plataforma; (iv) garantirá o cumprimento das responsabilidades e obrigações assumidas pelos usuários perante outros usuários.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>6.6 Demais Casos</b> Entre outras situações, a Arkc não se responsabiliza por eventuais danos, prejuízos ou ocorrências derivadas de caso fortuito, força maior ou da ação fraudulenta de terceiros, tampouco da apreensão, cooptação, eliminação, modificação ou uso indevido de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações e/ou executar atos fraudulentos.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>6.7 Compartilhamento de Dados</b> Você é o único responsável pelo compartilhamento de seus próprios dados de acesso à Plataforma, por conta e vontade própria, a terceiros.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">7. Propriedade Intelectual</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>7.1 Propriedade</b> A Arkc é proprietária de todos os direitos de propriedade intelectual sobre seus sites, todo seu conteúdo, serviços, produtos, marcas, nomes comerciais, logotipos, desenhos, imagens, frases publicitárias, direitos autorais, domínios, programas de computação, códigos, desenvolvimentos, software, bancos de dados, informações, tecnologia, patentes e modelos de utilidade, desenhos e modelos industriais, segredos comerciais, entre outros, que estão protegidos por leis nacionais e internacionais.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>7.2 Vedações</b> Não é permitido a você ou a quaisquer usuários (i) redistribuir, vender, alugar ou sublicenciar a Plataforma, bem como de copiar, acessar e/ou realizar engenharia reversa no código-fonte da Plataforma; (ii) acessar o código-fonte ou realizar engenharia reversa da Plataforma, bem como tentar quebrar qualquer medida de segurança relativa à Plataforma; (iii) realizar atos de hacking de qualquer natureza; (iv) acessar ou tentar obter acesso a dados exclusivos da, ou de seus usuários; (v) utilizar os serviços fornecidos para quaisquer fins ilegais; (vi) utilizar-se da Propriedade Intelectual de maneira a causar confusão em usuários ou possíveis usuários e clientes da Arkc, entre outros atos que possam infringir os direitos de propriedade intelectual da Arkc.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>7.3 Sanções</b> Caso o usuário infrinja os direitos de propriedade intelectual da Arkc, poderá ter seu cadastro excluído da Plataforma, bem como sofrer com as demais sanções previstas na legislação brasileira.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">8. Políticas Comerciais</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>8.1 Planos de Assinatura</b> A utilização da Plataforma é gratuita. Contudo, você poderá realizar a contratação do “Plano Black” ou “Plano Premium”, que possibilitarão uma série de benefícios específicos, de acordo com a oferta aceita no momento da contratação.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>8.2 Contratação</b> Os planos de assinatura poderão ser contratados na modalidade pré-paga, por meio de assinaturas mensais ou anuais. Após o seu pagamento, os benefícios do plano contratado serão disponibilizados imediatamente na Plataforma.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>8.3 Não Utilização</b> Você não ficará isento do pagamento da assinatura contratada caso não venha a acessar a Plataforma e/ou que não venha cumprir com as trilhas de conhecimento ou desafios, sendo o pagamento devido pela simples contratação de quaisquer dos planos disponibilizados.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>8.4 Formas de Pagamento</b> Você poderá contratar os planos realizando o pagamento pelos meios disponibilizados na Plataforma. Para tanto, a Arkc realiza integração com o sistema de pagamentos XXXXXXXX. Portanto, caso tenha alguma dúvida ou problema quanto ao pagamento, poderá entrar em contato direto com a XXXXXXXXX para que possam te ajudar a resolver a questão levantada, não tendo a Arkc qualquer responsabilidade quanto a essas questões, por não ser a proprietária do sistema de pagamento disponibilizado.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>8.5 Cancelamento</b> O cancelamento do plano contratado poderá ser feito a qualquer momento, sem necessidade de justificativa, por meio da Plataforma ou via solicitação de cancelamento por e-mail direcionado à Arkc. A solicitação de cancelamento deverá ser feita por meio de contato com a equipe de suporte, via suporte@arkc.com.br. Após o pedido de cancelamento, o acesso continuará liberado até o final do último mês adimplido.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>8.6 Renovação</b> O plano será renovado automaticamente a cada mês, exceto se o cliente solicitar o cancelamento, conforme descrito na cláusula 8.5. acima, com 30 (trinta) dias de antecedência.
              </p>
            </div>
            <div className="mt-16">
              <h5 className="heliotrope">9. Disposições Gerais</h5>
              <p className="f16 mt-10 mine-shaft">
                <b>9.1 Suporte</b> Caso você tenha alguma dúvida ou necessidade que requeira o suporte da Arkc, poderá enviar uma solicitação de suporte ao e-mail suporte@arkc.com.br, com o título “SOLICITAÇÃO DE SUPORTE”. Após o envio de pedido de suporte, a Arkc terá o prazo de resposta de até 72h (setenta e duas horas).
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>9.2 Fornecimento de Dados e Informações</b> A Arkc não se responsabiliza por quaisquer informações ou dados imprecisos ou errados fornecidos por você ou por seus usuários à própria Arkc ou a terceiros dentro ou fora da Plataforma.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>9.3 Prerrogativas</b> A Arkc reserva-se o direito de, a seu critério e a qualquer tempo: (i) alterar, remover ou adicionar novas funcionalidades à Plataforma Arkc; (ii) alterar as regras destes Termos de Uso e documentos complementares; (iii) interromper a disponibilização da Plataforma.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>9.4 Terceirização</b> A Arkc poderá terceirizar ou se apoiar em serviço de terceiros, com o objetivo de suportar o funcionamento e/ou o fornecimento da Plataforma a você e aos demais usuários.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>9.5 Não Afetação</b> Caso alguma disposição destes Termos de Uso venha a ser declarada inválida, ilegal ou inexequível, as demais disposições não serão afetadas ou prejudicadas.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>9.6 Legislação Aplicável</b> Todos os itens deste acordo serão regidos pelas leis vigentes no Brasil.
              </p>
              <p className="f16 mt-10 mine-shaft">
                <b>9.7 Foro</b> Fica eleito o foro da comarca de São Paulo – SP, para dirimir quaisquer controvérsias, dúvidas, desentendimentos, litígios ou questões advindas da relação entre você e a Arkc.
              </p>
            </div>
            <div className="flex justify-center item-center mt-18">
              <p className="f16 mt-10 mine-shaft text-center">
                Agradecemos pela leitura destes Termos de Uso.<br />
                Data da Publicação 18/05/2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsUseForm;