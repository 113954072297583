import { useState } from 'react';
import { Space, Breadcrumb, Input, Button, Radio } from 'antd';
import cx from 'classnames';
import useMedia from 'use-media';
import Header from '../Header';
import { ROUTES } from 'routes/routes';
import styles from './DeleteAccountForm.module.less';
import ModalConfirm from './ModalConfirm';

const DeleteAccountForm = ({ changeValue, changeValueOther, deleteMyAccount, setVisible, optionSelected, visible, loading }) => {
  const { TextArea } = Input;
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <div className={cx({ "mb-32": isMobile, "mb-140": !isMobile })}>
      <div className="mt-24 mb-24">
        <Header route={ROUTES.PROFILE} />
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <Breadcrumb separator="">
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Perfil</Breadcrumb.Item>
            <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
            <Breadcrumb.Item>Excluir minha conta</Breadcrumb.Item>
          </Breadcrumb>
          <div className={cx("flex flex-column jutify-center item-center", { "mt-32": isMobile, "mt-64": !isMobile })}>
            {isMobile ? (
              <h3 className={styles.titleDelete}>Por qual motivo você está excluindo a sua conta?</h3>
            ) : (
              <h4 className={styles.titleDelete}>Por qual motivo você está excluindo a sua conta?</h4>
            )}
            <div className={cx("mt-40", { "mb-32": optionSelected.predefined === 7, "mb-72": optionSelected.predefined !== 7, })}>
              <Radio.Group onChange={changeValue} value={optionSelected.predefined} disabled={loading ? true : false}>
                <Space direction="vertical" className={styles.spaceGap}>
                  <Radio value={1}>Já consigo investir por conta própria</Radio>
                  <Radio value={2}>Vou investir com ajuda de outra plataforma</Radio>
                  <Radio value={3}>Não é exatamente o que eu esperava</Radio>
                  <Radio value={4}>Estou com problemas financeiros</Radio>
                  <Radio value={5}>Investimentos não são para mim</Radio>
                  <Radio value={6}>Acho a assinatura muito cara</Radio>
                  <Radio value={7}>Outro</Radio>
                </Space>
              </Radio.Group>
              {optionSelected.predefined === 7 && (
                <div className="flex flex-column mt-24">
                  <span className="f18 mine-shaft">Digite o motivo da exclusão</span>
                  <div className="pt-8">
                    <TextArea
                      rows={4}
                      placeholder="Digite aqui o motivo"
                      disabled={loading ? true : false}
                      onChange={changeValueOther}
                      className={styles.textArea}
                    />
                  </div>
                </div>
              )}
            </div>
            <Button
              type="default"
              className={styles.btnContinue}
              disabled={loading ? true : false}
              onClick={() => setVisible(!visible)}
            >
              Continuar
            </Button>
          </div>
        </div>
      </div>
      <ModalConfirm
        loading={loading}
        visible={visible}
        isMobile={isMobile}
        setVisible={setVisible}
        deleteMyAccount={deleteMyAccount}
      />
    </div>
  );
};

export default DeleteAccountForm;