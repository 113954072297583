// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileForm_badgeProfile__ZMp6T {\n  width: 358px;\n  border: 2px solid #F6F6F6;\n  border-radius: 12px;\n}\n.ProfileForm_btnSave__SOCzk {\n  width: 358px;\n  margin-top: 32px;\n}\n.ProfileForm_modalPasswordTitle__7UL1r {\n  width: 286px;\n  line-height: 29px;\n}\n.ProfileForm_btnDelete__a\\+mVc {\n  width: 180px;\n}\n@media only screen and (max-width: 767px) {\n  .ProfileForm_badgeProfile__ZMp6T {\n    width: 100%;\n  }\n  .ProfileForm_btnSave__SOCzk {\n    width: 312px;\n    margin-top: 32px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/ProfileForm/ProfileForm.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;EACA,gBAAA;AAAF;AAGA;EACE,YAAA;EACA,iBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE;IACE,WAAA;EAHF;EAMA;IACE,YAAA;IACA,gBAAA;EAJF;AACF","sourcesContent":[".badgeProfile {\n  width: 358px;\n  border: 2px solid #F6F6F6;\n  border-radius: 12px;\n}\n\n.btnSave {\n  width: 358px;\n  margin-top: 32px;\n}\n\n.modalPasswordTitle {\n  width: 286px;\n  line-height: 29px;\n}\n\n.btnDelete {\n  width: 180px;\n}\n\n@media only screen and (max-width: 767px) {\n  .badgeProfile {\n    width: 100%;\n  }\n\n  .btnSave {\n    width: 312px;\n    margin-top: 32px;\n  }\n}\n@primary-color: #8C52FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeProfile": "ProfileForm_badgeProfile__ZMp6T",
	"btnSave": "ProfileForm_btnSave__SOCzk",
	"modalPasswordTitle": "ProfileForm_modalPasswordTitle__7UL1r",
	"btnDelete": "ProfileForm_btnDelete__a+mVc"
};
export default ___CSS_LOADER_EXPORT___;
