import { useState } from 'react';
import { Switch, Button, Breadcrumb } from 'antd';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import moment from 'moment';
import Header from '../Header';
import CancelPlan from './CancelPlan';
import { message } from 'helpers/messages';
import { arckBlack, arckPremium, arckBeginner } from 'assets';
import { ROUTES } from 'routes/routes';
import styles from './SignaturesForm.module.less';

const SignaturesForm = ({ cancelSubscription, viewBeginner, activateRenewal, disableRenewal, setVisible, signaturesData, visible }) => {
  const [cancelData, setCancelData] = useState();
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 });

  const renderTypePlan = (plan) => {
    switch (plan) {
      case "arkc-beginner":
        return (
          <div className={styles.badgeBeginner}>
            <div className="flex flex-row item-center p-12">
              <img src={arckBeginner} alt="arck-beginner" />
              <span className="f18 mine-shaft b ml-8">Arkc Beginner</span>
            </div>
          </div>
        );
      case "arkc-premium":
        return (
          <div className={styles.badgePremium}>
            <div className="flex flex-row item-center p-12">
              <img src={arckPremium} alt="arck-premium" />
              <span className="f18 mine-shaft b ml-8">Arkc Premium</span>
            </div>
          </div>
        );
      case "arkc-black":
        return (
          <div className={styles.badgeBlack}>
            <div className="flex flex-row item-center p-12">
              <img src={arckBlack} alt="arck-black" />
              <span className="f18 white b ml-8">Arkc Black</span>
            </div>
          </div>
        );
    }
  };

  // const handleRenewalPlan = (checked, detail) => {
  //   if (!checked) {
  //     activateRenewal(detail.id);
  //   } else {
  //     disableRenewal(detail.id);
  //   };
  // };

  const handleCallCancel = (item) => {
    setCancelData(item);
    setVisible(!visible);
  };

  return (
    <div className={cx({ "mb-40": isMobile, "mb-88": !isMobile })}>
      <div className="mt-24 mb-24">
        <Header route={ROUTES.DASHBOARD} />
      </div>
      <hr className="divider-internal mb-24" />
      <div className="container">
        <div className="content">
          <div className={cx("flex", { "flex-column": isMobile, "flex-row justify-between item-center": !isMobile })}>
            <Breadcrumb separator="">
              <Breadcrumb.Item>Inicio</Breadcrumb.Item>
              <Breadcrumb.Separator>{">"}</Breadcrumb.Separator>
              <Breadcrumb.Item>Assinaturas</Breadcrumb.Item>
            </Breadcrumb>
            {/* <div className={styles.badgeBorder}>
              <div className="flex flex-row justify-betwenn item-center p-8">
                <div className={styles.badgeSelected}>
                  <div className="flex justify-center item-center h-100">
                    <span className="f14 b white">Assinaturas</span>
                  </div>
                </div>
                <div className={cx("flex flex-row justify-center item-center pointer", styles.widthPage)} onClick={() => history.push(ROUTES.CREDIT_CARDS)}>
                  <span className="f14 mine-shaft b">Meus cartões</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className={cx({ "mt-32": isMobile, "mt-24": !isMobile })}>
            {isMobile ? (
              <h2 className="text-center">Minhas assinaturas</h2>
            ) : (
              <h4>Minhas assinaturas</h4>
            )}
            <div className={cx({ [styles.horizontalScroll]: isTablet || isDesktop })}>
              <div className={cx("flex", { "flex-column item-center mt-32": isMobile, "flex-row item-center mt-24": !isMobile })}>
                {_map(signaturesData, (item, index) => (
                  <div key={index} className={cx({ "mb-24": isMobile && index !== _size(signaturesData) - 1, "mr-24": !isMobile && index !== _size(signaturesData) - 1 })}>
                    <div className={styles.badgePlan}>
                      <div className={styles.badgePadding}>
                        <div className="flex justify-center item-center mb-32">
                          {renderTypePlan(item.plan)}
                        </div>
                        <div className="flex flex-row justify-between item-center mb-24">
                          <div className="flex flex-column">
                            <span className="f16 mine-shaft b mb-4">Valor da assinatura</span>
                            <span className="f16 mine-shaft">{item.plan === "arkc-beginner" ? item.price : item.price}</span>
                          </div>
                          <div className="flex flex-column">
                            <span className="f16 mine-shaft b mb-4">Data da renovação</span>
                            <span className="f16 mine-shaft">
                              {item.plan === "arkc-beginner" ? "Sem renovação" : item.status ? moment(item.date).format("LL") : "Desativada"}
                            </span>
                          </div>
                        </div>
                        {item.status ? (
                          <div className={styles.badgeActive}>
                            <div className={styles.badgeActivePadding}>
                              <span className="f14 b silver-tree">Ativo</span>
                            </div>
                          </div>
                        ) : (
                          <div className={styles.badgeDisabled}>
                            <div className={styles.badgeDisabledPadding}>
                              <span className="f14 b mine-shaft">Desativado</span>
                            </div>
                          </div>
                        )}
                        <div className="mt-8 mb-24">
                          <div className="flex flex-row justify-between item-center">
                            <div className="flex flex-column">
                              <span className="f16 b mine-shaft mb-4">Renovação automática</span>
                              <span className="f14 gray">
                                {item.plan === "arkc-beginner" ? message.beginner_free : item.status ? message.renovation_plan : message.canceled_plan}
                              </span>
                            </div>
                            {/* {(item.status && item.plan !== "arkc-beginner") && (
                              <div>
                                <Switch
                                  checked={item.renovation}
                                  onChange={(checked) => handleRenewalPlan(checked, item)}
                                />
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className="flex flex-row justify-between item-center">
                          {item.plan === "arkc-beginner" ? (
                            <Button
                              type="default"
                              className={styles.btnActionsBeginner}
                              onClick={() => viewBeginner(item.wallet)}
                            >
                              Investimentos
                            </Button>
                          ) : (
                            <>
                              <Button
                                type="default"
                                disabled={!item.status}
                                className={styles.btnActions}
                                onClick={() => handleCallCancel(item)}
                              >
                                Cancelar
                              </Button>
                              <Button
                                type="default"
                                disabled={!item.status}
                                onClick={() => viewBeginner(item.wallet)}
                                className={styles.btnActions}
                              >
                                Investimentos
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CancelPlan
        id={_get(cancelData, "id", "")}
        visible={visible}
        plan={_get(cancelData, "plan", "")}
        isMobile={isMobile}
        setVisible={setVisible}
        cancelSubscription={cancelSubscription}
      />
    </div>
  );
};

export default SignaturesForm;