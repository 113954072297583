import { Modal } from 'antd';
import { FiX } from 'react-icons/fi';
import useMedia from 'use-media';

const PlanModal = ({ setVisible, visible }) => {
  const isMobile = useMedia({ maxWidth: 767 });

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => setVisible(!visible)}
      bodyStyle={{ height: "100%", padding: isMobile ? "24px" : "32px" }}
      className="modal-complete"
      centered={!isMobile ? true : false}
    >
      <div className="relative">
        <button className="arkc-btn-link absolute" onClick={() => setVisible(!visible)}>
          <FiX className="i-24" />
        </button>
      </div>
      <div className="text-center mt-32">
        <p className="f22 b black">Você não possui uma carteira assinada...</p>
        <p className="f18 mt-12">
          Que tal conferir nossos planos
          {" "}
          <span className="heliotrope b">Beginner</span>
          {", "}
          <span className="texas-rose b">Premium</span>
          {" "}
          e
          {" "}
          <span className="black b">Black</span>
          ?
        </p>
      </div>
    </Modal>
  );
};

export default PlanModal;