import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import TrailChallengeForm from 'components/TrailChallengeForm';
import { message } from 'helpers/messages';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';

const TrailChallenge = (props) => {
  const [loading, setLoading] = useState(false);
  const [lessonId, setLessonId] = useState();
  const [challengeIntro, setChallengeIntro] = useState();
  const [challengeData, setChallengeData] = useState([]);
  const [challengesTotal, setChallengesTotal] = useState({ finished: 0, total: 0, percentage: 0 });
  const { lessons } = useStore();
  const { challengeDetails } = lessons;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.id && state.challenge) {
      setLessonId(state.id);
      setChallengeIntro(state.challenge);
      retrieveChallengeDetails(state.id);
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveChallengeDetails = async (id) => {
    setLoading(true);

    const body = { trail: id };
    const response = await challengeDetails(body);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const questionsTotal = _size(response.data);
      let questionsFinished = 0;
      let actualPercentage = 0;
      let total = _size(response.data);
      let count = 0;

      _forEach(response.data, (item, index) => {
        if (item.completed) {
          count++;
          questionsFinished = index + 1;
          actualPercentage = (count * 100 / total).toFixed(2)
        } else {
          actualPercentage = actualPercentage + 0;
        };
      });

      setChallengeData(response.data);
      setChallengesTotal({ ...challengesTotal, finished: questionsFinished, total: questionsTotal, percentage: Number(actualPercentage).toFixed(2) });
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleDoWorkChallenge = (item) => {
    if (lessonId) {
      history.push(ROUTES.LESSON_CHALLENGE, { id: lessonId, identifier: item?.identifier, lessonType: "CHALLENGE", challenge: challengeIntro, challengesTotal: challengesTotal });
    };
  };

  const handleMyArea = () => {
    history.push(ROUTES.LESSON_DETAIL);
  };

  return (
    <div>
      <TrailChallengeForm
        challengeIntro={challengeIntro}
        challengeData={challengeData}
        challengesTotal={challengesTotal}
        doWorkChallenge={handleDoWorkChallenge}
        handleMyArea={handleMyArea}
      />
    </div>
  );
};

export default observer(TrailChallenge);