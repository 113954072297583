import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import Loading from 'components/Loading';
import LessonChallengeForm from 'components/LessonChallengeForm';
import { history } from 'helpers/history';
import { ROUTES } from 'routes/routes';
import { message } from 'helpers/messages';

const LessonChallenge = (props) => {
  const [loading, setLoading] = useState(true);
  const [lessonId, setLessonId] = useState();
  const [identifierLesson, setiIdentifierLesson] = useState();
  const [typeLesson, setTypeLesson] = useState();
  const [challengeData, setChallengeData] = useState();
  const [challenge, setChallenge] = useState();
  const [lessonCompleted, setLessonCompleted] = useState(false);
  const [saveValue, setSaveValue] = useState(0);
  const [questionValue, setQuestionValue] = useState();
  const [questionChoice, setQuestionChoice] = useState();
  const [totalChallenges, setTotalChallenges] = useState({ finished: 0, total: 0, percentage: 0 });
  const { lessons } = useStore();
  const { lessonRegisterProgress, challengeByProgress, lessonById } = lessons;

  useEffect(() => {
    const { state } = props?.history?.location;

    if (state && state.id && state.identifier && state.lessonType && state.challenge && state.challengesTotal) {
      setLessonId(state.id);
      setiIdentifierLesson(state.identifier);
      setTypeLesson(state.lessonType);
      setTotalChallenges(state.challengesTotal);
      setChallenge(state.challenge);
      retrieveLessonById(state.id, state.challenge.identifier, state.identifier);
    } else {
      history.push(ROUTES.DASHBOARD);
    };
  }, []);

  const retrieveLessonById = async (id, challengeIdentifier, identifier) => {
    setLoading(true);

    const response = await lessonById(id);
    const responseProgress = await challengeByProgress(id, challengeIdentifier, identifier);

    if (response.status === 200 && responseProgress.status === 200) {
      if (!_get(responseProgress.data, "completed", "") || !_get(responseProgress.data, "rewarded", "")) {
        const responseData = _get(response.data, "challenge", "");
        const contentChallenge = responseData.phases.find(item => item.identifier === identifier);
        const actualChallenge = _get(responseProgress.data, "phases", "") ? _get(responseProgress.data, "current", "") : _get(contentChallenge.content[0], "identifier", "");

        let totalLesson = 0;
        let percentageQuestion = (100 / _size(contentChallenge.content)).toFixed(2);
        let numberQuestions = actualChallenge

        _forEach(contentChallenge.content, (item, index) => {
          if (item.identifier === numberQuestions) {
            totalLesson = percentageQuestion * index;
          }
        });

        const newResponse = {
          nameLesson: _get(responseData, "name", ""),
          imageLesson: _get(responseData, "logo", ""),
          actualQuestion: actualChallenge,
          percentage: totalLesson,
          level: _get(response.data, "level", 0) + 1,
          color: _get(responseData, "color", ""),
          trailQuestions: _get(contentChallenge, "content", [])
        };

        setChallengeData(newResponse);
        setLoading(false);
      } else {
        const responseData = _get(response.data, "challenge", "");
        const contentChallenge = responseData.phases.find(item => item.identifier === identifier);
        const actualChallenge = _get(contentChallenge?.content[0], "identifier", "");

        let totalLesson = 0;
        let percentageQuestion = (100 / _size(contentChallenge.content)).toFixed(2);
        let numberQuestions = actualChallenge

        _forEach(contentChallenge.content, (item, index) => {
          if (item.identifier === numberQuestions) {
            totalLesson = percentageQuestion * index;
          }
        });

        const newResponse = {
          nameLesson: _get(responseData, "name", ""),
          imageLesson: _get(responseData, "logo", ""),
          actualQuestion: actualChallenge,
          percentage: totalLesson,
          level: _get(response.data, "level", 0) + 1,
          color: _get(responseData, "color", ""),
          trailQuestions: _get(contentChallenge, "content", [])
        };

        setLessonCompleted(_get(responseProgress.data, "rewarded", false));
        setChallengeData(newResponse);
        setLoading(false);
      };
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setLoading(false);
    };
  };

  const handleBackDetails = () => {
    history.push(ROUTES.CHALLENGE_LESSON, { id: lessonId, challenge: challenge });
  };

  const handleRegisterMyProgress = async (values) => {
    const body = {
      trail: lessonId,
      lesson: identifierLesson,
      content: values.identifier,
      type: typeLesson ? typeLesson : "CHALLENGE",
      choice: {
        identifier: values.identifier,
        next: values.next
      }
    };

    const response = await lessonRegisterProgress(lessonId, identifierLesson, body);

    if (response.status !== 204) {
      retrieveLessonById(lessonId, challenge?.identifier, identifierLesson);
    } else {
      window.scrollTo(0, 0);
    };
  };

  const handleRegisterMyProgressChoices = async (values, questionChoice, questionValue) => {
    const body = {
      trail: lessonId,
      lesson: identifierLesson,
      content: values?.identifier,
      type: typeLesson ? typeLesson : "CHALLENGE",
      choice: {
        identifier: questionChoice?.identifier,
        next: questionChoice?.next,
        value: Number(questionValue)
      }
    };

    const response = await lessonRegisterProgress(lessonId, identifierLesson, body);

    if (response.status !== 204) {
      retrieveLessonById(lessonId, challenge?.identifier, identifierLesson);
      setQuestionValue("");
      setQuestionChoice("");
    } else {
      if (Number(questionValue)) {
        const calculation = saveValue + Number(questionValue);
        setSaveValue(calculation);
      };

      setQuestionValue("");
      setQuestionChoice("");
      window.scrollTo(0, 0);
    };
  };

  const handleFinishMyArea = () => {
    setLoading(true);

    setTimeout(() => {
      history.push(ROUTES.CHALLENGE_LESSON, { id: lessonId, challenge: challenge });
      setLoading(false);
    }, 2000);
  };

  const handleMyArea = () => {
    history.push(ROUTES.LESSON_DETAIL);
  };

  return (
    <div>
      {loading ? (
        <div className="h-100vh w-100 flex justify-center item-center">
          <Loading />
        </div>
      ) : (
        <LessonChallengeForm
          challengeData={challengeData}
          lessonCompleted={lessonCompleted}
          questionValue={questionValue}
          questionChoice={questionChoice}
          saveValue={saveValue}
          totalChallenges={totalChallenges}
          setQuestionValue={setQuestionValue}
          setQuestionChoice={setQuestionChoice}
          backDetails={handleBackDetails}
          registerMyProgress={handleRegisterMyProgress}
          registerMyProgressChoices={handleRegisterMyProgressChoices}
          finishMyArea={handleFinishMyArea}
          handleMyArea={handleMyArea}
        />
      )}
    </div>
  );
};

export default observer(LessonChallenge);