import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks/useStores';
import { notification } from 'antd';
import { FiX } from 'react-icons/fi';
import { message } from 'helpers/messages';
import RecoverPasswordForm from 'components/RecoverPasswordForm';
import Footer from 'components/Footer';

const RecoverPassword = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { login } = useStore();
  const { recoverPasswordAccount } = login;

  const handleRecoverPassword = async (params) => {
    const response = await recoverPasswordAccount(params);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
      setSuccess(true);
      setLoading(false);
    } else {
      notification.error({
        message: message.was_problem,
        description: response.message,
        placement: "bottom",
        duration: 4,
        closeIcon: <FiX className="i-24 white" />
      });
      setSuccess(false);
      setLoading(false);
    };
  };

  return (
    <div>
      <RecoverPasswordForm
        success={success}
        loading={loading}
        setLoading={setLoading}
        passwordRecover={handleRecoverPassword}
      />
      <Footer />
    </div>
  );
};

export default observer(RecoverPassword);