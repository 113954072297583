import { Button } from 'antd';
import { FaLock, FaPlay } from 'react-icons/fa';
import _map from 'lodash/map';
import _size from 'lodash/size';
import cx from 'classnames';
import useMedia from 'use-media';
import AccordionArkc from '../../AccordionArkc';
import { logoBeginner, logoColor, supportArkc, education, funy, opportunitiesBeginner } from 'assets';
import styles from '../LandingPageForm.module.less';

const LandingPageThreeForm = ({ startNow, lessons, topicsLP, supportQuestions, graphicImage, benefits, arkcThree }) => {
  const isMobile = useMedia({ maxWidth: 767 });
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMedia({ minWidth: 1024, maxWidth: 1365 });

  return (
    <div>
      <div className={cx("container", styles.headerLP)}>
        <div className="content mt-24 mb-24">
          <div className="flex flex-row">
            <div className="flex justify-center item-center w-100">
              <img src={logoColor} alt="logo-color" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.imageBackground}>
        <img src={logoBeginner} alt="logbeginner" className={styles.imageBgSize} />
      </div>
      <div className="flex flex-column justify-center item-center mt-82">
        <h2 className={cx("text-center mt-24 mb-24", styles.titleLP)}>
          {arkcThree.titleLP}
        </h2>
        <p className={cx("f18 text-center mb-40", styles.descriptionLP)}>
          {arkcThree.descriptionLP}
        </p>
        <Button type="primary" className={styles.btnSign} onClick={startNow}>Começar</Button>
        <div className="container">
          <div className="content">
            <div className="mt-80 mb-80">
              <div className={cx({ [styles.horizontalScroll]: isMobile, [styles.gridBenefits]: !isMobile })}>
                {_map(benefits, (item, index) => (
                  <div key={index} className={cx(styles.cardLP, { "mr-24": (isMobile || isTablet) && index !== _size(benefits) - 1, "mr-30": !isMobile && !isTablet && index !== _size(benefits) - 1 })}>
                    <div className="p-24">
                      <div className="mb-12">
                        <div className={styles.bgIcon}>
                          <div className="flex justify-center item-center h-100">
                            <img src={item.path} alt="benefits-plan" />
                          </div>
                        </div>
                      </div>
                      <span className="f18 b mine-shaft">{item.title}</span>
                      <p className={cx("f16 mine-shaft mt-6", styles.cardDescription)}>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="content">
            <div className={cx({ "flex flex-column": isMobile || isTablet, "flex flex-row": !isMobile && !isTablet })}>
              <div className={styles.space}>
                <h2 className={cx("mine-shaft", styles.titleMission, { "f48 mb-48": isMobile, "f56 mb-48": isTablet, "f56 mb-24": !isMobile && !isTablet })}>
                  {arkcThree.apprenticeshipTitle}
                </h2>
                {(isMobile || isTablet) && (
                  <img src={education} alt="education" className={styles.imageEducation} />
                )}
                <p className={cx("f24 fw4 gray", styles.p1, { "mb-16": isMobile, "mb-24": !isMobile })}>
                  {arkcThree.apprenticeshipDescriptionOne}
                </p>
                <p className={cx("f24 fw4 gray", styles.p1, { "mb-16": isMobile, "mb-24": !isMobile })}>
                  {arkcThree.apprenticeshipDescriptionTwo}
                </p>
              </div>
              {(!isMobile && !isTablet) && (
                <img src={education} alt="education" className={styles.imageEducation} />
              )}
            </div>
            <div className={cx({ "mb-24": isMobile || isTablet, "mt-48": !isTablet })}>
              <div className={cx({ "flex flex-column": isMobile || isTablet, "flex flex-row": !isMobile && !isTablet })}>
                <div className={cx("flex justify-center item-center", styles.funyContainer)}>
                  <img src={funy} alt="funy" className={styles.imageFuny} />
                </div>
                <div className={styles.space}>
                  <h2 className={cx("mine-shaft mb-16", styles.titleFuny, { "f40": isMobile, "f56": !isMobile })}>
                    {arkcThree.startInvestingTitle}
                  </h2>
                  <p className={cx("f24 gray", styles.p1, { "mb-32": isMobile, "mb-40": !isMobile })}>
                    {arkcThree.startInvestingDescriptionOne}
                  </p>
                  <p className={cx("f24 gray", styles.p1, { "mb-32": isMobile, "mb-40": !isMobile })}>
                    {arkcThree.startInvestingDescriptionTwo}
                  </p>
                  <Button type="primary" className={styles.startNow} onClick={startNow}>Começar agora</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bgMagnolia}>
          <div className="container mt-40 mb-40">
            <div className="content">
              <div className={cx("flex", { "flex-column": isMobile || isTablet, "flex-row": !isMobile && !isTablet })}>
                <div className={cx({ "flex flex-column justify-center item-center": isMobile || isTablet })}>
                  {isMobile ? (
                    <>
                      <h6 className="fw4 mine-shaft mb-24">{arkcThree.profitTitle}</h6>
                      <h2 className={cx("mb-16", styles.historyLP)}>{arkcThree.profitDescription}</h2>
                    </>
                  ) : (
                    <>
                      <h5 className="mine-shaft mb-24">{arkcThree.profitTitle}</h5>
                      <h3 className={cx("mb-48", styles.historyLP)}>{arkcThree.profitDescription}</h3>
                    </>
                  )}
                  <div className={cx("mb-16", styles.badgeProfitability)}>
                    <div className="flex justify-center item-center h-100">
                      <span className="f16 b white">{arkcThree.profitMarginOne}</span>
                    </div>
                  </div>
                  <div className={styles.badgeSavings}>
                    <div className="flex justify-center item-center h-100">
                      <span className="f16 b mine-shaft">{arkcThree.profitMarginTwo}</span>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.bgGraphic, { "mt-40": isMobile || isTablet, "ml-66": !isMobile && !isTablet && !isDesktop })}>
                  <div className={styles.paddingGraphic}>
                    {isMobile ? (
                      <div className="flex flex-row justify-between item-center mb-40">
                        <p className="f16 mine-shaft">{arkcThree.marginInvestmentOne}</p>
                        <h6 className="f16 heliotrope">{arkcThree.marginInvestmentTwo}</h6>
                      </div>
                    ) : (
                      <>
                        <h5 className="heliotrope">{arkcThree.marginInvestmentOne}</h5>
                        <p className="f18 b mine-shaft mt-12">{arkcThree.marginInvestmentTwo}</p>
                      </>
                    )}
                    <div className={cx({ "flex justify-center item-center": isTablet, "mt-32": !isMobile && !isTablet })}>
                      <img src={graphicImage} alt="graphic" className={styles.graphicImage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("flex justify-center item-center", { "flex-column mt-64 mb-64": isMobile || isTablet, "flex-row mt-120 mb-120": !isMobile && !isTablet })}>
          <div className={cx({ "mb-40": isMobile || isTablet, "mr-78": !isMobile && !isTablet })}>
            <img src={opportunitiesBeginner} alt="opportunities-beginner" className={styles.opportunitiesImage} />
          </div>
          <div className={cx({ "flex flex-column justify-center item-center": isMobile })}>
            {isMobile ? (
              <h3 className={cx("mb-12", styles.opportunitiesTitle)}>{arkcThree.liveTitle}</h3>
            ) : (
              <h4 className={cx("mb-24", styles.opportunitiesTitle)}>{arkcThree.liveTitle}</h4>
            )}
            <p className={cx("f24", styles.opportunitiesDescription, { "mb-24": isMobile || isTablet, "mb-40": !isMobile && !isTablet })}>
              {arkcThree.liveDescription}
            </p>
            <Button type="primary" className={styles.btnSignTwo} onClick={startNow}>Começar</Button>
          </div>
        </div>
        <div className={styles.badgeWorks}>
          <div className={styles.paddingBadge}>
            <div className={cx("flex flex-column", { "ml-32 mr-32": isMobile, "ml-24 mr-24": isTablet, "justify-center item-center": !isMobile })}>
              <span className={cx("f14 heliotrope b mb-8", { "mt-56": !isMobile })}>Invista na prática</span>
              <h2 className={cx("mine-shaft", styles.titlePort, { "f40 mb-16": isMobile, "text-center mb-8": !isMobile })}>
                {arkcThree.investingNowTitle}
              </h2>
              <p className={cx("f24 fw4 gray mb-40", styles.p1, { "text-center": !isMobile })}>
                {arkcThree.investingNowDescription}
              </p>
              <div className={styles.gridContainer}>
                {_map(topicsLP, (item, index) => (
                  <div key={index} className={styles.cardBase}>
                    <div className={cx({ "p-24": isMobile || isTablet, "p-32": !isMobile && !isTablet })}>
                      <div className={styles.backgroundTopic}>
                        <span className="f16 fw5 white">{item.topic}</span>
                      </div>
                      <p className={cx("f18 fw4 black mt-16", styles.descriptionTopic)}>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-center item-center mt-32">
                <Button type="primary" className={styles.startNow} onClick={startNow}>Começar agora</Button>
              </div>

            </div>
          </div>
        </div>
        <div className="container">
          <div className="content">
            <div className="flex flex-column justify-center item-center mt-24">
              <img src={supportArkc} alt="support-arkc" />
              {isMobile ? (
                <h2 className="mt-16 mb-8">{arkcThree.forMeTitle}</h2>
              ) : (
                <h4 className="mt-16 mb-8">{arkcThree.forMeTitle}</h4>
              )}
              <h5 className="mt-40 mb-24">{arkcThree.forMeQuestions}</h5>
              <div className={styles.gridAccordions}>
                {_map(supportQuestions, (item, index) => (
                  <AccordionArkc key={index} title={item.title}>
                    <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.description}</p>
                    {item.descriptionSub && (
                      <p className={cx("f16 gray mt-8", styles.descriptionAccordion)}>{item.descriptionSub}</p>
                    )}
                  </AccordionArkc>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={cx("flex flex-column justify-center item-center", { "mt-64 mb-24": isMobile || isTablet, "mt-72 mb-80": !isMobile && !isTablet })}>
          <span className="f18 melrose mb-8">Aprenda a investir com o Arkc Beginner</span>
          {isMobile ? (
            <h1 className={cx("text-center mb-24", styles.titlePlanLP)}>Investimentos</h1>
          ) : (
            <h2 className={cx("text-center mb-32", styles.titlePlanLP)}>Investimentos</h2>
          )}
          {(isMobile || isTablet) ? (
            <div className="flex justify-center item-center w-100">
              <div className={cx({ [styles.containerLessons]: isMobile || isTablet })}>
                {_map(lessons, (item, index) => {
                  if (item.available) {
                    return (
                      <div key={index} className={styles.cardEmphasis}>
                        <button className={cx("arkc-btn-link", { "mr-16": index !== _size(lessons) - 1 })}>
                          <div className={styles.cardLessons}>
                            <div className="p-12 h-100">
                              {(item.available && item.total >= 0) && (
                                <div className="relative">
                                  <div className={styles.positionPercentage}>
                                    <div className={styles.bgPercentage}>
                                      <div className="flex justify-center item-center">
                                        <span className="f12 b white">{`${item.total}%`}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className={styles.bgImage} style={{ background: item.color }}>
                                <div className="flex justify-center item-center h-100">
                                  <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                                </div>
                              </div>
                              <div className="flex flex-column justify-center item-center">
                                <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                                <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                                <button className="arkc-btn-link arkc-btn-play">
                                  <FaPlay className="i-16 white" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  }

                  return (
                    <div key={index} className={styles.cardEmphasis}>
                      <div className={cx(styles.cardLessons, { "mr-16": index !== _size(lessons) - 1 })}>
                        <div className="p-12 h-100">
                          {(item.available && item.total >= 0) && (
                            <div className="relative">
                              <div className={styles.positionPercentage}>
                                <div className={styles.bgPercentage}>
                                  <div className="flex justify-center item-center">
                                    <span className="f12 b white">{`${item.total}%`}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className={styles.bgImage} style={{ background: item.color }}>
                            <div className="flex justify-center item-center h-100">
                              <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                            </div>
                          </div>
                          <div className="flex flex-column justify-center item-center">
                            <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                            <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                            <button className="arkc-btn-link arkc-btn-blocked">
                              <FaLock className="i-16 silver" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="content">
                <div className={styles.gapLessons}>
                  {_map(lessons, (item, index) => {
                    if (item.available) {
                      return (
                        <div key={index} className={styles.cardEmphasis}>
                          <button className="arkc-btn-link">
                            <div className={styles.cardLessons}>
                              <div className="p-12 h-100">
                                {(item.available && item.total >= 0) && (
                                  <div className="relative">
                                    <div className={styles.positionPercentage}>
                                      <div className={styles.bgPercentage}>
                                        <div className="flex justify-center item-center">
                                          <span className="f12 b white">{`${item.total}%`}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className={styles.bgImage} style={{ background: item.color }}>
                                  <div className="flex justify-center item-center h-100">
                                    <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                                  </div>
                                </div>
                                <div className="flex flex-column justify-center item-center">
                                  <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                                  <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                                  <button className="arkc-btn-link arkc-btn-play">
                                    <FaPlay className="i-16 white" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    }

                    return (
                      <div key={index} className={cx("pointer", styles.cardEmphasis)}>
                        <div className={styles.cardLessons}>
                          <div className="p-12 h-100">
                            {(item.available && item.total >= 0) && (
                              <div className="relative">
                                <div className={styles.positionPercentage}>
                                  <div className={styles.bgPercentage}>
                                    <div className="flex justify-center item-center">
                                      <span className="f12 b white">{`${item.total}%`}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className={styles.bgImage} style={{ background: item.color }}>
                              <div className="flex justify-center item-center h-100">
                                <img src={item.logo} alt="logo-lesson" className={styles.imageLessons} />
                              </div>
                            </div>
                            <div className="flex flex-column justify-center item-center">
                              <span className="f16 mine-shaft mt-20 mb-2 b">{item.name}</span>
                              <span className="f14 dusty-gray mb-20">{`Nível ${item.level}`}</span>
                              <button className="arkc-btn-link arkc-btn-blocked">
                                <FaLock className="i-16 silver" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className={cx("flex flex-column justify-center item-center mt-12")}>
            {!isMobile && (
              <span className="f18 mine-shaft mb-2">100%</span>
            )}
            <h2 className={cx("melrose mb-24", { "f56 mt-24": isMobile })}>Gratuito</h2>
            <Button type="primary" className={styles.btnSize} onClick={startNow}>Começar</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageThreeForm;