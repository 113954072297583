import { useState, useRef } from 'react';
import useMedia from 'use-media';
import { FiChevronDown } from 'react-icons/fi';
import cx from 'classnames'
import styles from './AccordionArkc.module.less';

const AccordionArkc = ({ children, isDark, title }) => {
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState(styles.accordionIcon);
  const content = useRef(null);
  const isMobile = useMedia({ maxWidth: 767 });

  const toggleAccordion = () => {
    const bottomDescription = isMobile ? 12 : 24;
    setActive(active === "" ? "active" : "");
    setHeight(active === "active" ? "0px" : `${content.current.scrollHeight + bottomDescription}px`);
    setRotate(active === "active" ? styles.accordionIcon : cx(styles.accordionIcon, styles.rotate));
  };

  return (
    <div className="flex flex-column">
      <button className={cx(styles.accordion, { [styles.accordionDark]: isDark, [styles.accordionActive]: active === "active" })} onClick={toggleAccordion}>
        <div className="p-16">
          <div className="flex flex-row justify-between item-center w-100">
            <p className={cx("f16 b", { "melrose": isDark, "mine-shaft": !isDark })}>{title}</p>
            <FiChevronDown className={cx("i-16 heliotrope", rotate)} />
          </div>
          <div ref={content} style={{ maxHeight: `${height}` }} className={styles.accordionContent}>
            {children}
          </div>
        </div>
      </button>
    </div>
  );
};

export default AccordionArkc;